import styled, { css } from 'styled-components';
import Tippy from '@tippyjs/react';
import Icon from 'koddi-components/Icon';
import { HorizontalFlexBox } from 'koddi-components/FlexBox';

export const TooltipIcon = styled(Icon)<{
    isDisabled?: boolean;
    isPrimaryColor?: boolean;
    color?: string;
}>`
    ${({ theme, isPrimaryColor, isDisabled, color }) => css`
        display: flex;
        color: ${color || (isPrimaryColor ? theme.primary : theme.gray)};
        &:hover {
            color: ${isDisabled ? undefined : theme.black};
            cursor: ${isDisabled ? 'not-allowed' : 'pointer'};
        }
    `}
`;

export const TooltipContainer = styled.span<{
    margin?: string;
}>`
    ${({ theme, margin }) => css`
        display: inline-flex;
        &:focus {
            outline: none;
        }
        &:focus ${TooltipIcon} {
            color: ${theme.black};
        }
        margin: ${margin};
    `}
`;

export const IconTooltipWrapperBase = styled(HorizontalFlexBox)``;

export const TippyTooltip = styled(Tippy)`
    ${({ theme }) => css`
        &.koddi-tooltip[data-animation='fade'][data-state='hidden'] {
            opacity: 0;
        }
        &.koddi-tooltip[data-animation='fade'][data-state='visible'] {
            opacity: 1;
        }
        &.koddi-tooltip {
            max-width: 100%;
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
            padding: ${theme.spaceUnit * 4}px;
            font-size: 12px;
        }
        &.koddi-tooltip-default {
            background: #ffffff;
        }
        &.koddi-tooltip-default .tippy-content {
            color: #000000;
        }
        &.koddi-tooltip-fancy {
            background: #252830;
            border-radius: 4px;
        }
        &.koddi-tooltip-fancy .tippy-content {
            color: #ffffff;
        }
        &.koddi-tooltip-warning {
            background: #ffffff;
        }

        &.koddi-tooltip-fancy[data-placement='top'] .tippy-arrow {
            width: 0;
            height: 0;
            border-left: ${theme.tooltip.arrowSize}px solid transparent;
            border-right: ${theme.tooltip.arrowSize}px solid transparent;

            border-top: ${theme.tooltip.arrowSize}px solid #252830;
            bottom: -${theme.tooltip.arrowSize}px;
        }
        &.koddi-tooltip-fancy[data-placement='bottom'] .tippy-arrow {
            width: 0;
            height: 0;
            border-left: ${theme.tooltip.arrowSize}px solid transparent;
            border-right: ${theme.tooltip.arrowSize}px solid transparent;

            border-bottom: ${theme.tooltip.arrowSize}px solid #252830;
            top: -${theme.tooltip.arrowSize}px;
        }
        &.koddi-tooltip-fancy[data-placement='right'] .tippy-arrow {
            width: 0;
            height: 0;
            border-top: ${theme.tooltip.arrowSize}px solid transparent;
            border-bottom: ${theme.tooltip.arrowSize}px solid transparent;

            border-right: ${theme.tooltip.arrowSize}px solid #252830;
            left: -${theme.tooltip.arrowSize}px;
        }
        &.koddi-tooltip-fancy[data-placement='left'] .tippy-arrow {
            width: 0;
            height: 0;
            border-top: ${theme.tooltip.arrowSize}px solid transparent;
            border-bottom: ${theme.tooltip.arrowSize}px solid transparent;

            border-left: ${theme.tooltip.arrowSize}px solid #252830;
            right: -${theme.tooltip.arrowSize}px;
        }
        word-wrap: break-word;
    `}
`;

export const TooltipChildren = styled.div`
    max-width: 100%;
    cursor: pointer;
`;
