import { ReduxActionWithPayload } from 'types';
import {
    PaymentMethod,
    StripeBillingAddress,
} from 'react-ui/api/Advertiser/Advertiser.types';

export type Payload<PayloadType> = {
    payload: PayloadType;
};

export type AdvertiserPaymentMethodsState = PaymentMethod[];

export type Address = StripeBillingAddress;

export type StripeBillingAddressWithName = StripeBillingAddress & {
    name: string | null;
    email: string | null;
};

export type StripeBillingAddressWithCustomer = {
    name: string;
    email: string;
    stripe_customer_id?: string;
    address: StripeBillingAddress;
    tax_type?: string | null;
    tax_id?: string | null;
};

export type AddPaymentMethodFormDefaultBillingAddress = StripeBillingAddressWithCustomer;

type EditBillingAddressNotNullModal = {
    showModal: true;
    saveAction: AddressSaveActions;
    address: StripeBillingAddressWithCustomer;
    tax_type?: string | null;
    tax_id?: string | null;
};

type AddressSaveActions = 'UPDATE_ADVERTISER_DEFAULT_ADDRESS' | null;

type EditBillingAddressModalNull = {
    showModal: false;
    saveAction: AddressSaveActions;
    address: StripeBillingAddressWithCustomer | null;
};

export type EditBillingAddressModal =
    | EditBillingAddressModalNull
    | EditBillingAddressNotNullModal;

export type EditPaymentModalState = {
    showModal: boolean;
    paymentMethodId: string | null;
};

type AddPaymentModalNotNullState = {
    showModal: true;
    isDefaultPaymentMethod: boolean;
    defaultBillingAddress: StripeBillingAddressWithCustomer;
    otherBillingAddress: StripeBillingAddressWithCustomer | null;
    selectedBillingAddress: 'default' | 'other';
};

type AddPaymentModalNullState = {
    showModal: boolean;
    isDefaultPaymentMethod: boolean;
    defaultBillingAddress: StripeBillingAddressWithCustomer | null;
    otherBillingAddress: StripeBillingAddressWithCustomer | null;
    selectedBillingAddress: 'default' | 'other';
};

export type AddPaymentModalState =
    | AddPaymentModalNullState
    | AddPaymentModalNotNullState;

export type AdvertiserPaymentState = {
    addPaymentModal: AddPaymentModalState;
    editPaymentModal: EditPaymentModalState;
    billingAddressModal: EditBillingAddressModal;
    selectedPaymentMethod: PaymentMethod | null;
    paymentMethods: AdvertiserPaymentMethodsState;
    addFundsAmount: null | string | number;
};

export type LoadAdvertiserPaymentMethodsAction = ReduxActionWithPayload<
    AdvertiserPaymentMethodAction.FETCH,
    LoadPaymentMethodParams
>;

export type AdvertiserPaymentMethodsLoadedAction = ReduxActionWithPayload<
    AdvertiserPaymentMethodAction.LOADED,
    PaymentMethod[]
>;

export type DeletePaymentMethodParams = {
    paymentMethodId: string;
    memberGroupId: number;
    advertiserId: number;
    confirm: boolean;
};

export type DeletePaymentMethodActionType = ReduxActionWithPayload<
    AdvertiserPaymentMethodAction.DELETE_PAYMENT_METHOD,
    DeletePaymentMethodParams
>;

export type LoadPaymentMethodParams = {
    memberGroupId: number;
    advertiserId: number;
};

export enum AdvertiserPaymentMethodAction {
    FETCH = 'admin/advertisers/paymentMethods/FETCH',
    LOADED = 'admin/advertisers/paymentMethods/LOADED',
    LOAD_FAILED = 'admin/advertisers/paymentMethods/LOAD_FAILED',
    CLEAR = 'admin/advertisers/paymentMethods/CLEAR',
    DELETE_PAYMENT_METHOD = 'admin/advertisers/paymentMethods/DELETE_PAYMENT_METHOD',
}

export type SetDefaultPaymentMethodParams = boolean;
export type SetDefaultPaymentMethodActionType = ReduxActionWithPayload<
    AddPaymentMethodFormAction.SET_DEFAULT_PAYMENT_METHOD,
    SetDefaultPaymentMethodParams
>;

export type SetDefaultBillingAddressActionType = ReduxActionWithPayload<
    AddPaymentMethodFormAction.SET_DEFAULT_BILLING_ADDRESS,
    StripeBillingAddressWithCustomer
>;

export type SetOtherBillingAddressParams = Address | null;
export type SetOtherBillingAddressActionType = ReduxActionWithPayload<
    AddPaymentMethodFormAction.SET_OTHER_BILLING_ADDRESS,
    StripeBillingAddressWithCustomer | null
>;

export type SetSelectedBillingAddressParams = 'default' | 'other';
export type SetSelectedBillingAddressActionType = ReduxActionWithPayload<
    AddPaymentMethodFormAction.SET_SELECTED_BILLING_ADDRESS,
    SetSelectedBillingAddressParams
>;

export enum AddPaymentMethodFormAction {
    SET_DEFAULT_PAYMENT_METHOD = 'admin/advertiserPayment/AddPaymentForm/SET_DEFAULT_PAYMENT_METHOD',
    SET_DEFAULT_BILLING_ADDRESS = 'admin/advertiserPayment/AddPaymentForm/SET_DEFAULT_BILLING_ADDRESS',
    SET_OTHER_BILLING_ADDRESS = 'admin/advertiserPayment/AddPaymentForm/SET_OTHER_BILLING_ADDRESS',
    SET_SELECTED_BILLING_ADDRESS = 'admin/advertiserPayment/AddPaymentForm/SET_SELECTED_BILLING_ADDRESS',
}

export type AddPaymentMethodFormActions = SetDefaultPaymentMethodActionType;

export type AdvertiserPaymentMethodActions =
    | LoadAdvertiserPaymentMethodsAction
    | AdvertiserPaymentMethodsLoadedAction
    | DeletePaymentMethodActionType;

export enum AddressModalAction {
    SHOW_MODAL = 'show_edit_billing_address_modal',
    CLOSE_MODAL = 'close_edit_billing_address_modal',
}

export type ShowAddressModalParams = {
    saveAction: AddressSaveActions;
    address: StripeBillingAddressWithCustomer;
};
export type ShowAddressModalActionType = ReduxActionWithPayload<
    AddressModalAction.SHOW_MODAL,
    EditBillingAddressModal
>;

export enum EditPaymentModalAction {
    SHOW_MODAL = 'show_edit_payment_modal',
    CLOSE_MODAL = 'close_edit_payment_modal',
}
