/* eslint-disable no-unused-vars */
import styled, { css } from 'styled-components';

import Card, { CardContent, CardWrapper } from 'koddi-components/Card';
import { VerticalFlexBox, HorizontalFlexBox } from 'koddi-components/FlexBox';
import { Heading2, Heading4 } from 'koddi-components/Typography';

export const WizardHeaderWrapper = styled(VerticalFlexBox)<{
    reducedHeight?: boolean;
}>`
    ${({ reducedHeight }) => css`
        ${reducedHeight && 'margin-top: -45px;'}
        height: 90px;
        flex-direction: row;
    `}
`;

export const WizardSpinnerWrapper = styled(HorizontalFlexBox)`
    width: 100%;
    @media screen and (max-width: 1200px) {
        width: 56%;
    }
`;

export const WizardContentWrapper = styled(VerticalFlexBox)<{
    isStepThree?: boolean;
}>`
    ${({ isStepThree }) => css`
        max-height: ${isStepThree ? 'auto' : 'calc(100vh - 310px)'};
            @media (max-width: 1200px) {
                margin-top: 2%;
                }
            }
        `}
`;

export const WizardContent = styled(HorizontalFlexBox)`
    ${({ theme }) => css`
        background-color: ${theme.grayLightest};
        min-height: 300px;
        border-radius: 4px;
        padding: 30px;
        overflow: auto;
        @media screen and (max-width: 1200px) {
            flex-direction: row;
            flex-wrap: wrap;
            }
        }
    `}
`;

export const WizardContentFormWrapper = styled.div`
    padding: 20px;
    width: 100%;
    > form {
        height: 100%;
    }
    @media screen and (max-width: 1200px) {
        width: 70%;
    }
`;

export const WizardDetails = styled(VerticalFlexBox)<{ isStepFive?: boolean }>`
    ${({ isStepFive, theme }) => css`
        width: 30%;
        padding: 20px 28px 20px 28px;
        border-right: 1px solid ${theme.grayLight};
        @media screen and (max-width: 1200px) {
            height: ${isStepFive ? '645px' : 'auto'};
            overflow: auto;
        }
    `}
`;

export const StepFourWrapper = styled.div`
    width: 50%;
    ${CardWrapper} {
        min-width: 300px;
    }
    @media screen and (max-width: 1200px) {
        width: 360px;
    }
`;

export const WizardHelpText = styled.div`
    ${({ theme }) => css`
        display: grid;
        color: ${theme.primary};
        padding-top: 30px;
        padding-bottom: 30px;
        h3 {
            margin-bottom: 5px;
        }
        button {
            color: ${theme.primary};
            padding: 0px;
            &:hover {
                color: ${theme.primary};
            }
            margin-top: 5px;
        }
    `}
`;

export const WizardTitle = styled(Heading2)`
    ${({ theme }) => css`
        color: ${theme.primary};
        font-weight: 600;
    `}
`;
export const WizardDescription = styled.div`
        max-width: 280px;
        margin-top: 20px;
        @media screen and (max-width: 1200px) {
            max-width: 100%;
            }
        }
`;

export const WizardStepper = styled(HorizontalFlexBox)`
    justify-content: flex-end;
    max-width: 800px;
    width: 100%;
`;

export const StepperStep = styled(VerticalFlexBox)<{
    activeStep: boolean;
    pastStep: boolean;
}>`
    ${({ theme, activeStep, pastStep }) => css`
        align-items: center;
        max-width: 160px;
        position: relative;
        &:before,
        &:after {
            position: absolute;
            top: 0;
            left: 0;
            content: '';
            width: calc(50% - 12.4px);
            height: 4px;
            background: ${theme.primary};
            margin: 10px auto 0;
        }
        &:before {
            opacity: ${pastStep || activeStep ? 1 : 0.5};
        }
        &:after {
            right: 0;
            left: initial;
            opacity: ${pastStep ? 1 : 0.5};
        }
        &:first-of-type {
            &:before {
                content: none;
            }
        }
        &:last-of-type {
            &:after {
                content: none;
            }
        }
    `}
`;
export const StepperNumber = styled.div<{
    clickable?: boolean;
    faded?: boolean;
}>`
    ${({ theme, clickable, faded }) => css`
        background: ${theme.primary};
        border-radius: 20px;
        color: white;
        width: 25px;
        height: 25px;
        text-align: center;
        padding: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        z-index: 1;
        opacity: ${faded ? 0.5 : 1};
        cursor: ${clickable ? 'pointer' : 'initial'};
    `}
`;
export const StepperLabel = styled.div<{ faded?: boolean }>`
    ${({ theme, faded }) => css`
        margin-top: 10px;
        text-align: center;
        color: ${theme.primary};
        opacity: ${faded ? 0.6 : 1};
    `}
`;
export const StepperBar = styled.div<{ faded: boolean }>`
    ${({ theme, faded }) => css`
        height: 4px;
        background: ${theme.primary};
        margin-top: 10px;
        z-index: -1;
        opacity: ${faded ? 0.3 : 1};
    `}
`;

export const WizardFooter = styled(VerticalFlexBox)`
    align-items: end;
    justify-content: center;
    margin-top: 15px;
`;

export const WizardCard = styled(Card)<any>`
    ${({ theme, height }) => css`
        background-color: ${theme?.white};
        border: 1px solid ${theme.grayLight};
        width: 300px;
        min-height: ${height || undefined};
        ${CardContent} {
            border: none;
        }
    `}
`;

export const WizardCardTitle = styled(Heading4)`
    ${({ theme }) => css`
        color: ${theme.primary};
        font-weight: bold;
    `}
`;
