import {
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
} from '@chakra-ui/react';
import React from 'react';

import { CPrimaryButton, CSecondaryButton } from '../Buttons';
import { ChakraSizes } from '../ChakraTheme';
import { CRow } from '../Layouts';
import { CSubHeader } from '../Typography';
import { useDrawer } from './Drawer.hooks';
import { DrawerProps } from './Drawer.types';

export const CDrawer = ({
    header,
    children,
    testId,
    disableApply = false,
    disableCancel = false,
    cancelButtonText = 'Cancel',
    isEmbedded = false,
    size = ChakraSizes.lg,
    ...props
}: DrawerProps): JSX.Element => {
    const { handleApply, onClose } = useDrawer({ ...props });

    return (
        <>
            <Drawer
                data-test={`${testId}-drawer`}
                size={size}
                placement="right"
                {...props}
            >
                <DrawerOverlay />
                <DrawerContent>
                    {!disableCancel && (
                        <DrawerCloseButton
                            data-test={`${testId}-drawer--close`}
                        />
                    )}
                    {header && (
                        <DrawerHeader>
                            <CSubHeader text={header} />
                        </DrawerHeader>
                    )}
                    <DrawerBody flex={isEmbedded ? 'unset' : undefined}>
                        {children}
                    </DrawerBody>
                    <DrawerFooter>
                        <CRow justifyContent="end" noBreak>
                            {handleApply ? (
                                <CSecondaryButton
                                    testId={`${testId}-drawer--cancel`}
                                    onClick={onClose}
                                >
                                    {cancelButtonText}
                                </CSecondaryButton>
                            ) : (
                                <CPrimaryButton
                                    testId={`${testId}-drawer--cancel`}
                                    isDisabled={disableCancel}
                                    onClick={onClose}
                                >
                                    {cancelButtonText}
                                </CPrimaryButton>
                            )}
                            {handleApply && (
                                <CPrimaryButton
                                    isDisabled={disableApply}
                                    testId={`${testId}-drawer--apply`}
                                    onClick={handleApply}
                                >
                                    Apply
                                </CPrimaryButton>
                            )}
                        </CRow>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    );
};
