import { createSelector } from 'reselect';
import { ApplicationRootState } from 'redux-core/types';
import { AdvertiserGroup } from 'api/Admin/AdvertiserGroups';
import { CurrencyList } from 'api/Admin/Currency';
import { BiddingConfigReport } from 'api/Admin/Currency/Currency.types';
import {
    AppContextState,
    MemberGroupIndustrySelectorReturn,
    ContextMemberGroup,
    ContextAdvertiser,
    AdvertiserGroupError,
} from './types';

const selectAppContext = (state: ApplicationRootState): AppContextState =>
    state.app.context;

export const selectAppContextStatus = createSelector(
    selectAppContext,
    (state: AppContextState): string => state.status
);

export const selectAppContextMemberGroup = createSelector(
    selectAppContext,
    (state: AppContextState): ContextMemberGroup | null => state.memberGroup
);

export const selectAppContextAdvertiser = createSelector(
    selectAppContext,
    (state: AppContextState): ContextAdvertiser => state.advertiser
);

export const selectAppContextAdvertiserGroup = createSelector(
    selectAppContext,
    (state: AppContextState): AdvertiserGroup | AdvertiserGroupError | null =>
        state.advertiserGroup
);

export const selectMemberGroupEntityLabel = createSelector(
    selectAppContext,
    (state: AppContextState): MemberGroupIndustrySelectorReturn => {
        return (
            state.memberGroup?.label || {
                singular: 'Entity',
                plural: 'Entities',
            }
        );
    }
);

export const selectAppContextInitialized = createSelector(
    selectAppContext,
    (state: AppContextState): boolean => state.initialized
);

export const selectPreviousMemberGroupId = createSelector(
    selectAppContext,
    (state: AppContextState): number | null => state.previousMemberGroupId
);

export const selectSavedReportTitle = createSelector(
    selectAppContext,
    (state: AppContextState): string | null => state.savedReportTitle
);

export const selectContextErrorStatus = createSelector(
    selectAppContext,
    (state: AppContextState): number | null => state.errorCode
);

export const selectMemberGroupCurrencies = createSelector(
    selectAppContext,
    (state: AppContextState): CurrencyList | null => state.memberGroupCurrencies
);
export const selectMemberGroupBiddingConfigs = createSelector(
    selectAppContext,
    (state: AppContextState): BiddingConfigReport | null =>
        state.memberGroupBiddingConfigs
);

export const selectCalculatedPageCurrency = createSelector(
    selectAppContext,
    (state: AppContextState): number | null => state.calculatedPageCurrency
);

export const selectAdvertiserHasFunds = createSelector(
    selectAppContext,
    (state: AppContextState): boolean => state.advertiserHasFunds
);
