/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Form } from 'react-final-form';
import { FormApi } from 'final-form';

import { Box, ButtonGroup, Fade, SlideFade } from '@chakra-ui/react';
import WizardStepper from './WizardStepper';
import { StepperStep, WizardProps } from './Wizard.types';
import WizardContext from './Wizard.context';
import WizardForm from './WizardForm';
import { CColumn, CRow, CSection, CDeskTopOnlyBox } from '../Layouts';
import { CGrayCard } from '../Cards/Cards.component';
import { Spacing } from '../ChakraTheme/configs/Spacing.config';
import { CSubHeader, CHeader, CText } from '../Typography';
import {
    CLinkButton,
    CPrimaryButton,
    CSecondaryButton,
    CBorderButton,
} from '../Buttons';
import { responsiveStyles } from '../Chakra.utils';
import { Border } from '../ChakraTheme/configs/Borders.config';
import { Colors } from '../ChakraTheme';

function WizardComponent<WizardValues>({
    name,
    steps,
    onCancel,
    onNextClick,
    onBackClick,
    onLoadError,
    currentStepKey,
    initialValues,
    onGoToStep,
    onSubmit,
    onSubmitSuccess,
    onSubmitError,
    loadInitialData,
    stepsCompleted,
    submitButtonText,
    submitButtonDisabled,
    onWizardInit,
    isBackButtonDisabled,
    isDataLoadCompleted = true,
    assets,
    hasUserSetAssetValues,
    setHasUserSetAssetValues,
    alternativeActions,
}: WizardProps<WizardValues>): JSX.Element {
    const [initialized, setInitialized] = useState(false);
    const [disableNext, setDisableNext] = useState(false);
    const [isTransitionComplete, setIsTransitionComplete] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [submittingData, setSubmittingData] = useState(false);
    const [draft, setDraft] = useState<WizardValues>(initialValues);

    const [
        remoteInitialValues,
        setRemoteInitialValues,
    ] = useState<WizardValues | null>(null);
    const [formAPI, setFormAPI] = useState<FormApi>();
    const currentStepNumber =
        steps.findIndex((step) => step.key === currentStepKey) + 1;
    const isFirstStep = currentStepNumber === 1;

    const currentStepDetails = steps.find(
        (step: StepperStep) => step.key === currentStepKey
    );
    const StepComponent = currentStepDetails?.content;

    const lastStep = useMemo(() => {
        return currentStepKey === [...steps].pop()?.key;
    }, [currentStepKey, steps]);

    const handleSubmit = useCallback(async () => {
        if (formAPI?.getState()?.invalid) return;
        if (submittingData) return;
        setSubmittingData(true);
        async function submitData() {
            try {
                const result = await onSubmit(draft);
                onSubmitSuccess(result);
            } catch (error) {
                onSubmitError(error);
            }
        }
        await submitData();
        setSubmittingData(false);
    }, [
        onSubmit,
        draft,
        onSubmitError,
        onSubmitSuccess,
        submittingData,
        formAPI,
    ]);

    const handleTransition = () => {
        setIsTransitionComplete(false);
        setTimeout(() => setIsTransitionComplete(true), [250]);
    };

    const handleNextClick = useCallback(async () => {
        formAPI?.submit();
        if (formAPI?.getState()?.valid) {
            await onNextClick?.({
                /* @ts-ignore */
                draft,
            });
            handleTransition();
        }
    }, [onNextClick, draft, formAPI]);

    const handleBackClick = useCallback(async () => {
        await onBackClick?.();
        handleTransition();
    }, [onBackClick]);

    const handleCancelClick = useCallback(() => {
        onCancel?.();
    }, [onCancel]);

    const handleGoToStep = useCallback(
        (stepKey: string) => {
            onGoToStep?.(stepKey);
        },
        [onGoToStep]
    );

    const contextValue = useMemo(() => {
        return {
            draft,
            setDraft,
            formAPI,
            setFormAPI,
            steps,
            goToStep: handleGoToStep,
            setDisableNext,
            isLoading,
            setIsLoading,
            assets,
            hasUserSetAssetValues,
            setHasUserSetAssetValues,
        };
    }, [
        draft,
        formAPI,
        steps,
        handleGoToStep,
        isLoading,
        assets,
        hasUserSetAssetValues,
        setHasUserSetAssetValues,
    ]);

    useEffect(() => {
        async function loadData() {
            try {
                setIsLoading(true);
                const result = await loadInitialData?.();
                if (result) {
                    setRemoteInitialValues(result);
                    setDraft(result);
                }
                if (isDataLoadCompleted) {
                    setIsLoading(false);
                }
            } catch (error) {
                onLoadError?.(error);

                if (isDataLoadCompleted) {
                    setIsLoading(false);
                }
            }
        }

        if (loadInitialData) {
            loadData();
        }
    }, [loadInitialData, onLoadError, isDataLoadCompleted]);

    useEffect(() => {
        if (!initialized && formAPI) {
            setInitialized(true);
            setIsTransitionComplete(true);
            onWizardInit?.({
                form: formAPI,
            });
        }
    }, [initialized, formAPI, onWizardInit]);

    return (
        <WizardContext.Provider value={contextValue}>
            <CSection testId="wizard-section" h="95%">
                {/* { header } */}
                <Box minW="100%">
                    <SlideFade in={initialized} offsetY="-20px">
                        <CRow
                            minWidth="100%"
                            height={responsiveStyles('auto', '10%')}
                            minH={responsiveStyles('auto', '70px')}
                            data-test="wizard-component"
                            justifyContent="space-between"
                        >
                            {name ? <CHeader text={name} /> : null}
                            <WizardStepper
                                steps={steps}
                                onStepClick={({ stepKey, canNavigate }) => {
                                    if (canNavigate) {
                                        handleGoToStep(stepKey);
                                    }
                                }}
                                completedSteps={stepsCompleted}
                                currentStepKey={currentStepKey}
                            />
                        </CRow>
                    </SlideFade>
                </Box>

                {/* { header } */}
                {/* { content } */}

                <CGrayCard
                    data-test="wizard-content"
                    height="calc(100% - 140px)"
                    width="100%"
                    overflow="scroll"
                    p={Spacing.MD}
                >
                    <CRow
                        justifyContent="stretch"
                        alignItems="stretch"
                        minHeight="100%"
                        gap={Spacing.MD}
                    >
                        <CColumn
                            maxW="30%"
                            width="100%"
                            minWidth="160px"
                            height="100%"
                            pt={Spacing.SM}
                            pb={Spacing.SM}
                        >
                            <SlideFade
                                in={isTransitionComplete}
                                offsetX="-10px"
                                offsetY="0"
                                unmountOnExit
                            >
                                {currentStepDetails && (
                                    <CSubHeader
                                        wordBreak="break-word"
                                        data-test="wizard-details-title"
                                        text={currentStepDetails?.title}
                                        variant="bold"
                                        color="primaryColor"
                                    />
                                )}
                                {currentStepDetails?.description && (
                                    <CText
                                        data-test="wizard-details-description"
                                        text={currentStepDetails.description}
                                    />
                                )}

                                {currentStepDetails?.descriptionPartTwo && (
                                    <CText
                                        mt={Spacing.SM}
                                        data-test="wizard-details-description-part-two"
                                        text={
                                            currentStepDetails.descriptionPartTwo
                                        }
                                    />
                                )}

                                <Box mt={Spacing.LR}>
                                    <CText
                                        color="primaryColor"
                                        text={currentStepDetails?.helpText}
                                    />
                                    <CLinkButton
                                        testId="help-link"
                                        onClick={
                                            currentStepDetails?.helpTextLinkFunction
                                        }
                                    >
                                        {
                                            currentStepDetails?.helpTextDescription
                                        }
                                    </CLinkButton>
                                </Box>
                            </SlideFade>
                        </CColumn>
                        <CDeskTopOnlyBox>
                            <Box
                                borderLeft={`${Border.Width.Thin} solid`}
                                borderColor={Colors.grayLight}
                            />
                        </CDeskTopOnlyBox>

                        <CColumn
                            min-height="100%"
                            flexGrow={1}
                            pt={Spacing.SM}
                            pb={Spacing.SM}
                        >
                            <Form
                                initialValues={
                                    remoteInitialValues || initialValues
                                }
                                onSubmit={() => {}}
                                render={() => (
                                    <WizardForm>
                                        <Fade
                                            in={isTransitionComplete}
                                            unmountOnExit
                                        >
                                            {StepComponent && <StepComponent />}
                                        </Fade>
                                    </WizardForm>
                                )}
                            />
                        </CColumn>
                    </CRow>
                </CGrayCard>
                <Box width="100%">
                    <SlideFade in={initialized} offsetY="20px">
                        <CRow
                            data-test="wizard-footer"
                            justifyContent={
                                alternativeActions ? 'space-between' : 'end'
                            }
                            alignItems="end"
                        >
                            {!!alternativeActions && (
                                <ButtonGroup>{alternativeActions}</ButtonGroup>
                            )}
                            <ButtonGroup>
                                <CSecondaryButton
                                    testId="wizard-footer-cancel"
                                    onClick={handleCancelClick}
                                >
                                    Cancel
                                </CSecondaryButton>
                                {!isFirstStep && (
                                    <CBorderButton
                                        onClick={handleBackClick}
                                        testId="wizard-footer-back"
                                        isDisabled={isBackButtonDisabled}
                                    >
                                        Back
                                    </CBorderButton>
                                )}
                                {lastStep ? (
                                    <CPrimaryButton
                                        onClick={handleSubmit}
                                        testId="wizard-footer-next"
                                        isDisabled={
                                            submitButtonDisabled ||
                                            submittingData
                                        }
                                    >
                                        {submitButtonText || 'Submit'}
                                    </CPrimaryButton>
                                ) : (
                                    <CPrimaryButton
                                        isDisabled={isLoading || disableNext}
                                        onClick={handleNextClick}
                                        testId="wizard-footer-next"
                                    >
                                        Next
                                    </CPrimaryButton>
                                )}
                            </ButtonGroup>
                        </CRow>
                    </SlideFade>
                </Box>
                {/* { content } */}
            </CSection>
        </WizardContext.Provider>
    );
}

export default WizardComponent;
