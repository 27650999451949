import { useEffect, useState, useMemo, useCallback, FocusEvent } from 'react';
import numeral from 'numeral';
import { useLocale } from 'app/koddi-components/src/LocaleProvider';
import { CurrencyInputReturnType } from './CurrencyInput.types';
import { CustomCurrencyData } from '../../../../Types';

export const useCurrencyInput = ({
    onChange,
    onBlur,
    errorText,
    value,
    customCurrencyData,
}: {
    onChange: (newValue: number) => void;
    errorText?: string | null;
    onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
    value?: number | null;
    customCurrencyData?: CustomCurrencyData;
}): CurrencyInputReturnType => {
    const { currencyDigits, currencyFormat, currencySymbol } = useLocale();

    const localeData = numeral.localeData();
    const decimalSeparator =
        customCurrencyData?.decimalSeparator ||
        localeData?.delimiters?.decimal ||
        '.';
    const thousandsSeparator =
        customCurrencyData?.thousandsSeparator ||
        localeData?.delimiters?.thousands ||
        ',';
    const format = customCurrencyData?.format || currencyFormat;
    const defaultPlaceholder = numeral(0).format(format);

    const [displayValue, setDisplayValue] = useState<string | undefined>('');

    useEffect(() => {
        // watch for parent form changes
        if (displayValue) return;
        if (value || value === 0) {
            setDisplayValue(numeral(value).format(format));
            return;
        }
        setDisplayValue('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const [errorMessage, setErrorMessage] = useState<
        string | null | undefined
    >();

    const allowedCharacters: RegExp = useMemo(() => {
        if (currencyDigits === 0) {
            return new RegExp(/^[0-9,]+$/);
        }
        if (thousandsSeparator === '.' && decimalSeparator === ',') {
            return new RegExp(/^[0-9,.]+$/);
        }
        if (decimalSeparator === ',') {
            return new RegExp(/^[0-9, ]+$/);
        }
        return new RegExp(/^[0-9.,]+$/);
    }, [thousandsSeparator, decimalSeparator, currencyDigits]);

    // watch and set errors from parent components
    useEffect(() => {
        setErrorMessage(errorText);
    }, [errorText]);

    // format value onBlur
    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
        onBlur?.(e);
        if (value || value === 0) {
            const valueToDisplay = numeral(value).format(format);
            setDisplayValue(valueToDisplay);
        }
    };

    // core logic for currency input
    const handleValueChange = useCallback(
        (newValue?: string) => {
            const decimalLength = currencyDigits ?? 2;
            const strippedValue = numeral(newValue).value();
            const currentStrippedValue = numeral(displayValue).value();
            const valuesDiffer = strippedValue !== currentStrippedValue;
            const isEmpty = newValue === '';
            const splitValue = (newValue ?? '').split(decimalSeparator);

            const isInvalid =
                strippedValue < 0 ||
                !allowedCharacters.test(newValue ?? '') ||
                splitValue?.length > 2;

            const displayedCents = splitValue[1];

            const invalidDecimal =
                displayedCents?.split('').length > decimalLength;

            if (invalidDecimal) {
                setErrorMessage(
                    `This field accepts up to ${decimalLength} decimal places`
                );
                return;
            }

            if (isInvalid && !isEmpty) {
                setErrorMessage(
                    `Please only use a value in this format ${defaultPlaceholder}`
                );
                return;
            }

            setDisplayValue(newValue);
            if (valuesDiffer) {
                onChange(strippedValue);
            }
            setErrorMessage(errorText);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            allowedCharacters,
            setErrorMessage,
            errorText,
            displayValue,
            setDisplayValue,
            onChange,
            defaultPlaceholder,
            decimalSeparator,
            currencyDigits,
        ]
    );

    return {
        localeCurrencySymbol: customCurrencyData?.symbol || currencySymbol,
        handleValueChange,
        displayValue,
        errorMessage,
        handleBlur,
        defaultPlaceholder,
    };
};
