import styled, { css } from 'styled-components';
import Label from 'koddi-components/Label';

export const DateRangePickerFooter = styled.div`
    display: flex;
    flex-direction: column;
`;

export const DateRangePickerButtonGroup = styled.div`
    ${({ theme }) => css`
        display: flex;
        justify-content: flex-end;
        padding: ${theme.spaceUnit * 5}px;
    `}
`;

export const DateRangePickerError = styled(Label)`
    ${({ theme }) => css`
        color: ${theme.error};
        font-size: ${theme.typography.baseFontSize};
        text-align: right;
        padding: 0px ${theme.spaceUnit * 5}px;
    `}
`;
