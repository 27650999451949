import {
    Colors,
    Spacing,
} from 'app/koddi-components/src/_ChakraComponents/ChakraTheme';
import { CSearchIcon } from 'app/koddi-components/src/_ChakraComponents/Icons';
import { CRow } from 'app/koddi-components/src/_ChakraComponents/Layouts';

import React from 'react';
import { components as ReactSelectComponents, InputProps } from 'react-select';

export const SearchInput = (props: InputProps): JSX.Element => {
    /* @ts-ignore */ // outdated types creating issues... selectProps is in fact part of the props
    const selectProps: any = props?.selectProps;
    const innerProps = {
        ...props,
        placeholder: `Search ${
            selectProps?.selectedLabel?.toLowerCase() || ''
        }`,
    };
    return (
        <CRow gap={Spacing.XXS} noBreak alignItems="center" width="inherit">
            <CSearchIcon fill={Colors.grayDark} />
            <ReactSelectComponents.Input {...innerProps} />
        </CRow>
    );
};
