import { useEffect, useCallback, useState } from 'react';

import { LocalizeLanguage, LocalizeAPI } from './localize.types';

/**
 * Maps Localize.js codes to Koddi Locale codes, we only need to add locales
 * where our codes differ from localize.
 */
const LOCALIZE_TO_KODDI_LOCALES: Record<string, string> = {
    'es-LA': 'Es-419',
    en: 'en-US',
};

/* Maps Koddi locale codes to Localize.js locale codes */
const KODDI_LOCALE_TO_LOCALIZE: Record<string, string> = {
    'Es-419': 'es-LA',
    'en-US': 'en',
    'ko-KR': 'ko',
    'ja-JP': 'ja',
    'fr-FR': 'fr',
    'fr-CA': 'fr-CA',
    it: 'it',
    tr: 'tr',
    de: 'de',
    'pt-BR': 'pt-BR',
    'zh-TW': 'zh-TW',
    th: 'th',
    vi: 'vi',
    zh: 'zh',
};

/* Maps locale codes from the browser to Koddi locale codes */
const BROWSER_LOCALE_TO_KODDI_LOCALE: Record<string, string> = {
    'es-419': 'Es-419',
    'pt-BR': 'pt-BR',
    'fr-CA': 'fr-CA',
    'en-US': 'en-US',
    en: 'en-US',
    de: 'de',
    it: 'it',
    tr: 'tr',
    th: 'th',
    vi: 'vi',
    zh: 'zh',
};

/* Maps locale codes from the browser to Localize.js locale codes */
const BROWSER_LOCALE_TO_LOCALIZE: Record<string, string> = {
    'es-419': 'es-LA',
    'pt-BR': 'pt-BR',
    'fr-CA': 'fr-CA',
    'en-US': 'en-US',
    en: 'en-US',
    de: 'de',
    it: 'it',
    tr: 'tr',
    th: 'th',
    vi: 'vi',
    zh: 'zh',
};

const DEFAULT_LOCALE = 'en-US';

const useLocalize = (onChange?: (locale: string) => void): LocalizeAPI => {
    const [availableLanguages, setAvailableLanguages] = useState<
        LocalizeLanguage[]
    >([]);

    useEffect(() => {
        window.Localize.on('initialize', () => {
            window.Localize.getAvailableLanguages(
                (_: any, languages: LocalizeLanguage[]) => {
                    setAvailableLanguages(languages);
                }
            );
        });
        window.Localize.initialize({
            key: '2pmoYtFHJz6gF',
            rememberLanguage: false,
            autodetectLanguage: false,
        });
        window.Localize.hideWidget();
    }, []);

    useEffect(() => {
        if (onChange) {
            window.Localize.on('setLanguage', (data: any) => {
                onChange(LOCALIZE_TO_KODDI_LOCALES[data.to] || data.to);
            });
        }
    }, [onChange]);

    const setLocale = useCallback(
        (locale: string) => {
            const localizeLocale = availableLanguages?.find(
                (language) => language.code === KODDI_LOCALE_TO_LOCALIZE[locale]
            );
            if (localizeLocale) {
                window.Localize.setLanguage(localizeLocale.code);
            }
        },
        [availableLanguages]
    );

    const setBrowserLocale = useCallback(() => {
        window.Localize.detectLanguage((error: any, languages: string[]) => {
            if (error) {
                window.Localize.setLanguage(DEFAULT_LOCALE);
                return;
            }
            const supportedBrowserLocale = languages.find((locale: string) => {
                return BROWSER_LOCALE_TO_KODDI_LOCALE[locale];
            });
            const browserLocalizeLocale = supportedBrowserLocale
                ? BROWSER_LOCALE_TO_LOCALIZE[supportedBrowserLocale]
                : null;
            window.Localize.setLanguage(
                browserLocalizeLocale || DEFAULT_LOCALE
            );
        });
    }, []);

    const translate = useCallback((value: string) => {
        return window.Localize.translate(value);
    }, []);

    const formatNumber = useCallback((value: number) => {
        let formattedValue = null;
        window.Localize.number(value, (err: any, v: any) => {
            formattedValue = v;
        });
        return formattedValue;
    }, []);

    return {
        showWidget: window.Localize.showWidget,
        hideWidget: window.Localize.hideWidget,
        setLocale,
        setBrowserLocale,
        translate,
        formatNumber,
        availableLanguages,
    };
};

export default useLocalize;
