import { FocusEvent, Ref, useRef, useState } from 'react';
import {
    BaseDatePickerProps,
    SingleDatePickerHookReturn,
} from '../DatePicker.types';

export const useSingleDatePicker = ({
    onChange,
    inputValue,
}: Pick<
    BaseDatePickerProps,
    'onChange' | 'inputValue'
>): SingleDatePickerHookReturn => {
    const [date, setDate] = useState(inputValue);
    const refCustomInput = useRef() as Ref<HTMLInputElement>;
    const handleChange = (value: Date | null) => {
        onChange(value);
        setDate(value);
    };
    const handleChangeRaw = (rawDate: FocusEvent<HTMLInputElement>) => {
        const newDate = new Date(rawDate.currentTarget.value);
        if (newDate instanceof Date && !Number.isNaN(newDate.getTime())) {
            handleChange(newDate);
        }
        return handleChange(null);
    };
    return {
        handleChange,
        handleChangeRaw,
        refCustomInput,
        date,
    };
};
