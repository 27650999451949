import React, { FunctionComponent } from 'react';
import { BaseSVGProps } from '../icon.types';

const StatusOpenIcon: FunctionComponent<BaseSVGProps> = ({ width, height }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 17 17"
    >
        <title>Status Unapproved</title>
        <rect
            id="Rectangle_144842"
            data-name="Rectangle 144842"
            width="17"
            height="17"
            rx="8"
            fill="#0071c2"
        />
        <circle
            id="Ellipse_9364"
            data-name="Ellipse 9364"
            cx="4.5"
            cy="4.5"
            r="4.5"
            transform="translate(4 4)"
            fill="#fff"
        />
    </svg>
);

export default StatusOpenIcon;
