import { StyleProps } from '@chakra-ui/react';
import { ChangeEvent } from 'react';
import { ChakraSizes, BorderRadius, Colors } from '../../../ChakraTheme';
import { DynamicValueProp } from '../../Types';
import { InputBaseProps } from './Input.types';

export const useInputBase = ({
    onChange,
    inputValue,
    errorText,
    leftElement,
    rightElement,
    elementSize,
    isControlledInput = false,
}: Pick<
    InputBaseProps,
    | 'onChange'
    | 'inputValue'
    | 'errorText'
    | 'leftElement'
    | 'rightElement'
    | 'elementSize'
    | 'isControlledInput'
>): {
    handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
    styleProps: StyleProps;
    valueProp: DynamicValueProp;
} => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        onChange?.(e.target.value);
    };

    const styleProps = {
        size: ChakraSizes.sm,
        borderColor: errorText ? Colors.error : Colors.grayLight,
        focusBorderColor: errorText ? Colors.error : 'initial',
        borderRadius: BorderRadius.SM,
        backgroundColor: Colors.white,
        _hover: {
            borderColor: Colors.gray,
        },
        pl: leftElement ? elementSize : undefined,
        pr: rightElement ? elementSize : undefined,
    };

    const valueProp = isControlledInput
        ? { value: inputValue }
        : { defaultValue: inputValue };

    return {
        handleChange,
        valueProp,
        styleProps,
    };
};
