import { ReduxActionWithPayload, ReduxAction } from 'types';

export enum AdminAlertAction {
    SHOW_ALERT = 'admin/alert/SHOW_ALERT',
    CLEAR_ALERT = 'admin/alert/CLEAR_ALERT',
}

export type ShowAdminAlertAction = ReduxActionWithPayload<
    AdminAlertAction.SHOW_ALERT
>;

export type ClearAdminAlertAction = ReduxAction<AdminAlertAction.CLEAR_ALERT>;

export type AdminAlertActions = ShowAdminAlertAction | ClearAdminAlertAction;

export type AdminAlertState = {
    message: string | null;
    type: 'success' | 'warning' | 'success' | null;
};
