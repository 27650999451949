import { useEffect, useState } from 'react';
import {
    UseExpandingSectionArgs,
    UseExpandingSection,
} from './ExpandingSection.types';

export const useExpandingSection = ({
    isOpenByDefault,
    isDisabled,
}: UseExpandingSectionArgs): UseExpandingSection => {
    const [isCollapseOpen, setCollapseOpen] = useState(!!isOpenByDefault);
    const toggleCollapse = () => setCollapseOpen(!isCollapseOpen);
    useEffect(() => {
        if (isDisabled) {
            setCollapseOpen(false);
        }
    }, [isDisabled]);
    return {
        isOpen: isCollapseOpen,
        toggleCollapse,
    };
};
