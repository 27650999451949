import React, { FunctionComponent } from 'react';
import { BaseSVGProps } from '../icon.types';

const StatusClosedIcon: FunctionComponent<BaseSVGProps> = ({
    width,
    height,
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 17 17"
    >
        <title>Status Closed</title>
        <rect
            id="Rectangle_144844"
            data-name="Rectangle 144844"
            width="17"
            height="17"
            rx="8"
            fill="#ea3829"
        />
    </svg>
);

export default StatusClosedIcon;
