export enum DEPLOY_ENV {
    AZURE = 'azure',
    AWS = '',
}
export enum TARGET_ENV {
    Dev = 'development',
    UAT = 'uat',
    Prod = 'production',
}

export type Operation =
    | '='
    | '<='
    | '>= '
    | '<'
    | '>'
    | '!='
    | 'LIKE'
    | 'ILIKE'
    | 'IN'
    | 'NOT IN'
    | 'IS NULL'
    | 'NOT NULL'
    | 'NOT LIKE'
    | 'BETWEEN';

export type OrGroup = 'OR' | 'AND';

export type ReportFilter = {
    field: string | null;
    operation: Operation | string;
    value: any;
    or_group?: OrGroup;
};

export type Order = 'ASC' | 'DESC';

export type Sort = {
    field: string;
    order?: Order;
};

export type Pagination = {
    start?: number;
    count?: number;
};

/* TODO: upgrade filter params to support advanced filtering */
export type KoddiListNewFilterParams = {
    filters?: ReportFilter[];
    pagination?: Pagination;
    sort?: Sort;
};

export type BaseReportPayload = KoddiListNewFilterParams;

export type KoddiListFilterParams = {
    /** The `count` or `pageSize` i.e. the number of items to request.  */
    count?: number;
    /** The search query string.  */
    search?: string;
    /** The sort filter such as `ASC` or `DESC`.  */
    sort?: string;
    /** The page index to request.  */
    start?: number;
};

export type FailedBulkEditItem = {
    id: number;
    reason: string;
};

export type BulkEditResponse = {
    status: string;
    result: {
        failed: FailedBulkEditItem[];
        successful: number[] | null;
    };
};

export type AdGroupBulkEditResponse = {
    failed: FailedBulkEditItem[];
    successful: number[] | null;
};

export enum MatchType {
    Text = 1,
    Numeric = 2,
    Date = 3,
    Keyword = 4,
    Integer = 5,
}
