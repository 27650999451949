import 'react-datepicker/dist/react-datepicker.css';

import styled, { css } from 'styled-components';
import { fadeDownIn } from 'koddi-components/animations';

export const DateRangePickerWrapper = styled.div<{ marginRight: number }>`
    ${({ marginRight }) => css`
        position: relative;
        margin-right: ${marginRight}px;
    `}
`;

export const MultiDateRangePickerWrapper = styled.div<{
    dropdownOpen: boolean;
}>`
    ${({ theme, dropdownOpen }) => css`
        display: ${dropdownOpen ? 'flex' : 'none'};
        flex-direction: column;
        box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.12);
        animation: ${fadeDownIn} 0.2s ease-out both;
        z-index: ${theme.zIndex.dropdown};
        background: #ffffff;
    `}
`;

export const DateRangePickerModal = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
`;

export const DateRangePickerDropdown = styled.div<{
    dropdownOpen: boolean;
    showBackground?: boolean;
}>`
    ${({ theme, dropdownOpen, showBackground = true }) => css`
        display: ${dropdownOpen ? 'inline-flex' : 'none'};
        box-shadow: ${showBackground && '0px 3px 12px rgba(0, 0, 0, 0.12)'};
        animation: ${fadeDownIn} 0.2s ease-out both;
        z-index: ${theme.zIndex.dropdown};
        background: ${showBackground && '#ffffff'};
        ${!showBackground &&
        `border: 1px solid ${theme.grayLight}; border-top: none;`}
    `}
`;

export const DateRangePickerContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const DateRangePickerButtonFooter = styled.div`
    ${({ theme }) => css`
        display: flex;
        justify-content: flex-end;
        padding: ${theme.spaceUnit * 5}px;
    `}
`;

export const DateRangePickerInput = styled.input`
    position: absolute;
    opacity: 0;
    z-index: -1;
`;
