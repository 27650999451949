import CachedKoddiAPI from 'api/api.cachedObject';
import { GetEntityAttributeResponse } from './Entities.types';

class KoddiEntitiesAPI extends CachedKoddiAPI {
    // way its implemented it is not compatible with SWR
    getEntityAttributes = async (
        memberGroupId: number
    ): Promise<GetEntityAttributeResponse> => {
        const response = await this.cache.makeCachedRequest(
            'getEntityAttributes',
            memberGroupId,
            () => {
                return this.axios.get<any>(
                    `v1/member_groups/${memberGroupId}/entity_attributes`
                );
            }
        );

        return response.data.result;
    };
}

export default KoddiEntitiesAPI;
