import { useOnFirstRender } from 'app/react-ui/hooks/useOnFirstRender.hooks';
import { useMemo, useState, useCallback, useEffect } from 'react';
import { InputActionMeta, ValueType } from 'react-select';
import { useDebouncedCallback } from 'use-debounce/lib';

import { OptionType, selectValue } from '../../../../Types';
import {
    InputActions,
    PaginatedMultiSelectFieldProps,
    PaginatedSelectFieldHookReturn,
} from '../../SelectField.types';

export const useSelectPaginatedField = <
    T extends selectValue = string | number
>({
    onChange,
    inputValue,
    resetSearchOnSelection,
    fetchOptions,
    trackedPagination,
    dependsOn,
    isMulti,
}: Pick<
    PaginatedMultiSelectFieldProps<T>,
    | 'onChange'
    | 'inputValue'
    | 'resetSearchOnSelection'
    | 'fetchOptions'
    | 'trackedPagination'
    | 'dependsOn'
    | 'isMulti'
>): PaginatedSelectFieldHookReturn => {
    const [options, setOptions] = useState<OptionType[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [searchValue, setSearchValue] = useState('');
    const [initialized, setInitialized] = useState(false);
    const [open, setOpen] = useState(false);

    const portal = document.getElementById('select-portal');
    useOnFirstRender(() => {
        if (!portal) {
            // eslint-disable-next-line no-console
            console.error(
                'To enable menu portal add  <div id="select-portal"></div> to your DOM'
            );
        }
    });

    const fetchAndSetOptions = useCallback(
        async (count?: number, pageNumber?: number) => {
            if (!isLoading && hasMore) {
                setIsLoading(true);
                const data = await fetchOptions(
                    searchValue,
                    pageNumber ?? page,
                    count
                );
                if (data.length === 0) {
                    setHasMore(false);
                } else {
                    setOptions((prev) => {
                        const newOptions = data.filter(
                            (d) => !prev.find((p) => p.value === d.value)
                        );
                        return [...prev, ...newOptions];
                    });
                    setPage((prevPage) => prevPage + 1);
                }
                setIsLoading(false);
            }
        },
        [fetchOptions, hasMore, isLoading, page, searchValue]
    );

    useEffect(() => {
        if (trackedPagination && !initialized && dependsOn) {
            setInitialized(true);
            setPage(trackedPagination.page);
            fetchAndSetOptions(trackedPagination.count, trackedPagination.page);
        }
    }, [dependsOn, fetchAndSetOptions, initialized, trackedPagination]);

    const handleMenuOpen = async () => {
        if (options.length === 0) {
            await fetchAndSetOptions();
        }
        setOpen(true);
    };

    const valueProp = useMemo(() => {
        if (isMulti && Array.isArray(inputValue)) {
            return {
                value:
                    inputValue
                        ?.map((value) => {
                            const matchedOption = options?.find(
                                (option) => option.value === value
                            );
                            return matchedOption ?? null;
                        })
                        .filter((option) => option) ?? [],
            };
        }
        return {
            value:
                options?.find((option) => inputValue === option.value) ?? null,
        };
    }, [options, inputValue, isMulti]);

    const handleChange = (option: ValueType<OptionType>) => {
        if (option) {
            if (resetSearchOnSelection) {
                setSearchValue('');
            }
            if (isMulti && Array.isArray(option)) {
                const values = option.map((o) => o.value);
                onChange?.(values as T);
                return;
            }
            const { value } = option as OptionType<T>;
            onChange?.(value);
            return;
        }
        onChange?.(undefined);
    };

    const handleSearch = async (
        inp: string | undefined
    ): Promise<OptionType<string | number>[]> => {
        setIsLoading(true);
        const data = await fetchOptions(inp, 0);
        setOptions(data);
        setHasMore(data.length > 0);
        setIsLoading(false);
        return data;
    };

    const handleSearchChange = async (
        value: string,
        { action }: InputActionMeta
    ): Promise<OptionType<string | number>[] | undefined> => {
        switch (action) {
            case InputActions.setValue:
                return;
            case InputActions.inputBlur || InputActions.menuClose:
                setSearchValue('');
                return;
            default:
                setSearchValue(value);
                // eslint-disable-next-line consistent-return
                return handleSearch(value);
        }
    };

    const debouncedChange = useDebouncedCallback(handleChange, 100);

    return {
        portal,
        open,
        setOpen,
        valueProp: valueProp as {
            value?: OptionType<string | number> | undefined;
        },
        searchValue,
        options,
        isLoading,
        handleSearchChange,
        handleMenuOpen,
        debouncedChange,
        fetchAndSetOptions,
    };
};
