import { Input } from '@chakra-ui/react';
import {
    BorderRadius,
    Colors,
} from 'app/koddi-components/src/_ChakraComponents/ChakraTheme';
import React, { forwardRef, LegacyRef } from 'react';
import { CustomDatePickerInputProps } from '../DatePicker.types';

// eslint-disable-next-line react/display-name
export const CustomDatePickerInput = forwardRef(
    (
        {
            value,
            onClick,
            onFocus,
            onChange,
            name,
            hasError,
            disabled = false,
        }: CustomDatePickerInputProps,
        ref: LegacyRef<HTMLInputElement>
    ) => {
        const styleProps = {
            borderColor: hasError ? Colors.error : Colors.grayLight,
            borderRadius: BorderRadius.SM,
            backgroundColor: Colors.white,
            _hover: {
                borderColor: Colors.gray,
            },
        };

        return (
            <Input
                {...styleProps}
                data-test={`${name}--date-input`}
                name={name}
                value={value}
                disabled={disabled}
                onFocus={onFocus}
                onChange={onChange}
                onClick={onClick}
                ref={ref}
            />
        );
    }
);
