import { useTheme } from '@chakra-ui/react';
import { useOnFirstRender } from 'app/react-ui/hooks/useOnFirstRender.hooks';
import { CSSProperties, useMemo, useState } from 'react';
import { GroupedOptionsType, InputActionMeta, ValueType } from 'react-select';
import { useDebouncedCallback } from 'use-debounce/lib';
import {
    BorderRadius,
    BorderWidth,
    FontSizes,
    Spacing,
} from '../../../ChakraTheme';
import { OptionType, selectValue } from '../../Types';
import {
    InputActions,
    SelectFieldBaseProps,
    SelectFieldHookReturn,
    SelectFieldThemeHookReturn,
} from './SelectField.types';

export const useSelectFieldTheme = <T extends selectValue = string | number>({
    height,
    errorText,
    shouldWrap,
    isMulti,
}: Pick<
    SelectFieldBaseProps<T>,
    'errorText' | 'isMulti' | 'height' | 'shouldWrap'
>): SelectFieldThemeHookReturn => {
    const { colors } = useTheme();

    const styles = {
        control: (baseStyles: CSSProperties, state: any) => ({
            ...baseStyles,
            height: height || 'auto',
            padding: 0,
            fontSize: FontSizes.sm,
            borderRadius: BorderRadius.SM,
            cursor: 'pointer',
            minHeight: 0,
            borderColor:
                errorText && !state.isFocused
                    ? colors.red['500']
                    : colors.grayLight,
        }),
        option: (baseStyles: CSSProperties) => ({
            ...baseStyles,
            cursor: 'pointer',
            width: '100%',
            whiteSpace: 'nowrap' as const,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }),
        menu: (baseStyles: CSSProperties) => ({
            ...baseStyles,
            marginTop: BorderWidth.Thin,
            zIndex: 1500,
        }),
        menuList: (baseStyles: CSSProperties) => ({
            ...baseStyles,
            padding: 0,
            minHeight: '30px',
        }),
        noOptionsMessage: (baseStyles: CSSProperties) => ({
            ...baseStyles,
            padding: `${Spacing.MD} ${Spacing.SM}`,
        }),
        valueContainer: (baseStyles: CSSProperties) => ({
            ...baseStyles,
            flexWrap: (shouldWrap === undefined ? isMulti : shouldWrap)
                ? ('wrap' as const)
                : ('nowrap' as const),
        }),
        multiValue: (baseStyles: CSSProperties) => ({
            ...baseStyles,
            flexWrap: 'nowrap' as const,
            minWidth: '30px',
        }),
        dropdownIndicator: (baseStyles: CSSProperties) => ({
            ...baseStyles,
            padding: Spacing.XXS,
        }),
        clearIndicator: (baseStyles: CSSProperties) => ({
            ...baseStyles,
            padding: Spacing.XXS,
        }),
        loadingIndicator: (baseStyles: CSSProperties) => ({
            ...baseStyles,
            padding: Spacing.XXS,
        }),
        menuPortal: (baseStyles: CSSProperties) => ({
            ...baseStyles,
            zIndex: 1500,
        }),
        input: (baseStyles: CSSProperties) => ({
            ...baseStyles,
            minWidth: '40px',
            '[type="text"]': {
                minWidth: '40px',
                width: '100% !important',
            },
        }),
    };

    const theme = (baseTheme: any) => ({
        ...baseTheme,
        colors: {
            ...baseTheme.colors,
            primary: colors.primaryColor,
            primary75: colors.primaryColor,
            primary50: colors.grayLightest,
            primary25: colors.grayLightest,
            danger: colors.red['500'],
            dangerLight: colors.red['300'],
        },
    });
    return {
        styles,
        theme,
    };
};

export const useSelectField = <T extends selectValue = string | number>({
    onChange,
    options,
    groupedOptions,
    inputValue,
    isMulti,
    isControlledInput,
    resetSearchOnSelection,
}: Pick<
    SelectFieldBaseProps<T>,
    | 'onChange'
    | 'options'
    | 'groupedOptions'
    | 'inputValue'
    | 'isMulti'
    | 'isControlledInput'
    | 'resetSearchOnSelection'
>): SelectFieldHookReturn => {
    const [aggregatedOptions, setAggregatedOptions] = useState(options);
    const [searchValue, setSearchValue] = useState('');
    const [open, setOpen] = useState(false);

    const portal = document.getElementById('select-portal');
    useOnFirstRender(() => {
        if (!portal) {
            // eslint-disable-next-line no-console
            console.error(
                'To enable menu portal add  <div id="select-portal"></div> to your DOM'
            );
        }
    });

    const memoOptions = useMemo(() => {
        if (groupedOptions) {
            setAggregatedOptions(
                groupedOptions.reduce((a, b) => [...a, ...b.options], [] as any)
            );
            return groupedOptions;
        }
        if (options) {
            setAggregatedOptions(options);
            return options;
        }
        return [];
    }, [options, groupedOptions]);

    const defaultValue = useMemo<
        | OptionType<string | number>
        | (OptionType<string | number> | undefined)[]
        | undefined
    >(() => {
        if (isMulti && Array.isArray(inputValue)) {
            return inputValue
                ?.map((value) => {
                    const matchedOption = aggregatedOptions?.find(
                        (option) => option.value === value
                    );
                    return matchedOption;
                })
                .filter((option) => option);
        }
        return aggregatedOptions?.find((option) => inputValue === option.value);
    }, [aggregatedOptions, inputValue, isMulti]);

    const valueProp = isControlledInput
        ? { value: defaultValue ?? null }
        : { defaultValue: defaultValue ?? null };

    const handleChange = (option: ValueType<OptionType>) => {
        if (option) {
            if (resetSearchOnSelection) {
                setSearchValue('');
            }
            if (isMulti && Array.isArray(option)) {
                const values = option.map((o) => o.value);
                onChange?.(values as T);
                return;
            }
            const { value } = option as OptionType<T>;
            onChange?.(value);
            return;
        }
        onChange?.(undefined);
    };

    const handleSearchChange = (value: string, { action }: InputActionMeta) => {
        switch (action) {
            case InputActions.setValue:
                return;
            case InputActions.inputBlur || InputActions.menuClose:
                setSearchValue('');
                return;
            default:
                setSearchValue(value);
        }
    };

    const filterValues = (
        value: string,
        optionsToFilter: OptionType[] | GroupedOptionsType<OptionType>
    ) => {
        if (!value) {
            return optionsToFilter;
        }
        /* @ts-ignore */
        const filteredOptions = optionsToFilter.filter((option) => {
            if (!option.options) {
                return option.label.toLowerCase().includes(value.toLowerCase());
            }
            return option.options.filter((groupedOption: any) =>
                groupedOption.label.toLowerCase().includes(value.toLowerCase())
            );
        });

        return filteredOptions;
    };

    const loadOptions = useDebouncedCallback(
        (
            value,
            callback: (
                memoOptions: OptionType[] | GroupedOptionsType<OptionType>
            ) => void
        ) => {
            return callback(filterValues(value, memoOptions));
        },
        500
    );

    return {
        handleChange,
        loadOptions,
        defaultOptions: filterValues(searchValue, memoOptions),
        portal,
        valueProp,
        handleSearchChange,
        searchValue,
        open,
        setOpen,
    };
};
