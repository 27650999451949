import { Switch, SwitchProps } from '@chakra-ui/react';
import React, { ChangeEvent } from 'react';
import { ColorSchemes } from '../../../ChakraTheme';

export interface ToggleSwitchProps
    extends Omit<SwitchProps, 'defaultValue' | 'onChange'> {
    onChange: (value: boolean) => void;
    testId: string;
}

export const CToggleSwitch = ({
    onChange,
    id,
    testId,
    ...props
}: ToggleSwitchProps): JSX.Element => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.checked);
    };
    return (
        <div>
            <Switch
                {...props}
                colorScheme={ColorSchemes.primary}
                onChange={handleChange}
                test-id={testId}
                id={id}
            />
        </div>
    );
};
