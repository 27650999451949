import {
    CSingleDatePicker,
    CCheckboxGroup,
    CRadio,
    CSelectField,
    CSingleCheckbox,
    CTextarea,
    CToggleSwitch,
    CSimpleMultiSelectField,
} from '../Controls';
import { EmailGrabber } from '../Controls/EmailGrabber';
import {
    CEmailInput,
    CNumberInput,
    CTextInput,
    CPasswordInput,
} from '../Controls/Inputs/Variants';
import {
    CCustomCurrencyInput,
    CUserCurrencyInput,
} from '../Controls/Inputs/Variants/CurrencyInput';

export const FormControls = {
    textInput: CTextInput,
    numberInput: CNumberInput,
    emailInput: CEmailInput,
    passwordInput: CPasswordInput,
    select: CSelectField,
    multiSelect: CSimpleMultiSelectField,
    currencyInput: CUserCurrencyInput,
    customCurrencyInput: CCustomCurrencyInput,
    textarea: CTextarea,
    toggle: CToggleSwitch,
    radio: CRadio,
    checkboxGroup: CCheckboxGroup,
    singleCheckbox: CSingleCheckbox,
    singleDatePicker: CSingleDatePicker,
    multiEmail: EmailGrabber,
};
