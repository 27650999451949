/* eslint-disable no-underscore-dangle */
import React, {
    FunctionComponent,
    useCallback,
    useEffect,
    useState,
} from 'react';
import numeral from 'numeral';

import { Field } from 'react-final-form';
import Icon from 'koddi-components/Icon';
import Button from 'koddi-components/Button';
import { ErrorText } from 'koddi-components/Form';
import { useForwardedRef } from 'koddi-components/hooks';
import Label from '../Label';

import {
    InputProps,
    InputFieldProps,
    InputFieldComponentProps,
    AutoCompleteValues,
} from './input.types';
import {
    Input,
    InputContainer,
    InputInnerContainer,
    InputButtonGroup,
    LabelToolTip,
    LabelWrapper,
    InputCount,
    MessagingGroup,
} from './input.styled';
import { CFormLabel } from '../_ChakraComponents';

/**
 * The Koddi `Input` Component
 *
 * Text, Textarea, or Number input with label and error styling support.
 *
 * Example Usage:

        <Input
            name="my-input"
            iconLeft="magnifier"
            type="text"
            disabled={false}
            label="My Input"
        />
 */
export const InputComponent: FunctionComponent<InputProps> = (props) => {
    const {
        id,
        required,
        readOnly,
        disabled,
        label,
        pattern,
        meta,
        hasError = false,
        errorText = '',
        showErrors = true,
        onChange: onChangeProp,
        onFocus: onFocusProp,
        onBlur: onBlurProp,
        value: valueProp,
        checked: checkProp,
        name: nameProp,
        placeholder,
        input: { value, name, onBlur, onFocus, onChange, type, checked } = {},
        type: typeProp,
        forwardedRef,
        iconLeft,
        iconRight,
        fullWidth,
        flexDirection,
        outerRef,
        inputContainerRef,
        onContainerClick,
        useAriaLabel = false,
        hideIcons = false,
        autoComplete = AutoCompleteValues.Off,
        labelToolTip = false,
        minWidth,
        toolTipText,
        customChevronIconMargin,
        v2 = false,
        maxLength,
        hasCharacterCount = false,
        unitLabelContent,
        fixedBorderWidth,
        fieldWidth,
        height,
        maxHeight,
        paddingLeft,
        borderRadius,
        fitErrorTextContent = false,
        hasWordCount,
        maxAmountOfWords,
        // using regex here to allow for comma with or without a space - ie: ',' or ', '
        wordCountSeparator = /,\s+|,/,
        setExceededWordCount,
        maxWordLength,
        setExceededWordLength,
        onKeyDown,
        isChakra,
        ...restProps
    } = props;
    // internal var for accepting either `hasError` from the top level or `meta.error` from react-final-form
    const _hasError = hasError || (meta && meta.error && meta.touched);
    const [characterCount, setCharacterCount] = useState(maxLength);
    const [remainingAvailableWords, setRemainingAvailableWords] = useState(
        maxAmountOfWords
    );
    const [wordCountMaxLength, setWordCountMaxLength] = useState<number>();
    const [overMaxWordCount, setOverMaxWordCount] = useState(0);
    // local state to handle errors of max word length
    const [overMaxWordLength, setOverMaxWordLength] = useState(false);
    const localeData = numeral.localeData();

    const getCurrentWordCount = useCallback(() => {
        const words = value
            ?.toString()
            .split(wordCountSeparator)
            .filter(Boolean);
        const wordCount = words?.length || 0;
        let longestWordLength = 0;
        words?.forEach((word) => {
            if (word.length > longestWordLength)
                longestWordLength = word.length;
        });
        return { wordCount, longestWordLength };
    }, [value, wordCountSeparator]);

    useEffect(() => {
        if (!hasWordCount) return;
        const { wordCount, longestWordLength } = getCurrentWordCount();
        if (hasWordCount && maxWordLength && setExceededWordLength) {
            if (longestWordLength <= maxWordLength) {
                setExceededWordLength(false);
                setOverMaxWordLength(false);
            } else {
                setExceededWordLength(true);
                setOverMaxWordLength(true);
                return;
            }
        }
        if (hasWordCount && maxAmountOfWords) {
            if (wordCount === maxAmountOfWords) {
                setRemainingAvailableWords(0);
                setOverMaxWordCount(0);
                if (setExceededWordCount) setExceededWordCount(false);
                return;
            }
            if (wordCount > maxAmountOfWords && value) {
                setOverMaxWordCount(wordCount - maxAmountOfWords);
                setWordCountMaxLength(value?.length);
                if (setExceededWordCount) setExceededWordCount(true);
                return;
            }
            setWordCountMaxLength(undefined);
            setOverMaxWordCount(0);
            setRemainingAvailableWords(maxAmountOfWords - wordCount);
            if (setExceededWordCount) setExceededWordCount(false);
        }
    }, [
        getCurrentWordCount,
        hasWordCount,
        maxAmountOfWords,
        value,
        setExceededWordCount,
        maxWordLength,
        setExceededWordLength,
    ]);

    const isTextArea = type === 'textarea' || typeProp === 'textarea';
    const isNumber = type === 'number' || typeProp === 'number';
    const isInteger = type === 'integer' || typeProp === 'integer';

    const customOnKeyDownHandler = useCallback(
        (event) => {
            if (
                (localeData?.delimiters.decimal === ',' &&
                    event.keyCode === 188) ||
                (localeData?.delimiters.decimal === '.' &&
                    event.keyCode === 190)
            ) {
                event.preventDefault();
            }
            if (onKeyDown) {
                onKeyDown(event);
            }
        },
        [localeData?.delimiters.decimal, onKeyDown]
    );

    const inputProps = {
        ...restProps,
        required,
        readOnly,
        disabled,
        minWidth,
        height,
        maxHeight,
        value: valueProp === undefined ? value : valueProp,
        name: nameProp || name,
        onBlur: onBlurProp || onBlur,
        onFocus: onFocusProp || onFocus,
        onChange: onChangeProp || onChange,
        onKeyDown: isInteger ? customOnKeyDownHandler : onKeyDown,
        type: typeProp || type,
        placeholder,
        pattern,
        checked: checkProp || checked,
        autoComplete,
        id: id || name || nameProp,
        paddingLeft,
    };

    const ref = useForwardedRef(inputProps.ref || forwardedRef);
    const visibility =
        !inputProps.disabled && inputProps.required && !isChakra
            ? 'visible'
            : 'hidden';

    useEffect(() => {
        if (hasCharacterCount && maxLength) {
            const usedCharacters = value?.length || 0;
            setCharacterCount(maxLength - usedCharacters);
        }
    }, [value, hasCharacterCount, maxLength]);

    return (
        <InputContainer
            fullWidth={fullWidth}
            flexDirection={flexDirection}
            ref={outerRef}
            onClick={onContainerClick}
            fieldWidth={fieldWidth}
        >
            {label && useAriaLabel === false ? (
                <LabelWrapper
                    visibility={visibility}
                    labelToolTip={labelToolTip}
                >
                    {isChakra ? (
                        <CFormLabel
                            id={name}
                            isRequired={required}
                            label={label}
                        />
                    ) : (
                        <Label htmlFor={inputProps.id}>{label}</Label>
                    )}
                    {labelToolTip && toolTipText && (
                        <LabelToolTip
                            defaultText={toolTipText}
                            icon="info"
                            iconHeight={13}
                            iconWidth={13}
                        />
                    )}
                </LabelWrapper>
            ) : null}

            <InputInnerContainer
                hasError={_hasError || !!overMaxWordCount}
                disabled={disabled}
                readOnly={readOnly}
                ref={inputContainerRef}
                customChevronIconMargin={customChevronIconMargin}
                v2={v2}
                fixedBorderWidth={fixedBorderWidth}
                borderRadius={borderRadius}
                minWidth={minWidth}
                maxHeight={maxHeight}
            >
                {iconLeft && !isTextArea && (
                    <Icon icon={iconLeft} height={12} width={12} />
                )}
                <Input
                    {...inputProps}
                    as={isTextArea ? 'textarea' : 'input'}
                    ref={ref}
                    hasError={_hasError || !!overMaxWordCount}
                    aria-label={useAriaLabel ? label : inputProps['aria-label']}
                    v2={v2}
                    maxLength={hasWordCount ? wordCountMaxLength : maxLength}
                    height={height}
                />
                {iconRight && !isTextArea && iconRight}
                {isNumber && !readOnly && !disabled && !hideIcons && (
                    <InputButtonGroup>
                        <Button
                            data-test={`${inputProps.id}-input-minus`}
                            btnStyle="secondary"
                            iconLeft="minus"
                            iconSize={10}
                            noPadding
                            onClick={() => {
                                ref.current?.stepDown();
                                if (inputProps?.onChange) {
                                    const event = new Event('input', {
                                        bubbles: true,
                                    });
                                    ref?.current?.dispatchEvent(event);
                                }
                            }}
                        />
                        <Button
                            data-test={`${inputProps.id}-input-plus`}
                            btnStyle="secondary"
                            iconLeft="plus"
                            iconSize={10}
                            noPadding
                            onClick={() => {
                                ref.current?.stepUp();
                                if (inputProps?.onChange) {
                                    const event = new Event('input', {
                                        bubbles: true,
                                    });
                                    ref?.current?.dispatchEvent(event);
                                }
                            }}
                        />
                    </InputButtonGroup>
                )}
            </InputInnerContainer>
            {(showErrors || hasCharacterCount || hasWordCount) && (
                <MessagingGroup flexWrap>
                    {showErrors && (
                        <ErrorText
                            hasError={_hasError}
                            fitErrorTextContent={fitErrorTextContent}
                        >
                            {errorText || meta?.error}
                        </ErrorText>
                    )}
                    {!!overMaxWordCount && (
                        <ErrorText hasError fitErrorTextContent>
                            {`You have exceeded the max number of words by ${overMaxWordCount}`}
                        </ErrorText>
                    )}
                    {overMaxWordLength && maxWordLength && (
                        <ErrorText hasError fitErrorTextContent>
                            {`Each keyword may not be longer than ${maxWordLength} characters`}
                        </ErrorText>
                    )}
                    {hasCharacterCount && maxLength && (
                        <InputCount>
                            {`${characterCount} characters remaining`}
                        </InputCount>
                    )}
                    {hasWordCount &&
                        maxAmountOfWords &&
                        !overMaxWordCount &&
                        !overMaxWordLength && (
                            <InputCount>
                                {`${remainingAvailableWords} words remaining`}
                            </InputCount>
                        )}
                </MessagingGroup>
            )}

            {unitLabelContent && unitLabelContent}
        </InputContainer>
    );
};

const InputWithForwardedRef = React.forwardRef<HTMLInputElement, InputProps>(
    (props, ref) => <InputComponent {...props} forwardedRef={ref} />
);

/**
 * The Koddi `Input` Component
 *
 * Text, Textarea, or Number input with label, icon, and error styling support.
 *
 * Example Usage:

        <Input
            name="my-input"
            iconLeft="magnifier"
            type="textarea"
            disabled={false}
            label="My Input"
            onChange={(e) => console.log(e.target.value)}
        />
 */
const InputField = (props: InputFieldProps): JSX.Element => {
    return (
        <Field<string, InputFieldComponentProps, HTMLInputElement>
            {...props}
            component={
                InputWithForwardedRef as FunctionComponent<
                    InputFieldComponentProps
                >
            }
        />
    );
};

export const InputFieldWithForwardedRef = React.forwardRef<
    HTMLInputElement,
    InputFieldProps
>((props, ref) => <InputField {...props} forwardedRef={ref} />);

export default InputWithForwardedRef;
