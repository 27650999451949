import styled, { css } from 'styled-components';
import { IconWrapper } from 'koddi-components/Icon';
import {
    DragGroupContainerProps,
    DragTileContainerProps,
} from './DragGroup.types';

export const DragGroupContainer = styled.div<DragGroupContainerProps>`
    ${({ direction }) => css`
        display: flex;
        flex-direction: ${direction === 'horizontal' ? 'row' : 'column'};
        flex-wrap: wrap;
    `}
`;

export const DragTileName = styled.span`
    flex: 1;
`;

export const DragTileContainer = styled.div<DragTileContainerProps>`
    ${({ theme, isDragging, isParentDragging }) => css`
        min-height: 30px;
        background-color: ${isDragging ? theme.grayLight : theme.grayLighter};
        box-shadow: ${isDragging
            ? '0px 4px 10px 0px rgba(0, 0, 0, 0.12)'
            : 'none'};
        display: flex;
        cursor: ${isDragging ? 'grabbing' : 'grab'};
        justify-content: flex-start;
        align-items: center;
        min-width: 130px;
        user-select: none;
        margin: ${theme.spaceUnit / 2}px;
        transition: box-shadow 0.2s ease-in-out;
        font-size: ${theme.typography.baseFontSize};
        &:hover {
            background-color: ${theme.grayLight};
        }
        ${IconWrapper} {
            color: ${theme.grayDark};
            &:first-of-type {
                margin: 0px ${theme.spaceUnit * 3}px;
            }
            &:last-of-type {
                height: 30px;
                padding: 0px ${theme.spaceUnit * 3}px;
                &:hover {
                    cursor: ${isParentDragging ? 'grabbing' : 'pointer'};
                }
            }
        }
    `}
`;
