/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import {
    login,
    loginWithSSOSuccess,
    logoutWithSSO,
} from 'redux-core/auth/actions';
import { useAuthError, useAuthStatus } from 'redux-core/auth/hooks';
import { useKoddiThemeResource, useSSOIds } from 'redux-core/app/theme/hooks';
import { useHistory, useParams } from 'react-router';
import KoddiAPI from 'api/api.instance';
import { LoginRouteParams } from 'app/react-ui/types';
import { IS_TEST_ENV } from 'app/react-ui/utils/constants';
import {
    RETURN_ROUTE_SESSION_KEY,
    USER_ATTEMPTED_LOGIN_KEY,
    AUTH_LOGOUT_KEY,
} from './Auth0.const';
import { Auth0 } from './Auth0';

type Auth0HookReturnType = {
    isLoadingAuth0: boolean;
    authError: any; // we will fix this more in the refactor.... we are assigning it as an traditional Error but using it as a custom Object
    userAttemptedToLogin?: boolean;
    isAuthenticated?: boolean;
    handleLogin: () => void;
    handleLogout: () => void;
    checkAuth0State: () => void;
    handleReturnRouteStorage: (
        route: string,
        forceOverwrite?: boolean
    ) => Promise<void>;
    handleSignUp: () => void;
};

export const useAuth0 = (): Auth0HookReturnType => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { member_group_id, theme } = useKoddiThemeResource();
    const [isLoadingAuth0, setIsLoadingAuth0] = useState(true);
    const [userAttemptedToLogin, setUserAttemptedToLogin] = useState<boolean>();
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>();
    const params: LoginRouteParams = useParams();

    const authStatus = useAuthStatus();
    const authError = useAuthError();
    const { sso_organization_id, sso_connection_id } = useSSOIds();

    const handleSignUp = useCallback(async () => {
        try {
            await Auth0.signUp();
        } catch (err) {
            console.error(err);
            // do something with this later
        }
    }, []);

    const handleLogin = useCallback(async () => {
        try {
            sessionStorage.setItem(USER_ATTEMPTED_LOGIN_KEY, 'true');
            if (!sso_organization_id) {
                // get sso ID
                const hostName = params?.clientName ?? 'koddi_group';
                const hostNameTheme = await KoddiAPI.Admin.MemberGroup.getThemeByHostname(
                    hostName
                );
                await Auth0.login({
                    sso_organization_id: hostNameTheme.sso_organization_id,
                    sso_connection_id: hostNameTheme.sso_connection_id,
                    targetUrl: `${window.location.origin}/#/clients/${hostNameTheme.member_group_id}`,
                });
            }
            await Auth0.login({
                sso_organization_id,
                sso_connection_id,
                targetUrl: `${window.location.origin}/#/clients/${member_group_id}`,
            });
        } catch (err) {
            console.error(err);
            // do something with this later
        }
    }, [sso_organization_id, sso_connection_id]);

    const handleLogout = useCallback(async () => {
        try {
            dispatch(logoutWithSSO(theme?.login?.key));
            await Auth0.logout();
        } catch (err) {
            console.error(err);
            // do something with this later
        }
    }, [theme?.login?.key]);

    const checkAuth0State = async () => {
        if (IS_TEST_ENV) return;
        try {
            const auth0state = await Auth0.initializeAuth0();

            if (auth0state) {
                setIsAuthenticated(true);

                const returnRouteFromStorage = sessionStorage.getItem(
                    RETURN_ROUTE_SESSION_KEY
                );

                if (returnRouteFromStorage) {
                    history.push(returnRouteFromStorage);
                }
                dispatch(loginWithSSOSuccess(member_group_id, auth0state));
                sessionStorage.removeItem(RETURN_ROUTE_SESSION_KEY);
                localStorage.setItem(AUTH_LOGOUT_KEY, 'false');
            } else {
                setIsAuthenticated(false);
                dispatch(login());
            }
            setIsLoadingAuth0(false);
        } catch (e) {
            console.warn('could not check Auth0 state', e);
            setIsLoadingAuth0(false);
        }
    };

    const handleReturnRouteStorage = async (
        route: string,
        forceOverwrite = false
    ) => {
        const storedReturnRoute = sessionStorage.getItem(
            RETURN_ROUTE_SESSION_KEY
        );
        if (!storedReturnRoute || forceOverwrite) {
            sessionStorage.setItem(RETURN_ROUTE_SESSION_KEY, `${route}`);
        }
    };

    useEffect(() => {
        try {
            const hasUserAttemptedToLogin = !!sessionStorage.getItem(
                USER_ATTEMPTED_LOGIN_KEY
            );
            setUserAttemptedToLogin(hasUserAttemptedToLogin);
        } catch (e) {
            console.warn(e);
        }
        if (authStatus === 'signedIn' || authError) {
            setIsLoadingAuth0(false);
        }
    }, [authStatus, authError]);

    return {
        isLoadingAuth0,
        authError,
        userAttemptedToLogin,
        isAuthenticated,
        handleLogin,
        handleLogout,
        checkAuth0State,
        handleReturnRouteStorage,
        handleSignUp,
    };
};
