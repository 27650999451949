import { Status, StatusId } from 'app/react-ui/api/Reports';

export const STATUS_ACTIVE = 'active';
export const STATUS_ENDED = 'ended';
export const STATUS_PAUSED = 'paused';
export const STATUS_PENDING = 'pending';
export const STATUS_INACTIVE = 'inactive';
export const STATUS_OPEN = 'open';
export const STATUS_APPROVED = 'approved';
export const STATUS_LOCKED = 'locked';
export const STATUS_DEACTIVATED = 'deactivated';
export const STATUS_CLOSED = 'closed';
export const STATUS_OPTIONS = [
    { value: STATUS_ACTIVE, label: 'Active' },
    { value: STATUS_OPEN, label: 'Open' },
    { value: STATUS_APPROVED, label: 'Approved' },
    { value: STATUS_CLOSED, label: 'Closed' },
    { value: STATUS_ENDED, label: 'Ended' },
    { value: STATUS_PAUSED, label: 'Paused' },
    { value: STATUS_PENDING, label: 'Pending' },
    { value: STATUS_LOCKED, label: 'Locked' },
    { value: STATUS_DEACTIVATED, label: 'Deactivated' },
];

export const CREATING_IO_STATUS_OPTIONS = [
    {
        label: Status.Open,
        value: StatusId.Open,
    },
];

export const EDITING_IO_STATUS_OPTIONS = [
    ...CREATING_IO_STATUS_OPTIONS,

    {
        label: Status.Approved,
        value: StatusId.Approved,
    },
];

export const DISABLED_IO_STATUS_OPTIONS = [
    {
        label: Status.Pending,
        value: StatusId.Pending,
    },
    {
        label: Status.Approved,
        value: StatusId.Approved,
    },
];
