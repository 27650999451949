import { ValidationMessages } from './ValidationMessages';

export const requiredValidation = <Value extends any = string>(
    value?: Value
): ValidationMessages => {
    if (Array.isArray(value)) {
        return value.length
            ? ValidationMessages.Valid
            : ValidationMessages.Required;
    }
    return value ? ValidationMessages.Valid : ValidationMessages.Required;
};

export const minimumCurrencyValidation = ({
    value,
    minimumValue,
    currencySymbol,
}: {
    value?: number;
    minimumValue?: number;
    currencySymbol?: string;
}): string => {
    if (!value) {
        return requiredValidation(value);
    }

    if (minimumValue && value < minimumValue) {
        return `${ValidationMessages.InvalidCurrency} ${currencySymbol}${minimumValue}`;
    }

    return ValidationMessages.Valid;
};

export const emailValidation = (
    emailToValidate?: string
): ValidationMessages => {
    const regularExpression = new RegExp(
        '^(([^<>()[\\]\\.,;:\\s@"]+(\\.[^<>()[\\]\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'
    );
    if (!emailToValidate) {
        return requiredValidation(emailToValidate);
    }
    if (!regularExpression.test(emailToValidate.toLowerCase())) {
        return ValidationMessages.InvalidEmail;
    }
    return ValidationMessages.Valid;
};

export const passwordValidation = (value?: string): ValidationMessages => {
    const pattern = new RegExp(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$^*.[\]{}()?\-"!@#%&,><':;|_~`+=]).{8,}$/
    );
    if (!value) {
        return requiredValidation(value);
    }
    if (!pattern.test(value)) {
        return ValidationMessages.InvalidPassword;
    }
    return ValidationMessages.Valid;
};

export const postalCodeValidator = (value?: string): ValidationMessages => {
    if (!value) {
        return ValidationMessages.Required;
    }
    // TODO: improve pattern for postal codes should find one that better meets requirements
    const pattern = new RegExp(/^[a-zA-Z0-9-_ .-]*$/);
    if (!pattern.test(value)) {
        return ValidationMessages.InvalidPostalCode;
    }
    return ValidationMessages.Valid;
};

export const maxLengthValidator = ({
    maxLength,
    value,
    isRequired,
}: {
    maxLength: number;
    value?: string | string[];
    isRequired?: boolean;
}): ValidationMessages => {
    if (!value?.length && isRequired) {
        return requiredValidation(value);
    }
    if (value && value.length > maxLength) {
        return `${ValidationMessages.InvalidMax} ${maxLength}` as ValidationMessages;
    }

    return ValidationMessages.Valid;
};
