import { Box, Radio, RadioGroup, Stack } from '@chakra-ui/react';
import React from 'react';
import { ColorSchemes, Spacing } from '../../../ChakraTheme';
import { CFormError } from '../../../Typography';
import { ControlProps, OptionType } from '../../Types';

export interface CRadioProps extends ControlProps<string> {
    options: OptionType<string>[];
    isStacked?: boolean;
    hideError?: boolean;
    defaultValue?: string;
    variant: string;
    width?: string;
}

export const CRadio = ({
    options,
    onChange,
    inputValue,
    testId,
    errorText,
    hideError = false,
    isStacked = false,
    defaultValue,
    variant = '',
    width = '100%',
}: CRadioProps): JSX.Element => {
    return (
        <Box width={width}>
            <RadioGroup
                onChange={onChange}
                value={inputValue}
                defaultValue={defaultValue}
            >
                <Stack
                    direction={isStacked ? 'column' : 'row'}
                    gap={Spacing.SM}
                    flexWrap="wrap"
                >
                    {options.map(({ value, label }) => (
                        <Radio
                            variant={variant}
                            data-test={`${testId}--${value}--option`}
                            value={value}
                            key={value}
                            colorScheme={ColorSchemes.primary}
                        >
                            {label}
                        </Radio>
                    ))}
                </Stack>
            </RadioGroup>
            {!hideError && (
                <CFormError data-test={`${testId}--error`} text={errorText} />
            )}
        </Box>
    );
};
