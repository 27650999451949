import React, { useCallback, useContext, useEffect } from 'react';
import { FormSpy, useForm } from 'react-final-form';

import WizardFormContext from './WizardForm.context';
import WizardContext from './Wizard.context';

function WizardForm({
    children,
}: {
    children: JSX.Element | undefined;
}): JSX.Element {
    const { setDraft, setFormAPI, draft } = useContext(WizardContext);
    const form = useForm();

    const handleFormChange = useCallback(
        (props: any) => {
            setDraft?.(props?.values);
        },
        [setDraft]
    );

    const handleValueChange = useCallback(
        (fieldName: string, value: any) => {
            form?.change(fieldName, value);
            setDraft?.({
                ...draft,
                [fieldName]: value,
            });
        },
        [form, setDraft, draft]
    );

    useEffect(() => {
        setFormAPI?.(form);
    }, [form, setFormAPI]);

    const formContext = {
        setValue: handleValueChange,
    };

    return (
        <WizardFormContext.Provider value={formContext}>
            <FormSpy onChange={handleFormChange} />
            {children}
        </WizardFormContext.Provider>
    );
}

export default WizardForm;
