import { useSelector } from 'react-redux';
import { KoddiUser } from 'api/Admin/User';

import {
    selectAuthUser,
    selectAuthenticated,
    selectAuthStatus,
    selectAuthLoading,
    selectAuthError,
    selectEmail,
    selectUsername,
    selectAuthMessage,
} from './selectors';
import { AuthStateStatus } from './types';

/** Selected the current authenticated user from the redux auth state. */
export function useAuthUser(): KoddiUser | null {
    return useSelector(selectAuthUser);
}

/** Returns a boolean indicating whether or not a user is current authenticated. */
export function useAuthenticated(): boolean {
    const authenticated = useSelector(selectAuthenticated);
    return !!authenticated;
}

/** Selects the current auth status from the redux auth state. */
export function useAuthStatus(): AuthStateStatus {
    return useSelector(selectAuthStatus);
}

/** Returns a boolean indicating whether or not the auth store is currently fetching data for the current auth state. */
export function useAuthLoading(): boolean {
    return useSelector(selectAuthLoading);
}

/** Selects the current auth error from the redux auth state for the current auth status. */
export function useAuthError(): Error | null {
    return useSelector(selectAuthError);
}

/** Selects the email of the current authenticated user from the redux auth state. */
export function useEmail(): string | null {
    return useSelector(selectEmail);
}

/** Selects the username of the current authenticated user from the redux auth state. */
export function useUsername(): string | null {
    return useSelector(selectUsername);
}

/** Selects the username of the current authenticated user from the redux auth state. */
export function useAuthMessage(): string | null {
    return useSelector(selectAuthMessage);
}
