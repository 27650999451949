import { StyleProps } from '@chakra-ui/react';
import { ChangeEvent } from 'react';
import { BorderRadius, Colors } from '../../../ChakraTheme';
import { DynamicValueProp } from '../../Types';
import { CTextareaProps } from './Textarea.types';

export const useTextarea = ({
    onChange,
    inputValue,
    errorText,
    isControlledInput = false,
}: Pick<
    CTextareaProps,
    'onChange' | 'inputValue' | 'errorText' | 'isControlledInput'
>): {
    handleChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
    styleProps: StyleProps;
    valueProp: DynamicValueProp;
} => {
    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        onChange(e.target.value);
    };

    const styleProps = {
        borderColor: errorText ? Colors.error : Colors.grayLight,
        borderRadius: BorderRadius.SM,
        backgroundColor: Colors.white,
        _hover: {
            borderColor: Colors.gray,
        },
    };

    const valueProp = isControlledInput
        ? { value: inputValue }
        : { defaultValue: inputValue };

    return {
        handleChange,
        valueProp,
        styleProps,
    };
};
