import {
    useEffect,
    useState,
    useCallback,
    useRef,
    MutableRefObject,
    RefCallback,
} from 'react';
import { useKoddiTheme } from 'koddi-components/ThemeProvider';
import { formatDate, useLocale } from 'koddi-components/LocaleProvider';

import {
    createColorScheme,
    makeCancelablePromise,
    wasCanceledPromise,
    valueFormatter,
} from './utils';
import { CancelablePromise } from './types';

/** Keeps track of whether or not a component is still mounted in the dom. */
export function useIsMounted(): () => boolean {
    const isMountedRef = useRef(true);
    const isMounted = useCallback(() => isMountedRef.current, []);

    useEffect(() => {
        isMountedRef.current = true;
        return function cleanup() {
            isMountedRef.current = false;
        };
    }, []);

    return isMounted;
}
/**
 * Fixes an alignment issue with recharts
 *
 * Issue: https://github.com/recharts/recharts/issues/843
 */
export function useReChartsXAxisAlignment(): {
    yAxisWidth: number;
    handleChartRefCallback: (ref: any) => void;
} {
    const [yAxisWidth, setYAxisWidth] = useState<number>(0);
    const handleChartRefCallback = useCallback(
        (ref: any) => {
            if (ref?.container) {
                const container = ref.container as HTMLDivElement;
                const yAxis = container.getElementsByClassName('yAxis')[0];
                if (yAxis && !yAxisWidth) {
                    const yAxisRect = yAxis.getBoundingClientRect();
                    if (yAxisWidth !== yAxisRect.width) {
                        setYAxisWidth(yAxisRect.width);
                    }
                }
            }
        },
        [yAxisWidth]
    );
    return { yAxisWidth, handleChartRefCallback };
}

export function trendReportFormatter({
    dates,
    locale,
}: {
    dates: any[];
    locale: string;
}): any[] {
    const formattedResponse = dates?.map((data: any) => {
        return {
            ...data,
            date: formatDate(data.date, locale),
            prior_date: data?.prior_date
                ? formatDate(data?.prior_date, locale)
                : undefined,
        };
    });

    return formattedResponse;
}

export const useCurrencyFormatter = (): {
    currencyFormatter: (value: number) => string;
} => {
    const { currencySymbol, currencyDigits, locale } = useLocale();
    const currencyFormatter = useCallback(
        (value: number) =>
            valueFormatter(
                value,
                'currency',
                {
                    currencySymbol,
                    currencyDigits,
                },
                locale
            ),
        [locale, currencySymbol, currencyDigits]
    );
    return {
        currencyFormatter,
    };
};

/**
 * Generates a color scheme of 26 colors for a client
 * based on their graph theme colors.
 */
export function useChartColorScheme(): string[] {
    const { graphColors } = useKoddiTheme();
    const [colorScheme, setColorScheme] = useState<string[]>(
        createColorScheme(graphColors)
    );

    useEffect(() => {
        setColorScheme(createColorScheme(graphColors));
    }, [graphColors]);

    return colorScheme;
}

/**
 * Custom hook for storing the previous value of some data
 * for comparison.
 * @param value The initial value.
 */
export function usePrevious<Value>(value: Value): Value | undefined {
    const ref = useRef<Value>();

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
}

/**
 * A custom hook to check if a prop has changed value.
 * This hook uses shallow comparison.
 * @param currentValue The current value
 * @param onChange The callback to execute if the prop changes.
 */
export function useOnPropUpdated<Value>(
    currentValue: Value,
    onChange: (prev?: Value, current?: Value) => void
): void {
    const previousValue = usePrevious<Value>(currentValue);

    useEffect(() => {
        if (previousValue !== undefined && previousValue !== currentValue) {
            onChange();
        }
    }, [currentValue, previousValue, onChange]);
}

/**
 * A custom hook for creating promises that can be cancelled to prevent
 * memory leaks and to ensure that a component is still mounted whenever
 * state changes are triggered after a promise is resolved.
 * @param rejectOnCancel Whether or not to throw a Promise rejection when the promise is canceled manually.
 */
export function useCancelablePromise<Data>(
    rejectOnCancel: boolean,
    cancelPreviousRequest?: boolean
): (
    promise: Promise<Data>,
    resolve: (data: Data) => void,
    reject?: (e: any) => void
) => void {
    const promises = useRef<ReturnType<typeof makeCancelablePromise>[]>([]);

    const pendingPromise = useRef<{
        promise: CancelablePromise<Data>;
        cancel: VoidFunction;
    } | null>(null);

    const cancellablePromise = useCallback(
        (
            promise: Promise<Data>,
            resolve: (data: Data) => void,
            reject?: (e: any) => void
        ) => {
            const currentPromise = makeCancelablePromise<Data>(
                promise,
                rejectOnCancel
            );
            promises.current.push(currentPromise);
            if (cancelPreviousRequest) pendingPromise.current?.cancel();
            pendingPromise.current = currentPromise;

            currentPromise.promise
                .then((result) => {
                    if (!wasCanceledPromise(result)) {
                        resolve(result as Data);
                    }
                })
                .catch((e) => {
                    if (!wasCanceledPromise(e) || rejectOnCancel) {
                        if (reject) reject(e);
                    }
                });
        },
        [cancelPreviousRequest, rejectOnCancel]
    );

    useEffect(() => {
        promises.current = promises.current || [];
        return function cancel() {
            if (cancelPreviousRequest) {
                pendingPromise.current?.cancel();
            }
            promises.current.forEach((promise) => promise.cancel());
            promises.current = [];
            pendingPromise.current = null;
        };
    }, [cancelPreviousRequest]);

    return cancellablePromise;
}

/**
 * Custom hook to check if ref is an object or a callback and return as object
 * @see https://medium.com/the-non-traditional-developer/how-to-use-the-forwarded-ref-in-react-1fb108f4e6af
 * @param ref - The passed ref prop.
 * @returns The ref as object
 */
export function useForwardedRef<E extends HTMLElement = HTMLElement>(
    ref: string | RefCallback<E> | MutableRefObject<E | null> | null | undefined
): MutableRefObject<E | null> {
    const innerRef = useRef<E | null>(null);
    useEffect(() => {
        if (!ref || typeof ref === 'string' || ref instanceof String) {
            return;
        }
        if (typeof ref === 'function') {
            ref(innerRef.current);
        } else {
            ref.current = innerRef.current;
        }
    });

    return innerRef;
}

/** A hook for keeping track of the first mount. */
export function useIsFirstMount(): boolean {
    const [firstMount, setFirstMount] = useState(true);

    useEffect(() => {
        if (firstMount) {
            setFirstMount(false);
        }
    }, [firstMount]);

    return firstMount;
}

/* A hook that sets the current title of the document. */
export const useDocumentTitle = (title: string): void => {
    useEffect(() => {
        document.title = title;
    }, [title]);
};

export const useFavicon = (faviconURL: string): void => {
    useEffect(() => {
        const favicon: any = document.getElementById('favicon');
        if (favicon && faviconURL) {
            favicon.href = faviconURL;
            favicon.type = 'image/x-icon';
            favicon.rel = 'shortcut icon';
        }
    }, [faviconURL]);
};

export const useOnFirstRender = (fn: () => any): void => {
    const [hasRendered, setHasRendered] = useState(false);
    useEffect(() => {
        if (hasRendered) return;
        fn();
        setHasRendered(true);
    }, [fn, hasRendered]);
};
