import { KeyboardEvent, useState } from 'react';
import { useForm } from 'react-final-form';
import { BorderRadius, BorderWidth, Spacing } from '../../../ChakraTheme';
import { EmailGrabberHookReturn } from './EmailGrabber.types';

export const useEmailGrabber = ({
    name,
    inputValue,
    onChange,
    errorText,
}: {
    name: string;
    inputValue?: string[];
    onChange?: (value: string[]) => void;
    errorText?: string;
}): EmailGrabberHookReturn => {
    const [emails, setEmails] = useState(inputValue ?? []);
    const styles = {
        height: '150px',
        overflow: 'auto',
        color: 'var(--chakra-colors-black)',
        borderColor: errorText
            ? 'var(--chakra-colors-red-500)'
            : 'var(--chakra-colors-grayLight)',
        borderWidth: BorderWidth.Thinnest,
        borderRadius: BorderRadius.SM,
        gap: Spacing.XXS,
    };
    const { change } = useForm();
    const handleChange = (_emails: string[]) => {
        if (onChange) {
            onChange(_emails);
        }
        setEmails(_emails);
        change(name, _emails);
    };
    const isEmailValid = (emailToValidate: string) => {
        const regularExpression = new RegExp(
            '^(([^<>()[\\]\\.,;:\\s@"]+(\\.[^<>()[\\]\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'
        );

        return regularExpression.test(emailToValidate.toLowerCase());
    };

    const handleKeyEvent = (event: KeyboardEvent<HTMLInputElement>) => {
        const { key, target } = event;
        const { value } = target as HTMLInputElement;
        if (key === 'Tab' && value) {
            event.preventDefault();
        }
        return { key, value, target: target as HTMLInputElement };
    };

    const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
        const { key, value, target } = handleKeyEvent(event);
        if (key === 'Tab' && isEmailValid(value)) {
            const { value: eventValue } = event.target as HTMLInputElement;
            handleChange([...emails, eventValue]);
            target.blur();
            target.focus();
        }
    };

    return {
        emails,
        setEmails,
        styles,
        handleChange,
        handleKeyUp,
        handleKeyEvent,
    };
};
