import { useCallback } from 'react';
import { usePermissions } from '../../../react-ui/contexts/PermissionsProvider/PermissionsProvider.hook';
import { checkRoles, checkPermissions } from './CanView.utils';

type CheckPermissionAndRoleArgs = {
    role?: number | number[];
    permission?: string | string[];
    condition?: any;
    anyRole?: boolean;
    anyPermission?: boolean;
};

export const useCanView = (): {
    checkPermissionsAndRole: ({
        ...args
    }: CheckPermissionAndRoleArgs) => boolean;
} => {
    const { map: userPermissions, roles: userRoles } = usePermissions();

    const checkPermissionsAndRole = useCallback(
        ({
            condition,
            role = [],
            permission = [],
            anyRole = false,
            anyPermission = false,
        }: CheckPermissionAndRoleArgs): boolean => {
            const permissionRoleList = Array.isArray(role) ? role : [role];
            const permissionList = Array.isArray(permission)
                ? permission
                : [permission];

            const hasRole =
                userRoles && role
                    ? checkRoles(userRoles, permissionRoleList, anyRole)
                    : true;
            const havePermission =
                userPermissions && permission
                    ? checkPermissions(
                          permissionList,
                          userPermissions,
                          anyPermission
                      )
                    : true;

            const meetsCondition =
                condition || condition === false ? condition : true;
            return !!(hasRole && havePermission && meetsCondition);
        },
        [userRoles, userPermissions]
    );

    return { checkPermissionsAndRole };
};
