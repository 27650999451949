import {
    Alert,
    AlertIcon,
    AlertTitle,
    Box,
    CloseButton,
    Collapse,
} from '@chakra-ui/react';
import React from 'react';
import { AlertProps } from './Alert.types';
import { useAlert } from './Alert.hooks';
import { CText } from '../Typography';
import { Spacing } from '../ChakraTheme';

export const CAlertBase = ({
    isDismissable = false,
    title,
    description,
    onClose,

    ...props
}: AlertProps): JSX.Element => {
    const { isHidden, handleClose } = useAlert({ onClose });
    return (
        <Collapse in={!isHidden}>
            <Alert {...props}>
                <AlertIcon />
                <Box maxW="1200px">
                    {title && <AlertTitle>{title}</AlertTitle>}
                    <CText>{description}</CText>
                </Box>
                {isDismissable && (
                    <CloseButton
                        alignSelf="flex-start"
                        ml={Spacing.SM}
                        onClick={handleClose}
                    />
                )}
            </Alert>
        </Collapse>
    );
};

export const CWarningAlert = ({
    ...props
}: Omit<AlertProps, 'status'>): JSX.Element => (
    <CAlertBase status="warning" {...props} />
);

export const CSuccessAlert = ({
    ...props
}: Omit<AlertProps, 'status'>): JSX.Element => (
    <CAlertBase status="warning" {...props} />
);

export const CErrorAlert = ({
    ...props
}: Omit<AlertProps, 'status'>): JSX.Element => (
    <CAlertBase status="error" {...props} />
);

export const CInfoAlert = ({
    ...props
}: Omit<AlertProps, 'status'>): JSX.Element => (
    <CAlertBase status="info" {...props} />
);
