import React, { FunctionComponent } from 'react';
import { BaseSVGProps } from '../icon.types';

const PaperIcon: FunctionComponent<BaseSVGProps> = ({ width, height }) => (
    <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        height={height}
        width={width}
        viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet"
    >
        <g
            transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill="#000000"
            stroke="none"
        >
            <path
                d="M944 4832 c-100 -35 -184 -117 -226 -220 l-23 -57 -3 -2075 -2 -2075
                22 -45 c34 -66 79 -93 149 -88 59 4 66 9 509 314 l106 72 229 -165 c309 -223
                310 -223 364 -223 26 0 58 7 73 14 15 8 115 92 223 186 l196 171 195 -171
                c107 -95 207 -178 222 -186 15 -8 48 -14 73 -14 54 0 42 -8 366 224 122 87
                224 158 228 159 4 0 131 -85 283 -189 265 -181 278 -188 331 -192 66 -4 111
                20 145 78 l21 35 0 2085 0 2085 -23 57 c-29 71 -75 129 -137 174 -97 68 15 64
                -1709 64 -1462 -1 -1564 -2 -1612 -18z m2553 -858 c98 -73 98 -195 0 -268 -28
                -21 -31 -21 -937 -21 -906 0 -909 0 -937 21 -89 66 -99 179 -21 251 12 12 34
                26 48 32 16 6 340 9 922 8 893 -2 897 -2 925 -23z m-17 -846 c66 -34 93 -79
                88 -149 -5 -65 -32 -109 -82 -133 -31 -14 -124 -16 -926 -16 -802 0 -895 2
                -926 16 -50 24 -77 68 -82 133 -5 70 22 115 88 149 l44 22 876 0 876 0 44 -22z
                m-840 -860 c48 -33 72 -70 77 -120 7 -58 -23 -118 -74 -149 l-38 -24 -460 -3
                -460 -3 -45 23 c-66 34 -93 79 -88 149 5 64 31 108 80 132 29 15 91 17 504 17
                l471 0 33 -22z"
            />
        </g>
    </svg>
);

export default PaperIcon;
