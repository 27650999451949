export const chineseLocalization = {
    delimiters: {
        thousands: ',',
        decimal: '.',
    },
    abbreviations: {
        thousand: '千',
        million: '百万',
        billion: '十亿',
        trillion: '兆',
    },
    ordinal: (): string => {
        return '.';
    },
    currency: {
        symbol: '¥',
    },
};

export default chineseLocalization;
