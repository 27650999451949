import { ReduxActionWithPayload, ReduxAction } from 'types';
import { MemberGroup, MemberGroupFundingType } from 'api/Admin/MemberGroup';
import { AdvertiserGroup } from 'api/Admin/AdvertiserGroups';
import { CurrencyList } from 'api/Admin/Currency';
import { BiddingConfigReport } from 'api/Admin/Currency/Currency.types';
import { Advertiser } from 'app/react-ui/api/Advertiser';

export type DefaultMemberGroup = {
    member_group_id: number;
};

export type AdvertiserGroupError = {
    id: number;
};

export type ContextMemberGroup =
    | (Partial<MemberGroup> & {
          funding_types?: MemberGroupFundingType[];
      })
    | null;
export type ContextAdvertiser =
    | (Partial<Advertiser> & {
          metadata?: Record<string, unknown>;
      })
    | null;

export type AppContextState = {
    memberGroup: ContextMemberGroup;
    previousMemberGroupId: number | null;
    advertiser: ContextAdvertiser;
    advertiserHasFunds: boolean;
    advertiserGroup: AdvertiserGroup | AdvertiserGroupError | null;
    status: ContextStatuses;
    initialized: boolean;
    errorCode: number | null;
    memberGroupCurrencies: CurrencyList | null;
    calculatedPageCurrency: number | null;
    memberGroupBiddingConfigs: BiddingConfigReport | null;
    savedReportTitle: string | null;
};

export type MemberGroupIndustrySelectorReturn = {
    singular: string;
    plural: string;
};

export enum AppContextAction {
    FETCH_APP_CONTEXT = 'app/context/FETCH_APP_CONTEXT',

    CLEAR_CLIENT_CONTEXT = 'app/context/CLEAR_CLIENT_CONTEXT',
    CLEAR_ADVERTISER_CONTEXT = 'app/context/CLEAR_ADVERTISER_CONTEXT',
    CLEAR_ADVERTISER_GROUP_CONTEXT = 'app/context/CLEAR_ADVERTISER_GROUP_CONTEXT',

    FETCH_CLIENT_CONTEXT = 'app/context/FETCH_CLIENT_CONTEXT',
    FETCH_ADVERTISER_CONTEXT = 'app/context/FETCH_ADVERTISER_CONTEXT',
    FETCH_ADVERTISER_GROUP_CONTEXT = 'app/context/FETCH_ADVERTISER_GROUP_CONTEXT',

    FETCH_APP_CONTEXT_SUCCESS = 'app/context/FETCH_APP_CONTEXT_SUCCESS',
    FETCH_APP_CONTEXT_ERROR = 'app/context/FETCH_APP_CONTEXT_ERROR',
    FETCH_EMBEDDED_APP_CONTEXT = 'app/context/FETCH_EMBEDDED_APP_CONTEXT',
    SET_PREVIOUS_MEMBER_GROUP = 'app/context/SET_PREVIOUS_MEMBER_GROUP',
    SET_ERROR_CODE = 'app/context/SET_ERROR_CODE',

    SET_MEMBER_GROUP_CURRENCIES = 'app/context/SET_MEMBER_GROUP_CURRENCIES',
    SET_MEMBER_GROUP_BIDDING_CONFIGS = 'app/context/SET_MEMBER_GROUP_BIDDING_CONFIGS',
    SET_CALCULATED_PAGE_CURRENCY = 'app/context/SET_CALCULATED_PAGE_CURRENCY',
    SET_ADVERTISER_HAS_FUNDS = 'app/context/SET_ADVERTISER_HAS_FUNDS',
    SET_SAVED_REPORT_TITLE = 'app/context/SET_SAVED_REPORT_TITLE',
    SET_ADVERTISER_CONTENT = 'app/context/SET_ADVERTISER_CONTENT',
    SET_CLIENT_CONTEXT = 'app/context/SET_CLIENT_CONTEXT',
}

export type FetchClientContextAction = ReduxActionWithPayload<
    AppContextAction.FETCH_CLIENT_CONTEXT,
    {
        memberGroupId: number;
        advertiserId?: number | null;
        advertiserGroupId?: number | null;
    }
>;

export type FetchAdvertiserContextAction = ReduxActionWithPayload<
    AppContextAction.FETCH_ADVERTISER_CONTEXT,
    {
        advertiserId: number;
        memberGroupId?: number | null;
    }
>;

export type FetchAdvertiserGroupContextAction = ReduxActionWithPayload<
    AppContextAction.FETCH_ADVERTISER_GROUP_CONTEXT,
    {
        advertiserGroupId: number;
        memberGroupId?: number | null;
    }
>;

export type FetchEmbeddedAppContextAction = ReduxActionWithPayload<
    AppContextAction.FETCH_EMBEDDED_APP_CONTEXT,
    {
        memberGroupId: number;
    }
>;

export type FetchAppContextSuccessAction = ReduxActionWithPayload<
    AppContextAction.FETCH_APP_CONTEXT_SUCCESS,
    {
        memberGroup: ContextMemberGroup;
        advertiser: ContextAdvertiser;
        advertiserGroup: AdvertiserGroup | null;
    }
>;

export type FetchAppContextError = ReduxActionWithPayload<
    AppContextAction.FETCH_APP_CONTEXT_ERROR,
    {
        memberGroupId: number;
        advertiserId: number | null;
        advertiserGroupId: number | null;
    }
>;

export type SetPreviousMemberGroup = ReduxActionWithPayload<
    AppContextAction.SET_PREVIOUS_MEMBER_GROUP,
    {
        previousMemberGroupId: number | null;
    }
>;

export type SetSavedReportTitle = ReduxActionWithPayload<
    AppContextAction.SET_SAVED_REPORT_TITLE,
    {
        savedReportTitle: string | null;
    }
>;

export type SetErrorCodeAction = ReduxActionWithPayload<
    AppContextAction.SET_ERROR_CODE,
    {
        errorCode: number | null;
    }
>;

export type ClearClientContext = ReduxAction<
    AppContextAction.CLEAR_CLIENT_CONTEXT
>;

export type ClearAdvertiserContext = ReduxAction<
    AppContextAction.CLEAR_ADVERTISER_CONTEXT
>;

export type ClearAdvertiserGroupContext = ReduxAction<
    AppContextAction.CLEAR_ADVERTISER_GROUP_CONTEXT
>;

export type SetMemberGroupCurrencies = ReduxActionWithPayload<
    AppContextAction.SET_MEMBER_GROUP_CURRENCIES,
    {
        memberGroupCurrencies: CurrencyList;
    }
>;
export type SetMemberGroupBiddingConfigs = ReduxActionWithPayload<
    AppContextAction.SET_MEMBER_GROUP_BIDDING_CONFIGS,
    {
        biddingConfigs: BiddingConfigReport;
    }
>;

export type SetCalculatedPageCurrency = ReduxActionWithPayload<
    AppContextAction.SET_CALCULATED_PAGE_CURRENCY,
    {
        calculatedPageCurrency: number;
    }
>;

export type SetAdvertiserHasFunds = ReduxActionWithPayload<
    AppContextAction.SET_ADVERTISER_HAS_FUNDS,
    {
        hasFunds: boolean;
    }
>;

export type SetAdvertiserContext = ReduxActionWithPayload<
    AppContextAction.SET_ADVERTISER_CONTENT,
    {
        advertiser: ContextAdvertiser;
    }
>;

export type SetClientContext = ReduxActionWithPayload<
    AppContextAction.SET_CLIENT_CONTEXT,
    {
        memberGroup: ContextMemberGroup;
    }
>;

export type AppContextActions =
    | FetchAppContextSuccessAction
    | FetchAppContextError
    | FetchClientContextAction
    | FetchAdvertiserContextAction
    | FetchAdvertiserGroupContextAction
    | SetPreviousMemberGroup
    | SetErrorCodeAction
    | ClearClientContext
    | ClearAdvertiserContext
    | ClearAdvertiserGroupContext
    | SetMemberGroupCurrencies
    | SetMemberGroupBiddingConfigs
    | SetCalculatedPageCurrency
    | SetAdvertiserHasFunds
    | SetAdvertiserHasFunds
    | SetSavedReportTitle
    | FetchEmbeddedAppContextAction
    | SetAdvertiserContext
    | SetClientContext
    | { type: 'ROUTE_MATCH_CHANGED' };

export enum ContextStatuses {
    Idle = 'idle',
    Pending = 'pending',
    PendingAdvertiser = 'pending-advertiser',
    PendingAdvertiserGroup = 'pending-advertiser-group',
    Success = 'success',
    Error = 'error',
}
