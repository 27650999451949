import { Box } from '@chakra-ui/react';
import React from 'react';
import { FontSizes, Spacing } from '../../ChakraTheme';
import { CRow } from '../../Layouts';
import { CText } from '../../Typography';

export const CChartLegend = ({
    color,
    label,
    noWrap = false,
}: {
    color: string;
    label: string;
    noWrap?: boolean;
}): JSX.Element => (
    <CRow
        gap={Spacing.XS}
        alignItems="center"
        justifyContent="start"
        width="initial"
        noBreak
        flexWrap="nowrap"
    >
        <Box height="16px" width="16px" as="span" bgColor={color} />
        <CText text={label} fontSize={FontSizes.md} noWrap={noWrap} />
    </CRow>
);
