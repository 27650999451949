import { ToastId, useToast, UseToastOptions } from '@chakra-ui/react';
import { useMemo, useRef } from 'react';

// TODO: We need a better way to do this, pulling application specific code into a shared component is not a good idea
import { logAction } from 'utils/ddLogging';

import { useCToastReturn } from './Toast.types';

export function useCToast(): useCToastReturn {
    const toast = useToast();
    const activeToastsRef = useRef<ToastId[]>([]);

    const showBaseToast = (options: UseToastOptions) => {
        const duration = options?.duration || 2500;
        // hack for toast.isActive doesn't work in current chakra version

        if (options?.id && activeToastsRef.current.includes(options?.id))
            return;
        if (options?.id) {
            activeToastsRef.current = [...activeToastsRef.current, options.id];
            setTimeout(() => {
                activeToastsRef.current = activeToastsRef.current.filter(
                    (id) => id !== options.id
                );
            }, duration + 500);
        }

        toast({
            position: 'bottom-right',
            variant: 'left-accent',
            duration,
            isClosable: true,
            ...options,
        });
    };

    const showSuccessToast = (
        title: string,
        description?: string | JSX.Element,
        options: Omit<UseToastOptions, 'title' | 'description'> = {}
    ) => {
        showBaseToast({ title, description, status: 'success', ...options });
    };

    const showErrorToast = (
        title: string,
        description?: string,
        options: Omit<UseToastOptions, 'title' | 'description'> = {}
    ) => {
        logAction('error-toast', description);
        showBaseToast({ title, description, status: 'error', ...options });
    };

    const showWarningToast = (
        title: string,
        description?: string | JSX.Element,
        options: Omit<UseToastOptions, 'title' | 'description'> = {}
    ) => {
        showBaseToast({ title, description, status: 'warning', ...options });
    };

    const showInfoToast = (
        title: string,
        description?: string | JSX.Element,
        options: Omit<UseToastOptions, 'title' | 'description'> = {}
    ) => {
        showBaseToast({ title, description, status: 'info', ...options });
    };

    const showNotificationToast = ({
        title,
        status,
        description,
        options = {},
        id,
    }: {
        title: string;
        status: UseToastOptions['status'];
        description?: string | JSX.Element;
        id: number;
        options: Omit<
            UseToastOptions,
            'title' | 'description' | 'variant' | 'status' | 'id'
        >;
    }) => {
        showBaseToast({
            id,
            title,
            description,
            status,
            ...options,
        });
    };

    return useMemo(
        () => ({
            showSuccessToast,
            showErrorToast,
            showWarningToast,
            showInfoToast,
            showNotificationToast,
            toast,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );
}
