import React from 'react';

import { WizardContext } from './Wizard.types';

export const WizardContextValue = React.createContext<WizardContext>({
    steps: [],
    setIsLoading: () => null,
});

export default WizardContextValue;
