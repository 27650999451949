import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const CChevronRightIcon = createIcon({
    displayName: 'CChevronRightIcon',
    viewBox: '0 0 512 512',
    // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
    path: (
        <path d="m121.373 457.373 201.372-201.373-201.372-201.373a32 32 0 0 1 45.254-45.254l224 224a32 32 0 0 1 0 45.254l-224 224a32 32 0 0 1 -45.254-45.254z" />
    ),
});

export const CChevronLeftIcon = createIcon({
    displayName: 'CChevronLeftIcon',
    viewBox: '0 0 512 512',
    // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
    path: (
        <path d="m390.627 54.627-201.372 201.373 201.372 201.373a32 32 0 1 1 -45.254 45.254l-224-224a32 32 0 0 1 0-45.254l224-224a32 32 0 0 1 45.254 45.254z" />
    ),
});

export const CChevronDownIcon = createIcon({
    displayName: 'CChevronDownIcon',
    viewBox: '0 0 58.026 58.026',
    // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
    path: (
        <g>
            <path
                id="XMLID_467_"
                d="m29.013 46.019c-1.1 0-2.1-.4-2.9-1.2l-25-26c-1.5-1.6-1.5-4.1.1-5.7 1.6-1.5 4.1-1.5 5.7.1l22.1 23 22.1-23c1.5-1.6 4.1-1.6 5.7-.1s1.6 4.1.1 5.7l-25 26c-.8.8-1.8 1.2-2.9 1.2z"
            />
        </g>
    ),
});

export const CChevronUpIcon = createIcon({
    displayName: 'CChevronUpIcon',
    viewBox: '0 0 367.676 367.676',
    // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
    path: (
        <g>
            <path d="m344.696 287.246c-5.881 0-11.763-2.243-16.249-6.73l-144.61-144.609-144.608 144.609c-8.973 8.975-23.524 8.975-32.498 0-8.974-8.974-8.974-23.524 0-32.498l160.857-160.859c4.31-4.309 10.154-6.731 16.249-6.731 6.094 0 11.94 2.422 16.25 6.731l160.858 160.858c8.974 8.974 8.974 23.524 0 32.498-4.486 4.488-10.368 6.731-16.249 6.731z" />
        </g>
    ),
});

// Thin
export const CChevronDownThinIcon = createIcon({
    displayName: 'CThinChevronUpIcon',
    viewBox: '0 0 128 128',
    // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
    path: (
        <path d="m64 88c-1.023 0-2.047-.391-2.828-1.172l-40-40c-1.563-1.563-1.563-4.094 0-5.656s4.094-1.563 5.656 0l37.172 37.172 37.172-37.172c1.563-1.563 4.094-1.563 5.656 0s1.563 4.094 0 5.656l-40 40c-.781.781-1.805 1.172-2.828 1.172z" />
    ),
});

export const CChevronUpThinIcon = createIcon({
    displayName: 'CThinChevronUpIcon',
    viewBox: '0 0 2000 2000',
    // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
    path: (
        <path d="m1744 1436c-16.4 0-32.8-6.2-45.3-18.7l-698.7-698.8-698.7 698.7c-25 25-65.5 25-90.5 0s-25-65.5 0-90.5l744-744c25-25 65.5-25 90.5 0l744 744c25 25 25 65.5 0 90.5-12.5 12.6-28.9 18.8-45.3 18.8z" />
    ),
});

export const CChevronRightThinIcon = createIcon({
    displayName: 'CThinChevronRightIcon',
    viewBox: '0 0 128 128',
    // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
    path: (
        <path d="m44 108c-1.023 0-2.047-.391-2.828-1.172-1.563-1.563-1.563-4.094 0-5.656l37.172-37.172-37.172-37.172c-1.563-1.563-1.563-4.094 0-5.656s4.094-1.563 5.656 0l40 40c1.563 1.563 1.563 4.094 0 5.656l-40 40c-.781.781-1.805 1.172-2.828 1.172z" />
    ),
});

export const CChevronLeftThinIcon = createIcon({
    displayName: 'CThinChevronLeftIcon',
    viewBox: '0 0 128 128',
    // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
    path: (
        <path d="m84 108c-1.023 0-2.047-.391-2.828-1.172l-40-40c-1.563-1.563-1.563-4.094 0-5.656l40-40c1.563-1.563 4.094-1.563 5.656 0s1.563 4.094 0 5.656l-37.172 37.172 37.172 37.172c1.563 1.563 1.563 4.094 0 5.656-.781.781-1.805 1.172-2.828 1.172z" />
    ),
});
