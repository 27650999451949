import { Experience, ExperienceDetails } from 'api/Admin/types';
import { ReduxAction, ReduxActionWithPayload } from 'types';

export type ExperiencesState = {
    memberGroupExperiences: Experience[] | null;
    advertiserExperiences: Experience[] | null;
    selectedExperienceDetails: ExperienceDetails | null;
};

export enum ExperiencesAction {
    SET_MEMBER_GROUP_EXPERIENCES = 'Admin/types/SET_MEMBER_GROUP_EXPERIENCES',
    SET_ADVERTISER_EXPERIENCES = 'Admin/types/SET_ADVERTISER_EXPERIENCES',
    SET_SELECTED_EXPERIENCE_DETAILS = 'Admin/types/SET_SELECTED_EXPERIENCE_DETAILS',
    CLEAR_SELECTED_EXPERIENCE_DETAILS = 'Admin/types/CLEAR_SELECTED_EXPERIENCE_DETAILS',
}

export type SetMemberGroupExperiencesAction = ReduxActionWithPayload<
    ExperiencesAction.SET_MEMBER_GROUP_EXPERIENCES,
    {
        experiences: Experience[];
    }
>;
export type SetAdvertiserExperiencesAction = ReduxActionWithPayload<
    ExperiencesAction.SET_ADVERTISER_EXPERIENCES,
    {
        experiences: Experience[];
    }
>;

export type SetSelectedExperienceDetailsAction = ReduxActionWithPayload<
    ExperiencesAction.SET_SELECTED_EXPERIENCE_DETAILS,
    {
        experience: ExperienceDetails;
    }
>;
export type ClearSelectedExperienceDetailsAction = ReduxAction<
    ExperiencesAction.CLEAR_SELECTED_EXPERIENCE_DETAILS
>;

export type ExperiencesActions =
    | SetMemberGroupExperiencesAction
    | SetAdvertiserExperiencesAction
    | SetSelectedExperienceDetailsAction
    | ClearSelectedExperienceDetailsAction;
