import styled, { css } from 'styled-components';

export const Label = styled.label<{ showRequired?: boolean }>`
    ${({ theme, showRequired }) => css`
        display: block;
        font-size: ${theme.typography.baseFontSize};
        margin-bottom: ${theme.spaceUnit * 3}px;
        white-space: normal;
        word-break: break-word;
        &:hover {
            cursor: pointer;
        }
        ${showRequired &&
        `
            &:after {
                visibility: visible;
                color: red;
                content: ' *';
            }
            `}
    `}
`;
