import 'react-datepicker/dist/react-datepicker.css';
import styled, { css } from 'styled-components';
import Icon from 'koddi-components/Icon';
import { HorizontalFlexBox } from 'koddi-components/FlexBox';
import { IconTooltipWrapper } from 'koddi-components/Tooltip';

/** Creates the base styles for all Koddi Date Pickers */
export const BaseSingleDatePickerStyles = styled.div<{ v2: boolean }>`
    ${({ theme, v2 }) => css`
        border: 0px;
        .react-datepicker {
            border: 0px;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
        }
        .react-datepicker__triangle {
            display: none;
        }
        .react-datepicker-popper {
            transform: none !important;
            top: 100% !important;
        }
        .react-datepicker-wrapper {
            width: 100%;
        }
        /** Calendar Week Day Header */
        .react-datepicker__header {
            background: ${v2 ? theme.white : theme.black};
            color: ${v2 ? theme.primary : theme.white};
            border-radius: 0px;
            border-bottom: ${v2 && 'none'};
            padding: ${theme.spaceUnit * 4}px ${theme.spaceUnit * 4}px 0px
                ${theme.spaceUnit * 4}px;
        }
        .react-datepicker__month {
            margin: 0px;
            padding: ${theme.spaceUnit * 2}px ${theme.spaceUnit * 4}px;
        }
        .react-datepicker__day-name,
        .react-datepicker__day {
            width: 32px;
            height: 30px;
            line-height: 30px;
        }
        .react-datepicker__day-name {
            color: ${v2 ? theme.gray : theme.white};
            margin: 0px;
            height: 36px;
            line-height: 36px;
            text-transform: ${v2 ? 'uppercase' : 'capitalize'};
            font-size: ${v2 && 'smaller'};
            width: 32px;
        }
        /** Calendar Week Row */
        .react-datepicker__day-names,
        .react-datepicker__week {
            display: flex;
        }
        /** Calendar Day Default */
        .react-datepicker__day {
            margin: 4px 0px;
            border-radius: 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
        }
        .react-datepicker__day:focus {
            outline: none;
        }
        /** Calendar Day Outside of Current Month */
        .react-datepicker__day--outside-month {
            visibility: ${v2 ? '' : 'hidden'};
            pointer-events: none;
            color: ${theme.grayLight};
        }
        /** Calendar Day Selected */
        .react-datepicker__day--selected {
            background: ${theme.grayLight};
            color: ${v2 ? theme.primary : theme.black};
            position: relative;
            z-index: 2;
            font-weight: bold;
            border-radius: 100%;
            border: ${v2 ? 'none' : `2px solid ${theme.gray}`};
            text-decoration: ${v2 && 'underline'};
        }
        .react-datepicker__day--selected::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #ffffff;
            z-index: -1;
            border-radius: 100%;
            border: 0px;
        }
        /** Calendar Day Keyboard Selected */
        .react-datepicker__day--keyboard-selected {
            background: transparent;
            color: #000000;
        }
        .react-datepicker__day--keyboard-selected:hover {
            background: ${theme.grayLight};
        }
    `}
`;

export const LabelWrapper = styled(HorizontalFlexBox)<{ visibility: string }>`
    ${({ visibility }) => {
        return css`
            & > label:after {
                color: red;
                content: ' *';
                visibility: ${visibility};
            }
        `;
    }}
`;
export const SingleDatePickerWrapper = styled.div`
    display: flex;
    position: relative;
`;

export const SingleDatePickerContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const SingleDatePickerCheckboxContainer = styled.div`
    display: flex;
    flex-direction: column;
    ${({ theme: { spaceUnit } }) => css`
        padding: ${spaceUnit}px ${spaceUnit * 6}px;
    `}
`;

export const InputIcon = styled(Icon)`
    ${({ theme: { spaceUnit } }) => css`
        margin-right: ${spaceUnit * 4}px;
    `}
`;

export const LabelIcon = styled(IconTooltipWrapper)`
    margin-bottom: 12px;
    margin-left: 3px;
`;
