import { Experience, ExperienceDetails } from 'api/Admin/types';
import {
    ClearSelectedExperienceDetailsAction,
    ExperiencesAction,
    SetAdvertiserExperiencesAction,
    SetMemberGroupExperiencesAction,
    SetSelectedExperienceDetailsAction,
} from './types';

export function setMemberGroupExperiences(
    experiences: Experience[]
): SetMemberGroupExperiencesAction {
    return {
        type: ExperiencesAction.SET_MEMBER_GROUP_EXPERIENCES,
        payload: {
            experiences,
        },
    };
}

export function setAdvertiserExperiences(
    experiences: Experience[]
): SetAdvertiserExperiencesAction {
    return {
        type: ExperiencesAction.SET_ADVERTISER_EXPERIENCES,
        payload: {
            experiences,
        },
    };
}

export function setSelectedExperienceDetails(
    experience: ExperienceDetails
): SetSelectedExperienceDetailsAction {
    return {
        type: ExperiencesAction.SET_SELECTED_EXPERIENCE_DETAILS,
        payload: {
            experience,
        },
    };
}

export function clearSelectedExperienceDetails(): ClearSelectedExperienceDetailsAction {
    return {
        type: ExperiencesAction.CLEAR_SELECTED_EXPERIENCE_DETAILS,
    };
}
