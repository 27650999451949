/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/ban-types */
import React, { forwardRef } from 'react';
import { StyledComponent, DefaultTheme } from 'styled-components';
import {
    StyledBodyText,
    StyledErrorText,
    StyledHeading3Text,
    StyledSmallText,
    StyledLabelText,
    StyledWarningText,
    StyledSuccessText,
    StyledHeading1Text,
    StyledHeading2Text,
    StyledHeading4Text,
} from './Typography.styled';
import {
    TypographyProps,
    TypographyType,
    TypographyComponentProps,
    TypographyPropsWithRef,
    TypographyColor,
} from './Typography.types';

const Components: Record<
    TypographyType,
    StyledComponent<
        'div' | 'label' | 'span' | 'p',
        DefaultTheme,
        TypographyComponentProps,
        any
    >
> = {
    body: StyledBodyText,
    error: StyledErrorText,
    warning: StyledWarningText,
    success: StyledSuccessText,
    label: StyledLabelText,
    h1: StyledHeading1Text,
    h2: StyledHeading2Text,
    h3: StyledHeading3Text,
    h4: StyledHeading4Text,
    small: StyledSmallText,
};

/**
 * The `Typography` component makes it easy to render text using common
 * styles to reduce boilerplat code around font-size and color.
 */
function Typography<
    C extends keyof JSX.IntrinsicElements | React.ComponentType<any> = 'div',
    O extends object = {},
    A extends keyof any = never
>(
    {
        textStyle = 'body',
        text,
        children,
        bold = false,
        color,
        noTranslate,
        ...rest
    }: TypographyProps<C, O, A>,
    ref?: React.Ref<C>
): JSX.Element {
    const Component = Components[textStyle];
    return (
        <Component {...rest} ref={ref} isBold={bold} textColor={color}>
            {noTranslate ? (
                <div translate="no">{text || children}</div>
            ) : (
                text || children
            )}
        </Component>
    );
}

/**
 * Create a component with forwardref that keeps the generic
 * type definition of the `Typography` component to allow
 * for casting.
 */
function createForwardRef(Component: typeof Typography) {
    return forwardRef(Component) as <
        C extends
            | keyof JSX.IntrinsicElements
            | React.ComponentType<any> = 'div',
        O extends object = {},
        A extends keyof any = never
    >(
        p: TypographyPropsWithRef<C, O, A>
    ) => JSX.Element;
}

function createTextStyle<
    C extends keyof JSX.IntrinsicElements | React.ComponentType<any> = 'div',
    O extends object = {},
    A extends keyof any = never
>(
    textStyle: TypographyType,
    defaultColor: TypographyColor,
    disableColorChange?: boolean
) {
    function Component(
        { color, ...rest }: TypographyProps<C, O, A>,
        ref?: React.Ref<C>
    ) {
        return Typography<C, O, A>(
            {
                ...rest,
                textStyle,
                color: disableColorChange
                    ? defaultColor
                    : color || defaultColor,
            } as TypographyProps<C, O, A>,
            ref
        );
    }
    return Component;
}

/**
 * The `Typography` component makes it easy to render text using common
 * styles to reduce boilerplate code around font-size and color.
 */
const TypographyWithForwardedRef = createForwardRef(Typography);

export const BodyText = createForwardRef(createTextStyle('body', 'black'));
export const ErrorText = createForwardRef(
    createTextStyle('error', 'error', true)
);
export const SuccessText = createForwardRef(
    createTextStyle('success', 'success', true)
);
export const WarningText = createForwardRef(
    createTextStyle('warning', 'warning', true)
);
export const LabelText = createForwardRef(createTextStyle('label', 'black'));
export const SmallText = createForwardRef(createTextStyle('small', 'black'));
export const Heading3 = createForwardRef(createTextStyle('h3', 'black', true));
export const Heading4 = createForwardRef(createTextStyle('h4', 'black', true));
export const Heading1 = createForwardRef(createTextStyle('h1', 'black', true));
export const Heading2 = createForwardRef(createTextStyle('h2', 'black', true));

export default TypographyWithForwardedRef;
