import { datadogRum } from '@datadog/browser-rum';

export function logErrorBoundary(
    error: Error,
    info: { componentStack: string }
): void {
    datadogRum.addError(error, {
        type: 'error-boundary',
        message: 'User Hit Error Boundary',
        componentStack: info.componentStack,
    });
}

export function logError(error: Error, context?: any | undefined): void {
    datadogRum.addError(error, context);
}

export function logAction(name: string, message?: string): void {
    datadogRum.addAction('event', {
        type: 'action',
        message,
        name,
    });
}
