/* eslint-disable @typescript-eslint/no-empty-function */
import React, { FunctionComponent, useRef } from 'react';
import { DragSourceMonitor, useDrop, useDrag } from 'react-dnd';
import Icon from 'koddi-components/Icon';
import Tooltip from 'koddi-components/Tooltip';
import CanView from 'koddi-components/CanView';
import { DragTileProps } from './DragGroup.types';
import { DragTileContainer, DragTileName } from './DragGroup.styled';

export const DragTile: FunctionComponent<DragTileProps> = ({
    data,
    index,
    move,
    onDestroy,
    isParentDragging,
    renderTile: renderTileFunc,
}) => {
    const leftIconSize = data.isPermanent ? 10 : 14;
    const ref = useRef<HTMLDivElement>(null);
    const [, drop] = useDrop({
        accept: data.isPermanent ? 'NOTHING' : 'ITEM',
        canDrop: () => !data.isPermanent,
        hover(item: any) {
            const sourceIndex = item.index;
            const targetIndex = index;
            if (!ref.current || sourceIndex === targetIndex) {
                return;
            }
            move(sourceIndex, targetIndex);
            item.index = targetIndex;
        },
    });
    const [{ isDragging }, drag] = useDrag({
        canDrag: () => !data.isPermanent,
        item: { type: 'ITEM', id: data.id, index },
        collect: (monitor: DragSourceMonitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    });

    drag(drop(ref));
    const renderTile = () => (
        <CanView condition={!data.hidden}>
            {renderTileFunc ? (
                renderTileFunc({
                    data,
                    onDestroy,
                    ref,
                    isDragging,
                    isParentDragging,
                    index,
                })
            ) : (
                <DragTileContainer
                    ref={ref}
                    isDragging={isDragging}
                    isParentDragging={isParentDragging}
                    isPermanent={data.isPermanent}
                    data-test={`drag-item-${data.id}`}
                >
                    <Icon
                        height={data.iconSize || leftIconSize}
                        width={data.iconSize || leftIconSize}
                        icon={
                            data.iconLeft || data.isPermanent
                                ? 'lock'
                                : 'doubleEllipses'
                        }
                    />
                    <DragTileName>{data.name}</DragTileName>
                    {!data.isPermanent && !data.disableDestroy && (
                        <Icon
                            height={data.iconSize || 8}
                            width={data.iconSize || 8}
                            icon={data.iconRight || 'closeX'}
                            onClick={() => onDestroy(index)}
                        />
                    )}
                </DragTileContainer>
            )}
        </CanView>
    );
    return data.tooltipConfig ? (
        <Tooltip {...data.tooltipConfig}>{renderTile()}</Tooltip>
    ) : (
        renderTile()
    );
};

export default DragTile;
