import createAsyncReducer from 'utils/createAsyncReducer';
import { MemberGroupAction } from './types';

export const initialState: any = {
    last_updated: null,
    loading: false,
    error: null,
    data: null,
    activeTemplates: null,
};

export const memberGroupListReducer = createAsyncReducer(
    initialState,
    {
        start: MemberGroupAction.GET_MEMBER_GROUP_LIST,
        success: MemberGroupAction.GET_MEMBER_GROUP_LIST_SUCCESS,
        error: MemberGroupAction.GET_MEMBER_GROUP_LIST_ERROR,
    },
    {
        extendReducer: (action, draft) => {
            switch (action.type) {
                case MemberGroupAction.GET_MEMBER_GROUP_ACTIVE_TEMPLATES:
                    draft.activeTemplates = action.payload.data;
                    break;
                default:
                    break;
            }
        },
    }
);

export default memberGroupListReducer;
