/* eslint-disable react/require-default-props */
import React, { useCallback, useRef } from 'react';
import { Box } from '@chakra-ui/react';
import { CDeskTopOnlyBox } from '../Layouts';

import { StepperStep, StepperLabel, StepperNumber } from './Wizard.styled';
import { WizardStepperProps, WizardStepProps } from './Wizard.types';

const StepperStepComponent = ({
    step,
    stepKey,
    currentStepKey,
    index,
    onStepClick,
    canNavigate,
    steps,
}: WizardStepProps) => {
    const numberRef = useRef();

    const stepWidth = `${100 / steps.length}%`;

    const stepKeyIndex = steps.findIndex((s) => s.key === stepKey);
    const stepNumber = stepKeyIndex + 1;

    const currentStepKeyIndex = steps.findIndex(
        (s) => s.key === currentStepKey
    );

    return (
        <StepperStep
            width={stepWidth}
            key={step.name}
            activeStep={currentStepKey === stepKey}
            pastStep={stepKeyIndex < currentStepKeyIndex}
        >
            <StepperNumber
                /* @ts-ignore */
                ref={numberRef}
                faded={index !== 0 ? stepKeyIndex > currentStepKeyIndex : false}
                onClick={() => {
                    onStepClick({
                        stepKey,
                        canNavigate,
                    });
                }}
                clickable={canNavigate}
                data-test="wizard-stepper-number"
            >
                <span>{stepNumber}</span>
            </StepperNumber>
            <StepperLabel
                faded={index !== 0 ? stepKeyIndex > currentStepKeyIndex : false}
                data-test="wizard-stepper-label"
            >
                {step.name}
            </StepperLabel>
        </StepperStep>
    );
};

const WizardStepperComponent = (props: WizardStepperProps): JSX.Element => {
    const { steps, completedSteps, currentStepKey } = props;
    const canNavigateToStep = useCallback(
        (stepKey: string) => {
            const isCurrentStep = stepKey === currentStepKey;
            if (completedSteps) {
                return completedSteps.includes(stepKey) && !isCurrentStep;
            }
            return !isCurrentStep;
        },
        [currentStepKey, completedSteps]
    );
    return (
        <CDeskTopOnlyBox>
            <Box
                data-test="wizard-stepper"
                justifyContent="flex-end"
                display="flex"
                maxW="800px"
                width="100%"
            >
                {steps.map((step, index) => {
                    const canNavigate = canNavigateToStep(step.key);
                    const stepProps = {
                        ...props,
                        canNavigate,
                        step,
                        stepKey: step.key,
                        index,
                    };
                    return (
                        <StepperStepComponent key={step.key} {...stepProps} />
                    );
                })}
            </Box>
        </CDeskTopOnlyBox>
    );
};

export default WizardStepperComponent;
