import { useContext } from 'react';
import {
    SUPER_USER_ROLE_ID,
    INTERNAL_ADMIN_ROLE_ID,
    CLIENT_ADMIN_ROLE_ID,
    CLIENT_VIEWER_ROLE_ID,
    ADVERTISER_ADMIN_ROLE_ID,
    ADVERTISER_MANAGER_ROLE_ID,
    ADVERTISER_VIEWER_ROLE_ID,
} from 'modules/constants/roles';
import uniq from 'lodash/uniq';
import { checkRoles } from 'koddi-components/CanView/CanView.utils';
import { useAuthUser } from 'app/react-ui/redux-core/auth/hooks';
import { PermissionsProviderValue } from './PermissionsProvider.types';
import { PermissionsProviderContext } from './PermissionsProvider.component';
/*
 * A custom hook to use the permissions found in the permissions provider.
 */
export function usePermissions(): PermissionsProviderValue {
    const context = useContext<PermissionsProviderValue | undefined>(
        PermissionsProviderContext
    );

    if (context === undefined) {
        throw new Error(
            '`usePermissions` must be used inside of a PermissionsProvider.'
        );
    }

    return context;
}

export function useUserHasSuperAdminAccess(): boolean {
    const { roles } = usePermissions();
    return checkRoles(roles, [SUPER_USER_ROLE_ID]);
}

export function useUserHasInternalAdminAccess(): boolean {
    const { roles } = usePermissions();
    return checkRoles(roles, [INTERNAL_ADMIN_ROLE_ID]);
}

// user accesses admin at the global level
export function useUserHasGlobalAdminAccess(): boolean {
    const { roles } = usePermissions();
    return checkRoles(
        roles,
        [SUPER_USER_ROLE_ID, INTERNAL_ADMIN_ROLE_ID],
        true
    );
}

// user accesses admin at the client level
export function useUserIsClientAdmin(): boolean {
    const { roles } = usePermissions();
    return checkRoles(roles, [CLIENT_ADMIN_ROLE_ID], true);
}

// user accesses admin at the client level
export function useUserHasClientAdminAccess(): boolean {
    const { roles } = usePermissions();
    return checkRoles(
        roles,
        [CLIENT_ADMIN_ROLE_ID, CLIENT_VIEWER_ROLE_ID],
        true
    );
}

// user accesses admin at the advertiser level
export function useUserHasAdvertiserAdminAccess(): boolean {
    const { roles } = usePermissions();
    return checkRoles(
        roles,
        [
            ADVERTISER_ADMIN_ROLE_ID,
            ADVERTISER_MANAGER_ROLE_ID,
            ADVERTISER_VIEWER_ROLE_ID,
        ],
        true
    );
}

export function useUserOnlyHasAdvertiserViewerAccess(): boolean {
    const { roles } = usePermissions();
    return (
        roles.length === 1 &&
        checkRoles(roles, [ADVERTISER_VIEWER_ROLE_ID], true)
    );
}

export function useUserHasAdvertiserLevelAccess(): boolean {
    const { roles } = usePermissions();
    return checkRoles(roles, [ADVERTISER_ADMIN_ROLE_ID], true);
}

export function useUserHasSingleClientAccess(): boolean {
    const user = useAuthUser();
    const { access } = usePermissions();
    const advertiserUser = useUserHasAdvertiserAdminAccess();
    if (advertiserUser && user?.advertiser_member_group) {
        const values = uniq(Object.values(user?.advertiser_member_group));
        return values.length === 1;
    }
    return access?.memberGroupIds?.length === 1;
}

export function useUserHasSingleAdvertiserAccess(): boolean {
    const { access } = usePermissions();
    return access?.advertiserIds?.length === 1;
}
