/* eslint-disable react/display-name */
import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';

import AccountProfileIcon from './icons/account_profile';
import BarChartIcon from './icons/bar_chart';
import BellIcon from './icons/bell';
import BillIcon from './icons/bill';
import CheckIcon from './icons/check';
import ChevronDownIcon from './icons/chevron_down';
import ChevronLeftIcon from './icons/chevron_left';
import ChevronUpIcon from './icons/chevron_up';
import ChevronRightIcon from './icons/chevron_right';
import CloseXIcon from './icons/close_x';
import DoubleEllipsesIcon from './icons/double_ellipses';
import DownloadIcon from './icons/download';
import EllipsesIcon from './icons/ellipses';
import ExclamationIcon from './icons/exclamation';
import GraphIcon from './icons/graph';
import InfoIcon from './icons/info';
import LineChartIcon from './icons/line_chart';
import LockIcon from './icons/lock';
import MagnifierIcon from './icons/magnifier';
import MinusIcon from './icons/minus';
import MinusBoxIcon from './icons/minusbox';
import PencilIcon from './icons/pencil';
import PercentIcon from './icons/percent';
import PerformanceArrowIcon from './icons/performance_arrow';
import PlacePinIcon from './icons/place_pin';
import PlacePinShadowIcon from './icons/place_pin_shadow';
import PlusIcon from './icons/plus';
import ProfileIcon from './icons/profile';
import RadioIcon from './icons/radio';
import CheckboxIcon from './icons/checkbox';
import RefreshIcon from './icons/refresh';
import SettingsIcon from './icons/settings';
import TargetIcon from './icons/target';
import TriangleDown from './icons/triangle_down';
import Thermometer from './icons/thermometer';
import DollarSignIcon from './icons/dollar_sign';
import ProductsIcon from './icons/products';
import CampaignIcon from './icons/campaign';
import AGroupIcon from './icons/ad_groups';
import OverviewIcon from './icons/overview';
import ArrowTargetIcon from './icons/arrow_target';
import PieChart from './icons/pie_chart';
import ThumbsUp from './icons/thumbsUp';
import Warning from './icons/warning';
import AddFileIcon from './icons/add_file';
import TrashCanIcon from './icons/trash_can';
import VisaIcon from './icons/visa';
import MastercardIcon from './icons/mastercard';
import AmericanExpressIcon from './icons/american_express';
import DiscoverIcon from './icons/discover';
import DinersClubIcon from './icons/diners_club';
import JCBIcon from './icons/jcb';
import UnionPayIcon from './icons/union_pay';
import GenericCardIcon from './icons/generic_card';
import FilterIcon from './icons/filter';
import BankIcon from './icons/bank';
import PaidIcon from './icons/paid';
import SaveIcon from './icons/save';
import MedicalReportIcon from './icons/medical_report';
import WarningSignIcon from './icons/warning_sign';
import CheckV2Icon from './icons/check_V2';
import PaperIcon from './icons/paper';
import TimeForwardIcon from './icons/time_forward';
import SupportQuestionMarkIcon from './icons/support_question_mark';

import { IconProps, IconKey, BaseSVGProps } from './icon.types';
import {
    CColumnChartIcon,
    CHistoryIcon,
    CReviewIcon,
} from '../_ChakraComponents/Icons/General.component';
import { Colors } from '../_ChakraComponents/ChakraTheme/configs/Colors.config';

export const IconWrapper = styled.span<{
    marginTop?: number;
    marginRight?: number;
    overRidePathColor?: boolean;
}>`
    ${({
        theme,
        /* @ts-ignore */
        disabled,
        marginTop,
        marginRight,
        overRidePathColor,
    }) => css`
        color: inherit;
        display: inline-flex;
        align-items: center;
        cursor: ${disabled ? 'not-allowed' : 'pointer'};
        svg path {
            fill: ${overRidePathColor ? 'currentColor' : 'null'};
        }
        svg circle {
            fill: currentColor;
        }
        .icon-primary {
            fill: ${theme.primary};
        }
        margin-top: ${marginTop}px;
        margin-right: ${marginRight}px;
        .icon-gray {
            fill: ${theme.gray};
        }
        .icon-black {
            fill: black;
        }
        .icon-white {
            fill: white;
        }
    `}
`;

export const ICON_MAP: Record<IconKey, FunctionComponent<BaseSVGProps>> = {
    adGroup: AGroupIcon,
    addFile: AddFileIcon,
    accountProfile: AccountProfileIcon,
    americanExpress: AmericanExpressIcon,
    arrowTarget: ArrowTargetIcon,
    barChart: BarChartIcon,
    bell: BellIcon,
    bill: BillIcon,
    campaign: CampaignIcon,
    check: CheckIcon,
    chevronDown: ChevronDownIcon,
    chevronLeft: ChevronLeftIcon,
    chevronRight: ChevronRightIcon,
    chevronUp: ChevronUpIcon,
    closeX: CloseXIcon,
    dinersClub: DinersClubIcon,
    discover: DiscoverIcon,
    dollarSign: DollarSignIcon,
    doubleEllipses: DoubleEllipsesIcon,
    download: DownloadIcon,
    ellipses: EllipsesIcon,
    exclamation: ExclamationIcon,
    genericCard: GenericCardIcon,
    graph: GraphIcon,
    info: InfoIcon,
    jcb: JCBIcon,
    lineChart: LineChartIcon,
    lock: LockIcon,
    magnifier: MagnifierIcon,
    mastercard: MastercardIcon,
    minus: MinusIcon,
    minusbox: MinusBoxIcon,
    overview: OverviewIcon,
    pencil: PencilIcon,
    percent: PercentIcon,
    performanceArrow: PerformanceArrowIcon,
    pieChart: PieChart,
    placePin: PlacePinIcon,
    placePinShadow: PlacePinShadowIcon,
    plus: PlusIcon,
    profile: ProfileIcon,
    product: ProductsIcon,
    radio: RadioIcon,
    checkbox: CheckboxIcon,
    refresh: RefreshIcon,
    settings: SettingsIcon,
    target: TargetIcon,
    trashCan: TrashCanIcon,
    triangleDown: TriangleDown,
    thermometer: Thermometer,
    timeForward: TimeForwardIcon,
    thumbsUp: ThumbsUp,
    unionPay: UnionPayIcon,
    warning: Warning,
    visa: VisaIcon,
    filter: FilterIcon,
    bank: BankIcon,
    paid: PaidIcon,
    save: SaveIcon,
    medicalReport: MedicalReportIcon,
    warningSign: WarningSignIcon,
    checkV2: CheckV2Icon,
    paper: PaperIcon,
    support: SupportQuestionMarkIcon,
    // enable old icon patterns with table cells with Chakra
    cColumnChart: () => (
        <CColumnChartIcon h="14px" w="14px" color={Colors.black} />
    ),
    cReview: () => <CReviewIcon h="10px" w="10px" color={Colors.black} />,
    cHistory: () => <CHistoryIcon h="10px" w="10px" color={Colors.black} />,
};

export const Icon: FunctionComponent<IconProps> = ({
    icon = 'chevronDown',
    height = 16,
    width = 16,
    testSuffix,
    forwardedRef,
    marginTop = 0,
    marginRight = 0,
    overRidePathColor = true,
    ...restProps
}) => {
    const IconComponent = ICON_MAP[icon];
    const suffix = testSuffix ? `-${testSuffix}` : '';

    return (
        <IconWrapper
            {...restProps}
            ref={forwardedRef}
            marginTop={marginTop}
            marginRight={marginRight}
            overRidePathColor={overRidePathColor}
            data-test={`icon-${icon}${suffix}`}
        >
            <IconComponent height={height} width={width} />
        </IconWrapper>
    );
};

const IconWithForwardedRef = React.forwardRef<HTMLSpanElement, IconProps>(
    (props, ref) => <Icon {...props} forwardedRef={ref} />
);

IconWithForwardedRef.displayName = 'IconWithForwardedRef';

export default IconWithForwardedRef;
