import { Box, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { components as ReactSelectComponents, OptionProps } from 'react-select';
import { OptionType } from '../../../Types';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const OptionWithTooltip = ({
    isSelected,
    children,
    ...rest
}: OptionProps<OptionType>): JSX.Element => {
    return (
        <Tooltip
            label={children}
            shouldWrapChildren
            openDelay={500}
            isDisabled={!rest.selectProps?.hasTooltip}
            placement="bottom-start"
        >
            <ReactSelectComponents.Option isSelected={isSelected} {...rest}>
                <Box overflow="hidden" textOverflow="ellipsis">
                    {children}
                </Box>
            </ReactSelectComponents.Option>
        </Tooltip>
    );
};
