/**
 * use to generate consistent object of responsive  styles
 * @param smallValue
 * @param regularValue
 * @returns
 */
export const responsiveStyles = (
    smallValue: number | string,
    regularValue: number | string
): any => {
    return {
        base: smallValue,
        md: regularValue,
    };
};
