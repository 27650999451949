/* eslint-disable no-console */
// Documentation for Auth0's JS SDK https://auth0.com/docs/libraries/auth0-single-page-app-sdk
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createAuth0Client } from '@auth0/auth0-spa-js';
import { Auth0State } from './Auth0.types';
import { AUTH0_CONFIG, AUTH_LOGOUT_KEY } from './Auth0.const';

export class KoddiAuth0 {
    auth0Client: any;

    isAuthenticated = false;

    public getState = async (): Promise<Auth0State> => {
        try {
            if (!this.auth0Client) {
                await this.configureClient();
            }
            const isAuthenticated = await this.auth0Client.isAuthenticated();
            if (isAuthenticated) {
                this.isAuthenticated = isAuthenticated;
            }

            const user = await this.auth0Client.getUser();

            const claims = await this.auth0Client.getIdTokenClaims();

            // values in seconds
            const idTokenExpirationTime = claims ? claims.exp : 0;
            const idTokenIssuedAtTime = claims ? claims.iat : 0;

            // values in Milliseconds
            const nowInMS = +new Date();
            const tokenExpirationInMs = idTokenExpirationTime * 1000;
            const expiresInMs = tokenExpirationInMs - nowInMS;

            const {
                id_token,
                access_token,
            } = await this.auth0Client.getTokenSilently({
                authorizationParams: {
                    audience: AUTH0_CONFIG.authorizationParams.audience,
                },
                // expiration is less than 10 minutes we want to force a request for new tokens
                cacheMode: expiresInMs < 600000 ? 'off' : 'on',
                detailedResponse: true,
            });

            // Don't Delete, use for Testing token expirations and statuses
            // console.log('Auth0s Get State was ran these values were returned', {
            //     claims,
            //     id_token,
            //     access_token,
            //     willExpireAt: new Date(tokenExpirationInMs),
            //     willExpireInMinutes: expiresInMs / 1000 / 60,
            //     expiresInMs,
            //     cacheMode: expiresInMs < 600000 ? 'off' : 'on',
            // });

            return {
                isAuthenticated,
                user,
                idToken: id_token,
                accessToken: access_token,
                expires_in: idTokenExpirationTime - idTokenIssuedAtTime,
                expirationTime: idTokenExpirationTime * 1000,
            };
        } catch (err) {
            console.error(err);
            return null;
        }
    };

    private handleCallBack = async () => {
        const { search, hash } = window.location;
        if (search.includes('code=') && search.includes('state=')) {
            return (async () => {
                try {
                    await this.auth0Client.handleRedirectCallback();
                    window.history.replaceState({}, document.title, hash);
                } catch (error) {
                    console.error(error);
                }
                return this.getState();
            })();
        }
        const isLoggedIn = await this.auth0Client.isAuthenticated();
        if (isLoggedIn) {
            return this.getState();
        }
        return null;
    };

    /**
     * Starts the sign up flow
     */
    private configureClient = async () => {
        this.auth0Client = await createAuth0Client(AUTH0_CONFIG);
    };

    /**
     * Starts the sign up flow
     */
    public signUp = async (targetUrl?: string) => {
        const options = {
            authorizationParams: {
                redirect_uri: targetUrl ?? `${window.location.origin}`,
                screen_hint: 'signup',
            },
            appState: targetUrl ?? null,
        };
        await this.auth0Client.loginWithRedirect(options);
    };

    /**
     * Starts the authentication flow
     */
    public login = async ({
        sso_organization_id,
        targetUrl,
        sso_connection_id,
    }: {
        sso_organization_id?: string;
        targetUrl?: string;
        sso_connection_id?: string;
    }) => {
        const options = {
            authorizationParams: {
                redirect_uri: targetUrl ?? `${window.location.origin}`,
                organization: sso_organization_id ?? undefined,
                connection_id: sso_connection_id ?? undefined,
            },
            appState: targetUrl ?? null,
        };
        await this.auth0Client.loginWithRedirect(options);
    };

    /**
     * Executes the logout flow
     */
    public logout = async (returnTo?: string) => {
        if (!this.isAuthenticated) return;
        localStorage.setItem(AUTH_LOGOUT_KEY, 'true');
        await this.auth0Client.logout({
            logoutParams: {
                returnTo: returnTo || `${window.location.origin}`,
            },
        });
    };

    public async initializeAuth0() {
        await this.configureClient();
        return this.handleCallBack();
    }
}

export const Auth0 = new KoddiAuth0();
