// add reusable validation messages here.
export enum ValidationMessages {
    Valid = '',
    InvalidCurrency = 'Value must be greater than or equal to',
    Required = 'Required',
    NumbersOnly = 'This field only accepts numbers',
    InvalidMax = 'Value has exceeded the max of',
    InvalidEmail = 'A proper email is required',
    InvalidPassword = `Password must contain one special character (^ $ * . [ ] { } ( ) ? - " ! @ # % & / \\ , > < ' : ; | _ ~ \` + =), one number, one uppercase
    and lower case, and at least 8 or more characters.`,
    NegativeValue = 'Value can not be negative',
    DecimalValue = 'Value should be a whole number',
    InvalidPostalCode = 'Must enter valid postal code',
}
