import { Box } from '@chakra-ui/react';
import React from 'react';
import { Field } from 'react-final-form';
import { Spacing } from '../../ChakraTheme';
import { CFormLabel } from '../../Typography';

import { requiredValidation } from '../Validations';
import { FormControls } from './FormField.const';
import { useFormField } from './FormField.hooks';
import { FormFieldProps } from './FormField.types';

export const CFormField = ({ ...props }: FormFieldProps): JSX.Element => {
    const {
        isRequired,
        label,
        name,
        validation,
        formControl: FormControl,
        controlProps,
    } = useFormField({ ...props });

    return (
        <Field
            validate={
                !validation && isRequired ? requiredValidation : validation
            }
            name={name}
            render={() => (
                <Box width="100%">
                    {FormControl !== FormControls.singleCheckbox &&
                        (typeof label === 'string' ? (
                            <CFormLabel
                                data-test={`${name}--label`}
                                label={label}
                                id={name}
                                isRequired={isRequired}
                                mb={Spacing.XXS}
                            />
                        ) : (
                            label
                        ))}
                    <FormControl {...controlProps} />
                </Box>
            )}
        />
    );
};
