import React from 'react';
import { createGlobalStyle, css } from 'styled-components';
import { FontValues } from 'koddi-components/global.types';
import { FontSizes } from '../_ChakraComponents/ChakraTheme/configs/Typography.config';

const limitlessPath =
    'https://e451-meridiancdn-0006-p-cdn.azureedge.net/e451meridiancdn0006/fonts/limitless';

const KoddiGlobalStyles = createGlobalStyle`
    ${({ theme }) => {
        const fontFamily =
            theme.typography.fontFamily === 'sans-serif'
                ? FontValues.default
                : theme.typography.fontFamily;
        return css`
            /** Reference: https://css-tricks.com/snippets/css/hide-scrollbar-in-edge-ie-1011/ */
            html {
                -ms-overflow-style: -ms-autohiding-scrollbar;
            }
            html,
            body,
            #root {
                width: 100%;
                height: 100%;
            }

            body {
                font-family: ${fontFamily};
                margin: 0px;
                font-size: ${FontSizes.md};
            }

            * {
                box-sizing: border-box;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }

            a {
                text-decoration: none;
                color: ${theme.linkColor};
            }
            p {
                line-height: 1.2rem;
            }
            @font-face {
                font-family: 'Limitless Sans';
                src: url(${limitlessPath} + '/LimitlessSans-Light.woff2')
                        format('woff2'),
                    url(${limitlessPath} + '/LimitlessSans-Light.woff')
                        format('woff');
                font-weight: 300;
                font-style: normal;
            }

            @font-face {
                font-family: 'Limitless Sans';
                src: url(${limitlessPath} + '/LimitlessSans-SemiBold.woff2')
                        format('woff2'),
                    url(${limitlessPath} + '/LimitlessSans-SemiBold.woff')
                        format('woff');
                font-weight: 600;
                font-style: normal;
            }

            @font-face {
                font-family: 'Limitless Sans';
                src: url(${limitlessPath} + '/LimitlessSans-Medium.woff2')
                        format('woff2'),
                    url(${limitlessPath} + '/LimitlessSans-Medium.woff')
                        format('woff');
                font-weight: 500;
                font-style: normal;
            }

            @font-face {
                font-family: 'Limitless Sans';
                src: url(${limitlessPath} + '/LimitlessSans-Bold.woff2')
                        format('woff2'),
                    url(${limitlessPath} + '/LimitlessSans-Bold.woff')
                        format('woff');
                font-weight: 700;
                font-style: normal;
            }

            @font-face {
                font-family: 'Limitless Sans';
                src: url(${limitlessPath} + '/LimitlessSans-Regular.woff2')
                        format('woff2'),
                    url(${limitlessPath} + '/LimitlessSans-Regular.woff')
                        format('woff');
                font-weight: 400;
                font-style: normal;
            }
        `;
    }}
`;

export default React.memo(KoddiGlobalStyles);
