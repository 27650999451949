import { AnyAction } from 'redux';
import produce, { Draft } from 'immer';
import {
    AdvertiserPaymentMethodAction,
    AdvertiserPaymentState,
    AddPaymentMethodFormAction,
    AddressModalAction,
    EditPaymentModalAction,
} from './types';

export const initialState: AdvertiserPaymentState = {
    editPaymentModal: {
        showModal: false,
        paymentMethodId: null,
    },
    billingAddressModal: {
        showModal: false,
        saveAction: null,
        address: null,
    },
    addPaymentModal: {
        showModal: false,
        isDefaultPaymentMethod: false,
        otherBillingAddress: null,
        defaultBillingAddress: null,
        selectedBillingAddress: 'default',
    },
    selectedPaymentMethod: null,
    paymentMethods: [],
    addFundsAmount: null,
};

export const advertiserPaymentReducer = (
    state = initialState,
    action: AnyAction
): AdvertiserPaymentState =>
    produce<AdvertiserPaymentState>(
        state,
        (draft: Draft<AdvertiserPaymentState>) => {
            switch (action.type) {
                case AddPaymentMethodFormAction.SET_DEFAULT_PAYMENT_METHOD:
                    draft.addPaymentModal.isDefaultPaymentMethod =
                        action.payload;
                    break;

                case AddPaymentMethodFormAction.SET_DEFAULT_BILLING_ADDRESS:
                    draft.addPaymentModal.defaultBillingAddress =
                        action.payload;
                    break;

                case AddPaymentMethodFormAction.SET_OTHER_BILLING_ADDRESS:
                    draft.addPaymentModal.otherBillingAddress = action.payload;
                    break;

                case AddPaymentMethodFormAction.SET_SELECTED_BILLING_ADDRESS:
                    draft.addPaymentModal.selectedBillingAddress =
                        action.payload;
                    break;

                case 'show_add_payment_modal':
                case 'close_add_payment_modal':
                    draft.addPaymentModal.showModal = action.payload.showModal;
                    break;

                case EditPaymentModalAction.SHOW_MODAL:
                case EditPaymentModalAction.CLOSE_MODAL:
                    draft.editPaymentModal = action.payload;
                    break;

                case AddressModalAction.SHOW_MODAL:
                case AddressModalAction.CLOSE_MODAL:
                    draft.billingAddressModal = action.payload;
                    break;

                case 'set_add_funds_amount':
                    draft.addFundsAmount = action.payload.amount;
                    break;

                case AdvertiserPaymentMethodAction.LOADED:
                    draft.paymentMethods = action.payload;
                    break;
                case AdvertiserPaymentMethodAction.CLEAR:
                    draft.paymentMethods = [];
                    break;
                default:
                    break;
            }
        }
    );

export default advertiserPaymentReducer;
