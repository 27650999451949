/* eslint-disable @typescript-eslint/no-empty-function */
import { useCallback, useEffect, useMemo, useState } from 'react';
import format from 'date-fns/format';
import isSameDay from 'date-fns/isSameDay';
import { addMonths, isBefore, isValid, subMonths } from 'date-fns';
import { usePopper } from 'react-popper';
import {
    OnDatesChangeArg,
    DatePickerPresetDateRange,
    DatePickerDateRange,
    DateRangePickerProps,
} from './DateRangePicker.types';
import { createPresetDateRanges } from './DateRangePicker.utils';

/**
 * Will check if the current date range matches one of
 * the preset date ranges and set the preset range to the found match.
 */
export function usePresetRangeMatcher(
    datesMap: OnDatesChangeArg,
    presetDateRanges: Record<DatePickerPresetDateRange, DatePickerDateRange>,
    setPresetDateRange: (key: DatePickerPresetDateRange | null) => void,
    presetDateRange: DatePickerPresetDateRange | null,
    initialDateRangePreset: DatePickerPresetDateRange | null
): void {
    useEffect(() => {
        const { startDate, endDate } = datesMap;
        Object.keys(presetDateRanges).forEach((key) => {
            const presetDateRangeOption = key as DatePickerPresetDateRange;

            const range = presetDateRanges[presetDateRangeOption];

            if (presetDateRange === null) {
                setPresetDateRange(initialDateRangePreset);
            }
            if (startDate && endDate) {
                const startDateIsSame = isSameDay(range.startDate, startDate);
                const endDateIsSame = isSameDay(range.endDate, endDate);
                if (
                    presetDateRangeOption === presetDateRange &&
                    startDateIsSame &&
                    endDateIsSame
                ) {
                    setPresetDateRange(presetDateRangeOption);
                }
                if (
                    presetDateRangeOption === presetDateRange &&
                    (!startDateIsSame || !endDateIsSame)
                ) {
                    setPresetDateRange('Custom');
                }
            }
        });
    }, [
        datesMap,
        presetDateRanges,
        setPresetDateRange,
        presetDateRange,
        initialDateRangePreset,
    ]);
}

/** Dispatches the react final form `onChange` handler whenever the dates change. */
export function useReactFinalFormChange(
    dates: OnDatesChangeArg,
    onChange?: (event: any) => void
): void {
    useEffect(() => {
        const { startDate, endDate } = dates;
        if (onChange)
            onChange({
                target: {
                    value: JSON.stringify({
                        startDate: startDate
                            ? format(startDate, 'MM/dd/yyyy')
                            : null,
                        endDate: endDate ? format(endDate, 'MM/dd/yyyy') : null,
                    }),
                },
            });
    }, [dates, onChange]);
}

export type DateRangePickerHookReturn = {
    rootRef: HTMLDivElement | null;
    popperRef: HTMLDivElement | null;
    setPopperRef: (ref: HTMLDivElement | null) => void;
    setRootRef: (ref: HTMLDivElement | null) => void;
    styles: any;
    attributes: any;
    presetRangeChanged: boolean;
    setPresetDateRangeChanged: (changed: boolean) => void;
    dropdownOpen: boolean;
    setDropdownOpen: (open: boolean) => void;
    error: string | null;
    setError: (error: string | null) => void;
    presetDateRange: DatePickerPresetDateRange | null;
    setPresetDateRange: (range: DatePickerPresetDateRange | null) => void;
    customDateRange: {
        startDate: Date | null;
        endDate: Date | null;
    } | null;
    presetDateRanges: Record<DatePickerPresetDateRange, DatePickerDateRange>;
    setPresetDateRanges: (
        ranges: Record<DatePickerPresetDateRange, DatePickerDateRange>
    ) => void;
    selectedPresetDateRange: DatePickerDateRange | null;
    dates: OnDatesChangeArg;
    setDates: (dates: OnDatesChangeArg) => void;
    restoreDates: OnDatesChangeArg;
    setRestoreDates: (dates: OnDatesChangeArg) => void;
    datesMap: OnDatesChangeArg;
    datesChangeHandler: (dates: OnDatesChangeArg) => void;
    rangeIsValid: boolean;
    months: {
        firstMonth: Date;
        secondMonth: Date;
    };
    setMonths: (months: { firstMonth: Date; secondMonth: Date }) => void;
    hoveredDate: Date | null;
    setHoveredDate: (date: Date | null) => void;
    handleDatesRangeChange: (date: Date) => void;
    handlePresetRangeSelected: (rangeKey: DatePickerPresetDateRange) => void;
    handleCancel: () => void;
    handleApply: () => void;
    handleFirstMonthChange: (month: Date) => void;
    handleSecondMonthChange: (month: Date) => void;
    toggleDropdown: () => void;
};

export const useDateRangePicker = ({
    startDate = new Date(),
    endDate = null,
    initialDateRangePreset,
    onDatesChange,
    allTimeDate,
    locale = 'en-US',
    onApply = () => {},
    open = false,
    onChange = () => {},
    placement = 'bottom-start',
}: Pick<
    DateRangePickerProps,
    | 'startDate'
    | 'endDate'
    | 'initialDateRangePreset'
    | 'onDatesChange'
    | 'allTimeDate'
    | 'locale'
    | 'onApply'
    | 'open'
    | 'placement'
> & {
    onChange: (event: any) => void;
}): DateRangePickerHookReturn => {
    const [rootRef, setRootRef] = useState<HTMLDivElement | null>(null);
    const [popperRef, setPopperRef] = useState<HTMLDivElement | null>(null);
    const { styles, attributes } = usePopper(rootRef, popperRef, {
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 8],
                },
            },
        ],
        placement,
    });
    const [presetRangeChanged, setPresetDateRangeChanged] = useState(false);

    const [dropdownOpen, setDropdownOpen] = useState<boolean>(open);
    const [error, setError] = useState<string | null>(null);
    const [
        presetDateRange,
        setPresetDateRange,
    ] = useState<DatePickerPresetDateRange | null>(
        startDate ? null || initialDateRangePreset : initialDateRangePreset
    );
    const customDateRange = useMemo(() => {
        if (initialDateRangePreset === 'Custom' && !presetRangeChanged) {
            return {
                startDate,
                endDate,
            };
        }
        return null;
    }, [startDate, endDate, initialDateRangePreset, presetRangeChanged]);

    const [presetDateRanges, setPresetDateRanges] = useState(
        createPresetDateRanges({ allTimeDate, locale, customDateRange })
    );

    useEffect(() => {
        setPresetDateRanges(
            createPresetDateRanges({ allTimeDate, locale, customDateRange })
        );
    }, [allTimeDate, locale, customDateRange]);

    const selectedPresetDateRange = presetDateRange
        ? presetDateRanges[presetDateRange]
        : null;

    const [dates, setDates] = useState<OnDatesChangeArg>({
        startDate:
            presetDateRange && selectedPresetDateRange
                ? selectedPresetDateRange.startDate
                : startDate,
        endDate:
            presetDateRange && selectedPresetDateRange
                ? selectedPresetDateRange.endDate
                : endDate,
        buttonLabel: presetDateRange,
    });
    const [restoreDates, setRestoreDates] = useState<OnDatesChangeArg>(dates);

    const datesMap = useMemo(
        () => (onDatesChange ? { startDate, endDate, presetDateRange } : dates),
        [onDatesChange, dates, startDate, endDate, presetDateRange]
    );
    const datesChangeHandler = onDatesChange || setDates;

    const rangeIsValid =
        datesMap.startDate !== null && datesMap.endDate !== null;

    const [months, setMonths] = useState<{
        firstMonth: Date;
        secondMonth: Date;
    }>({
        firstMonth: startDate || new Date(),
        secondMonth: addMonths(startDate || new Date(), 1),
    });

    const [hoveredDate, setHoveredDate] = useState<Date | null>(null);

    const handleDatesRangeChange = useCallback(
        (date: Date) => {
            if (datesMap.startDate && datesMap.endDate) {
                datesChangeHandler({ startDate: date, endDate: null });
            } else if (datesMap.startDate && !datesMap.endDate) {
                if (isBefore(date, datesMap.startDate)) {
                    datesChangeHandler({ startDate: date, endDate: null });
                } else
                    datesChangeHandler({
                        startDate: datesMap.startDate,
                        endDate: date,
                    });
            } else {
                datesChangeHandler({ startDate: date, endDate: null });
            }
            setError(null);
            setPresetDateRange(null);
        },
        [datesMap, datesChangeHandler]
    );

    const handlePresetRangeSelected = useCallback(
        (rangeKey: DatePickerPresetDateRange) => {
            const range = presetDateRanges[rangeKey];
            setPresetDateRangeChanged(true);
            setPresetDateRange(rangeKey);
            datesChangeHandler({
                startDate: range.startDate,
                endDate: range.endDate,
            });
            setMonths({
                firstMonth: range.startDate,
                secondMonth: addMonths(range.startDate, 1),
            });
            setError(null);
        },
        [datesChangeHandler, presetDateRanges]
    );

    const handleCancel = useCallback(() => {
        setDropdownOpen(false);
        datesChangeHandler(restoreDates);
        setMonths({
            firstMonth: restoreDates.startDate || new Date(),
            secondMonth: addMonths(restoreDates.startDate || new Date(), 1),
        });
        setPresetDateRange(
            restoreDates.buttonLabel ? restoreDates.buttonLabel : 'Custom'
        );
        setError(null);
        setHoveredDate(null);
    }, [datesChangeHandler, restoreDates]);

    function handleApply() {
        const hasValidDate =
            datesMap.startDate &&
            datesMap.endDate &&
            isValid(new Date(datesMap.startDate)) &&
            isValid(new Date(datesMap.endDate));
        if (hasValidDate) {
            setDropdownOpen(false);
            onApply({ ...datesMap, buttonLabel: presetDateRange });
            setRestoreDates({ ...datesMap, buttonLabel: presetDateRange });
        } else setError('Please select a valid date range.');
    }

    function handleFirstMonthChange(month: Date) {
        setMonths({
            firstMonth: month,
            secondMonth: addMonths(month, 1),
        });
    }

    function handleSecondMonthChange(month: Date) {
        setMonths({
            firstMonth: subMonths(month, 1),
            secondMonth: month,
        });
    }

    const toggleDropdown = useCallback(() => {
        if (dropdownOpen) {
            handleCancel();
        } else {
            setDropdownOpen(!dropdownOpen);
        }
    }, [dropdownOpen, handleCancel]);

    useEffect(() => {
        setPresetDateRanges(
            createPresetDateRanges({ allTimeDate, locale, customDateRange })
        );
    }, [allTimeDate, locale, customDateRange]);

    usePresetRangeMatcher(
        datesMap,
        presetDateRanges,
        setPresetDateRange,
        presetDateRange,
        initialDateRangePreset
    );

    useReactFinalFormChange(datesMap, onChange);

    return {
        rootRef,
        popperRef,
        setPopperRef,
        setRootRef,
        styles,
        attributes,
        presetRangeChanged,
        setPresetDateRangeChanged,
        dropdownOpen,
        setDropdownOpen,
        error,
        setError,
        presetDateRange,
        setPresetDateRange,
        customDateRange,
        presetDateRanges,
        setPresetDateRanges,
        selectedPresetDateRange,
        dates,
        setDates,
        restoreDates,
        setRestoreDates,
        datesMap,
        datesChangeHandler,
        rangeIsValid,
        months,
        setMonths,
        hoveredDate,
        setHoveredDate,
        handleDatesRangeChange,
        handlePresetRangeSelected,
        handleCancel,
        handleApply,
        handleFirstMonthChange,
        handleSecondMonthChange,
        toggleDropdown,
    };
};
