import { PermissionName } from 'app/react-ui/contexts/PermissionsProvider/PermissionsProvider.types';

export const checkPermissions = (
    permissions: PermissionName[],
    userPermissions: Record<string, string[]>,
    any?: boolean
): boolean => {
    const hasPermissions: string[] = permissions.filter((permission) => {
        if (!permission) return true;
        const [name, activity] = permission.split('.');
        return userPermissions[name]?.includes(activity) ?? false;
    });
    const hasAllPermissions = hasPermissions.length === permissions.length;
    const hasSomePermissions = hasPermissions.length > 0;
    return any ? hasSomePermissions : hasAllPermissions;
};

export const checkRoles = (
    userRoles: number[],
    permissionRole: number[],
    anyRole?: boolean
): boolean => {
    if (!permissionRole.length) return true;
    const hasRoles = permissionRole.filter((role) => userRoles.includes(role));
    const hasAllRoles = hasRoles.length === permissionRole.length;
    const hasSomeRoles = hasRoles.length > 0;
    return anyRole ? hasSomeRoles : hasAllRoles;
};

/** @Deprecated use hook useCanView to return this function */
export const checkPermissionsAndRole = (
    role: number | number[],
    permission: string | string[],
    userRoles: number[],
    userPermissions: Record<string, string[]>,
    condition?: boolean,
    anyRole = false,
    anyPermission = false
): boolean => {
    const permissionRoleList = Array.isArray(role) ? role : [role];
    const permissionList = Array.isArray(permission)
        ? permission
        : [permission];

    const hasRole =
        userRoles && role
            ? checkRoles(userRoles, permissionRoleList, anyRole)
            : true;
    const havePermission =
        userPermissions && permission
            ? checkPermissions(permissionList, userPermissions, anyPermission)
            : true;

    const meetsCondition = condition ?? true;
    return !!(hasRole && havePermission && meetsCondition);
};
