/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import KoddiAPI from 'api';
import createSimpleSaga from 'utils/createSimpleSaga';
import { takeEvery, all, call, select, put } from 'redux-saga/effects';
import SagaRegistry from 'redux-core/sagaRegistry';
import { Advertiser } from 'api/Advertiser';
import { selectAuthUser } from 'redux-core/auth/selectors';
import { KoddiUser } from 'api/Admin/User';
import {
    getAvailableAdvertisersList,
    getAvailableAdvertisersListError,
    getAvailableAdvertisersListSuccess,
} from './actions';
import {
    AppAdvertiserAction,
    GetAvailableAdvertisersListAction,
} from './types';
import { selectFetchedMemberGroupId } from './selectors';

export function* getAvailableAdvertisersListApi({
    payload: { memberGroupId },
}: {
    payload: { memberGroupId: number };
}) {
    const user: KoddiUser | null = yield select(selectAuthUser);
    if (user && memberGroupId) {
        const result: Advertiser[] = yield call(
            KoddiAPI.Advertiser.getList,
            memberGroupId
        );
        return result;
    }
    return [];
}

export function* updateAvailableAdvertisersListSaga({
    payload,
}: GetAvailableAdvertisersListAction) {
    const { memberGroupId } = payload;
    const fetchedMemberGroupId = yield select(selectFetchedMemberGroupId);
    if (memberGroupId === 0 || memberGroupId === fetchedMemberGroupId) return;
    yield put(getAvailableAdvertisersList(memberGroupId));
}

export const getAvailableAdvertisersListSaga = createSimpleSaga<
    GetAvailableAdvertisersListAction,
    Advertiser[]
>(
    getAvailableAdvertisersListApi,
    getAvailableAdvertisersListSuccess,
    getAvailableAdvertisersListError
);

export function* watchAvailableAdvertisersList() {
    yield takeEvery(
        AppAdvertiserAction.GET_AVAILABLE_ADVERTISERS_LIST,
        getAvailableAdvertisersListSaga
    );
}

function* appAdvertisersSagas() {
    yield all([watchAvailableAdvertisersList()]);
}

SagaRegistry.registerSaga(appAdvertisersSagas);
