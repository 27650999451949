import { SelectOption } from 'app/koddi-components/src/Select';
import { ReduxActionWithPayload } from 'app/react-ui/types';
import {
    EntityAttributesAction,
    entityTableAttributeData,
    FetchEntityAttributesAction,
    FetchEntityAttributesErrorAction,
    FetchEntityAttributesSuccessAction,
} from './types';

export function fetchEntityAttributes(
    memberGroupId: number
): FetchEntityAttributesAction {
    return {
        type: EntityAttributesAction.FETCH_ENTITY_ATTRIBUTES,
        payload: {
            memberGroupId,
        },
    };
}

export function fetchEntityAttributesSuccess(
    data: entityTableAttributeData
): FetchEntityAttributesSuccessAction {
    return {
        type: EntityAttributesAction.FETCH_ENTITY_ATTRIBUTES_SUCCESS,
        payload: {
            data,
        },
    };
}

export function fetchEntityAttributesError(
    error: string
): FetchEntityAttributesErrorAction {
    return {
        type: EntityAttributesAction.FETCH_ENTITY_ATTRIBUTES_ERROR,
        payload: {
            error,
        },
    };
}

export function setCampaignPacingOptions(
    pacingOptions: SelectOption[]
): ReduxActionWithPayload<
    EntityAttributesAction.SET_PACING_OPTIONS,
    { pacingOptions: SelectOption[] }
> {
    return {
        type: EntityAttributesAction.SET_PACING_OPTIONS,
        payload: { pacingOptions },
    };
}
