import React from 'react';
import { Box, Textarea } from '@chakra-ui/react';

import { ColorSchemes } from '../../../ChakraTheme';
import { CTextareaProps } from './Textarea.types';
import { useTextarea } from './Textarea.hooks';
import { CFormError } from '../../../Typography';

export const CTextarea = ({
    onChange,
    inputValue,
    errorText,
    testId,
    isControlledInput = false,
    hideError = false,
    ...props
}: CTextareaProps): JSX.Element => {
    const { valueProp, styleProps, handleChange } = useTextarea({
        onChange,
        inputValue,
        errorText,
        isControlledInput,
    });
    return (
        <Box width="100%">
            <Textarea
                {...props}
                {...valueProp}
                {...styleProps}
                onChange={handleChange}
                colorScheme={ColorSchemes.primary}
                data-test={`${testId}--textarea`}
            />
            {!hideError && (
                <CFormError data-test={`${testId}--error`} text={errorText} />
            )}
        </Box>
    );
};
