import { KoddiAPIResponse } from 'types';
import CachedKoddiAPI from 'api/api.cachedObject';
import { KoddiListFilterParams } from 'koddi/types';
import {
    CurrencyList,
    BiddingConfigReport,
    UnassignedCurrenciesList,
    BidValues,
    BidValuesWithExperiences,
} from './Currency.types';

class CurrencyAPI extends CachedKoddiAPI {
    getList = async (): Promise<CurrencyList> => {
        const response = await this.cache.makeCachedRequest(
            'getList',
            '',
            () => {
                return this.axios.get<
                    KoddiAPIResponse<{ currencies: CurrencyList }>
                >(`v1/currencies`);
            }
        );

        return response.data.result.currencies;
    };

    public getMemberGroupCurrencies = async (
        memberGroupId: number
    ): Promise<CurrencyList> => {
        const response = await this.axios.get<
            KoddiAPIResponse<{ currency: CurrencyList }>
        >(`v1/member_groups/${memberGroupId}/currencies`);
        return response.data.result.currency;
    };

    /**
     * excludes experiences
     * @deprecated pending the removal of the experience_configuration feature flag
     * replace with createCurrencyBidValuesV2
     */
    public getMemberGroupBiddingConfigsReport = async (
        memberGroupId: number,
        params?: KoddiListFilterParams
    ): Promise<any> => {
        const [id, direction] = params?.sort?.split(' ') ?? [];
        const response = await this.axios.post<
            KoddiAPIResponse<{ bidding_configs: BiddingConfigReport }>
        >(`v1/member_groups/${memberGroupId}/bidding_configs_report`, {
            pagination: {
                start: params?.start || 0,
                count: params?.count || 250,
            },
            filters: params?.search
                ? [
                      {
                          field: 'name',
                          operation: 'ILIKE',
                          value: params?.search,
                      },
                  ]
                : [],
            ...(id
                ? {
                      sort: [
                          {
                              field: id,
                              ...(direction ? { order: direction } : {}),
                          },
                      ],
                  }
                : {}),
        });
        return response.data.result.bidding_configs || [];
    };

    // Note: We fetch these when a user logs in and use them to determine various bidding configs.
    // The default pagintion count is set to 540 to support a client having up to three experiences
    // that are configured for all currencies available. Current product expectation is that a
    // client will only have two experiences maximum.
    public getMemberGroupBiddingConfigsReportV2 = async (
        memberGroupId: number,
        params?: KoddiListFilterParams
    ): Promise<any> => {
        const [id, direction] = params?.sort?.split(' ') ?? [];

        const response = await this.axios.post<
            KoddiAPIResponse<{ bidding_configs: BiddingConfigReport }>
        >(`v2/member_groups/${memberGroupId}/bidding_configs_report`, {
            pagination: {
                start: params?.start || 0,
                count: params?.count || 540,
            },
            filters: params?.search
                ? [
                      {
                          field: 'name',
                          operation: 'ILIKE',
                          value: params?.search,
                      },
                  ]
                : [],
            ...(id
                ? {
                      sort: [
                          {
                              field: id,
                              ...(direction ? { order: direction } : {}),
                          },
                      ],
                  }
                : {}),
        });
        return response.data.result.bidding_configs || [];
    };

    /**
     * excludes experiences
     * @deprecated pending the removal of the experience_configuration feature flag
     * replace with createCurrencyBidValuesV2
     */
    public createCurrencyBidValues = async (
        memberGroupId: number,
        payload: BidValues
    ): Promise<BidValues> => {
        const response = await this.axios.post<KoddiAPIResponse<BidValues>>(
            `v1/member_groups/${memberGroupId}/bidding_configs`,
            payload
        );
        return response.data.result;
    };

    public createCurrencyBidValuesV2 = async (
        memberGroupId: number,
        payload: BidValues
    ): Promise<BidValues> => {
        const response = await this.axios.post<KoddiAPIResponse<BidValues>>(
            `v2/member_groups/${memberGroupId}/bidding_configs`,
            payload
        );
        return response.data.result;
    };

    /**
     * excludes experiences
     * @deprecated pending the removal of the experience_configuration feature flag
     * replace with updateCurrencyBidValuesV2
     */
    public updateCurrencyBidValues = async (
        memberGroupId: number,
        payload: BidValues
    ): Promise<BidValues> => {
        const response = await this.axios.patch<KoddiAPIResponse<BidValues>>(
            `v1/member_groups/${memberGroupId}/bidding_configs`,
            payload
        );
        return response.data.result;
    };

    public updateCurrencyBidValuesV2 = async (
        memberGroupId: number,
        payload: BidValuesWithExperiences
    ): Promise<BidValues> => {
        const response = await this.axios.patch<KoddiAPIResponse<BidValues>>(
            `v2/member_groups/${memberGroupId}/bidding_configs`,
            payload
        );
        return response.data.result;
    };

    /**
     * excludes experiences
     * @deprecated pending the removal of the experience_configuration feature flag
     * replace with getUnassignedCurrenciesV2
     */
    public getUnassignedCurrencies = async (
        memberGroupId: number
    ): Promise<any> => {
        const response = await this.axios.get<
            KoddiAPIResponse<{ currencies: UnassignedCurrenciesList }>
        >(
            `v1/member_groups/${memberGroupId}/bidding_configs/unassigned_currencies`
        );
        return response.data.result.currencies;
    };

    public getUnassignedCurrenciesV2 = async (
        memberGroupId: number
    ): Promise<any> => {
        const response = await this.axios.get<KoddiAPIResponse<any>>(
            `v2/member_groups/${memberGroupId}/bidding_configs/unassigned_currencies`
        );
        return response.data.result.currencies;
    };
}

export default CurrencyAPI;
