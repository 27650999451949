import { CampaignReviewStatusID } from '../api/Campaigns';

export const campaignReviewIdMap = (id: number | null): string => {
    switch (id) {
        case CampaignReviewStatusID.UnderReview:
            return 'Under Review';
        case CampaignReviewStatusID.Approved:
            return 'Approved';
        case CampaignReviewStatusID.Denied:
            return 'Rejected';
        default:
            return '--';
    }
};
