/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { DateRangePickerListProps } from './DateRangePickerList.types';

import * as Styled from './DateRangePickerList.styled';
import { DatePickerPresetDateRange } from '../DateRangePicker.types';

const DateRangePickerList = ({
    ranges,
    onRangeSelected,
    selectedRange,
}: DateRangePickerListProps): JSX.Element => {
    return (
        <Styled.DateRangePickerList>
            {Object.keys(ranges).map((key) => {
                const rangeKey = key as DatePickerPresetDateRange;
                const range = ranges[rangeKey];
                return (
                    <Styled.DateRangePickerListItem
                        selected={selectedRange?.label === range.label}
                        key={range.label}
                        onClick={() => onRangeSelected(rangeKey)}
                    >
                        {range.label}
                    </Styled.DateRangePickerListItem>
                );
            })}
        </Styled.DateRangePickerList>
    );
};

export default DateRangePickerList;
