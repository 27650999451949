import { FocusEvent, useCallback, useMemo, useState } from 'react';
import { useField, useForm } from 'react-final-form';
import {
    CCheckboxGroupProps,
    CSingleCheckboxProps,
    ToggleSwitchProps,
} from '../Controls';
import { InputProps } from '../Controls/Inputs/Input.types';
import { FormValue } from '../Types';
import { getDeepObjectValue } from '../Utils';
import { FormControls } from './FormField.const';
import { FormFieldHookValues, FormFieldProps } from './FormField.types';

export const useFormField = ({
    ...props
}: FormFieldProps): FormFieldHookValues => {
    const {
        name,
        formControlProps,
        onChange,
        onBlur,
        placeholder,
        formControl,
        label,
        options,
    } = props;
    const [isTouched, setIsTouched] = useState(false);

    const form = useForm();
    const field = useField(name);

    const {
        meta: { error, invalid },
    } = field;

    const { getState, change: changeField } = form;

    const { values, submitFailed } = getState();
    const inputValue = getDeepObjectValue(values, name);

    const errorText: string | undefined | null =
        (invalid && isTouched) || submitFailed ? error : undefined;

    const handleChange = useCallback(
        (value?: FormValue) => {
            onChange?.(value);
            changeField(name, value);
        },
        [changeField, name, onChange]
    );

    const handleBlur = useCallback(
        (e: FocusEvent<HTMLInputElement>) => {
            onBlur?.(e);
            setIsTouched(true);
        },
        [onBlur]
    );

    const controlProps = useMemo(() => {
        const inputControlProps: InputProps<FormValue> = {
            name,
            inputValue,
            isControlledInput: true,
            onChange: handleChange,
            onBlur: handleBlur,
            testId: `${name}--control`,
            errorText,
            placeholder,
            id: name,
            ...formControlProps,
        };
        const switchControlProps: ToggleSwitchProps = {
            name,
            defaultChecked: !!inputValue,
            onChange: handleChange,
            testId: `${name}--control`,
            id: name,
            ...formControlProps,
        };
        const singleCheckboxControlProps: CSingleCheckboxProps = {
            name,
            defaultChecked: !!inputValue,
            onChange: handleChange,
            testId: `${name}--control`,
            label: label ?? '',
            errorText,
            id: name,
            ...formControlProps,
        };

        const checkboxGroupControlProps: CCheckboxGroupProps = {
            name,
            options: options ?? [],
            onChange: handleChange,
            testId: `${name}--control`,
            errorText,
            ...formControlProps,
        };

        const currencyInputControlProps = {
            name,
            inputValue,
            onChange: handleChange,
            testId: `${name}--control`,
            id: name,
            ...formControlProps,
        };

        switch (formControl) {
            case FormControls.singleCheckbox:
                return singleCheckboxControlProps;
            case FormControls.toggle:
                return switchControlProps;
            case FormControls.currencyInput:
                return currencyInputControlProps;
            case FormControls.checkboxGroup:
                return checkboxGroupControlProps;
            default:
                return inputControlProps;
        }
    }, [
        name,
        inputValue,
        handleChange,
        handleBlur,
        errorText,
        placeholder,
        formControlProps,
        label,
        options,
        formControl,
    ]);

    return {
        ...props,
        controlProps,
    };
};
