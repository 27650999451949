import React from 'react';
import { OptionWithCheckbox } from '../atoms/OptionWithCheckbox.atom';
import { MultiSelectFieldProps } from '../SelectField.types';
import { SimpleMultiValue } from '../atoms/SimpleMultiValue.atom';
import { CSelectFieldBase } from '../SelectField.component';
import { SearchInput } from '../atoms/SearchInput.atom';
import { DnDControl } from '../atoms/DnDControl.atom';
import { MultiSelectMenu } from '../atoms/MultiSelectMenu.atom';
import { IconSearchInput } from '../atoms/IconSearchInput.atom';
import { DropdownIndicator } from '../atoms/DropdownIndicator.atom';
import { OptionType } from '../../../Types';
import { CSelectFieldPaginated } from './SelectFieldPaginated';

export const CMultiSelectField = <T extends string[] | number[] = string[]>({
    ...props
}: MultiSelectFieldProps<T>): JSX.Element => {
    const components = {
        Option: OptionWithCheckbox,
    };
    return <CSelectFieldBase<T> {...props} isMulti components={components} />;
};

export const CSortableMultiSelectField = <
    T extends string[] | number[] = string[]
>({
    ...props
}: MultiSelectFieldProps<T>): JSX.Element => {
    const components = {
        Option: OptionWithCheckbox,
        Control: DnDControl as any,
        MultiValue: SimpleMultiValue,
        Menu: MultiSelectMenu,
        Input: SearchInput,
    };
    return (
        <CSelectFieldBase<T>
            {...props}
            isMulti
            isClearable={false}
            resetSearchOnSelection
            placeholder=""
            components={components}
            hideSelectAll
            backspaceRemovesValue={false}
        />
    );
};

export const CSplitSelectField = <T extends string[] | number[] = string[]>({
    isMulti = false,
    isPaginated = false,
    fetchOptions,
    ...props
}: MultiSelectFieldProps<T> & {
    isMulti?: boolean;
    isPaginated?: boolean;
    fetchOptions?: (
        searchValue: string | undefined,
        page: number
    ) => Promise<OptionType<string | number>[]>;
    trackedPagination?: {
        page: number;
        count: number;
    };
    dependsOn?: boolean;
    defaultValues?: OptionType[];
}): JSX.Element => {
    const components = {
        Option: OptionWithCheckbox,
        MultiValue: SimpleMultiValue,
        Menu: MultiSelectMenu,
        Input: IconSearchInput,
        DropdownIndicator,
        LoadingIndicator: () => null, // this will overflow, we are handling loading in IconSearchInput
    };
    if (isPaginated && fetchOptions)
        return (
            <CSelectFieldPaginated<T>
                {...props}
                fetchOptions={fetchOptions}
                isMulti={isMulti}
                shouldWrap={false}
                isClearable={false}
                resetSearchOnSelection
                placeholder=""
                components={components}
                height={32}
            />
        );

    return (
        <CSelectFieldBase<T>
            {...props}
            isMulti={isMulti}
            shouldWrap={false}
            isClearable={false}
            resetSearchOnSelection
            placeholder=""
            components={components}
            height={32}
        />
    );
};

export const CSimpleMultiSelectField = <
    T extends string[] | number[] = string[]
>({
    ...props
}: MultiSelectFieldProps<T>): JSX.Element => {
    const components = {
        Option: OptionWithCheckbox,
        MultiValue: SimpleMultiValue,
        Menu: MultiSelectMenu,
        Input: SearchInput,
    };
    return (
        <CSelectFieldBase<T>
            {...props}
            isMulti
            isClearable={false}
            components={components}
            backspaceRemovesValue={false}
            placeholder=""
        />
    );
};
