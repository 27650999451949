/* eslint-disable no-console */

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import {
    all,
    put,
    takeEvery,
    takeLeading,
    call,
    select,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import KoddiAPI from 'api';
import { Auth0 } from 'modules/Auth0';
import SagaRegistry from 'redux-core/sagaRegistry';
import createSimpleSaga from 'utils/createSimpleSaga';
import { THEME_STORAGE_KEY, AUTH0_ENABLED } from 'utils/constants';
import { LOGIN_ROUTE } from 'modules/constants/routes';
import { RETURN_ROUTE_SESSION_KEY } from 'modules/Auth0/Auth0.const';
import {
    loginSuccess,
    loginError,
    logoutSubmit,
    logoutSuccess,
    logoutError,
    updateUserSuccess,
    updateUserError,
    logoutWithSSO,
    logoutWithSSOSubmit,
    loginWithSSOSuccess,
} from './actions';
import {
    AuthAction,
    LoginSubmitAction,
    LogoutSubmitAction,
    AuthLocalStorageData,
    LogoutAction,
    UnauthorizedRequestAction,
    LoginWithSSOSuccessAction,
} from './types';
import { selectEmail, selectAuthUser } from './selectors';

export function getThemeFromLocalStorage(): AuthLocalStorageData | null {
    const previousSession = localStorage.getItem(THEME_STORAGE_KEY);
    if (previousSession) {
        return JSON.parse(previousSession);
    }
    return null;
}

export function getExpirationTime(expiresIn: number) {
    return +new Date() + expiresIn * 1000;
}

// Login Sagas

export function* loginSubmitSaga({
    payload: { email, password },
}: LoginSubmitAction) {
    try {
        put(loginError(null));
        // Replace cognito SDK with internal /session/login endpoint to get Koddi specific id_token
        const loginResult = yield call(KoddiAPI.Auth.login, email, password);
        const expiration = yield call(
            getExpirationTime,
            loginResult.token.expires_in
        );
        yield call(
            KoddiAPI.signIn,
            loginResult.token.id_token,
            loginResult.token.expires_in,
            expiration
        );
        const koddiUser = yield call(KoddiAPI.Admin.User.get, email);

        // Store token and email in localStorage and redirect to dashboard
        yield put(
            loginSuccess(
                koddiUser,
                loginResult.token.id_token,
                loginResult.token.refresh_token,
                loginResult.token.expires_in,
                true
            )
        );
    } catch (e) {
        yield put(loginError(new Error(e.response.data.error)));
    }
}

export function* watchLoginSubmit() {
    yield takeLeading(AuthAction.LOGIN_SUBMIT, loginSubmitSaga);
}

// Logout Sagas
export function* returnToLoginRoute(action: LogoutAction) {
    const { loginKey, returnRoute } = action.payload;
    let loginRoute = LOGIN_ROUTE;
    if (returnRoute && loginKey) {
        loginRoute = `/${loginKey}/login?returnRoute=${returnRoute}`;
    }
    if (!loginKey && returnRoute) {
        loginRoute = `${LOGIN_ROUTE}?returnRoute=${returnRoute}`;
    }
    if (loginKey && !returnRoute) {
        loginRoute = `/${loginKey}/login`;
    }
    yield put(push(loginRoute));
}

export function* logoutSaga({ payload }: LogoutAction) {
    yield call(returnToLoginRoute, {
        type: AuthAction.LOGOUT_WITH_SSO,
        payload,
    });
}

export function* logoutApi({ payload }: LogoutSubmitAction) {
    const { message, loginKey, returnRoute } = payload;
    yield call(KoddiAPI.Session.logout, message);
    yield call(KoddiAPI.signOut);
    yield call(logoutSaga, {
        type: AuthAction.LOGOUT_WITH_SSO,
        payload: { loginKey, returnRoute },
    });
}

export const logoutSubmitSaga = createSimpleSaga(
    logoutApi,
    logoutSuccess,
    logoutError
);

export function* watchLogout() {
    yield takeEvery(AuthAction.LOGOUT, logoutSaga);
}

export function* watchLogoutSubmit() {
    yield takeEvery(AuthAction.LOGOUT_SUBMIT, logoutSubmitSaga);
}

// Login with Auth0 Sagas
export function* loginWithSSOSuccessSaga({
    payload: { member_group_id, auth0State },
}: LoginWithSSOSuccessAction) {
    try {
        if (!auth0State) throw Error('');

        const {
            idToken,
            accessToken,
            user,
            expirationTime,
            expires_in,
        } = auth0State;

        yield call(
            KoddiAPI.Auth.postAuth0Token,
            member_group_id,
            idToken,
            accessToken
        );

        yield call(
            KoddiAPI.signIn,
            idToken,
            expires_in ?? 0,
            expirationTime ?? 0
        );

        localStorage.setItem(AUTH0_ENABLED, 'true');
        const koddiUser = yield call(KoddiAPI.Admin.User.get, user.email);
        yield put(
            loginSuccess(koddiUser, idToken, null, expires_in ?? 0, true, false)
        );
    } catch (e) {
        yield put(logoutWithSSO());
        yield put(
            loginError(
                new Error('Sorry, we failed to log you in. Please try again.')
            )
        );
    }
}

export function* watchLoginWithSSOSuccess() {
    yield takeEvery(AuthAction.LOGIN_WITH_SSO_SUCCESS, loginWithSSOSuccessSaga);
}

// Logout with Auth0 sagas

export function* logoutWithSSOSubmitSaga({ payload }: LogoutSubmitAction) {
    const { loginKey, returnRoute } = payload;
    let loginRoute = LOGIN_ROUTE;
    if (returnRoute && loginKey) {
        loginRoute = `/${loginKey}/login?returnRoute=${returnRoute}`;
    }
    if (!loginKey && returnRoute) {
        loginRoute = `${LOGIN_ROUTE}?returnRoute=${returnRoute}`;
    }
    if (loginKey && !returnRoute) {
        loginRoute = `/${loginKey}/login`;
    }
    const auth0ReturnUrl = `${window.location.origin}/#${loginRoute}`;
    try {
        if (returnRoute) {
            sessionStorage.setItem(RETURN_ROUTE_SESSION_KEY, returnRoute);
        }
        yield call(Auth0.logout, auth0ReturnUrl);
        yield call(KoddiAPI.signOut);
    } catch (err) {
        yield call(logoutError, err);
    }
}
export function* watchLogoutWithSSO() {
    yield takeEvery(AuthAction.LOGOUT_WITH_SSO, logoutSaga);
}
export function* watchLogoutWithSSOSubmit() {
    yield takeEvery(AuthAction.LOGOUT_WITH_SSO_SUBMIT, logoutWithSSOSubmitSaga);
}

// Update User Saga

export function* updateUserApi() {
    const email = yield select(selectEmail);
    const user = yield call(KoddiAPI.Admin.User.get, email);
    return user;
}

export const updateUserSaga = createSimpleSaga(
    updateUserApi,
    updateUserSuccess,
    updateUserError
);

export function* watchUpdateUser() {
    yield takeLeading(AuthAction.UPDATE_USER, updateUserSaga);
}

// Refresh Session Sagas
export function* refreshSessionSubmit() {
    try {
        const auth0State = yield call(Auth0.getState);
        // This member group id must be for the user that is logged in, not the member group in context
        const { member_group_id } = yield select(selectAuthUser);
        if (auth0State) {
            yield put(loginWithSSOSuccess(member_group_id, auth0State));
        } else {
            throw Error('Auth0 could not refresh this session');
        }
    } catch (error) {
        yield put(logoutWithSSO());
    }
}

export function* watchRefreshSession() {
    yield takeEvery(AuthAction.REFRESH_SESSION, refreshSessionSubmit);
}

// Unauthorized Request Sagas

export function* unauthorizedRequestSaga(action: UnauthorizedRequestAction) {
    yield put(
        logoutSubmit(action.message, action.loginKey, action.returnRoute)
    );
    yield put(
        logoutWithSSOSubmit(action.message, action.loginKey, action.returnRoute)
    );
}

export function* watchUnauthorizedRequest() {
    yield takeEvery(AuthAction.UNAUTHORIZED_REQUEST, unauthorizedRequestSaga);
}

// All Auth Sagas to be run

function* authSagas() {
    yield all([
        watchLoginWithSSOSuccess(),
        watchLoginSubmit(),
        watchLogoutSubmit(),
        watchLogoutWithSSOSubmit(),
        watchRefreshSession(),
        watchUpdateUser(),
        watchUnauthorizedRequest(),
        watchLogout(),
        watchLogoutWithSSO(),
    ]);
}

SagaRegistry.registerSaga(authSagas);
