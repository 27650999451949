import { CTextButton } from 'app/koddi-components/src/_ChakraComponents/Buttons';
import {
    FontSizes,
    Spacing,
} from 'app/koddi-components/src/_ChakraComponents/ChakraTheme';
import { CRow } from 'app/koddi-components/src/_ChakraComponents/Layouts';
import React from 'react';
import { MenuProps, components as ReactSelectComponents } from 'react-select';
import { OptionType } from '../../../Types';
import { OptionWithProps } from '../SelectField.types';

export const MultiSelectMenu: any = ({
    children,

    ...props
}: {
    children: Element;
} & MenuProps<OptionWithProps>) => {
    const {
        selectProps: { inputValue, hideSelectAll },
        setValue,
        getValue,
        options,
    } = props;
    const hasSearchValue = !!inputValue;
    const handleSelectAll = () => {
        const value = getValue() as OptionType[];
        if (hasSearchValue && value) {
            const valueValues = value.map((v) => v.value);
            const filteredOptions = options.filter(
                (option) => !valueValues.includes(option.value)
            );
            setValue([...value, ...filteredOptions], 'set-value');
            return;
        }
        setValue(options, 'set-value');
    };
    const handleClearAll = () => {
        const permanentOptions = options.filter(
            (option) => !!option?.isPermanent
        );
        setValue(permanentOptions, 'set-value');
    };

    const buttonStyles = {
        mx: Spacing.XS,
        mt: Spacing.XS,
        mb: Spacing.XXS,
        fontSize: FontSizes.sm,
        display: 'block',
    };

    return (
        <ReactSelectComponents.Menu {...props}>
            <>
                <CRow
                    gap={Spacing.XXS}
                    noBreak
                    justifyContent={
                        hideSelectAll ? 'flex-end' : 'space-between'
                    }
                >
                    {!hideSelectAll && (
                        <CTextButton
                            {...buttonStyles}
                            testId="multiselect--select-all"
                            onClick={handleSelectAll}
                            isDisabled={!options.length}
                        >
                            {hasSearchValue
                                ? 'Add to current selection'
                                : 'Select all'}
                        </CTextButton>
                    )}

                    <CTextButton
                        {...buttonStyles}
                        testId="multiselect--clear-all"
                        onClick={handleClearAll}
                        isDisabled={!options.length}
                    >
                        Clear selections
                    </CTextButton>
                </CRow>
                {children}
            </>
        </ReactSelectComponents.Menu>
    );
};
