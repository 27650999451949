/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Tooltip } from '@chakra-ui/react';
import {
    BorderRadius,
    Colors,
    Spacing,
} from 'app/koddi-components/src/_ChakraComponents/ChakraTheme';
import { CRow } from 'app/koddi-components/src/_ChakraComponents/Layouts';
import { CText } from 'app/koddi-components/src/_ChakraComponents/Typography';
import React from 'react';
import { EmailTagProps } from '../EmailGrabber.types';

export const EmailTag = ({
    email,
    removeFunction,
}: EmailTagProps): JSX.Element => {
    const exceedsCharacterMax = email.length > 40;
    return (
        <Tooltip
            label={email}
            isDisabled={!exceedsCharacterMax}
            shouldWrapChildren
        >
            <CRow
                noBreak
                gap={Spacing.XXS}
                backgroundColor={Colors.grayLighter}
                alignItems="center"
                borderRadius={BorderRadius.XS}
                py={Spacing.XXS}
                px={Spacing.XS}
            >
                <CText
                    isTruncated={exceedsCharacterMax}
                    data-tag-item
                    text={email}
                />
                <span role="button" data-tag-handle onClick={removeFunction}>
                    ×
                </span>
            </CRow>
        </Tooltip>
    );
};
