/**
 * Cached API
 * The Cached API allows us to make cached requests to the API. The responses are
 * stored in a map, whenever that data is requested again we will return the cached
 * response until the cache has expired or been broken. When the cache is broken for
 * an item, we store a key in localStorage so the other tabs know the cache has been
 * brken on that item (usually because we made a change on the cached item).
 */

export type CacheKey = string | number;
export type CacheValue = Map<CacheKey, any>;
export type ClearedCacheMap = Map<string, number>;
export type Cache = Map<CacheKey, any>;
export type CacheTime = Map<CacheKey, number>;
export type CacheMethodValue = string;
export type CacheOptions = {
    cacheTimeout?: number;
    cacheName?: string;
};

const DEFAULT_CACHE_TIMEOUT = 300000; // how long we keep something in the cache
export const API_CACHE_LOCAL_STORAGE_KEY = 'api-cache-break';

const getLocalStorageCacheBreakKeys: any = () => {
    try {
        const localStorageCache = localStorage.getItem(
            API_CACHE_LOCAL_STORAGE_KEY
        );
        return localStorageCache ? JSON.parse(localStorageCache) : null;
    } catch (e) {
        // eslint-disable-next-line no-console
        console.warn('could not retrieve API Cache key from Local Storage:', e);
        return null;
    }
};

// Store key/timestamp in local storage for cross window cache break
const storeCacheBreakKeyInLocalStorage = (cacheName: string, key: CacheKey) => {
    const localStorageCache = getLocalStorageCacheBreakKeys();
    localStorage.setItem(
        API_CACHE_LOCAL_STORAGE_KEY,
        JSON.stringify({
            ...localStorageCache,
            [`${cacheName}-${key}`]: +new Date(),
        })
    );
};

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
class CachedAPI {
    protected cache: Cache;

    protected cacheTime: CacheTime;

    protected cacheTimeout: number;

    protected cacheName: string;

    /* cache keys from localStorage that have already been cleared */
    protected clearedCacheKeysMap: ClearedCacheMap;

    constructor(options?: CacheOptions) {
        this.cache = new Map<CacheKey, any>();
        this.cacheTime = new Map<CacheKey, number>();
        this.clearedCacheKeysMap = new Map<string, any>();
        this.cacheTimeout = options?.cacheTimeout || DEFAULT_CACHE_TIMEOUT;
        this.cacheName = options?.cacheName || '';
    }

    protected set(method: CacheMethodValue, key: CacheKey, value: any): void {
        const cacheKey = `${method}-${key}`;
        this.cache.set(cacheKey, value);
        this.cacheTime.set(cacheKey, +new Date());
    }

    /**
     * Removes the item from the cache and stores the cache break
     * key in local storage
     */
    protected delete(key: CacheKey, addToLocalStorage = true) {
        this.cache.delete(key);
        if (addToLocalStorage) {
            storeCacheBreakKeyInLocalStorage(this.cacheName, key);
            const cacheBreakKey = `${this.cacheName}-${key}`;
            this.clearedCacheKeysMap.set(
                cacheBreakKey,
                getLocalStorageCacheBreakKeys()?.[cacheBreakKey]
            );
        }
    }

    /**
     * Returns whether the cache was broken for key in
     * another tab
     */
    protected cacheClearedInAnotherTab(key: CacheKey) {
        const localStorageValue = getLocalStorageCacheBreakKeys()?.[
            `${this.cacheName}-${key}`
        ];
        const memoryValue = this.clearedCacheKeysMap.get(
            `${this.cacheName}-${key}`
        );
        const wasClearedAlready = localStorageValue === memoryValue;

        if (!wasClearedAlready) {
            this.clearedCacheKeysMap.set(
                `${this.cacheName}-${key}`,
                localStorageValue
            );
        }
        return localStorageValue && !wasClearedAlready;
    }

    /**
     * Clears items from the cache that have expired or been broken
     * in another tab
     */
    protected clearExipredItems(): void {
        this.cache.forEach((_, key) => {
            const cacheTime = this.cacheTime.get(key);
            const cacheTimeExpired =
                cacheTime && +new Date() >= cacheTime + this.cacheTimeout;
            const clearedInAnotherTab = this.cacheClearedInAnotherTab(key);
            if (cacheTimeExpired || clearedInAnotherTab) {
                this.delete(key, !clearedInAnotherTab);
            }
        });
    }

    protected getCachedValue(
        method: CacheMethodValue,
        key: CacheKey
    ): CacheValue | undefined {
        return this.cache.get(`${method}-${key}`);
    }

    public break(method: string, key: CacheKey): void {
        this.delete(`${method}-${key}`);
    }

    public breakMethod(method: string): void {
        this.cache.forEach((_, key) => {
            if (key.toString().includes(`${method}-`)) {
                this.delete(key);
            }
        });
    }

    public makeCachedRequest(
        method: CacheMethodValue,
        key: CacheKey,
        apiCall: () => Promise<any>,
        force?: boolean
    ): Promise<any> {
        this.clearExipredItems();
        const cachedValue = this.getCachedValue(method, key);
        if (cachedValue && !force) {
            return new Promise((resolve) => resolve(cachedValue));
        }
        return new Promise((resolve, reject) => {
            apiCall()
                .then((response) => {
                    this.set(method, key, response);
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
}

export default CachedAPI;
