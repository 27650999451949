import ColorScheme from 'color-scheme';
import numeral from 'numeral';
import isNumber from 'lodash/isNumber';
import {
    StandardLonghandPropertiesHyphen,
    StandardShorthandPropertiesHyphen,
} from 'csstype';
import { getLuminance } from 'polished';
import {
    STATUS_ACTIVE,
    STATUS_ENDED,
    STATUS_PAUSED,
    STATUS_PENDING,
    STATUS_INACTIVE,
    STATUS_LOCKED,
    STATUS_DEACTIVATED,
    STATUS_APPROVED,
    STATUS_OPEN,
    STATUS_CLOSED,
} from 'modules/constants/status';
import { Dimension, ReportContext } from 'api/Reports';
import {
    GroupedOptionsType,
    GroupType,
    OptionsType,
    OptionTypeBase,
    ValueType,
} from 'react-select';
import { KoddiTheme } from 'koddi-components/ThemeProvider';
import { CancelablePromise, CancelablePromiseData } from './types';
import { KoddiLocaleKey } from './LocaleProvider';

export function getStatusColor(value = '', theme: KoddiTheme): string {
    switch (value.toLowerCase()) {
        case STATUS_ACTIVE: {
            return '';
        }
        case STATUS_APPROVED: {
            return theme.white;
        }
        case STATUS_OPEN: {
            return theme.white;
        }
        case STATUS_ENDED: {
            return theme.gray;
        }
        case STATUS_CLOSED: {
            return '#ff2e3b';
        }
        case STATUS_PAUSED: {
            return theme.warning;
        }
        case STATUS_PENDING: {
            return '#ED6E33'; // @TODO add this to theme
        }
        case STATUS_INACTIVE: {
            return theme.gray;
        }
        case STATUS_LOCKED: {
            return theme.error;
        }
        case STATUS_DEACTIVATED: {
            return theme.error;
        }
        default:
            return theme.gray;
    }
}

/**
 * Returns light or dark colors for best
 * contrast depending on the luminosity of the given color.
 * @param color original color.
 * @param lightReturnColor light color.
 * @param darkReturnColor dark color.
 */
export function readableStateColor(
    color: string,
    lightReturnColor: string,
    darkReturnColor: string
): string {
    const isLightColor = getLuminance(color) > 0.179;
    return isLightColor ? darkReturnColor : lightReturnColor;
}

export function getDataTestName(name: string, prepend = ''): string {
    const base = name?.toLowerCase().split(' ').join('-') || '';
    return `${prepend}${base}`;
}

/**
 * Creates a color palette of 26 colors using the 2 graph theme colors
 * from the theme object.
 * @param themeGraphColors The graph colors from the theme object.
 */
export function createColorScheme(
    themeGraphColors: [string, string]
): string[] {
    const newColorsFromFirst = new ColorScheme()
        .from_hex(themeGraphColors[0].replace('#', ''))
        .scheme('analogic')
        .distance(1)
        .variation('soft')
        .colors()
        .sort();
    const newColorsFromSecond = new ColorScheme()
        .from_hex(themeGraphColors[1].replace('#', ''))
        .scheme('analogic')
        .distance(1)
        .variation('soft')
        .colors()
        .sort();
    const newColors = [...newColorsFromFirst, ...newColorsFromSecond]
        .sort()
        .map((color) => `#${color}`);
    return themeGraphColors.concat(newColors);
}

/**
 * A utility function for converting a string or a number
 * to a CSS value. is the value is a number then `px` is added
 * to the end. If not, then the string is return.
 */
export function cssValue(
    value?: string | number,
    defaultValue?: string | number
): string {
    return value !== undefined
        ? `${isNumber(value) ? `${value}px` : value}`
        : `${isNumber(defaultValue) ? `${defaultValue}px` : defaultValue}`;
}

/**
 * Creates a CSS Style property or returns an empty string.
 *
 * Useful for preventing useless styles such as `width: undefined`.
 */
export function cssProp(
    prop:
        | keyof StandardLonghandPropertiesHyphen
        | keyof StandardShorthandPropertiesHyphen,
    value?: string | number,
    defaultValue?: string | number
): string {
    if (value !== undefined || defaultValue !== undefined) {
        return `${prop}: ${cssValue(value, defaultValue)}`;
    }
    return ``;
}

/**
 * Creates a promise that can be canceled later.
 * @param promise The promise to wait for.
 */
export function makeCancelablePromise<Data>(
    promise: Promise<Data>,
    rejectOnCancel: boolean
): { promise: CancelablePromise<Data>; cancel: VoidFunction } {
    let isCanceled = false;

    function createRejectionError() {
        const error = new Error(JSON.stringify({ isCanceled }));
        error.name = 'CancelablePromiseError';
        return error;
    }

    const wrappedPromise = new Promise<CancelablePromiseData<Data>>(
        (resolve, reject) => {
            promise
                .then((val) => {
                    if (isCanceled) {
                        if (rejectOnCancel) {
                            reject(createRejectionError());
                        } else {
                            resolve({ canceled: true });
                        }
                    } else {
                        resolve(val);
                    }
                })
                .catch((error) =>
                    isCanceled ? reject(createRejectionError()) : reject(error)
                );
        }
    );

    return {
        promise: wrappedPromise,
        cancel() {
            isCanceled = true;
        },
    };
}

/**
 * A utility to determine if a resolved or rejected value
 * was the result of a cancelled promise.
 * @param value The resolved or rejected value
 */
export function wasCanceledPromise<Data>(
    value: CancelablePromiseData<Data> | Error
): boolean {
    if (value instanceof Error) {
        if (value.name === 'CancelablePromiseError') return true;
        return false;
    }

    if (value instanceof Object && value.canceled) {
        return true;
    }

    return false;
}

/**
 * Resolves a promise after a certain number of milliseconds.
 * @param ms The number of milliseconds to wait before resolving the promise.
 * @param resolveValue The value to resolve with.
 */
export function resolveAfter<Value extends any = any>(
    ms: number,
    resolveValue?: Value
): Promise<Value extends (...args: any[]) => any ? ReturnType<Value> : Value> {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(
                typeof resolveValue === 'function'
                    ? resolveValue()
                    : resolveValue
            );
        }, ms);
    });
}

export function getContextFromPathname(pathname: string): ReportContext {
    if (pathname.includes('restaurants')) return 'entity';
    if (pathname.includes('ad_group')) return 'ad_group';
    if (pathname.includes('campaign')) return 'campaign';
    if (pathname.includes('media_plan')) return 'media_plan';
    return 'media_plan';
}

export function getDimensionsByPathname(pathname: string): Dimension[] {
    return pathname.indexOf('/ad_groups') !== -1
        ? ['ad_group_name', 'ad_group_id']
        : ['media_plan_name', 'media_plan_id'];
}

export function capitalizeString(str = ''): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function notEmpty<TValue>(
    value: TValue | null | undefined
): value is TValue {
    return value !== null && value !== undefined;
}

/**
 * A utility function to determine if a react select value
 * is an object with label/value.
 */
export function isSingleValue<
    OptionType extends OptionTypeBase = OptionTypeBase
>(option: ValueType<OptionType>): option is OptionType {
    return !Array.isArray(option);
}

/**
 * A utility function to determine if a react select value
 * is an array of objects with label/value.
 */
export function isMultiValue<
    OptionType extends OptionTypeBase = OptionTypeBase
>(option: ValueType<OptionType>): option is OptionsType<OptionType> {
    return Array.isArray(option);
}

export function isGroupType<OptionType extends OptionTypeBase = OptionTypeBase>(
    option: GroupType<OptionType> | OptionType
): option is GroupType<OptionType> {
    return 'options' in option;
}

export function isGroupedOptions<
    OptionType extends OptionTypeBase = OptionTypeBase
>(
    options: GroupedOptionsType<OptionType> | OptionsType<OptionType>
): options is GroupedOptionsType<OptionType> {
    return isGroupType(options[0]);
}

export function formatError(
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    errorObject: any,
    showCode = false,
    options?: {
        formatter?: Record<string, (value: any) => string>;
    }
): string {
    if (!errorObject) return '';
    try {
        const {
            code,
            errorResponse: {
                data: { error, formatting },
            },
        } = errorObject;
        let errorString = error;
        if (formatting) {
            Object.keys(formatting).forEach((key) => {
                const formattedValue = options?.formatter?.[key]?.(
                    formatting?.[key]
                );
                errorString = errorString.replace(
                    `{{${key}}}`,
                    formattedValue || formatting[key]
                );
            });
        }
        return code && showCode
            ? capitalizeString(`${errorString} (code: ${code})`)
            : capitalizeString(errorString);
    } catch (error) {
        return 'Something went wrong.';
    }
}

export const formatNumber = ({
    value,
    useLocalize,
    format,
}: {
    value: number;
    useLocalize?: boolean;
    format?: string;
    decimals?: number;
}): string => {
    if (useLocalize) {
        let formattedValue = value;
        window.Localize?.number?.(
            Number(value),
            (_: any, localizeFormatted: number) => {
                formattedValue = localizeFormatted;
            }
        );
        return numeral(formattedValue).format(format);
    }
    return numeral(value).format(format);
};

export const BUDGET_TYPES: { [key: string]: string } = {
    Daily: 'Day',
    Weekly: 'Week',
    Monthly: 'Month',
};

/**
 * A utility function that accepts either Daily, Weekly or Monthly
 * and returns either Day, Week, or Month
 */
export const formatBudgetType = (
    budgetType: 'Daily' | 'Weekly' | 'Monthly'
): string => {
    return BUDGET_TYPES[budgetType] || budgetType;
};

export const copyToClipboard = (content: string): void => {
    const el = document.createElement('textarea');
    el.value = content;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    const rangeCount = document.getSelection()?.rangeCount ?? 0;
    const selected =
        rangeCount > 0 ? document.getSelection()?.getRangeAt(0) : false;
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    if (selected) {
        document.getSelection()?.removeAllRanges();
        document.getSelection()?.addRange(selected);
    }
};

export const truncateString = (value: string, length: number): string => {
    if (value.length > length) {
        const lastSpaceIndex = value.lastIndexOf(' ', length);

        const truncatedValue =
            lastSpaceIndex !== -1
                ? value.substring(0, lastSpaceIndex).replace(/\s+$/, '')
                : value.substring(0, length);

        return `${truncatedValue}...`;
    }
    return value;
};

export const kebabCase = (str: string): string => {
    return str
        .replace(/([A-Z])([A-Z])/g, '$1-$2')
        .replace(/([a-z])([A-Z])/g, '$1-$2')
        .replace(/[\s_]+/g, '-')
        .toLowerCase();
};

export const getDecimalPlaces = (digits: number): string => {
    if (digits === 0) return '';
    const result = [];
    let i = 0;
    do {
        i += 1;
        result.push('0');
    } while (i <= digits - 1);
    return result.join('').replace(',', '');
};

export function valueFormatter(
    value: number,
    presentation?: string,
    formatOptions?: {
        currencyOptionalDecimal?: boolean;
        currencySymbol?: string;
        currencyDigits?: number;
        hideCurrency?: boolean;
    },
    locale?: KoddiLocaleKey
): string {
    const decimalPlaces =
        formatOptions?.currencyDigits !== undefined &&
        presentation === 'currency'
            ? getDecimalPlaces(formatOptions?.currencyDigits)
            : '00';
    const currencySymbol = formatOptions?.currencySymbol ?? '$';
    switch (presentation) {
        case 'currency': {
            const formattedValue = formatOptions?.currencyOptionalDecimal
                ? formatNumber({
                      value,
                      format: `0,0.[${decimalPlaces}]`,
                      useLocalize: true,
                  })
                : formatNumber({
                      value,
                      useLocalize: true,
                      format: `0,0.${decimalPlaces}`,
                  });
            // add locales that have the currency symbol to the right pf amount to this array
            const rightHandCurrencySymbolLocales = ['fr-CA', 'fr-FR'];
            // swap currency symbol for fr locales
            if (formatOptions?.hideCurrency) {
                return formattedValue;
            }
            return rightHandCurrencySymbolLocales.includes(locale ?? '')
                ? formattedValue + currencySymbol
                : currencySymbol + formattedValue;
        }
        case 'percentage':
            return `${formatNumber({
                value: value * 100,
                format: '0.00',
                useLocalize: true,
            })}%`;
        case 'decimal':
            return formatNumber({
                value,
                format: '0,0.[00]',
                useLocalize: true,
            });
        case 'number':
            return formatNumber({
                value,
                format: '0,0.[00]',
                useLocalize: true,
            });
        default:
            return value.toString();
    }
}

export const IS_DEV_ENV = process.env.NODE_ENV === 'development';
export const IS_PROD_ENV = process.env.NODE_ENV === 'production';
export const IS_TEST_ENV = process.env.NODE_ENV === 'test';
