import { NestedSelectOption, SelectOption } from 'koddi-components/Select';
import { ReduxActionWithPayload } from 'types';

export type formattedEntityAttributes = {
    Header: string;
    accessor: string;
    order: number;
    isInternalId: boolean;
    isName: string;
};

export type entityTableAttributeData = {
    attributes: any[];
    minified_attributes: any[];
};

export type AppConfigsState = {
    options: {
        budgetTypeOptions: {
            mediaPlan: SelectOption[];
            mediaStrategy: SelectOption[];
            campaign: SelectOption[];
            adGroup: SelectOption[];
        };
        goalTypeOptions: {
            campaign: SelectOption[];
        };
        pacingOptions: {
            campaign: SelectOption[];
            adGroup: SelectOption[];
        };
        statusOptions: {
            mediaStrategy: SelectOption[];
            editCampaign: SelectOption[];
            editClient: SelectOption[];
            createCampaign: SelectOption[];
            editAdGroup: SelectOption[];
            createAdGroup: SelectOption[];
            bulkEdit: SelectOption[];
            tableCell: SelectOption[];
        };
        cuisineOptions: NestedSelectOption[];
        userHistoryOptions: SelectOption[];
        orderValueOptions: SelectOption[];
        userStatusOptions: SelectOption[];
        advertiserStatusOptions: SelectOption[];
    };
    styles: {
        fontFamilyOptions: SelectOption[];
    };
    tableColumns: {
        entityTableColumns: {
            data: entityTableAttributeData;
            status: string;
        };
    };
};

export enum EntityAttributesAction {
    FETCH_ENTITY_ATTRIBUTES = 'app/dashboard/FETCH_ENTITY_ATTRIBUTES',
    FETCH_ENTITY_ATTRIBUTES_SUCCESS = 'app/dashboard/FETCH_ENTITY_ATTRIBUTES_SUCCESS',
    FETCH_ENTITY_ATTRIBUTES_ERROR = 'app/dashboard/FETCH_ENTITY_ATTRIBUTES_ERROR',
    SET_PACING_OPTIONS = 'app/dashboard/SET_PACING_OPTIONS',
}

export type FetchEntityAttributesAction = ReduxActionWithPayload<
    EntityAttributesAction.FETCH_ENTITY_ATTRIBUTES,
    { memberGroupId: number }
>;

export type SetPacingOptionsAction = ReduxActionWithPayload<
    EntityAttributesAction.SET_PACING_OPTIONS,
    { pacingOptions: SelectOption[] }
>;

export type FetchEntityAttributesSuccessAction = ReduxActionWithPayload<
    EntityAttributesAction.FETCH_ENTITY_ATTRIBUTES_SUCCESS,
    { data: entityTableAttributeData }
>;

export type FetchEntityAttributesErrorAction = ReduxActionWithPayload<
    EntityAttributesAction.FETCH_ENTITY_ATTRIBUTES_ERROR,
    { error: string }
>;

export type EntityAttributesActions =
    | FetchEntityAttributesAction
    | FetchEntityAttributesSuccessAction
    | FetchEntityAttributesErrorAction
    | SetPacingOptionsAction;
