import { AxiosInstance } from 'axios';
import { KoddiAPIResponse } from 'types';
import {
    BudgetRecommendationsByEntitiesPayload,
    AggregatedBudgetRecommendations,
    BudgetRecommendationsByAdvertiser,
    AdvertisersBudgetRecommendationPayload,
} from './KoddiRecommendations.types';

class KoddiRecommendationsAPI {
    protected axios: AxiosInstance;

    constructor(axios: AxiosInstance) {
        this.axios = axios;
    }

    // last one needs to be refactored to use the new API
    getBudgetRecommendationsByAdvertisers = async ({
        memberGroupId,
        experienceId,
        advertisers,
    }: AdvertisersBudgetRecommendationPayload): Promise<any> => {
        const response = await this.axios.post<
            KoddiAPIResponse<BudgetRecommendationsByAdvertiser>
        >(
            `v1/member_groups/${memberGroupId}/experiences/${experienceId}/advertisers/budget_recommendations`,
            {
                advertisers,
            }
        );

        return response.data.result;
    };

    // last use of this is in CampainWizardV3 and would take significant refactors to implement
    getBudgetRecommendationsByEntities = async ({
        memberGroupId,
        advertiserId,
        uuid,
        experienceId,
        budgetTypeId,
        startDate,
        endDate,
        targeting,
    }: BudgetRecommendationsByEntitiesPayload): Promise<{
        aggregated_budget_recommendation: AggregatedBudgetRecommendations;
    }> => {
        const response = await this.axios.post<
            KoddiAPIResponse<{
                aggregated_budget_recommendation: AggregatedBudgetRecommendations;
            }>
        >(
            `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/drafts/${uuid}/budget_recommendations`,
            {
                experience_id: experienceId,
                budget_type_id: budgetTypeId,
                start_date: startDate,
                end_date: endDate,
                targeting,
            }
        );

        return response.data.result;
    };
}

export default KoddiRecommendationsAPI;
