import CachedKoddiAPI from 'api/api.cachedObject';
import { CreateReportInput } from 'api/Reports';
import { BulkEditResponse } from 'koddi/types';
import { KoddiAPIResponse } from 'types';
import {
    AdGroup,
    AdGroupBudgetInfo,
    BulkEditAdGroupStatus,
    EntitiesPayload,
    AllEntitiesPayload,
    UpdateAdvertiserAdGroupInput,
    CreateAdvertiserAdGroupInput,
    BidStrength,
    PCLSum,
    AdGroupSpecificArgs,
    AdvertiserSpecificArgs,
    AdGroupDraftValues,
    AdGroupDraftDetails,
    EditedAdGroupDraftValues,
} from './AdGroups.types';

class KoddiAdGroupsAPI extends CachedKoddiAPI {
    public getByID = async (
        memberGroupId: number,
        advertiserId: number,
        adGroupId: number,
        forceRequest = false
    ): Promise<AdGroup> => {
        const response = await this.cache.makeCachedRequest(
            'getByID',
            `${memberGroupId}-${advertiserId}-${adGroupId}`,
            () => {
                return this.axios.get<KoddiAPIResponse<AdGroup>>(
                    `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/ad_groups/${adGroupId}`
                );
            },
            forceRequest
        );

        return response.data.result;
    };

    public create = async (
        advertiser_id: number,
        member_group_id: number,
        data: CreateAdvertiserAdGroupInput
    ): Promise<AdGroup> => {
        const response = await this.axios.post<KoddiAPIResponse<AdGroup>>(
            `v1/member_groups/${member_group_id}/advertisers/${advertiser_id}/ad_groups`,
            data
        );
        return response.data.result;
    };

    public createV2 = async ({
        advertiser_id,
        member_group_id,
        data,
    }: {
        advertiser_id: number;
        member_group_id: number;
        data: CreateAdvertiserAdGroupInput;
    }): Promise<AdGroup> => {
        const response = await this.axios.post<KoddiAPIResponse<AdGroup>>(
            `v2/member_groups/${member_group_id}/advertisers/${advertiser_id}/ad_groups`,
            data
        );
        return response.data.result;
    };

    public createV3 = async ({
        memberGroupId,
        advertiserId,
        data,
    }: AdvertiserSpecificArgs & {
        data: CreateAdvertiserAdGroupInput;
    }): Promise<{ id: number }> => {
        const response = await this.axios.post<
            KoddiAPIResponse<{ id: number }>
        >(
            `v3/member_groups/${memberGroupId}/advertisers/${advertiserId}/ad_groups`,
            data
        );
        return response.data.result;
    };

    public update = async (
        advertiser_id: number,
        member_group_id: number,
        ad_group_id: number,
        data: UpdateAdvertiserAdGroupInput
    ): Promise<AdGroup> => {
        const response = await this.axios.put<KoddiAPIResponse<AdGroup>>(
            `v1/member_groups/${member_group_id}/advertisers/${advertiser_id}/ad_groups/${ad_group_id}`,
            data
        );
        this.cache.break(
            'getByID',
            `${member_group_id}-${advertiser_id}-${ad_group_id}`
        );
        return response.data.result;
    };

    public updateV2 = async ({
        advertiser_id,
        member_group_id,
        ad_group_id,
        data,
    }: {
        advertiser_id: number;
        member_group_id: number;
        ad_group_id: number;
        data: UpdateAdvertiserAdGroupInput;
    }): Promise<AdGroup> => {
        const response = await this.axios.patch<KoddiAPIResponse<AdGroup>>(
            `v2/member_groups/${member_group_id}/advertisers/${advertiser_id}/ad_groups/${ad_group_id}`,
            data
        );
        this.cache.break(
            'getByID',
            `${member_group_id}-${advertiser_id}-${ad_group_id}`
        );
        return response.data.result;
    };

    public updateV3 = async ({
        memberGroupId,
        advertiserId,
        adGroupId,
        data,
    }: // eslint-disable-next-line prettier/prettier
    AdGroupSpecificArgs & { data: UpdateAdvertiserAdGroupInput }): Promise<
        AdGroup
    > => {
        const response = await this.axios.patch<KoddiAPIResponse<AdGroup>>(
            `v3/member_groups/${memberGroupId}/advertisers/${advertiserId}/ad_groups/${adGroupId}`,
            data
        );
        return response.data.result;
    };

    public getBudgetInfo = async (
        memberGroupId: number,
        advertiserId: number,
        adGroupId: number
    ): Promise<AdGroupBudgetInfo> => {
        const response = await this.axios.get<
            KoddiAPIResponse<AdGroupBudgetInfo>
        >(
            `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/ad_groups/${adGroupId}/budget`
        );
        return response.data.result || {};
    };

    /**
     * Bulk edit ad groups by their ids.
     */
    public bulkEdit = async (
        memberGroupId: number,
        advertiserId: number,
        ad_group_ids: AdGroup['id'][],
        status: BulkEditAdGroupStatus,
        budget_percentage?: number,
        base_bid_percentage?: number,
        budget_increase?: number,
        base_bid_increase?: number,
        reportPayload?: CreateReportInput,
        selectAll?: boolean
    ): Promise<BulkEditResponse> => {
        const response = await this.axios.patch<
            KoddiAPIResponse<BulkEditResponse>
        >(
            `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/ad_groups/bulk`,
            {
                ad_group_ids,
                budget_percentage,
                base_bid_percentage,
                budget_increase,
                base_bid_increase,
                status: status || undefined,
                report: reportPayload,
                all_selected: selectAll,
            }
        );
        this.cache.breakMethod('getByID');
        return response.data.result;
    };

    public bulkEditV2 = async ({
        memberGroupId,
        advertiserId,
        ad_group_ids,
        status,
        budget_percentage,
        base_bid_percentage,
        budget_increase,
        base_bid_increase,
        reportPayload,
        selectAll,
    }: {
        memberGroupId: number;
        advertiserId: number;
        ad_group_ids: AdGroup['id'][];
        status: BulkEditAdGroupStatus;
        budget_percentage?: number;
        base_bid_percentage?: number;
        budget_increase?: number;
        base_bid_increase?: number;
        reportPayload?: CreateReportInput;
        selectAll?: boolean;
    }): Promise<BulkEditResponse> => {
        const response = await this.axios.patch<
            KoddiAPIResponse<BulkEditResponse>
        >(
            `v2/member_groups/${memberGroupId}/advertisers/${advertiserId}/ad_groups/bulk`,
            {
                ad_group_ids,
                budget_percentage,
                base_bid_percentage,
                budget_increase,
                base_bid_increase,
                status: status || undefined,
                report: reportPayload,
                all_selected: selectAll,
            }
        );
        this.cache.breakMethod('getByID');
        return response.data.result;
    };

    public disableEntities = async (
        entities: EntitiesPayload,
        advertiser_id?: number
    ): Promise<any> => {
        const endpointPath = `v1/ad_groups/entities/bulk`;
        const queryParam = advertiser_id
            ? `?advertiserId=${advertiser_id}`
            : '';
        const response = await this.axios.delete<
            KoddiAPIResponse<BulkEditResponse>
        >(`${endpointPath}${queryParam}`, {
            data: { entities },
        });
        this.cache.breakMethod('getByID');
        return response.data.result;
    };

    public enableEntities = async (
        entities: EntitiesPayload,
        advertiser_id?: number,
        use_base_bid?: boolean
    ): Promise<any> => {
        const endpointPath = `v1/ad_groups/entities/bulk`;
        const queryParam = advertiser_id
            ? `?advertiserId=${advertiser_id}`
            : '';
        const response = await this.axios.post<
            KoddiAPIResponse<BulkEditResponse>
        >(`${endpointPath}${queryParam}`, {
            entities,
            use_base_bid,
        });
        this.cache.breakMethod('getByID');
        return response.data.result;
    };

    public enableAllEntities = async (
        payload: AllEntitiesPayload
    ): Promise<any> => {
        const response = await this.axios.post<
            KoddiAPIResponse<BulkEditResponse>
        >('v1/ad_groups/entities/bulk/all', payload);
        this.cache.breakMethod('getByID');
        return response.data.result;
    };

    public getNewBidStrength = async (
        memberGroupId: number,
        advertiserId: number,
        adGroupId: number,
        newBid: number
    ): Promise<BidStrength> => {
        const response = await this.axios.post<KoddiAPIResponse<BidStrength>>(
            `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/ad_groups/${adGroupId}/bid_strength`,
            { base_bid: newBid }
        );
        return response.data.result;
    };

    public getNewEntityBidStrength = async (
        memberGroupId: number,
        entityId: number,
        newBid: number
    ): Promise<BidStrength> => {
        const response = await this.axios.post<KoddiAPIResponse<BidStrength>>(
            `v1/member_groups/${memberGroupId}/entities/${entityId}/bid_strength`,
            { base_bid: newBid }
        );
        return response.data.result;
    };

    /** @deprecated */
    public getAdGroupPCLSum = async (
        memberGroupId: number,
        advertiserId: number,
        adGroupId: number
    ): Promise<PCLSum> => {
        const response = await this.axios.get<KoddiAPIResponse<PCLSum>>(
            `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/ad_groups/${adGroupId}/pcl`
        );
        return response.data.result;
    };

    public deleteAdGroup = async ({
        memberGroupId,
        advertiserId,
        adGroupId,
    }: {
        memberGroupId: number;
        advertiserId: number;
        adGroupId: number;
    }): Promise<null> => {
        await this.axios.delete<KoddiAPIResponse<null>>(
            `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/ad_groups/${adGroupId}`
        );
        return null;
    };

    public deleteAdGroupDraft = async ({
        memberGroupId,
        advertiserId,
        campaignUUID,
        adGroupUUID,
    }: {
        memberGroupId: number;
        advertiserId: number;
        campaignUUID: string;
        adGroupUUID: string;
    }): Promise<null> => {
        await this.axios.delete<KoddiAPIResponse<null>>(
            `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/campaign_drafts/${campaignUUID}/ad_group_drafts/${adGroupUUID}`
        );
        return null;
    };

    public saveNewAdGroupDraft = async ({
        memberGroupId,
        advertiserId,
        campaignUUID,
        payload,
    }: {
        memberGroupId: number;
        advertiserId: number;
        campaignUUID: string;
        payload: AdGroupDraftValues;
    }): Promise<{ ad_group_uuid: string }> => {
        const response = await this.axios.post<
            KoddiAPIResponse<{ ad_group_uuid: string }>
        >(
            `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/campaign_drafts/${campaignUUID}/ad_group_drafts`,
            payload
        );
        return response.data.result;
    };

    public saveEditedAdGroupDraft = async ({
        memberGroupId,
        advertiserId,
        campaignUUID,
        adGroupUUID,
        payload,
    }: {
        memberGroupId: number;
        advertiserId: number;
        campaignUUID: string;
        adGroupUUID: string;
        payload: EditedAdGroupDraftValues;
    }): Promise<AdGroupDraftDetails> => {
        const response = await this.axios.patch<
            KoddiAPIResponse<AdGroupDraftDetails>
        >(
            `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/campaign_drafts/${campaignUUID}/ad_group_drafts/${adGroupUUID}`,
            payload
        );
        return response.data.result;
    };
}

export default KoddiAdGroupsAPI;
