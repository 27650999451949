import { useState } from 'react';
import { AlertProps, AlertHookReturn } from './Alert.types';

export const useAlert = ({
    onClose,
}: Pick<AlertProps, 'onClose'>): AlertHookReturn => {
    const [isHidden, setIsHidden] = useState(false);

    const handleClose = () => {
        setIsHidden(true);
        onClose?.();
    };
    return { isHidden, handleClose };
};
