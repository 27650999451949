import CachedKoddiAPI from 'api/api.cachedObject';
import { KoddiAPIResponse } from 'types';
import { KoddiListFilterParams, Order } from 'koddi/types';
import axios from 'axios';

import {
    AdminInsertionOrderReport,
    AdminInsertionOrderReportResponse,
    CreateReportInput,
    ReportConfig,
    SavedReport,
} from './Reports.types';

export const KODDI_REPORT_API = '/report';
export const KODDI_TREND_REPORT_API = '/report/trend';
export const KODDI_TOTAL_REPORT_API = '/report/total';
export const KODDI_REPORT_CONFIG_API = '/report/config';
export const KODDI_TARGETING_REPORT_API = '/report/targeting';

class ReportAPI extends CachedKoddiAPI {
    public createReportPayload = (
        report: CreateReportInput,
        params?: KoddiListFilterParams,
        excludePagination?: boolean
    ): CreateReportInput => {
        const [id, direction] = params?.sort?.split(' ') ?? [];
        const reportPayload = excludePagination
            ? { ...report }
            : {
                  ...report,
                  pagination: {
                      start: params?.start || 0,
                      count: params?.count || 20,
                  },
              };
        if (id && direction) {
            reportPayload.sort = [
                { field: id, order: (direction as Order) || 'DESC' },
            ];
        }

        return reportPayload;
    };

    public getAdminInsertionOrderReport = async ({
        memberGroupId,
        params,
        excludePagination,
    }: AdminInsertionOrderReport): Promise<
        AdminInsertionOrderReportResponse[]
    > => {
        const [id, direction] = params?.sort?.split(' ') ?? [];
        const response = await this.axios.post<
            KoddiAPIResponse<{
                insertion_orders: AdminInsertionOrderReportResponse[];
            }>
        >(`v1/member_groups/${memberGroupId}/insertion_order_report`, {
            pagination: excludePagination
                ? undefined
                : {
                      start: params.start || 0,
                      count: params.count || 20,
                  },
            ...(id
                ? {
                      sort: [
                          {
                              field: id,
                              ...(direction ? { order: direction } : {}),
                          },
                      ],
                  }
                : {}),
            filters: params?.search
                ? [
                      ...(params.filters || []),
                      {
                          field: 'name',
                          operation: 'ILIKE',
                          value: params.search,
                      },
                  ]
                : params?.filters,
        });
        return response.data.result.insertion_orders;
    };

    // the remaining use of these is because we are calling this function twice in the same component making it unusable in SWR
    /**
     * Creates a report
     * @param report formatted request for which fields and filters to use
     */
    public create = async (
        report: CreateReportInput,
        params?: KoddiListFilterParams,
        excludePagination = false,
        returnFullResponse = false
    ): Promise<any> => {
        const endpoint =
            report.context === 'general'
                ? `v2${KODDI_TARGETING_REPORT_API}`
                : `v2${KODDI_REPORT_API}`;
        const response = await this.axios.post<any>(
            endpoint,
            this.createReportPayload(report, params, excludePagination)
        );
        return returnFullResponse
            ? response.data.result
            : response.data.result.data || [];
    };

    // charts need to be refactored to use SWR
    public fetchTrendReport = async (
        report: CreateReportInput,
        params?: KoddiListFilterParams
    ): Promise<any> => {
        const excludePagination = true;
        const response = await this.axios.post<any>(
            `v2${KODDI_TREND_REPORT_API}`,
            this.createReportPayload(report, params, excludePagination)
        );
        return response.data.result || {};
    };

    // theres not a good way to handle manual aborts with swr, so we will just not use it here, handling errors on backend seems weird
    public fetchTotalReport = async (
        report: CreateReportInput,
        params?: KoddiListFilterParams,
        signal?: AbortSignal
    ): Promise<any> => {
        let source: any;
        if (signal) {
            source = axios.CancelToken.source();
            signal.addEventListener('abort', () => {
                source.cancel('Request was aborted.');
            });
        }

        try {
            const response = await this.axios.post<any>(
                `v2${KODDI_TOTAL_REPORT_API}`,
                this.createReportPayload(report, params),
                source ? { cancelToken: source.token } : {}
            );

            return response.data.result;
        } catch (error) {
            if (axios.isCancel(error)) {
                // eslint-disable-next-line no-console
                console.error('Request was cancelled');
            } else {
                throw error;
            }

            return null;
        }
    };

    // used in redux, no application for swr
    public getConfig = async (memberGroupId: number): Promise<ReportConfig> => {
        const response = await this.cache.makeCachedRequest(
            'getConfig',
            memberGroupId,
            () => {
                return this.axios.get<KoddiAPIResponse<ReportConfig>>(
                    `v1/member_groups/${memberGroupId}/report/config`
                );
            }
        );
        return response.data.result;
    };

    // really weird how we do this, where its used, we are consuming this in a promise.all instead of just calling all saved reports or something, will not work with swr without hacks or mass refactors
    public getSavedReport = async (reportID: number): Promise<SavedReport> => {
        const response = await this.axios.get<any>(
            `/v2/saved_report/${reportID}`
        );
        return response.data.result;
    };
}

export default ReportAPI;
