/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
    FunctionComponent,
    useMemo,
    useState,
    useCallback,
} from 'react';
import useEvent from '@react-hook/event';
import { DragGroupProps } from './DragGroup.types';
import { DragGroupContainer } from './DragGroup.styled';
import DragTile from './DragTile';

/**
 * The Koddi `DragGroup` Component
 * 
 * `direction` horizontal or vertical. Default horizontal
 * `data` pass an array of objects with 'id' and 'name' to be dragged, optionally pass `isPermanent` to lock an item
 * `onDragEnd` called with updated array after successful drag
 * `onDestroy` called with updated array after removing an item and also the index that was deleted
 * 
 * Example Usage:

        <DragGroup
            data={[{ id: '1', name: 'what a drag!', isPermanent: true }]}
            onDragEnd={(result) => setState(result)}
            onDestroy={(result, deletedIndex) => setState(result)} />
 */
export const DragGroup: FunctionComponent<DragGroupProps> = ({
    direction = 'horizontal',
    data,
    onDragEnd,
    onDestroy,
    id,
    renderTile,
}) => {
    const [isDragging, setIsDragging] = useState<boolean>(false);
    useEvent(document, 'mouseup', () => {
        setIsDragging(false);
    });

    const move = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const result = Array.from(data);
            const [removed] = result.splice(dragIndex, 1);
            result.splice(hoverIndex, 0, removed);
            onDragEnd(result);
        },
        [data, onDragEnd]
    );

    const handleDestroy = useCallback(
        (index) => {
            const result = Array.from(data);
            result.splice(index, 1);
            if (onDestroy) onDestroy(result, index);
        },
        [data, onDestroy]
    );

    const dataList = useMemo(
        () =>
            data
                .sort((a, b) => {
                    if (b.alwaysFirst) return 1;
                    return a.isPermanent ? -1 : 0;
                })
                .map((d, i) => (
                    <DragTile
                        data={d}
                        index={i}
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${d.id}-${i}`}
                        move={move}
                        isParentDragging={isDragging}
                        onDestroy={handleDestroy}
                        tooltipConfig={d.tooltipConfig}
                        renderTile={renderTile}
                    />
                )),
        [data, move, handleDestroy, isDragging, renderTile]
    );

    return (
        <DragGroupContainer
            direction={direction}
            isDragging={isDragging}
            onMouseUp={() => setIsDragging(false)}
            onDragEnd={() => setIsDragging(false)}
            onMouseDown={() => setIsDragging(true)}
            data-test={`drag-group-${id}`}
        >
            {dataList}
        </DragGroupContainer>
    );
};

export default DragGroup;
