import {
    Colors,
    Spacing,
} from 'app/koddi-components/src/_ChakraComponents/ChakraTheme';
import { CBoldText } from 'app/koddi-components/src/_ChakraComponents/Typography';
import React from 'react';
import { MultiValueProps } from 'react-select';
import { OptionType } from '../../../Types';

export const GroupHeading = ({
    children,
}: MultiValueProps<OptionType>): JSX.Element => {
    return (
        <CBoldText mb={Spacing.XXS} ml={Spacing.XXS} color={Colors.slate}>
            {children}
        </CBoldText>
    );
};
