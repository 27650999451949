import { IconWrapper } from 'koddi-components/Icon';
import styled, { css } from 'styled-components';

export const CheckboxLabelText = styled.span<{
    disabled?: boolean;
    isVisible?: boolean;
    hasEllipsis?: boolean;
}>`
    ${({ theme: { spaceUnit, gray }, disabled, isVisible, hasEllipsis }) => css`
        margin-left: ${spaceUnit * 3}px;
        display: flex;
        line-height: 1rem;
        align-items: center;
        color: ${disabled ? gray : 'inherit'};
        &:after {
            margin-left: ${spaceUnit * 1}px;
            visibility: ${isVisible ? 'visible' : 'hidden'};
            color: red;
            content: ' *'};
        }
        ${IconWrapper} {
            margin-left: ${spaceUnit * 3}px;
        }
        ${
            hasEllipsis &&
            css`
                div {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 190px;
                    overflow: hidden;
                    display: inline-block;
                }
            `
        }
    `}
`;

export const CheckboxContainer = styled.div<{
    fullWidth?: boolean;
    hasError?: boolean;
}>`
    ${({ fullWidth, hasError, theme }) => css`
        height: 30px;
        flex: 1;
        input[type='checkbox'] {
            opacity: 0;
            visibility: hidden;
            display: none;
        }
        svg {
            path {
                fill: ${hasError ? theme.error : 'inherit'};
            }
        }
        label {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            width: ${fullWidth ? '100%' : 'auto'};
            user-select: none;
            height: 100%;
            margin-bottom: 0;
            line-height: 30px;
            font-size: 12px;
            &:hover {
                cursor: pointer;
            }
        }
    `}
`;
