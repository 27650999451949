export enum FontSizes {
    xs = '8px',
    sm = '12px',
    md = '14px',
    lg = '16px',
    xl = '20px',
    '2xl' = '24px',
    '3xl' = '28px',
    '4xl' = '36px',
    '5xl' = '48px',
    '6xl' = '64px',
}

export enum FontWeights {
    bold = 'bold',
    semibold = 'semi-bold',
    normal = 'normal',
}

export enum LineHeight {
    normal = 'normal',
    none = 1,
    shorter = 1.25,
    short = 1.375,
    base = 1.2,
    tall = 1.625,
    taller = '2',
}

export const Typography = {
    fontSizes: FontSizes,
    lineHeights: {
        ...LineHeight,
        '3': '.75rem',
        '4': '1rem',
        '5': '1.25rem',
        '6': '1.5rem',
        '7': '1.75rem',
        '8': '2rem',
        '9': '2.25rem',
        '10': '2.5rem',
    },
    fontWeights: FontWeights,
};
