import styled, { css } from 'styled-components';
import Icon from 'koddi-components/Icon';
import { ButtonBase } from 'koddi-components/Button/button.styled';
import Dropdown, { DropdownMenuList } from 'koddi-components/Dropdown';

export const Wrapper = styled.div`
    font-size: 14px;
`;

export const MonthNavigator = styled.div`
    ${({ theme }) => css`
        display: flex;
        margin-bottom: ${theme.spaceUnit}px;
        padding-left: ${theme.spaceUnit * 2}px;
        padding-right: ${theme.spaceUnit * 2}px;
        align-items: center;
        justify-content: center;
        text-transform: capitalize;
        ${ButtonBase} {
            &:hover,
            &:active,
            &:focus {
                background: transparent;
            }
        }
    `}
`;

export const DropdownArrow = styled.div`
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
`;

export const MonthNavigatorDropdown = styled(Dropdown)<{ v2: boolean }>`
    ${({ v2, theme }) => css`
        flex: 1;
        align-items: center;
        justify-content: center;
        ${ButtonBase} {
            background: transparent;
            font-size: 14px;
            font-weight: ${v2 && '600'};
            color: ${v2 ? theme.primary : theme.white};
            padding: 0px;
            text-transform: capitalize;
            &:focus {
                outline: none;
            }
        }
        ${DropdownMenuList} {
            max-height: 200px;
            overflow: auto;
            margin-top: ${theme.spaceUnit}px;
            left: 50%;
            transform: translate(-50%, 0%);
        }
    `}
`;

export const MonthIcon = styled(Icon)`
    display: flex;
    &:hover {
        cursor: pointer;
    }
    &:focus {
        outline: none;
    }
`;

export const ChevronRightIcon = styled(MonthIcon)`
    transform: rotate(180deg);
`;
