import 'react-datepicker/dist/react-datepicker.css';
import styled, { css } from 'styled-components';
import { BorderWidth, Spacing } from '../../../../ChakraTheme';
/** Creates the base styles for all Koddi Date Pickers */
export const DatePickerStyled = styled.div`
    ${({ theme }) => {
        return css`
            position: relative;
            .react-datepicker {
                border: ${BorderWidth.Thin} solid ${theme.grayLighter};
                box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
            }

            .react-datepicker-wrapper {
                width: 100%;
            }

            .react-datepicker__triangle {
                display: none;
            }

            .react-datepicker-popper {
                margin: ${BorderWidth.Thin};
                top: auto !important;
                left: 0px !important;
                transform: none !important;
                z-index: 1000;
            }
            /** Calendar Week Day Header */
            .react-datepicker__navigation {
                top: 12px;
            }
            .react-datepicker__header {
                background: ${theme.white};
                color: ${theme.primary};
                border-radius: 0px;
                padding: ${Spacing.XS};
                padding-bottom: 0;
            }
            .react-datepicker__month {
                margin: 0px;
                padding: ${Spacing.XS};
            }
            .react-datepicker__day-name,
            .react-datepicker__day {
                width: 32px;
                height: 30px;
                line-height: 30px;
            }
            .react-datepicker__day-name {
                color: ${theme.gray};
                margin: 0px;
                height: 36px;
                line-height: 36px;
                text-transform: 'uppercase';
                font-size: smaller;
                width: 32px;
            }
            /** Calendar Week Row */
            .react-datepicker__day-names,
            .react-datepicker__week {
                display: flex;
            }
            /** Calendar Day Default */
            .react-datepicker__day {
                margin: 4px 0px;
                border-radius: 0px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100%;
            }
            .react-datepicker__day:focus {
                outline: none;
            }
            /** Calendar Day Outside of Current Month */
            .react-datepicker__day--outside-month {
                pointer-events: none;
                color: ${theme.grayLight};
            }
            /** Calendar Day Selected */
            .react-datepicker__day--selected {
                background: ${theme.grayLight};
                color: ${theme.primary};
                position: relative;
                z-index: 2;
                font-weight: bold;
                border-radius: 100%;
                border: none;
                text-decoration: underline;
            }
            .react-datepicker__day--selected::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: -1;
                border-radius: 100%;
                border: 0px;
            }
            /** Calendar Day Keyboard Selected */
            .react-datepicker__day--keyboard-selected {
                background: transparent;
                color: #000000;
            }
            .react-datepicker__day--keyboard-selected:hover {
                background: ${theme.grayLight};
            }
        `;
    }}
`;
