import { useEffect, useState } from 'react';

export const useOnFirstRender = (fn: () => any): void => {
    const [hasRendered, setHasRendered] = useState(false);
    useEffect(() => {
        if (hasRendered) return;
        fn();
        setHasRendered(true);
    }, [fn, hasRendered]);
};
