import { InputProps as ChakraInputProps } from '@chakra-ui/react';
import { ControlProps, FormValue } from '../../Types';

export enum InputTypes {
    Text = 'text',
    Email = 'email',
    Number = 'number',
    Search = 'search',
    Password = 'password',
}

export interface InputBaseProps<T extends FormValue = string>
    extends Omit<
            ControlProps<T>,
            'options' | 'errorText' | 'customCurrencyData'
        >,
        Omit<ChakraInputProps, 'name' | 'onChange'> {
    type: InputTypes;
    isControlledInput?: boolean;
    leftElement?: JSX.Element | string;
    rightElement?: JSX.Element | string;
    elementSize?: string;
    hideError?: boolean;
    errorText?: string | null;
    maxW?: string;
}

export type InputProps<T extends FormValue = string> = Omit<
    InputBaseProps<T>,
    'type'
>;
