import React, { FunctionComponent } from 'react';
import { BaseSVGProps } from '../icon.types';

const StatusApprovedIcon: FunctionComponent<BaseSVGProps> = ({
    width,
    height,
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 17 17"
    >
        <title>Status Approved</title>
        <rect
            id="Rectangle_144844"
            data-name="Rectangle 144844"
            width="17"
            height="17"
            rx="8"
            fill="#4ca25c"
        />
        <path
            id="Icon_awesome-check"
            data-name="Icon awesome-check"
            d="M2.327,9.587.1,7.36a.343.343,0,0,1,0-.485l.485-.485a.343.343,0,0,1,.485,0l1.5,1.5L5.783,4.678a.343.343,0,0,1,.485,0l.485.485a.343.343,0,0,1,0,.485l-3.94,3.94A.343.343,0,0,1,2.327,9.587Z"
            transform="translate(5.074 1.368)"
            fill="#fff"
        />
    </svg>
);

export default StatusApprovedIcon;
