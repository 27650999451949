import React, { FunctionComponent } from 'react';
import { usePermissions } from '../../../react-ui/contexts/PermissionsProvider/PermissionsProvider.hook';
import { CanViewProps } from './CanView.types';
import { checkPermissionsAndRole } from './CanView.utils';

/*
 * The `CanView` Component is used to conditionally render a component
 * based on the current users permissions.
 */
const CanView: FunctionComponent<CanViewProps> = ({
    permission = [],
    children,
    condition,
    noAccessRender,
    role = [],
    any = false,
    anyRole = false,
}) => {
    const { map: userPermissions, roles: userRoles } = usePermissions();

    const canView: boolean = checkPermissionsAndRole(
        role,
        permission,
        userRoles,
        userPermissions,
        condition,
        anyRole,
        any
    );

    const noAccess = noAccessRender ? noAccessRender() : null;
    return <>{canView ? children : noAccess}</>;
};

export default CanView;
