import { Box, Checkbox, CheckboxGroup, Stack } from '@chakra-ui/react';
import React, { ChangeEvent, useState } from 'react';
import { ColorSchemes, Spacing } from '../../../ChakraTheme';
import { CFormError } from '../../../Typography';
import { CCheckboxGroupProps } from './Checkbox.types';

export const CCheckboxGroup = ({
    options,
    onChange,
    inputValue,
    testId,
    errorText,
    hideError = false,
    isStacked = false,
    gap = Spacing.SM,
}: CCheckboxGroupProps): JSX.Element => {
    return (
        <Box width="100%">
            <CheckboxGroup onChange={onChange} value={inputValue}>
                <Stack
                    direction={isStacked ? 'column' : 'row'}
                    gap={gap}
                    flexWrap="wrap"
                >
                    {options.map(({ value, label }) => (
                        <Checkbox
                            data-test={`${testId}--${value}--option`}
                            value={value}
                            key={value}
                            colorScheme={ColorSchemes.primary}
                        >
                            {label}
                        </Checkbox>
                    ))}
                </Stack>
            </CheckboxGroup>
            {!hideError && (
                <CFormError data-test={`${testId}--error`} text={errorText} />
            )}
        </Box>
    );
};

export const CCheckboxGroupWithSelectAll = ({
    options,
    onChange,
    inputValue,
    testId,
    errorText,
    hideError = false,
    isStacked = false,
    gap = Spacing.SM,
}: CCheckboxGroupProps): JSX.Element => {
    const [checkedItems, setCheckedItems] = useState(
        options.map((option) => inputValue?.includes(option.value))
    );

    const allChecked = checkedItems.every(Boolean);
    const isIndeterminate = checkedItems.some(Boolean) && !allChecked;

    const handleSelectAllChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.target;
        const updatedCheckedItems = options.map(() => checked);
        setCheckedItems(updatedCheckedItems);
        onChange(checked ? options.map((opt) => opt.value) : []);
    };

    const handleCheckboxChange = (index: number, checked: boolean) => {
        const updatedCheckedItems = [...checkedItems];
        updatedCheckedItems[index] = checked;

        setCheckedItems(updatedCheckedItems);
        const selectedValues = options
            .filter((_, i) => updatedCheckedItems[i])
            .map((opt) => opt.value);
        onChange(selectedValues);
    };

    return (
        <Box width="100%">
            <CheckboxGroup value={inputValue}>
                <Checkbox
                    isChecked={allChecked}
                    isIndeterminate={isIndeterminate}
                    onChange={handleSelectAllChange}
                    mb={Spacing.XXS}
                >
                    {allChecked ? 'Deselect All' : 'Select All'}
                </Checkbox>
                <Stack
                    direction={isStacked ? 'column' : 'row'}
                    gap={gap}
                    flexWrap="wrap"
                >
                    {options.map(({ value, label }, index) => (
                        <Checkbox
                            key={value}
                            data-test={`${testId}--${value}--option`}
                            value={value}
                            isChecked={checkedItems[index]}
                            onChange={(e) =>
                                handleCheckboxChange(index, e.target.checked)
                            }
                            colorScheme={ColorSchemes.primary}
                        >
                            {label}
                        </Checkbox>
                    ))}
                </Stack>
            </CheckboxGroup>
            {!hideError && (
                <CFormError data-test={`${testId}--error`} text={errorText} />
            )}
        </Box>
    );
};
