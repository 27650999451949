import axios from 'axios';
import { LoginResult } from './Auth.types';
/**
 * The Koddi Auth API.
 */
class KoddiAuthAPI {
    public login = async (
        username: string,
        password: string
    ): Promise<LoginResult> => {
        // login with cognito. Only use this for the first time a user is created incase there is a lag in creating the user in Auth0
        const result = await axios.post(`${window.API_ROUTE}v1/session/login`, {
            email: username,
            password,
        });
        return result.data.result;
    };

    // Login with Auth0
    public postAuth0Token = async (
        member_group_id: number,
        id_token: string,
        access_token: string
    ): Promise<void> => {
        await axios.post(`${window.API_ROUTE}v1/sso/login`, {
            member_group_id,
            id_token,
            access_token,
        });
    };

    public resetPassword = async (email: string): Promise<boolean> => {
        await axios.put(`${window.API_ROUTE}v1/users/reset_password`, {
            email,
        });
        return true;
    };
}

export default KoddiAuthAPI;
