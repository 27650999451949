import { createMachine, assign, StateMachine } from '@xstate/fsm';
import {
    AuthMachineContext,
    AuthActions,
    AuthMachineState,
    AuthStateStatus,
} from './types';

export const authMachineConfig: StateMachine.Config<
    AuthMachineContext,
    AuthActions,
    AuthMachineState
> = {
    id: 'auth',
    initial: 'attemptingLoginOnLoad' as AuthStateStatus,
    context: {
        user: null,
        error: null,
        message: null,
        loading: false,
        email: null,
        username: null,
        session: null,
    },
    states: {
        attemptingLoginOnLoad: {
            on: {
                'auth/LOGIN': {
                    target: 'signIn',
                },
                'auth/LOGIN_SUCCESS': {
                    target: 'signedIn',
                    actions: 'handleLoginSuccess',
                },
                'auth/LOGIN_ERROR': {
                    target: 'signIn',
                    actions: ['setLoadingToFalse', 'setError'],
                },
            },
        },
        signIn: {
            on: {
                'auth/LOGIN_SUBMIT': {
                    target: 'signIn',
                    actions: 'setLoadingToTrue',
                },
                'auth/LOGIN_SUCCESS': {
                    target: 'signedIn',
                    actions: 'handleLoginSuccess',
                },
                'auth/LOGIN_ERROR': {
                    target: 'signIn',
                    actions: ['setLoadingToFalse', 'setError'],
                },
            },
        },
        signedIn: {
            on: {
                'auth/LOGOUT': {
                    target: 'signIn',
                    actions: ['resetState'],
                },
                'auth/LOGOUT_SUBMIT': {
                    target: 'signIn',
                    actions: ['resetState'],
                },
                'auth/UPDATE_USER_SUCCESS': {
                    target: 'signedIn',
                    actions: ['updateUser'],
                },
                'auth/UNAUTHORIZED_REQUEST': {
                    target: 'signIn',
                    actions: ['setError', 'resetStateWithError'],
                },
            },
        },
    },
};

export const authMachineActionMap: {
    actions?: StateMachine.ActionMap<AuthMachineContext, AuthActions>;
} = {
    actions: {
        resetState: assign<AuthMachineContext>({
            user: () => null,
            error: () => null,
            message: () => null,
            loading: () => false as boolean,
            email: () => null,
            username: () => null,
        }),
        resetStateWithError: assign<AuthMachineContext>({
            user: () => null,
            loading: () => false as boolean,
            email: () => null,
            username: () => null,
        }),
        setError: assign({
            error: (_, event: AuthActions) =>
                'error' in event ? event.error : null,
        }),
        removeError: assign<AuthMachineContext>({
            error: null,
        }),
        setLoadingToFalse: assign({
            loading: false as boolean,
        }),
        setLoadingToTrue: assign({
            loading: true as boolean,
        }),
        removeMessage: assign<AuthMachineContext>({
            message: () => null,
        }),
        setEmail: assign<AuthMachineContext, any>({
            email: (_, event) => {
                return event.payload?.email;
            },
        }),
        setUsername: assign<AuthMachineContext, any>({
            username: (_, event) => event.payload?.username ?? null,
        }),
        setSession: assign<AuthMachineContext, any>({
            session: (_, event) => {
                return event.payload?.session;
            },
        }),
        updateUser: assign<AuthMachineContext, any>({
            user: (_, event) => event.payload?.user,
        }),
        handleLoginSuccess: assign<AuthMachineContext, any>({
            loading: false,
            username: (_, event) => event.payload?.user?.user_name ?? null,
            email: (_, event) => event.payload?.user?.email ?? null,
            user: (_, event) => event.payload?.user ?? null,
            error: null,
        }),
        handleAccountSetupSuccess: assign<AuthMachineContext, any>({
            error: null,
            loading: false,
            username: (_, event) => event.payload?.user?.user_name ?? null,
            email: (_, event) => event.payload?.user?.email ?? null,
            user: (_, event) => event.payload?.user ?? null,
        }),
    },
};

const authMachine = createMachine<
    AuthMachineContext,
    AuthActions,
    AuthMachineState
>(authMachineConfig, authMachineActionMap);

export default authMachine;
