import subDays from 'date-fns/subDays';
import subMonths from 'date-fns/subMonths';
import subQuarters from 'date-fns/subQuarters';
import endOfMonth from 'date-fns/endOfMonth';
import startOfWeek from 'date-fns/startOfWeek';
import isEqual from 'date-fns/isEqual';
import lastDayOfMonth from 'date-fns/lastDayOfMonth';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import lastDayOfWeek from 'date-fns/lastDayOfWeek';
import startOfYear from 'date-fns/startOfYear';
import startOfQuarter from 'date-fns/startOfQuarter';
import startOfMonth from 'date-fns/startOfMonth';
import endOfQuarter from 'date-fns/endOfQuarter';
import {
    KoddiLocaleKey,
    KODDI_LOCALE_TO_DATEFNS,
} from 'koddi-components/LocaleProvider';
import {
    DatePickerDateRange,
    DatePickerPresetDateRange,
} from './DateRangePicker.types';
import { DATE_RANGE_PICKER_TRANSLATIONS } from './DateRangePicker.translations';

/** Adds additional classnames to calendar days for styling purposes. */
export function addAdditionalClassNames(
    date: Date,
    startDate: Date | null,
    endDate: Date | null,
    hoveredDate: Date | null,
    localeKey: KoddiLocaleKey
): string {
    const locale = KODDI_LOCALE_TO_DATEFNS[localeKey];
    const isStartOfMonth = isEqual(date, startOfMonth(date));
    const isEndOfMonth = isEqual(date, lastDayOfMonth(date));
    const isStartOfWeek = isEqual(date, startOfWeek(date, { locale }));
    const isEndOfWeek = isEqual(date, lastDayOfWeek(date, { locale }));
    const isPotentiallyInRange =
        startDate && hoveredDate && !endDate
            ? isAfter(date, startDate) && isBefore(date, hoveredDate)
            : false;
    const startOfMonthClass = isStartOfMonth
        ? 'react-datepicker__day--start_of_month'
        : '';
    const endOfMonthClass = isEndOfMonth
        ? 'react-datepicker__day--end_of_month'
        : '';
    const startOfWeekClass = isStartOfWeek
        ? 'react-datepicker__day--start_of_week'
        : '';
    const endOfWeekClass = isEndOfWeek
        ? 'react-datepicker__day--end_of_week'
        : '';
    const potentiallyInRangeClass = isPotentiallyInRange
        ? 'react-datepicker__day--potenially_in_range'
        : '';
    return `${startOfMonthClass} ${endOfMonthClass} ${startOfWeekClass} ${endOfWeekClass} ${potentiallyInRangeClass}`.trim();
}

export function createPresetDateRanges({
    allTimeDate,
    locale,
    customDateRange,
}: {
    allTimeDate: string;
    locale: KoddiLocaleKey;
    customDateRange?: {
        startDate?: Date | null;
        endDate?: Date | null;
    } | null;
}): Record<DatePickerPresetDateRange, DatePickerDateRange> {
    return {
        AllTime: {
            order: 0,
            label: DATE_RANGE_PICKER_TRANSLATIONS.AllTime[locale],
            startDate: new Date(allTimeDate),
            endDate: new Date(),
        },
        YearToDate: {
            order: 1,
            label: DATE_RANGE_PICKER_TRANSLATIONS.YearToDate[locale],
            startDate: startOfYear(new Date()),
            endDate: new Date(),
        },
        QuarterToDate: {
            order: 2,
            label: DATE_RANGE_PICKER_TRANSLATIONS.QuarterToDate[locale],
            startDate: startOfQuarter(new Date()),
            endDate: new Date(),
        },
        MonthToDate: {
            order: 3,
            label: DATE_RANGE_PICKER_TRANSLATIONS.MonthToDate[locale],
            startDate: startOfMonth(new Date()),
            endDate: new Date(),
        },
        LastQuarter: {
            order: 4,
            label: DATE_RANGE_PICKER_TRANSLATIONS.LastQuarter[locale],
            startDate: startOfQuarter(subQuarters(new Date(), 1)),
            endDate: endOfQuarter(subQuarters(new Date(), 1)),
        },
        LastMonth: {
            order: 5,
            label: DATE_RANGE_PICKER_TRANSLATIONS.LastMonth[locale],
            startDate: startOfMonth(subMonths(new Date(), 1)),
            endDate: endOfMonth(subMonths(new Date(), 1)),
        },
        Last60Days: {
            order: 6,
            label: DATE_RANGE_PICKER_TRANSLATIONS.Last60Days[locale],
            startDate: subDays(new Date(), 60),
            endDate: subDays(new Date(), 1),
        },
        Last30Days: {
            order: 7,
            label: DATE_RANGE_PICKER_TRANSLATIONS.Last30Days[locale],
            startDate: subDays(new Date(), 30),
            endDate: subDays(new Date(), 1),
        },
        Last14Days: {
            order: 8,
            label: DATE_RANGE_PICKER_TRANSLATIONS.Last14Days[locale],
            startDate: subDays(new Date(), 14),
            endDate: subDays(new Date(), 1),
        },
        Last7Days: {
            order: 9,
            label: DATE_RANGE_PICKER_TRANSLATIONS.Last7Days[locale],
            startDate: subDays(new Date(), 7),
            endDate: subDays(new Date(), 1),
        },
        Yesterday: {
            order: 10,
            label: DATE_RANGE_PICKER_TRANSLATIONS.Yesterday[locale],
            startDate: subDays(new Date(), 1),
            endDate: subDays(new Date(), 1),
        },
        Today: {
            order: 11,
            label: DATE_RANGE_PICKER_TRANSLATIONS.Today[locale],
            startDate: new Date(),
            endDate: new Date(),
        },
        Custom: {
            order: 12,
            label: DATE_RANGE_PICKER_TRANSLATIONS.Custom[locale],
            startDate: customDateRange?.startDate || new Date(),
            endDate: customDateRange?.endDate || new Date(),
        },
    };
}
