import React, { FunctionComponent } from 'react';
import { FieldInputProps, Field } from 'react-final-form';
import CheckboxIcon from 'koddi-components/Icon/icons/checkbox';
import Tooltip, { IconTooltipWrapper } from 'koddi-components/Tooltip';
import { Icon } from 'koddi-components/Icon/icon';
import { ErrorText } from 'koddi-components/Form';
import { CheckboxContainer, CheckboxLabelText } from './checkbox.styled';

import {
    CheckBoxProps,
    CheckBoxFieldProps,
    CheckBoxFieldComponentProps,
} from './checkbox.types';

/**
 * The Koddi `Checkbox` component.
 */
export const Checkbox: FunctionComponent<CheckBoxProps | any> = ({
    id,
    autoFill,
    required = false,
    disabled = false,
    isMinus = false,
    label,
    renderLabel,
    pattern,
    meta,
    onChange: onChangeProp,
    onFocus: onFocusProp,
    checked: checkProp,
    name: nameProp,
    fullWidth,
    'data-test': dataTest,
    input: {
        name,
        onBlur,
        onFocus,
        onChange,
        checked = checkProp,
    } = {} as FieldInputProps<string, HTMLInputElement>,
    tooltip,
    renderInput = true,
    animated = false,
    noTranslateLabel,
    isRequired,
    hasEllipsis,
    ...restProps
}: CheckBoxProps) => {
    const hasError = meta && meta.error && meta.touched;

    const inputProps = {
        ...restProps,
        required,
        disabled,
        name: nameProp || name,
        onBlur,
        onFocus: onFocusProp || onFocus,
        onChange: onChangeProp || onChange,
        type: 'checkbox',
        pattern,
        checked: checkProp || checked,
        autoComplete: autoFill,
        id: id || name || nameProp,
    };

    const tooltipProps = {
        ...tooltip,
        icon: 'info',
        iconHeight: 12,
        iconWidth: 12,
        tooltipProps: {
            variant: 'fancy',
            placement: 'top',
        },
    };

    const labelText = renderLabel ? renderLabel() : label;

    return (
        <CheckboxContainer
            role="button"
            hasError={hasError}
            data-test={dataTest}
            fullWidth={fullWidth}
            as={tooltip ? IconTooltipWrapper : 'div'}
            {...tooltipProps}
        >
            <label htmlFor={inputProps.id}>
                {renderInput && (
                    <input
                        {...inputProps}
                        autoComplete={autoFill ? 'on' : 'off'}
                        type="checkbox"
                        data-test={inputProps.id}
                    />
                )}
                {isMinus && checked ? (
                    <Icon
                        width={12}
                        height={12}
                        icon="minusbox"
                        color="black"
                    />
                ) : (
                    <CheckboxIcon
                        width={12}
                        height={12}
                        animated={animated}
                        checked={!!inputProps.checked}
                        disabled={inputProps.disabled}
                    />
                )}
                {renderLabel || label ? (
                    <Tooltip
                        key={inputProps.id}
                        defaultText={labelText || ''}
                        hideToolTip={!hasEllipsis}
                    >
                        <CheckboxLabelText
                            hasEllipsis={hasEllipsis}
                            isVisible={!inputProps.disabled && isRequired}
                            disabled={inputProps.disabled}
                        >
                            {noTranslateLabel ? (
                                <div translate="no">{labelText}</div>
                            ) : (
                                labelText
                            )}
                        </CheckboxLabelText>
                    </Tooltip>
                ) : null}
                <ErrorText hasError={hasError}>{meta?.error}</ErrorText>
            </label>
        </CheckboxContainer>
    );
};

/**
 * The `CheckboxField` is a strongly typed `Input`
 * that uses the `Checkbox` from `react-final-form`.
 *
 * It prevents us from having to re-type these type definitions
 * whenever we need to use a `Checkbox` with a `Input` component.
 */
export const CheckboxField = (props: CheckBoxFieldProps): JSX.Element => (
    <Field<string, CheckBoxFieldComponentProps, HTMLInputElement>
        {...props}
        type="checkbox"
        component={Checkbox as FunctionComponent<CheckBoxFieldComponentProps>}
    />
);

export default Checkbox;
