/* eslint-disable import/no-extraneous-dependencies */
import Axios, { AxiosInstance, AxiosResponse } from 'axios';
import { InjectedStore } from 'redux-core/types';
import {
    unauthorizedRequest,
    logout,
    refreshSession,
    logoutWithSSO,
} from 'redux-core/auth/actions';
import { setAppMessage } from 'redux-core/app/messages';
import { selectAppTheme } from 'redux-core/app/theme/selectors';

import { IS_TEST_ENV } from 'utils/constants';

import KoddiThemeAPI from './Theme';
import KoddiAuthAPI from './Auth';

import { API_CACHE_LOCAL_STORAGE_KEY } from './cachedAPI';
import { KoddiAPIRecord, KoddiAdminAPIS } from './api.types';
import {
    UNAUTHORIZED_REQUEST_MESSAGE,
    REDUX_STORE_NOT_SET_MESSAGE,
    ATTEMPTING_TO_CHANGE_REDUX_STORE_MESSAGE,
} from './api.messages';
import { createKoddiApis } from './api.utils';
import KoddiAdvertiserAPI from './Advertiser';
import KoddiCampaignsAPI from './Campaigns';
import KoddiAdGroupsAPI from './AdGroups';
import SessionAPI from './Session';
import ReportAPI from './Reports';
import KoddiEntitiesAPI from './Entities';
import KoddiBillingAPI from './Billing';
import KoddiRecommendationsAPI from './KoddiRecommendations';
import { EMBEDDED_ERROR_PATH } from '../modules/constants/routes';

const DEFAULT_ERROR_MESSAGE = 'There was an processing your request.';
const NO_PERMISSON = 'You do not have permission to view this information.';

export const msInOneMinute = 1000 * 60;
export const FIVE_MINUTES = msInOneMinute * 5;
export const TWENTY_MINUTES = msInOneMinute * 20;

export const getReturnRoute = (): string => {
    const hasReturnRoute = window.location.hash.includes('returnRoute');
    const returnRoute = hasReturnRoute
        ? window.location.hash.split('?returnRoute=')?.[1]?.replace('#', '')
        : window.location.hash.replace('#', '');
    return returnRoute;
};

export const LAST_ACTIVITY_TIME_KEY = 'last-activity';

export function createAPIError(
    defaultMessage: string,
    errorResponse?: Record<string, any>
): any {
    return {
        error: new Error(defaultMessage),
        code: errorResponse?.data?.code,
        errorResponse,
    };
}

/**
 * The `KoddiAPI` is a centralized api HUB for Koddi One.
 *
 * The `KoddiAPI` contains all of the available api methods and actions. It gracefully
 * handles errors and authorization issues and manages authentication tokens as well.
 */
export class KoddiAPIClass {
    protected store: InjectedStore | undefined;

    protected axios: AxiosInstance | null = null;

    private apis: KoddiAPIRecord | null = IS_TEST_ENV
        ? this.createKoddiApis(Axios)
        : null;

    private AuthAPI = new KoddiAuthAPI();

    private refreshRetries = 3;

    private token: string | null = null;

    private tokenExpiration: number | null = null;

    private tokenExpirationTime: number | null = null;

    private refreshTimer: number | null = null;

    private signOutTimer: number | null = null;

    public createPublicAPI = (): void => {
        this.axios = Axios.create({
            baseURL: window.API_ROUTE,
            headers: {
                'Content-Type': 'application/json',
                'application-name': 'koddi-one-ui',
            },
        });
        this.createKoddiApis(this.axios);
    };

    public getStore = (): InjectedStore | undefined => {
        return this.store;
    };

    public setLastActivityTime = (time: number | null): void => {
        try {
            if (time) {
                localStorage.setItem(LAST_ACTIVITY_TIME_KEY, time?.toString());
            } else {
                localStorage.removeItem(LAST_ACTIVITY_TIME_KEY);
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.warn('could not set last activity time', e);
        }
    };

    public getLastActivityTime = (): number | null => {
        try {
            const localStorageActivity = localStorage.getItem(
                LAST_ACTIVITY_TIME_KEY
            );
            return Number(localStorageActivity) || null;
        } catch (e) {
            // eslint-disable-next-line no-console
            console.warn('could not get last activity time', e);
            return null;
        }
    };

    public getAxiosInstance = (): AxiosInstance | null => {
        return this.axios;
    };

    /**
     * When a user has been signed out we disable access
     * to the authenticated apis.
     */
    public signOut = (): void => {
        this.apis = null;
        this.createPublicAPI();
        this.clearRefreshTokenTimer();
        this.clearSignOutTimer();
        localStorage.removeItem(API_CACHE_LOCAL_STORAGE_KEY);

        // We need apis to be available during testing
        if (IS_TEST_ENV) this.createKoddiApis(Axios);
    };

    /**
     * When a user has been validated we create the axios instance to
     * enable the authenticated apis.
     */
    public signIn = (
        session: string,
        expiresIn: number,
        expirationTime: number
    ): void => {
        const axios = this.createAxiosInstance(
            session,
            expiresIn,
            expirationTime
        );
        this.createKoddiApis(axios);
        this.startRefreshTokenTimer();
    };

    /**
     * Creates the global axios instance that will be used by all Koddi
     * API's that contains the appropriate authorization headers for every
     * request.
     */
    private createAxiosInstance(
        token: string,
        expiresIn: number,
        expirationTime: number
    ): AxiosInstance {
        this.axios = Axios.create({
            baseURL: window.API_ROUTE,
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
                'application-name': 'koddi-one-ui',
            },
        });

        this.tokenExpirationTime = expirationTime;
        this.tokenExpiration = expiresIn;
        this.token = token;

        this.axios.interceptors.response.use(
            this.handleSuccessfulResponse,
            this.handleErroredResponse
        );

        return this.axios;
    }

    public isUserIdle = (): boolean => {
        const idleThreshhold = +new Date() - TWENTY_MINUTES;
        const lastActivity = this.getLastActivityTime();
        return !!(lastActivity && lastActivity < idleThreshhold);
    };

    /**
     * Starts a timer to sign the user out after 45 minutes
     * of no api inactivity.
     */
    public startSignOutTimer = (): void => {
        const msIn45Minutes = msInOneMinute * 45;

        this.clearSignOutTimer();

        this.signOutTimer = setTimeout(() => {
            this.dispatchUnauthorizedRequest(
                this.getLocalizedSessionExpiredMessage()
            );
        }, msIn45Minutes);
    };

    private clearSignOutTimer = () => {
        if (this.signOutTimer !== null) {
            clearTimeout(this.signOutTimer);
            this.signOutTimer = null;
        }
    };

    /**
     * Starts a timer to refresh the users token 5 minutes
     * before it expires.
     */
    private startRefreshTokenTimer = () => {
        if (this.token && this.tokenExpirationTime) {
            const expiresInMilliseconds =
                this.tokenExpirationTime - +new Date();
            const msUntilRefreshToken = expiresInMilliseconds - FIVE_MINUTES;

            if (this.refreshTimer) clearTimeout(this.refreshTimer);

            this.refreshTimer = setTimeout(() => {
                if (!this.isUserIdle()) {
                    this.dispatchRefreshSession();
                }
            }, msUntilRefreshToken);
        }
    };

    /**
     * Cancels the pending refresh token timer.
     */
    private clearRefreshTokenTimer = () => {
        if (this.refreshTimer !== null) {
            clearTimeout(this.refreshTimer);
        }
    };

    private handleSuccessfulResponse = (response: AxiosResponse) => {
        this.setLastActivityTime(+new Date());
        this.startSignOutTimer();
        return response;
    };

    /**
     * The Koddi API Global Axios instance response interceptor for errored responses.
     * Handles unauthorized api requests as well as localizing error responses.
     */
    private handleErroredResponse = (error: any) => {
        this.startSignOutTimer();
        // client received an error response (5xx, 4xx)
        if (error.response) {
            if (error.response.status === 401) {
                return this.dispatchLogout();
            }
            if (error.response.status === 403) {
                return Promise.reject(
                    // Eventually we will recieve a translation key to localize error messages.
                    createAPIError(NO_PERMISSON, error.response)
                );
            }
            if (error?.response?.data?.error) {
                return Promise.reject(
                    // Eventually we will recieve a translation key to localize error messages.
                    createAPIError(error.response.data.error, error.response)
                );
            }
            return Promise.reject(
                createAPIError(DEFAULT_ERROR_MESSAGE, error.response)
            );
        }

        // ad block detected
        if (
            error.message === 'Network Error' &&
            error.config?.url.includes('advertiser')
        ) {
            if (this.store) {
                this.store.dispatch(
                    setAppMessage(
                        'error',
                        'Ad Blocker Detected',
                        'Please disable your ad blocker to view all details on this page',
                        error.config.url
                    )
                );
            }
        }

        // client never received a response, or request never left
        if (error.request) {
            return Promise.reject(
                createAPIError(DEFAULT_ERROR_MESSAGE, error.response)
            );
        }
        // anything else
        return Promise.reject(error);
    };

    private getLocalizedSessionExpiredMessage = () => {
        const { error } = createAPIError(UNAUTHORIZED_REQUEST_MESSAGE);
        return error.message;
    };

    private createKoddiApis(axios: AxiosInstance) {
        const apis = createKoddiApis(axios);
        this.apis = apis;
        return apis;
    }

    private dispatchUnauthorizedRequest(message?: string) {
        if (this.store) {
            const state = this.store.getState();
            const theme = selectAppTheme(state);
            const loginKey = theme.login?.key;
            this.store.dispatch(
                unauthorizedRequest({
                    message,
                    loginKey,
                    returnRoute: getReturnRoute(),
                })
            );
        } else this.warnStoreNotSet();
    }

    private dispatchLogout() {
        // if we hit a login token error from embedded, redirect to error page
        const { href } = window.location;
        if (href.includes('embedded')) {
            window.location.href = `#${EMBEDDED_ERROR_PATH}`;
            return; // dont dispatch logout instead redirect to error page
        }

        if (this.store) {
            const state = this.store.getState();
            const theme = selectAppTheme(state);

            this.store.dispatch(logout(theme.login?.key, getReturnRoute()));
            this.store.dispatch(
                logoutWithSSO(theme.login?.key, getReturnRoute())
            );
        } else this.warnStoreNotSet();
    }

    private dispatchRefreshSession() {
        if (this.store) this.store.dispatch(refreshSession());
        else this.warnStoreNotSet();
    }

    private warnStoreNotSet = () => {
        if (!IS_TEST_ENV) throw new Error(REDUX_STORE_NOT_SET_MESSAGE);
    };

    /** Below are all of the publically available apis. */

    /**
     * Sets the redux store on the Koddi API.
     */
    public setStore(store: InjectedStore): InjectedStore | void {
        if (this.store === undefined || IS_TEST_ENV) this.store = store;
        else {
            throw new Error(ATTEMPTING_TO_CHANGE_REDUX_STORE_MESSAGE);
        }
    }

    private getAPI(feature: keyof KoddiAPIRecord) {
        if (this.apis && this.apis[feature]) return this.apis[feature];
        this.dispatchUnauthorizedRequest(UNAUTHORIZED_REQUEST_MESSAGE);
        throw new Error(UNAUTHORIZED_REQUEST_MESSAGE);
    }

    /**
     * The Koddi Auth API
     */
    get Auth(): KoddiAuthAPI {
        return this.AuthAPI;
    }

    /**
     * API's related to ad groups accross multiple advertisers.
     */
    get AdGroups(): KoddiAdGroupsAPI {
        return this.getAPI('AdGroups') as KoddiAdGroupsAPI;
    }

    /**
     * API's related to admin functionality.
     */
    get Admin(): KoddiAdminAPIS {
        return this.getAPI('Admin') as KoddiAdminAPIS;
    }

    /**
     * API's related to a specific advertiser.
     */
    get Advertiser(): KoddiAdvertiserAPI {
        return this.getAPI('Advertiser') as KoddiAdvertiserAPI;
    }

    /**
     * API's related to entities.
     */
    get Entities(): KoddiEntitiesAPI {
        return this.getAPI('Entities') as KoddiEntitiesAPI;
    }

    /**
     * API's related to campaigns accross multiple advertisers.
     */
    get Campaigns(): KoddiCampaignsAPI {
        return this.getAPI('Campaigns') as KoddiCampaignsAPI;
    }

    /**
     * API's related to a users session.
     */
    get Session(): SessionAPI {
        return this.getAPI('Session') as SessionAPI;
    }

    /**
     * API's related to themes for a specific member group.
     */
    get Theme(): KoddiThemeAPI {
        return this.getAPI('Theme') as KoddiThemeAPI;
    }

    /**
     * API's related to report generation
     */
    get Report(): ReportAPI {
        return this.getAPI('Reports') as ReportAPI;
    }

    /**
     * API's related to a billing/stripe.
     */
    get Billing(): KoddiBillingAPI {
        return this.getAPI('Billing') as KoddiBillingAPI;
    }

    /**
     * API's related to Koddi Bid/Budget Recommendations.
     */
    get KoddiRecommendations(): KoddiRecommendationsAPI {
        return this.getAPI('KoddiRecommendations') as KoddiRecommendationsAPI;
    }
}
