/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosInstance } from 'axios';
import { KoddiAPIResponse } from 'types';
import { KoddiListFilterParams } from 'koddi/types';
import {
    TargetingReportType,
    TextMatchType,
    TargetingDimensionsType,
    OptionType,
    TargetingOperators,
    TargetingType,
    UpdateTargetingTypesV2Payload,
    GetTargetingV2Payload,
    UpdateTargetingOptionsV2Payload,
    CreateTargetingTypesV2Payload,
    CreateTargetingsV2,
    DeleteTargetingOptionsV2Payload,
    DeleteTargetingDimensionsV2Payload,
    ExperienceTargetingDimensionsType,
    TargetingPayloadItem,
} from './TargetingConfiguration.types';

class TargetingConfigurationAPI {
    protected axios: AxiosInstance;

    constructor(axios: AxiosInstance) {
        this.axios = axios;
    }

    /* Get match types for targeting */
    public getTargetingMatchTypes = async (): Promise<TextMatchType[]> => {
        const response = await this.axios.get(`v1/targetings/match_types`);
        return response.data.result;
    };

    /* Get Targeting by client */
    public getTargetings = async (
        memberGroupId: number
    ): Promise<TargetingDimensionsType[]> => {
        const response = await this.axios.get(
            `v1/member_groups/${memberGroupId}/targetings`
        );
        return response.data.result?.targeting_options ?? [];
    };

    public getTargetingsV2 = async (
        memberGroupId: number,
        experienceId: number
    ): Promise<ExperienceTargetingDimensionsType[]> => {
        const response = await this.axios.get(
            `v2/member_groups/${memberGroupId}/experiences/${experienceId}/targetings`
        );
        return response.data.result?.targeting_dimensions ?? [];
    };

    /* Get Targeting by targeting id */
    public getTargetingById = async (
        memberGroupId: number,
        targetingId: number
    ): Promise<TargetingDimensionsType> => {
        const response = await this.axios.get(
            `v1/member_groups/${memberGroupId}/targetings/${targetingId}`
        );
        return response.data.result;
    };

    /**
     * Get Targeting by targeting id with experiences V2
     */
    public getTargetingByIdV2 = async ({
        memberGroupId,
        experienceId,
        targetingId,
    }: {
        memberGroupId: number;
        experienceId: number;
        targetingId: number;
    }): Promise<ExperienceTargetingDimensionsType> => {
        const response = await this.axios.get(
            `v2/member_groups/${memberGroupId}/experiences/${experienceId}/targetings/${targetingId}`
        );
        return response.data.result;
    };

    /* Get Targeting Operators */
    public getTargetingOperators = async (): Promise<TargetingOperators[]> => {
        const response = await this.axios.get('v1/targetings/operators');
        return response.data.result.match_types;
    };

    /* Get Targeting options */
    public getTargetingOptions = async (
        memberGroupId: number,
        targetingId: number
    ): Promise<OptionType[]> => {
        const response = await this.axios.get(
            `v1/member_groups/${memberGroupId}/targetings/${targetingId}/options`
        );
        return response.data.result;
    };

    /* Get Targeting options V2 */
    public getTargetingOptionsV2 = async ({
        memberGroupId,
        experienceId,
        targetingId,
    }: GetTargetingV2Payload): Promise<OptionType[]> => {
        const response = await this.axios.get(
            `v2/member_groups/${memberGroupId}/experiences/${experienceId}/targetings/${targetingId}/options`
        );
        return response.data.result;
    };

    /* Get Targeting types */
    public getTargetingTypes = async (): Promise<TargetingType[]> => {
        const response = await this.axios.get(`v1/targetings/targeting_types`);
        return response.data.result.targeting_types;
    };

    /**
     * @deprecated use createTargetingsV2 instead
     *  Create Targeting POST
     */
    public createTargetings = async (
        memberGroupId: number,
        targetingPayload: any
    ) => {
        const response = await this.axios.post(
            `v1/member_groups/${memberGroupId}/targetings`,
            targetingPayload
        );
        return response.data.result;
    };

    /**
     *  Create Targeting POST with experiences
     */
    public createTargetingsV2 = async ({
        memberGroupId,
        experienceId,
        targetingPayload,
    }: CreateTargetingsV2) => {
        const response = await this.axios.post(
            `v2/member_groups/${memberGroupId}/experiences/${experienceId}/targetings`,
            targetingPayload
        );
        return response.data.result;
    };

    /**
     *  @deprecated use createTargetingOptionsV2 instead
     *  Create Targeting Text Match Options
     */
    public createTargetingOptions = async (
        memberGroupId: number,
        targetingId: number,
        targetingOptionsPayload: OptionType[]
    ) => {
        const response = await this.axios.post(
            `v1/member_groups/${memberGroupId}/targetings/${targetingId}/options`,
            { targeting_options: targetingOptionsPayload }
        );
        return response.data.result;
    };

    /**
     *  Create Targeting Text Match Options
     */
    public createTargetingOptionsV2 = async ({
        memberGroupId,
        targetingId,
        experienceId,
        targetingOptionsPayload,
    }: CreateTargetingTypesV2Payload) => {
        const response = await this.axios.post(
            `v2/member_groups/${memberGroupId}/experiences/${experienceId}/targetings/${targetingId}/options`,
            { targeting_options: targetingOptionsPayload }
        );
        return response.data.result;
    };

    /* Update Targeting Text Match Options */
    public updateTargetingOptions = async (
        memberGroupId: number,
        targetingId: number,
        targetingOptionsPayload: any
    ) => {
        const response = await this.axios.patch(
            `v1/member_groups/${memberGroupId}/targetings/${targetingId}/options`,
            { targeting_options: targetingOptionsPayload }
        );
        return response.data.result;
    };

    /* Update Targeting Text Match Options V2 */
    public updateTargetingOptionsV2 = async ({
        memberGroupId,
        experienceId,
        targetingId,
        updateTargetingPayload,
    }: UpdateTargetingOptionsV2Payload) => {
        const response = await this.axios.patch(
            `v2/member_groups/${memberGroupId}/experiences/${experienceId}/targetings/${targetingId}/options`,
            { targeting_options: updateTargetingPayload }
        );
        return response.data.result;
    };

    public updateTargetingTypes = async (
        memberGroupId: number,
        targetingId: number,
        targetingTypesPayload: TargetingType[],
        hidden: boolean
    ) => {
        const response = await this.axios.patch(
            `v1/member_groups/${memberGroupId}/targetings/${targetingId}`,
            { targeting_types: targetingTypesPayload, hidden }
        );
        return response.data.result;
    };

    public updateTargetingTypesV2 = async ({
        memberGroupId,
        experienceId,
        targetingId,
        targetingTypesPayload,
        hidden,
    }: UpdateTargetingTypesV2Payload) => {
        const response = await this.axios.patch(
            `v2/member_groups/${memberGroupId}/experiences/${experienceId}/targetings/${targetingId}`,
            { targeting_types: targetingTypesPayload, hidden }
        );
        return response.data.result;
    };

    /** Gets the paginated list of the client's targeting dimensions */
    public getTargetingReport = async (
        memberGroupId: number,
        params?: KoddiListFilterParams
    ): Promise<TargetingReportType[]> => {
        const [id, direction] = params?.sort?.split(' ') ?? [];
        const response = await this.axios.post<KoddiAPIResponse<any>>(
            `v1/member_groups/${memberGroupId}/targetings/report`,
            {
                pagination: {
                    start: params?.start || 0,
                    count: params?.count || 20,
                },
                filters: params?.search
                    ? [
                          {
                              field: 'name',
                              operation: 'ILIKE',
                              value: params?.search,
                          },
                      ]
                    : [],
                ...(id
                    ? {
                          sort: [
                              {
                                  field: id,
                                  ...(direction ? { order: direction } : {}),
                              },
                          ],
                      }
                    : {}),
            }
        );
        return response.data.result.targeting_dimensions || [];
    };

    /**
     * @deprecated use deleteTargetingDimensionV2
     * Deletes the given targeting dimension. without experiences
     */
    public deleteTargetingDimension = async (
        memberGroupId: number,
        targetingId: number
    ): Promise<string> => {
        const response = await this.axios.delete<KoddiAPIResponse<any>>(
            `v1/member_groups/${memberGroupId}/targetings/${targetingId}`
        );
        return response.data.result;
    };

    /** Deletes the given targeting dimension. with experiences */
    public deleteTargetingDimensionV2 = async ({
        memberGroupId,
        experienceId,
        targetingId,
    }: DeleteTargetingDimensionsV2Payload): Promise<string> => {
        const response = await this.axios.delete<KoddiAPIResponse<any>>(
            `v2/member_groups/${memberGroupId}/experiences/${experienceId}/targetings/${targetingId}`
        );
        return response.data.result;
    };

    /**
     * @deprecated use deleteTargetingOptionsV2 instead
     *  Deletes targeting text match options with out Experiences */
    public deleteTargetingOptions = async (
        memberGroupId: number,
        targetingId: number,
        deletedOptionIds: number[]
    ): Promise<any> => {
        const response = await this.axios.delete<KoddiAPIResponse<any>>(
            `v1/member_groups/${memberGroupId}/targetings/${targetingId}/options`,
            { data: { targeting_option_ids: deletedOptionIds } }
        );
        return response.data.result;
    };

    /** Deletes targeting text match options with experiences */
    public deleteTargetingOptionsV2 = async ({
        memberGroupId,
        experienceId,
        targetingId,
        deletedOptionIds,
    }: DeleteTargetingOptionsV2Payload): Promise<any> => {
        const response = await this.axios.delete<KoddiAPIResponse<any>>(
            `v2/member_groups/${memberGroupId}/experiences/${experienceId}/targetings/${targetingId}/options`,
            { data: { targeting_option_ids: deletedOptionIds } }
        );
        return response.data.result;
    };

    /** Gets client's targeting limits */
    public getTargetingLimits = async (memberGroupId: number): Promise<any> => {
        const response = await this.axios.get(
            `v1/member_groups/${memberGroupId}/targetings/limits`
        );
        return response.data.result;
    };

    public getTargetingReach = async (
        targetingPayload: TargetingPayloadItem[]
    ): Promise<number> => {
        const response = await this.axios.post(`v1/targetings/reach`, {
            targeting: targetingPayload,
        });
        return response.data.result.reach;
    };
}

export default TargetingConfigurationAPI;
