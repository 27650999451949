import React, { FunctionComponent } from 'react';
import { useKoddiTheme } from 'koddi-components/ThemeProvider';
import {
    TooltipProps,
    IconTooltipWrapperProps,
    IconTooltipProps,
    WarningTooltipWrapperProps,
} from './Tooltip.types';
import {
    TooltipContainer,
    TooltipChildren,
    TippyTooltip,
    TooltipIcon,
    IconTooltipWrapperBase,
} from './Tooltip.styled';

/**
 * The Koddi `Tooltip` component is an accessible and localized
 * tooltip for display helpful information in the Koddi platform.
 */
const Tooltip = ({
    defaultText,
    variant = 'default',
    placement,
    children,
    delay = 0,
    content,
    hideToolTip,
    customMaxWidth,
    ...rest
}: TooltipProps): JSX.Element => {
    const { tooltip, zIndex } = useKoddiTheme();

    if (hideToolTip || (!defaultText && !content)) {
        return <div>{children}</div>;
    }

    return (
        <TippyTooltip
            placement={placement || 'right'}
            content={content || defaultText}
            className={`koddi-tooltip koddi-tooltip-${variant}`}
            arrow={variant === 'fancy'}
            maxWidth={customMaxWidth ?? tooltip.maxWidth}
            zIndex={zIndex.popup}
            animation="fade"
            delay={delay}
            {...rest}
        >
            <TooltipChildren>{children}</TooltipChildren>
        </TippyTooltip>
    );
};

/**
 * A koddi `Icon` with a tooltip.
 */
export const IconTooltip = ({
    icon,
    width,
    height,
    onClick,
    isPrimaryColor,
    margin,
    isDisabled,
    color,

    ...rest
}: IconTooltipProps): JSX.Element => {
    return (
        <Tooltip {...rest}>
            <TooltipContainer onClick={onClick} tabIndex={0} margin={margin}>
                <TooltipIcon
                    color={color}
                    isDisabled={isDisabled}
                    icon={icon}
                    width={width}
                    height={height}
                    isPrimaryColor={isPrimaryColor}
                />
            </TooltipContainer>
        </Tooltip>
    );
};

/**
 * A wrapper for creating an element that has an `IconTooltip`
 * to the right that is center aligned.
 */
export const IconTooltipWrapper: FunctionComponent<IconTooltipWrapperProps> = ({
    children,
    tooltipProps,
    icon,
    defaultText,
    iconHeight,
    iconWidth,
    isPrimaryColor,
    margin,
    ...rest
}) => {
    return (
        <IconTooltipWrapperBase alignItems="center" {...rest}>
            {children}
            <IconTooltip
                {...tooltipProps}
                defaultText={defaultText}
                icon={icon}
                width={iconWidth}
                height={iconHeight}
                isPrimaryColor={isPrimaryColor}
                margin={margin}
            />
        </IconTooltipWrapperBase>
    );
};

export const WarningTooltipWrapper: FunctionComponent<WarningTooltipWrapperProps> = ({
    children,
    tooltipProps,
    defaultText,
    iconHeight,
    iconWidth,
    margin,
    ...rest
}) => {
    const { warning } = useKoddiTheme();
    return (
        <IconTooltipWrapperBase alignItems="center" {...rest}>
            {children}
            <IconTooltip
                {...tooltipProps}
                defaultText={defaultText}
                icon="warningSign"
                width={iconWidth}
                height={iconHeight}
                isPrimaryColor={false}
                margin={margin}
                variant="warning"
                color={warning}
            />
        </IconTooltipWrapperBase>
    );
};

export default Tooltip;
