import produce, { Draft } from 'immer';
import {
    ExperiencesState,
    ExperiencesAction,
    ExperiencesActions,
} from './types';

export const initialState: ExperiencesState = {
    memberGroupExperiences: null,
    advertiserExperiences: null,
    selectedExperienceDetails: null,
};

export const experiencesReducer = (
    state: ExperiencesState = initialState,
    action: ExperiencesActions
): ExperiencesState =>
    produce<ExperiencesState>(state, (draft: Draft<ExperiencesState>) => {
        switch (action.type) {
            case ExperiencesAction.SET_MEMBER_GROUP_EXPERIENCES:
                draft.memberGroupExperiences = action.payload.experiences;
                break;
            case ExperiencesAction.SET_ADVERTISER_EXPERIENCES:
                draft.advertiserExperiences = action.payload.experiences;
                break;
            case ExperiencesAction.SET_SELECTED_EXPERIENCE_DETAILS:
                draft.selectedExperienceDetails = action.payload.experience;
                break;
            case ExperiencesAction.CLEAR_SELECTED_EXPERIENCE_DETAILS:
                draft.selectedExperienceDetails = null;
                break;
            default:
                break;
        }
    });

export default experiencesReducer;
