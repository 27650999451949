import { Colors } from 'app/koddi-components/src/_ChakraComponents/ChakraTheme';
import { CText } from 'app/koddi-components/src/_ChakraComponents/Typography';
import React from 'react';
import { CTextInput } from '../Variants.components';
import { useCurrencyInput } from './CurrencyInput.hooks';
import {
    BaseCurrencyProps,
    CustomCurrencyInputProps,
    UserCurrencyProps,
} from './CurrencyInput.types';

export const CBaseCurrencyInput = ({
    inputValue,
    onChange,
    onBlur,
    errorText,
    customCurrencyData,
    hideSymbol = false,
    placeholder,
    ...props
}: BaseCurrencyProps): JSX.Element => {
    const {
        handleValueChange,
        displayValue,
        handleBlur,
        errorMessage,
        defaultPlaceholder,
        localeCurrencySymbol,
    } = useCurrencyInput({
        value: inputValue as number,
        onChange,
        onBlur,
        errorText,
        customCurrencyData,
    });
    return (
        <CTextInput
            {...props}
            onChange={handleValueChange}
            onBlur={handleBlur}
            inputValue={displayValue}
            placeholder={placeholder ?? defaultPlaceholder}
            errorText={errorMessage}
            isControlledInput
            elementSize="50px"
            leftElement={
                hideSymbol ? undefined : (
                    <CText
                        translate="no"
                        color={Colors.inputIcon}
                        text={localeCurrencySymbol}
                    />
                )
            }
        />
    );
};

export const CCustomCurrencyInput = ({
    ...props
}: CustomCurrencyInputProps): JSX.Element => {
    return <CBaseCurrencyInput {...props} />;
};

export const CUserCurrencyInput = ({
    ...props
}: UserCurrencyProps): JSX.Element => {
    return <CBaseCurrencyInput {...props} />;
};
