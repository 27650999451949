import { Box } from '@chakra-ui/react';
import { faCircle } from '@fortawesome/pro-regular-svg-icons';
import { faCircleCheck, fas } from '@fortawesome/pro-solid-svg-icons';
import React, { FunctionComponent, useState } from 'react';
import { CShadowedCard } from '../../../Cards';
import { BorderRadius, Colors, Spacing } from '../../../ChakraTheme';
import {
    CDV360Icon,
    CFacebookIcon,
    CFAIcon,
    CGoogleAdManagerIcon,
    CGoogleShoppingIcon,
    CPinterestIcon,
    CSA360Icon,
    CTradeDeskIcon,
    CVibenomicsIcon,
    CYIcon,
} from '../../../Icons';
import { CRow } from '../../../Layouts';
import { CBoldText, CText } from '../../../Typography';
import { ControlProps } from '../../Types';

interface RadioCardProps<ValueType extends number | string>
    extends ControlProps {
    value: ValueType;
    header: string;
    content: string;
    cardLabel?: string;
    dependantField?: JSX.Element;
    selectedValue?: ValueType | null;
    iconKey?: string;
    isDisabled?: boolean;
    onClick?: () => void;
}

const customIcons: { [key: string]: JSX.Element } = {
    dv360Icon: <CDV360Icon width="32px" height="32px" mt={Spacing.XXS} />,
    tradeDeskIcon: (
        <CTradeDeskIcon width="32px" height="32px" mt={Spacing.XXS} />
    ),
    googleShoppingIcon: (
        <CGoogleShoppingIcon width="32px" height="32px" mt={Spacing.XXS} />
    ),
    googleAdManagerIcon: (
        <CGoogleAdManagerIcon width="32px" height="32px" mt={Spacing.XXS} />
    ),
    yIcon: <CYIcon width="32px" height="32px" mt={Spacing.XXS} />,
    sa360Icon: <CSA360Icon width="32px" height="32px" mt={Spacing.XXS} />,
    vibenomicsIcon: (
        <CVibenomicsIcon width="32px" height="32px" mt={Spacing.XXS} />
    ),
    facebookIcon: <CFacebookIcon width="32px" height="32px" mt={Spacing.XXS} />,
    pinterestIcon: (
        <CPinterestIcon width="32px" height="32px" mt={Spacing.XXS} />
    ),
};

export const CustomIcon: FunctionComponent<{
    iconKey?: string;
    isChecked: boolean;
}> = ({ iconKey = '', isChecked }) => {
    if (fas[iconKey]) {
        return (
            <CFAIcon
                icon={fas[iconKey]}
                width="32px"
                height="32px"
                mt={Spacing.XXS}
            />
        );
    }
    if (customIcons[iconKey]) {
        return customIcons[iconKey];
    }
    return (
        <CFAIcon icon={isChecked ? faCircleCheck : faCircle} mt={Spacing.XXS} />
    );
};

export const CRadioCard: FunctionComponent<RadioCardProps<string | number>> = ({
    header,
    content,
    dependantField,
    onChange,
    cardLabel,
    isDisabled,
    testId,
    selectedValue,
    iconKey,
    value,
    name,
    onClick,
}) => {
    const isChecked = selectedValue === value;
    const showCardLabel = cardLabel && !isDisabled;
    const [isFocused, setIsFocused] = useState(false);
    const handleEnter = () => {
        setIsFocused(true);
    };

    const handleLeave = () => {
        setIsFocused(false);
    };

    const isHighLighted = (isFocused || isChecked) && !isDisabled;
    return (
        <CShadowedCard
            p="0"
            onClick={onClick}
            position="relative"
            maxW="530px"
            data-test={`${testId}--container`}
            onMouseEnter={handleEnter}
            onMouseLeave={handleLeave}
            filter={isHighLighted ? 'grayscale(0)' : 'grayscale(1)'}
            borderColor={isHighLighted ? Colors.primary : undefined}
            color={isHighLighted ? Colors.primary : Colors.grayDark}
            transition="all .25s ease"
        >
            {showCardLabel && (
                <Box
                    bg="primaryColor"
                    position="absolute"
                    color="white"
                    py={Spacing.XXS}
                    px={Spacing.XS}
                    borderRadius={BorderRadius.XS}
                    borderBottomRightRadius={0}
                    borderTopLeftRadius={0}
                    top="0"
                    right="0"
                >
                    <CBoldText
                        text={cardLabel}
                        fontSize="sm"
                        lineHeight={1}
                        m="0"
                    />
                </Box>
            )}

            <CRow
                cursor={isDisabled ? 'not-allowed' : 'pointer'}
                as="label"
                p={Spacing.LR}
                px={Spacing.MD}
                data-test={`${testId}--radio`}
                noBreak
                height="100%"
            >
                <Box>
                    <input
                        id={`${name}-input`}
                        type="radio"
                        name={name}
                        value={value}
                        hidden
                        checked={isChecked}
                        onChange={(e) => onChange(e.target.value)}
                        disabled={isDisabled}
                    />
                    <CustomIcon iconKey={iconKey} isChecked={isChecked} />
                </Box>

                <Box>
                    <CBoldText
                        text={header}
                        color="inherit"
                        mb={Spacing.XXS}
                        data-test={`${testId}--label`}
                    />
                    <CText
                        text={content}
                        mb="0"
                        color={Colors.grayDark}
                        transition="inherit"
                    />
                    {dependantField && (
                        <Box mt={Spacing.SM}>{dependantField}</Box>
                    )}
                </Box>
            </CRow>
        </CShadowedCard>
    );
};
