import React, { useState, useEffect, createContext } from 'react';
import { AppConfigResp } from './appConfig.types';

export const AppConfigContext = createContext<AppConfigResp>({
    appError: {
        message: '',
        cloud: [],
        env: [],
    },
    redirect: null,
});
export const AppConfigWrapper = ({
    children,
}: {
    children: JSX.Element | JSX.Element[];
}): JSX.Element => {
    const [configData, setConfigData] = useState<AppConfigResp>({
        appError: {
            message: '',
            cloud: [],
            env: [],
        },
        redirect: null,
    });

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchConfig() {
            try {
                const response = await fetch(
                    'https://k1-ui-config.koddi.workers.dev/'
                );
                const config = await response.json();
                setConfigData(config);
                setLoading(false);
            } catch {
                // eslint-disable-next-line no-console
                console.error('Error fetching app config');
                setLoading(false);
            }
        }

        setLoading(true);
        fetchConfig();
    }, []);

    return (
        <AppConfigContext.Provider value={configData}>
            {loading ? null : children}
        </AppConfigContext.Provider>
    );
};
