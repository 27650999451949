import React from 'react';
import DragGroup, { DragTileType } from 'app/koddi-components/src/DragGroup';
import {
    components as ReactSelectComponents,
    ControlProps,
} from 'react-select';
import { Spacing } from 'app/koddi-components/src/_ChakraComponents/ChakraTheme';
import { CColumn } from 'app/koddi-components/src/_ChakraComponents/Layouts';
import { OptionType } from '../../../Types';
import { OptionWithProps } from '../SelectField.types';

export const DnDControl = ({
    children,
    ...props
}: ControlProps<OptionType>): JSX.Element => {
    const { getValue, setValue } = props;
    // hack to override react select types
    // if there is an index then we only want to return the first.
    /* @ts-ignore */
    if (props?.index) return <></>;
    const values = getValue();
    if (!Array.isArray(values)) return <></>;

    const dndData = values?.map(
        ({ value, label, isPermanent, alwaysFirst }: OptionWithProps) => ({
            id: `${value}`,
            name: label,
            isPermanent,
            alwaysFirst,
        })
    );

    const handleDragEnd = (result: DragTileType[]) => {
        const valuesCopy = result.map(
            ({ id, name, isPermanent, alwaysFirst }) => ({
                value: id,
                label: name,
                isPermanent,
                alwaysFirst,
            })
        );
        setValue(valuesCopy, 'set-value');
    };
    const handleDragDestroy = (
        _result: DragTileType[],
        deletedIndex: number
    ) => {
        const valuesCopy = [...values];
        valuesCopy.splice(deletedIndex, 1);
        setValue(valuesCopy, 'set-value');
    };

    return (
        <CColumn gap={Spacing.XXS}>
            <DragGroup
                id="draggable-multiValue"
                data={dndData}
                onDragEnd={handleDragEnd}
                onDestroy={handleDragDestroy}
            />
            <ReactSelectComponents.Control {...props}>
                {children}
            </ReactSelectComponents.Control>
        </CColumn>
    );
};
