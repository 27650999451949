import React from 'react';
import { Colors } from '../../ChakraTheme';
import { ButtonBaseProps } from '../Button.type';
import { CSubtleButton } from '../Buttons.component';

interface StatefulButton extends ButtonBaseProps {
    isActive?: boolean;
}

export const CStatefulButton = ({
    children,
    isActive = false,
    ...props
}: StatefulButton): JSX.Element => {
    const activeStyles = isActive
        ? {
              bgColor: Colors.primary,
              _hover: {
                  bgColor: Colors.primary,
              },
              color: Colors.white,
          }
        : {};
    return (
        <CSubtleButton {...props} {...activeStyles}>
            {children}
        </CSubtleButton>
    );
};
