import React, { FunctionComponent, Children } from 'react';
import Icon from 'koddi-components/Icon';
import { ButtonProps, ButtonStyle } from './button.types';
import {
    LoginButton,
    LoginSecondaryButton,
    PrimaryButton,
    SecondaryButton,
    TertiaryButton,
    PillButton,
    RectButton,
    TransparentButton,
    SelectorButton,
    ButtonText,
    BorderButton,
    IconWithNumberWrapper,
    SuccessButton,
    ErrorButton,
} from './button.styled';

const btnMap: Record<ButtonStyle, FunctionComponent<any>> = {
    login: LoginButton,
    loginSecondary: LoginSecondaryButton,
    primary: PrimaryButton,
    secondary: SecondaryButton,
    tertiary: TertiaryButton,
    pill: PillButton,
    rect: RectButton,
    transparent: TransparentButton,
    border: BorderButton,
    selector: SelectorButton,
    success: SuccessButton,
    error: ErrorButton,
};

/*
 * The Koddi `Button` Component
 *
 * Reusable Button Component
 * `btnStyle` - primary or secondary styles, defaults to primary
 * `onClick` - callback
 * `type` - html attribute passed to button tag
 * `iconLeft` - string name of icon to be rendered left of children
 * `iconRight` - string name of icon to be rendered right of children
 *
 * Example Usage:
        <Button disabled btnStyle="secondary" iconRight="chevronDown" onClick={handleClick}> Ayeee lmao </Button>
 */
export const Button: FunctionComponent<ButtonProps> = ({
    children,
    btnStyle = 'primary',
    onClick,
    type = 'button',
    iconRight,
    iconLeft,
    iconSize = 8,
    disabled,
    minWidth,
    'data-test': dataTest,
    ellipsisOnOverflow = false,
    forwardedRef,
    width,
    textAlign,
    hasIconWithNumber,
    numberToDisplay,
    hasSmallerTextOnButton,
    ...rest
}) => {
    const ButtonComponent = btnMap[btnStyle];

    const iconOnly =
        !Children.count(children) &&
        ((iconLeft && iconLeft.length > 0) ||
            (iconRight && iconRight.length > 0));

    return (
        <ButtonComponent
            onClick={onClick}
            type={type}
            disabled={disabled}
            minWidth={minWidth}
            data-test={dataTest}
            ellipsisOnOverflow={ellipsisOnOverflow}
            iconOnly={iconOnly}
            role="button"
            width={width}
            {...rest}
            ref={forwardedRef}
            hasIconWithNumber={hasIconWithNumber}
            numberToDisplay={numberToDisplay}
        >
            {iconLeft && (
                <Icon
                    disabled={disabled}
                    width={iconSize}
                    height={iconSize}
                    icon={iconLeft}
                />
            )}
            <ButtonText
                textAlign={textAlign}
                hasSmallerTextOnButton={hasSmallerTextOnButton}
            >
                {children}
            </ButtonText>
            {iconRight && (
                <Icon
                    disabled={disabled}
                    width={iconSize}
                    height={iconSize}
                    icon={iconRight}
                />
            )}
            {hasIconWithNumber && (
                <IconWithNumberWrapper>{numberToDisplay}</IconWithNumberWrapper>
            )}
        </ButtonComponent>
    );
};

export const ButtonWithForwardedRef = React.forwardRef((props, ref) => (
    <Button {...props} forwardedRef={ref} />
)) as typeof Button;

export default Button;
