import { KoddiThemeBase, defaultTheme } from 'koddi-components/ThemeProvider';

import appConfig from 'app/app_config.json';

const defaultKoddiTheme: KoddiThemeBase = {
    ...defaultTheme,
    logoSrc: appConfig.default_logo,
    loginLogoSrc: appConfig.default_logo,
    faviconSrc: appConfig.default_favicon,
};

export default defaultKoddiTheme;
