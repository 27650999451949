import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { CFAIcon } from 'app/koddi-components/src/_ChakraComponents/Icons';
import { CRow } from 'app/koddi-components/src/_ChakraComponents/Layouts';
import {
    Colors,
    Spacing,
} from 'app/koddi-components/src/_ChakraComponents/ChakraTheme';
import React from 'react';
import { IndicatorProps } from 'react-select';
import { OptionType } from '../../../Types';

export const DropdownIndicator = (
    props: IndicatorProps<OptionType>
): JSX.Element => {
    /* @ts-ignore */ // outdated types creating issues... selectProps is in fact part of the props
    const { selectProps } = props;
    return (
        <CRow
            justifyContent="space-between"
            alignItems="center"
            ml={Spacing.XXXS}
            px={Spacing.SM}
            width="125px"
            color={Colors.grayDark}
            pb={Spacing.XXXS}
        >
            {selectProps?.isLoading ? 'Loading...' : 'Select'}
            <CFAIcon icon={faChevronDown} />
        </CRow>
    );
};
