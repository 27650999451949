import { useLocale } from 'app/koddi-components/src/LocaleProvider';
import { BaseActivity } from 'app/react-ui/api/Admin/User';
import useLocalize from 'app/react-ui/hooks/useLocalize';
import {
    useAdvertiserGroupId,
    useAdvertiserId,
    useMemberGroupId,
} from 'app/react-ui/redux-core/app/context/hooks';
import { fetchTermsAndConditions } from 'app/react-ui/redux-core/app/termsAndConditions';
import {
    useTermsAndConditionsLoading,
    useTermsContextChanged,
} from 'app/react-ui/redux-core/app/termsAndConditions/hooks';
import { useAuthUser } from 'app/react-ui/redux-core/auth/hooks';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

export const useUserSetup = (): { userPermissions: BaseActivity[] } => {
    const user = useAuthUser();
    const contextMemberGroupId = useMemberGroupId();
    const contextAdvertiserGroupId = useAdvertiserGroupId();
    const contextAdvertiserId = useAdvertiserId();
    const { changeLocale } = useLocale();
    const { setLocale, setBrowserLocale } = useLocalize(changeLocale);
    const dispatch = useDispatch();

    const termsContextChanged = useTermsContextChanged();
    const termsAndConditionsLoading = useTermsAndConditionsLoading();

    useEffect(() => {
        if (user?.locale?.code) {
            setLocale(user.locale.code);
        }
        if (!user) {
            setBrowserLocale();
        }
    }, [user?.locale?.code, user, setLocale, setBrowserLocale]);

    // fetches T&C
    useEffect(() => {
        if (
            user &&
            termsContextChanged &&
            !termsAndConditionsLoading &&
            contextMemberGroupId !== null
        ) {
            dispatch(
                fetchTermsAndConditions(
                    user.user_id,
                    contextMemberGroupId,
                    user.locale.id
                )
            );
        }
    }, [
        contextMemberGroupId,
        dispatch,
        termsAndConditionsLoading,
        termsContextChanged,
        user,
    ]);

    const userPermissions = useMemo(() => {
        if (contextMemberGroupId === null) return [];
        return (
            user?.base_activities ||
            user?.member_group_activities?.[contextMemberGroupId]?.activities ||
            (contextAdvertiserGroupId &&
                user?.advertiser_group_activities?.[contextAdvertiserGroupId]
                    ?.activities) ||
            (contextAdvertiserId &&
                user?.advertiser_activities?.[contextAdvertiserId]
                    ?.activities) ||
            []
        );
    }, [
        user,
        contextMemberGroupId,
        contextAdvertiserGroupId,
        contextAdvertiserId,
    ]);
    return { userPermissions };
};
