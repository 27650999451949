import { IconKey } from 'koddi-components/Icon';
import { ReactElement } from 'react';
import { FieldRenderProps, FieldProps } from 'react-final-form';

export type BaseHTMLInputProps = React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
>;

type BaseInputProps = {
    label?: string;
    text?: string;
    name: string;
    fullWidth?: boolean;
    flexDirection?: string;
    forwardedRef?:
        | ((instance: HTMLInputElement | null) => void)
        | React.MutableRefObject<HTMLInputElement | null>
        | null;
    outerRef?: React.Ref<HTMLDivElement>;
    inputContainerRef?: React.Ref<HTMLDivElement>;
    iconRight?: React.ReactNode;
    iconLeft?: IconKey;
    useAriaLabel?: boolean;
    hasError?: boolean;
    errorText?: string;
    labelToolTip?: boolean;
    toolTipText?: string;
    /**
     * Flag to control +/- icons from being displayed on number input
     */
    hideIcons?: boolean;
    onContainerClick?: (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => void;

    /**
     * Enables content (like units) to be displayed to the right of the input box
     */
    unitLabelContent?: ReactElement;

    /**
     * Force the element to have a border of exactly some measurement
     */
    fixedBorderWidth?: string;

    /**
     * Set how wide the entire input element will be
     */
    fieldWidth?: string;

    /**
     * Set the height of the element
     */
    height?: string;
    maxHeight?: string;

    paddingLeft?: string;
    borderRadius?: string;
    /** Word count separated by the given separator */
    hasWordCount?: boolean;
    maxAmountOfWords?: number;
    wordCountSeparator?: string;
    setExceededWordCount?: React.Dispatch<React.SetStateAction<boolean>>;
    maxWordLength?: number;
    setExceededWordLength?: React.Dispatch<React.SetStateAction<boolean>>;
};

export type InputProps = BaseInputProps &
    Partial<FieldRenderProps<string, HTMLInputElement>> &
    Omit<BaseHTMLInputProps, 'name'>;

export type InputFieldProps = BaseInputProps &
    Omit<
        FieldProps<string, InputFieldComponentProps, HTMLInputElement>,
        'component'
    >;

export type InputFieldComponentProps = BaseInputProps & {
    input: FieldRenderProps<string, HTMLInputElement>['input'];
    meta: FieldRenderProps<string, HTMLInputElement>['meta'];
} & Omit<BaseHTMLInputProps, 'name'>;

export type StyledInputProps = {
    hasError?: boolean;
    readOnly?: boolean;
    disabled?: boolean;
    minWidth?: string;
    minHeight?: string;
    customChevronIconMargin?: boolean;
    ref?: any;
    v2?: boolean;
    maxLength?: number;
    hasCharacterCount?: boolean;
    fixedBorderWidth?: string;
    fieldWidth?: string;
    height?: string;
    maxHeight?: string;
    paddingLeft?: string;
    borderRadius?: string;
};

export type StyledInputInnerProps = Omit<StyledInputProps, 'ref'>;

export enum AutoCompleteValues {
    NewPassword = 'new-password',
    NewPhone = 'new-phone',
    Off = 'off',
    On = 'on',
}
