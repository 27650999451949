import React, { FunctionComponent } from 'react';
import { BaseSVGProps } from '../icon.types';

const PaidIcon: FunctionComponent<BaseSVGProps> = ({ height, width }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        height={height}
        width={width}
        viewBox="0 0 1920 1129"
        enableBackground="new 0 0 1920 1129"
        xmlSpace="preserve"
    >
        <path
            fill="#FFFFFF"
            opacity="0"
            stroke="none"
            d="
M1450.000000,1130.000000 
   C966.666748,1130.000000 483.833496,1130.000000 1.000194,1130.000000 
   C1.000130,753.666809 1.000130,377.333649 1.000065,1.000346 
   C640.999695,1.000231 1280.999390,1.000231 1920.999268,1.000115 
   C1920.999512,377.333008 1920.999512,753.666016 1920.999756,1129.999512 
   C1764.166626,1130.000000 1607.333374,1130.000000 1450.000000,1130.000000 
M968.252380,886.023376 
   C968.484680,886.016907 968.685303,885.933960 969.569763,885.375977 
   C970.382141,885.304321 971.194458,885.232605 972.278198,885.533142 
   C972.871826,885.312805 973.465515,885.092407 974.850647,884.482727 
   C989.778076,881.858398 1004.709412,879.255554 1019.629211,876.588501 
   C1022.120667,876.143188 1024.904297,876.275635 1026.014160,872.916992 
   C1026.252930,872.194153 1029.880981,871.742798 1029.958252,871.941101 
   C1031.343750,875.499634 1034.046265,874.034790 1036.270630,873.658630 
   C1047.437012,871.770508 1058.693604,870.236084 1069.707642,867.672119 
   C1082.366699,864.725098 1095.900513,865.589478 1107.749023,859.065674 
   C1108.209595,858.812073 1109.000366,859.164978 1109.638184,859.225708 
   C1111.539307,859.406799 1113.515991,860.013306 1115.331543,859.697327 
   C1146.179565,854.327881 1177.007568,848.842957 1207.840332,843.385498 
   C1213.580811,842.369446 1219.288696,840.971985 1225.074341,840.463562 
   C1230.494995,839.987122 1235.001099,836.868408 1240.975952,837.159912 
   C1248.530762,837.528381 1256.234741,834.837036 1263.601318,833.514465 
   C1264.550171,822.801331 1270.695801,816.565979 1279.206543,812.501953 
   C1285.082886,809.695984 1288.706909,811.877197 1290.081909,818.393982 
   C1290.760376,821.609680 1290.998779,824.918274 1291.468872,828.428528 
   C1293.206299,828.221680 1294.833984,828.106506 1296.432495,827.825684 
   C1319.563232,823.763245 1342.678467,819.612244 1365.824463,815.640015 
   C1372.730713,814.454773 1379.713867,813.747559 1383.735107,806.826904 
   C1383.892456,806.555969 1384.226318,806.382935 1384.489014,806.178467 
   C1387.336670,803.962036 1389.336182,804.217285 1390.139893,808.090820 
   C1390.331177,809.012756 1390.644531,809.909424 1390.988770,811.125122 
   C1468.948608,797.383118 1546.661987,783.684570 1622.957642,770.235962 
   C1624.527466,766.807678 1625.655762,764.343750 1626.784058,761.879822 
   C1627.460693,762.038391 1628.137329,762.196899 1628.813965,762.355469 
   C1628.917236,764.543274 1629.020386,766.731079 1629.132080,769.099670 
   C1633.553467,768.351196 1637.958984,767.513245 1642.392578,766.868958 
   C1693.659668,759.418579 1722.666626,712.143982 1714.194580,667.284729 
   C1705.416992,620.807495 1697.661133,574.137268 1689.449463,527.553101 
   C1688.298218,521.022583 1687.095459,514.501099 1686.083496,508.899536 
   C1683.850342,510.321198 1681.889771,511.569305 1679.929199,512.817444 
   C1678.985596,510.544067 1677.359619,508.293854 1677.281494,505.991058 
   C1677.195557,503.460144 1677.881348,500.385040 1679.376099,498.416626 
   C1683.646729,492.792694 1682.630737,486.988861 1681.234253,480.912262 
   C1680.349976,477.064148 1679.823120,473.133759 1679.137695,469.239990 
   C1668.655518,409.695282 1658.177979,350.149689 1647.686523,290.606567 
   C1646.197266,282.153748 1644.639893,273.712921 1643.078979,265.072327 
   C1639.672241,269.343536 1636.577515,273.223663 1633.602051,276.954132 
   C1633.602051,268.654785 1633.587036,260.888245 1633.630493,253.122070 
   C1633.633911,252.499283 1633.865112,251.512985 1634.285278,251.311905 
   C1640.949219,248.123154 1638.656006,242.249603 1638.076294,237.358322 
   C1637.058594,228.769333 1635.311890,220.263092 1633.769775,211.740448 
   C1625.291504,164.885376 1582.944702,134.856781 1536.265259,142.795624 
   C1503.427246,148.380447 1470.568970,153.909500 1437.911621,160.425674 
   C1432.997681,161.406158 1428.138672,161.963409 1423.261475,162.803680 
   C1348.748657,175.640900 1274.301025,188.857071 1199.840820,201.999390 
   C1198.492798,202.237320 1197.176025,202.652832 1195.036499,203.187439 
   C1200.892700,209.269318 1206.108032,214.685547 1211.805176,220.602219 
   C1203.257935,221.808670 1192.478027,215.206192 1189.477051,207.753952 
   C1188.962524,206.476501 1187.480469,204.342331 1187.012817,204.483551 
   C1181.829590,206.048950 1175.558105,203.932770 1171.354248,209.085403 
   C1170.723145,209.858841 1168.779663,209.793274 1167.463989,209.723709 
   C1165.316406,209.610153 1163.087402,208.605682 1161.062622,208.956787 
   C1109.041382,217.977585 1057.037598,227.100601 1005.056030,236.346603 
   C1003.051697,236.703125 1001.360352,238.594711 999.399902,239.505844 
   C998.204468,240.061417 996.754333,240.403519 995.451477,240.321106 
   C992.984253,240.165070 990.438538,238.993805 988.104492,239.366379 
   C975.606384,241.361343 963.167603,243.725677 950.700439,245.917419 
   C938.398682,248.080078 926.082764,250.162766 913.781616,252.329147 
   C901.316284,254.524414 888.914490,257.162781 876.386780,258.898987 
   C864.114136,260.599792 852.094299,263.524597 839.902771,265.614349 
   C761.108276,279.120758 682.410034,293.188782 603.680237,307.072449 
   C596.791199,308.287323 589.902710,309.504578 583.012634,310.713196 
   C576.285522,311.893188 569.554749,313.052277 562.829285,314.241272 
   C555.941101,315.459045 549.058838,316.710602 542.171204,317.931519 
   C535.282654,319.152588 528.390991,320.356506 521.499939,321.563324 
   C514.773926,322.741241 508.044983,323.901917 501.321014,325.091217 
   C494.432068,326.309662 487.550659,327.570801 480.660370,328.781403 
   C473.934052,329.963226 467.197693,331.087830 460.471222,332.268890 
   C453.582367,333.478424 446.700684,334.728790 439.814941,335.956207 
   C432.928589,337.183777 426.043213,338.417114 419.153534,339.625824 
   C412.427124,340.805847 405.726257,342.199097 398.957855,343.052338 
   C392.563416,343.858459 386.386261,345.634003 380.024261,346.650848 
   C352.233856,351.092499 324.535278,356.123291 296.843292,361.159790 
   C282.788635,363.715973 268.454651,365.125732 255.209793,371.213776 
   C223.304016,385.879395 199.783646,422.190613 208.277908,467.179657 
   C220.976715,534.437561 232.031219,602.004578 244.373032,669.332336 
   C246.492691,680.895691 244.640121,693.938904 253.098358,702.475830 
   C252.351730,705.441223 251.022522,707.745911 251.373306,709.757568 
   C263.560608,779.652649 275.730865,849.551575 288.291595,919.380188 
   C296.586365,965.492981 339.036957,996.132141 383.907959,988.586182 
   C420.042053,982.509644 456.080261,975.864746 492.190887,969.645264 
   C498.228516,968.605225 504.043030,967.850525 506.322021,960.948792 
   C506.398895,960.716003 507.162842,960.710083 507.720062,960.569824 
   C508.018585,962.471069 508.294556,964.228516 508.638885,966.421448 
   C510.677490,966.159790 512.469788,966.003357 514.234802,965.692566 
   C576.235657,954.773315 638.269775,944.036987 700.206970,932.767395 
   C715.433777,929.996826 731.500488,930.078918 745.355469,921.565979 
   C745.563660,921.438171 745.973450,921.638489 746.477112,921.713684 
   C746.641846,922.560059 746.816345,923.456543 747.041321,924.612610 
   C771.892822,920.244629 796.627502,915.870117 821.372925,911.556885 
   C839.904419,908.326721 858.449646,905.175293 877.873413,902.040039 
   C881.247009,901.379883 884.620544,900.719727 888.747253,899.756226 
   C896.929932,898.267578 905.071289,896.451782 913.310730,895.413086 
   C918.242188,894.791382 921.343994,893.316772 922.860535,888.123962 
   C923.883301,884.621887 925.342346,880.423523 930.607605,879.739380 
   C932.145935,885.561462 927.316345,888.565308 925.239075,893.069458 
   C936.289551,891.264221 946.919922,889.527527 958.328674,887.946289 
   C959.905212,887.567993 961.481750,887.189697 963.734436,886.450867 
   C964.489319,886.359314 965.244202,886.267761 966.204102,886.473389 
   C966.470398,886.340637 966.736633,886.207886 967.450439,885.905396 
   C967.450439,885.905396 967.925415,885.963989 968.252380,886.023376 
M1284.415039,826.276062 
   C1279.829712,823.505432 1275.880371,825.312378 1272.587524,832.071960 
   C1275.933105,831.472656 1279.053955,831.185730 1281.969360,830.255371 
   C1283.111206,829.890930 1283.792603,828.083374 1284.415039,826.276062 
z"
        />
        <path
            fill="#D62D35"
            opacity="1.000000"
            stroke="none"
            d="
M876.988708,901.988770 
   C858.449646,905.175293 839.904419,908.326721 821.372925,911.556885 
   C796.627502,915.870117 771.892822,920.244629 747.041321,924.612610 
   C746.816345,923.456543 746.641846,922.560059 746.477112,921.713684 
   C745.973450,921.638489 745.563660,921.438171 745.355469,921.565979 
   C731.500488,930.078918 715.433777,929.996826 700.206970,932.767395 
   C638.269775,944.036987 576.235657,954.773315 514.234802,965.692566 
   C512.469788,966.003357 510.677490,966.159790 508.638885,966.421448 
   C508.294556,964.228516 508.018585,962.471069 507.720062,960.569824 
   C507.162842,960.710083 506.398895,960.716003 506.322021,960.948792 
   C504.043030,967.850525 498.228516,968.605225 492.190887,969.645264 
   C456.080261,975.864746 420.042053,982.509644 383.907959,988.586182 
   C339.036957,996.132141 296.586365,965.492981 288.291595,919.380188 
   C275.730865,849.551575 263.560608,779.652649 251.373306,709.757568 
   C251.022522,707.745911 252.351730,705.441223 253.098358,702.475830 
   C244.640121,693.938904 246.492691,680.895691 244.373032,669.332336 
   C232.031219,602.004578 220.976715,534.437561 208.277908,467.179657 
   C199.783646,422.190613 223.304016,385.879395 255.209793,371.213776 
   C268.454651,365.125732 282.788635,363.715973 296.843292,361.159790 
   C324.535278,356.123291 352.233856,351.092499 380.024261,346.650848 
   C386.386261,345.634003 392.563416,343.858459 398.957855,343.052338 
   C405.726257,342.199097 412.427124,340.805847 419.153534,339.625824 
   C426.043213,338.417114 432.928589,337.183777 439.814941,335.956207 
   C446.700684,334.728790 453.582367,333.478424 460.471222,332.268890 
   C467.197693,331.087830 473.934052,329.963226 480.660370,328.781403 
   C487.550659,327.570801 494.432068,326.309662 501.321014,325.091217 
   C508.044983,323.901917 514.773926,322.741241 521.499939,321.563324 
   C528.390991,320.356506 535.282654,319.152588 542.171204,317.931519 
   C549.058838,316.710602 555.941101,315.459045 562.829285,314.241272 
   C569.554749,313.052277 576.285522,311.893188 583.012634,310.713196 
   C589.902710,309.504578 596.791199,308.287323 603.680237,307.072449 
   C682.410034,293.188782 761.108276,279.120758 839.902771,265.614349 
   C852.094299,263.524597 864.114136,260.599792 876.386780,258.898987 
   C888.914490,257.162781 901.316284,254.524414 913.781616,252.329147 
   C926.082764,250.162766 938.398682,248.080078 950.700439,245.917419 
   C963.167603,243.725677 975.606384,241.361343 988.104492,239.366379 
   C990.438538,238.993805 992.984253,240.165070 995.451477,240.321106 
   C996.754333,240.403519 998.204468,240.061417 999.399902,239.505844 
   C1001.360352,238.594711 1003.051697,236.703125 1005.056030,236.346603 
   C1057.037598,227.100601 1109.041382,217.977585 1161.062622,208.956787 
   C1163.087402,208.605682 1165.316406,209.610153 1167.463989,209.723709 
   C1168.779663,209.793274 1170.723145,209.858841 1171.354248,209.085403 
   C1175.558105,203.932770 1181.829590,206.048950 1187.012817,204.483551 
   C1187.480469,204.342331 1188.962524,206.476501 1189.477051,207.753952 
   C1192.478027,215.206192 1203.257935,221.808670 1211.805176,220.602219 
   C1206.108032,214.685547 1200.892700,209.269318 1195.036499,203.187439 
   C1197.176025,202.652832 1198.492798,202.237320 1199.840820,201.999390 
   C1274.301025,188.857071 1348.748657,175.640900 1423.261475,162.803680 
   C1428.138672,161.963409 1432.997681,161.406158 1437.911621,160.425674 
   C1470.568970,153.909500 1503.427246,148.380447 1536.265259,142.795624 
   C1582.944702,134.856781 1625.291504,164.885376 1633.769775,211.740448 
   C1635.311890,220.263092 1637.058594,228.769333 1638.076294,237.358322 
   C1638.656006,242.249603 1640.949219,248.123154 1634.285278,251.311905 
   C1633.865112,251.512985 1633.633911,252.499283 1633.630493,253.122070 
   C1633.587036,260.888245 1633.602051,268.654785 1633.602051,276.954132 
   C1636.577515,273.223663 1639.672241,269.343536 1643.078979,265.072327 
   C1644.639893,273.712921 1646.197266,282.153748 1647.686523,290.606567 
   C1658.177979,350.149689 1668.655518,409.695282 1679.137695,469.239990 
   C1679.823120,473.133759 1680.349976,477.064148 1681.234253,480.912262 
   C1682.630737,486.988861 1683.646729,492.792694 1679.376099,498.416626 
   C1677.881348,500.385040 1677.195557,503.460144 1677.281494,505.991058 
   C1677.359619,508.293854 1678.985596,510.544067 1679.929199,512.817444 
   C1681.889771,511.569305 1683.850342,510.321198 1686.083496,508.899536 
   C1687.095459,514.501099 1688.298218,521.022583 1689.449463,527.553101 
   C1697.661133,574.137268 1705.416992,620.807495 1714.194580,667.284729 
   C1722.666626,712.143982 1693.659668,759.418579 1642.392578,766.868958 
   C1637.958984,767.513245 1633.553467,768.351196 1629.132080,769.099670 
   C1629.020386,766.731079 1628.917236,764.543274 1628.813965,762.355469 
   C1628.137329,762.196899 1627.460693,762.038391 1626.784058,761.879822 
   C1625.655762,764.343750 1624.527466,766.807678 1622.957642,770.235962 
   C1546.661987,783.684570 1468.948608,797.383118 1390.988770,811.125122 
   C1390.644531,809.909424 1390.331177,809.012756 1390.139893,808.090820 
   C1389.336182,804.217285 1387.336670,803.962036 1384.489014,806.178467 
   C1384.226318,806.382935 1383.892456,806.555969 1383.735107,806.826904 
   C1379.713867,813.747559 1372.730713,814.454773 1365.824463,815.640015 
   C1342.678467,819.612244 1319.563232,823.763245 1296.432495,827.825684 
   C1294.833984,828.106506 1293.206299,828.221680 1291.468872,828.428528 
   C1290.998779,824.918274 1290.760376,821.609680 1290.081909,818.393982 
   C1288.706909,811.877197 1285.082886,809.695984 1279.206543,812.501953 
   C1270.695801,816.565979 1264.550171,822.801331 1263.601318,833.514465 
   C1256.234741,834.837036 1248.530762,837.528381 1240.975952,837.159912 
   C1235.001099,836.868408 1230.494995,839.987122 1225.074341,840.463562 
   C1219.288696,840.971985 1213.580811,842.369446 1207.840332,843.385498 
   C1177.007568,848.842957 1146.179565,854.327881 1115.331543,859.697327 
   C1113.515991,860.013306 1111.539307,859.406799 1109.638184,859.225708 
   C1109.000366,859.164978 1108.209595,858.812073 1107.749023,859.065674 
   C1095.900513,865.589478 1082.366699,864.725098 1069.707642,867.672119 
   C1058.693604,870.236084 1047.437012,871.770508 1036.270630,873.658630 
   C1034.046265,874.034790 1031.343750,875.499634 1029.958252,871.941101 
   C1029.880981,871.742798 1026.252930,872.194153 1026.014160,872.916992 
   C1024.904297,876.275635 1022.120667,876.143188 1019.629211,876.588501 
   C1004.709412,879.255554 989.778076,881.858398 974.197754,884.591309 
   C973.032227,884.853577 972.519531,885.007202 972.006836,885.160889 
   C971.194458,885.232605 970.382141,885.304321 969.050293,885.483643 
   C968.250793,885.588257 968.048950,885.712463 967.925415,885.963989 
   C967.925415,885.963989 967.450439,885.905396 966.967407,885.933472 
   C966.322632,886.033142 966.160889,886.104675 965.999084,886.176270 
   C965.244202,886.267761 964.489319,886.359314 963.038574,886.508789 
   C962.254517,885.724365 961.833191,884.727905 962.138611,884.067627 
   C963.188721,881.796814 964.493042,879.643433 965.700623,877.445374 
   C965.270142,877.017883 964.839722,876.590332 964.409302,876.162842 
   C961.569397,877.923462 958.323547,879.300720 956.105896,881.648987 
   C955.310852,882.490784 956.996765,885.675476 957.550354,887.790894 
   C946.919922,889.527527 936.289551,891.264221 925.239075,893.069458 
   C927.316345,888.565308 932.145935,885.561462 930.607605,879.739380 
   C925.342346,880.423523 923.883301,884.621887 922.860535,888.123962 
   C921.343994,893.316772 918.242188,894.791382 913.310730,895.413086 
   C905.071289,896.451782 896.929932,898.267578 887.961548,899.853271 
   C886.673645,899.295715 885.643982,898.540222 885.747009,898.001099 
   C887.933167,886.556396 886.185669,882.056824 878.292297,879.237793 
   C872.331299,877.108887 870.009949,879.144653 870.911743,884.679871 
   C873.034119,885.344177 874.748352,885.880737 876.462646,886.417297 
   C875.061768,888.074524 873.842896,889.952148 872.196411,891.312317 
   C871.083374,892.231750 869.368469,892.422607 867.009460,893.265808 
   C876.232178,894.744324 876.232178,894.744324 876.988708,901.988770 
M1002.344421,256.263580 
   C995.697266,263.017822 994.197937,267.812500 997.229980,273.573273 
   C999.167664,267.506287 1001.023438,261.695953 1003.128174,255.922073 
   C1003.128174,255.922073 1003.297668,255.684464 1003.297668,255.684464 
   C1003.297668,255.684464 1002.940796,255.957611 1002.344421,256.263580 
M294.972290,414.003143 
   C294.972290,414.003143 294.979584,413.941223 294.155518,413.830933 
   C293.079926,413.830933 292.004333,413.830933 290.928741,413.830933 
   C291.383484,413.523773 291.838226,413.216614 292.292969,412.909454 
   C290.989441,409.660339 289.819336,406.345825 288.282471,403.211121 
   C287.927185,402.486359 285.973724,401.775543 285.365631,402.123688 
   C284.440216,402.653503 283.376404,404.239441 283.548889,405.155060 
   C284.159943,408.399292 285.270538,411.549438 286.234039,414.881226 
   C285.950226,415.038025 285.247894,415.426056 284.013092,415.725372 
   C283.674286,415.835724 283.335480,415.946045 282.334686,415.967133 
   C281.530884,416.299469 280.727081,416.631836 279.204773,416.987915 
   C263.027435,423.803406 255.508728,437.507690 258.532562,454.705933 
   C266.780579,501.617065 275.065247,548.521851 283.206635,595.451477 
   C283.635986,597.926392 282.757751,600.605286 282.709747,603.193359 
   C282.674957,605.070068 282.538727,608.357422 283.254333,608.596008 
   C286.723511,609.752380 286.365326,612.594910 286.794678,615.024292 
   C304.213867,713.589050 321.585571,812.162170 338.985504,910.730347 
   C342.235413,929.140808 358.633453,940.765747 377.049774,937.662903 
   C390.517242,935.393860 403.951385,932.927307 417.402100,930.558716 
   C430.825775,928.194824 444.251617,925.843384 457.512512,923.515259 
   C459.101471,926.361755 460.448120,928.774292 462.121033,931.771240 
   C463.902283,927.643066 460.188080,922.851990 467.362335,921.607849 
   C561.225952,905.329895 655.030640,888.710693 748.819946,872.008118 
   C753.562073,871.163635 756.770935,871.428467 758.598816,876.367676 
   C759.127014,877.794983 760.477478,878.917969 761.985840,880.874146 
   C763.283142,878.074402 764.540283,876.099426 765.124695,873.942200 
   C766.244690,869.807495 768.832642,868.468506 772.946167,867.802856 
   C790.700378,864.929993 808.385071,861.628296 826.099304,858.506958 
   C833.348206,857.229553 840.607971,856.013611 847.576782,854.818787 
   C847.405396,857.265076 847.277222,859.095093 847.149048,860.925171 
   C848.090881,860.963135 849.684204,861.337891 849.867188,860.983704 
   C850.932983,858.921448 851.667664,856.688049 852.771179,853.852112 
   C860.667480,852.437866 869.482910,850.659912 878.370239,849.382385 
   C881.224915,848.972046 884.265564,849.934448 887.229492,850.156860 
   C888.129517,850.224487 889.484924,850.258301 889.907166,849.729309 
   C894.429565,844.063171 901.039001,846.264099 906.778381,844.259033 
   C906.778381,848.395386 906.393250,851.764709 906.947388,854.971741 
   C907.208618,856.483154 909.446472,858.957275 910.491638,858.814941 
   C916.708923,857.967957 919.895325,862.063171 923.493347,865.769958 
   C925.567261,867.906616 927.614197,870.292236 930.160522,871.655945 
   C931.484375,872.364868 934.322083,871.623657 935.593140,870.520447 
   C936.315735,869.893433 935.834045,866.738953 934.868896,865.568665 
   C933.337952,863.712280 931.083130,862.305176 928.896484,861.164246 
   C921.491516,857.300720 915.498474,852.389526 914.590393,842.934448 
   C933.407227,839.615601 952.525696,836.243469 971.314392,832.929565 
   C972.164001,834.940369 972.809692,836.468811 973.455505,837.997253 
   C974.895630,836.804321 976.329712,835.604004 977.777466,834.420471 
   C979.169250,833.282715 980.438293,831.444458 981.996582,831.147034 
   C995.408875,828.586365 1008.875610,826.311279 1022.324036,823.939575 
   C1089.568237,812.080383 1156.811401,800.215332 1224.055664,788.356628 
   C1296.384888,775.601196 1368.713135,762.839111 1441.045410,750.100769 
   C1506.126831,738.639404 1571.243774,727.374695 1636.252319,715.514038 
   C1643.135864,714.258118 1651.023560,711.078308 1655.568359,706.142151 
   C1662.699097,698.397278 1665.776978,688.091125 1663.669678,676.881470 
   C1660.010742,657.419067 1656.679688,637.894897 1653.242432,618.391113 
   C1652.600464,614.748352 1652.105713,611.079773 1651.437500,606.737793 
   C1662.301514,610.002136 1671.662842,613.469849 1674.715820,625.135254 
   C1675.100708,626.605957 1676.887695,628.602722 1678.155640,628.702820 
   C1679.474487,628.806946 1681.394653,627.195740 1682.285034,625.833496 
   C1686.750122,619.002197 1683.839966,607.655212 1676.528687,604.133423 
   C1675.087524,603.439270 1671.699707,604.179749 1670.831177,605.406677 
   C1668.509033,608.686829 1666.214844,608.173340 1663.055786,607.168762 
   C1653.372803,604.089539 1647.122559,598.636047 1647.954346,587.526306 
   C1648.049316,586.257629 1647.359619,584.944458 1647.128418,583.635193 
   C1631.811035,496.871490 1616.484253,410.109436 1601.295166,323.323334 
   C1600.872314,320.908081 1601.934082,318.233063 1602.244385,316.088776 
   C1601.012695,313.408325 1599.156738,310.751984 1598.564575,307.838776 
   C1596.144409,295.931946 1594.110352,283.946655 1592.083252,271.379639 
   C1592.215210,271.095154 1592.347168,270.810699 1592.479248,270.526184 
   C1592.687622,270.676117 1592.963257,270.782013 1593.094482,270.982391 
   C1598.768921,279.652100 1606.369629,286.268799 1615.297119,291.339081 
   C1617.479248,292.578369 1620.188354,292.889587 1622.655151,293.627533 
   C1622.912354,293.056946 1623.169556,292.486359 1623.426636,291.915771 
   C1622.410522,291.031525 1621.535034,289.746918 1620.354004,289.332092 
   C1615.119141,287.493378 1615.239990,283.980011 1616.953247,279.748810 
   C1618.073730,276.981567 1619.220947,274.207581 1620.054932,271.348724 
   C1620.512939,269.778900 1620.345947,268.026794 1620.460815,266.356873 
   C1619.987549,266.125519 1619.514404,265.894135 1619.041138,265.662750 
   C1617.348022,267.491119 1615.431152,269.164246 1614.024536,271.191315 
   C1612.599609,273.244873 1611.678955,275.648346 1610.272583,278.420776 
   C1606.808228,268.857849 1598.376221,263.609833 1590.981934,266.082764 
   C1590.981934,266.082764 1590.898193,266.033356 1590.960693,265.506653 
   C1590.886475,265.346161 1590.812256,265.185699 1590.807007,264.228790 
   C1588.196777,249.471695 1585.614624,234.709579 1582.965942,219.959396 
   C1581.392700,211.197388 1576.848755,204.351303 1569.851807,198.817886 
   C1567.244873,196.756210 1565.548462,193.543015 1563.436768,190.854935 
   C1564.049683,190.325424 1564.662476,189.795929 1565.275391,189.266434 
   C1569.764404,194.078217 1574.253296,198.890015 1579.261963,204.258789 
   C1582.734009,195.947006 1586.051025,188.006332 1589.368042,180.065643 
   C1589.012207,179.759705 1588.656494,179.453766 1588.300781,179.147827 
   C1584.037598,180.994278 1580.159790,180.996735 1576.853516,177.479858 
   C1575.148560,175.666245 1573.445190,173.716019 1571.368164,172.429779 
   C1569.709106,171.402374 1567.485962,171.285797 1564.666748,170.541473 
   C1565.204834,172.615936 1565.294312,173.261246 1565.535522,173.843674 
   C1567.583008,178.788071 1564.340820,181.761642 1561.229980,184.491760 
   C1560.423828,185.199280 1557.831055,185.068100 1556.968018,184.300278 
   C1553.740845,181.428696 1554.289185,177.790497 1555.852905,174.200562 
   C1556.185181,173.437881 1556.455566,172.623337 1556.921265,171.947601 
   C1560.319580,167.016296 1563.822876,162.156281 1567.163696,157.186905 
   C1569.048340,154.383575 1570.688110,151.415588 1572.438232,148.521790 
   C1571.945679,148.146484 1571.453125,147.771179 1570.960571,147.395874 
   C1569.262695,149.374130 1566.843872,151.086731 1565.999268,153.379318 
   C1563.221191,160.920197 1557.920654,164.723999 1550.264282,166.349243 
   C1546.017456,167.250763 1542.569458,170.014664 1542.089478,174.756256 
   C1541.637207,179.224228 1544.697510,181.673004 1548.293945,183.522491 
   C1548.738159,183.750900 1549.310547,183.892944 1549.587280,184.255341 
   C1550.620972,185.609543 1552.631714,187.422028 1552.298340,188.357986 
   C1551.661133,190.146622 1549.888062,192.396301 1548.198608,192.790070 
   C1541.888306,194.260864 1535.405518,194.974167 1529.019287,196.144684 
   C1526.053101,196.688339 1524.622192,195.249435 1524.998779,192.737396 
   C1525.551514,189.052292 1526.808716,185.472855 1527.868530,181.459824 
   C1526.416626,182.283936 1524.009277,182.929214 1522.772583,184.497635 
   C1519.928345,188.104614 1517.716797,192.203903 1515.111084,196.009308 
   C1514.296143,197.199524 1513.171265,198.790573 1511.969727,199.011856 
   C1491.858643,202.715591 1471.712769,206.231049 1451.638916,209.777008 
   C1450.107788,205.986145 1447.155273,205.749832 1444.219238,208.690262 
   C1442.939209,209.972229 1441.361572,211.506592 1439.718872,211.798111 
   C1347.077271,228.237762 1254.410767,244.536499 1161.765381,260.954956 
   C1157.181152,261.767334 1153.021851,262.698303 1150.311890,257.622192 
   C1150.099121,257.223724 1149.195801,257.194000 1147.659302,256.663452 
   C1148.351318,259.167511 1148.838745,260.931458 1149.420898,263.038269 
   C1121.077026,268.036560 1092.745239,273.032715 1064.306641,278.047699 
   C1062.695190,274.881378 1061.215454,271.973846 1059.342773,268.294220 
   C1057.820190,272.518127 1057.510498,276.940399 1055.148560,278.945160 
   C1052.701294,281.022522 1048.292114,281.117859 1044.688965,281.389709 
   C1042.249512,281.573761 1039.666016,280.746002 1037.249878,280.025452 
   C1035.645264,279.546936 1034.222412,278.458649 1031.439453,276.950134 
   C1032.766235,279.888458 1033.449951,281.402466 1034.225586,283.120270 
   C1033.145508,283.435699 1032.539429,283.676605 1031.910278,283.787354 
   C966.472656,295.306335 901.029236,306.792603 835.594971,318.330231 
   C719.486023,338.803070 603.396301,359.385590 487.251434,379.652252 
   C477.643646,381.328766 468.157623,385.465454 457.998566,382.918671 
   C457.432526,382.776764 456.639099,383.677582 455.913269,384.009460 
   C454.308868,384.742950 452.742310,385.770447 451.052002,386.073395 
   C423.025208,391.096832 394.976654,395.998810 366.943420,400.986572 
   C346.620819,404.602417 326.313446,408.304077 305.478455,411.862396 
   C305.319855,411.941254 305.161285,412.020111 304.193604,412.050140 
   C301.778107,412.507111 299.362610,412.964081 296.412750,413.313110 
   C295.921722,413.525421 295.430695,413.737732 294.972290,414.003143 
M1429.201050,800.772522 
   C1435.002441,795.843567 1440.731934,790.825500 1446.653809,786.045898 
   C1448.210449,784.789490 1450.390137,784.325989 1452.233398,783.398315 
   C1453.930054,782.544556 1456.804199,781.710693 1456.943115,780.592834 
   C1457.187622,778.624329 1455.742920,776.446106 1455.005615,774.355652 
   C1453.216187,775.287781 1450.870483,775.785706 1449.740479,777.232788 
   C1446.229980,781.728333 1441.499146,783.307007 1436.229736,783.771240 
   C1429.563232,784.358582 1427.164307,782.117615 1426.814087,775.447449 
   C1426.758179,774.381653 1426.545288,773.323975 1426.204224,770.743225 
   C1424.630859,773.540588 1424.015503,775.279114 1422.869995,776.533325 
   C1420.664185,778.948303 1416.778320,783.411560 1416.008911,782.954224 
   C1412.031616,780.590210 1414.540283,776.698181 1415.864746,773.769348 
   C1420.120728,764.358215 1413.582520,760.627808 1407.050415,759.310242 
   C1405.036499,758.904053 1402.603394,760.575439 1400.362183,761.295410 
   C1401.433228,763.006897 1402.252197,764.965820 1403.633301,766.373047 
   C1405.818604,768.599792 1408.380493,770.456909 1410.782959,772.470398 
   C1410.756958,772.936218 1410.730835,773.401978 1410.704834,773.867798 
   C1402.410156,774.183655 1400.970825,778.843811 1401.697754,785.535461 
   C1401.886353,787.269897 1399.606445,789.218567 1398.621704,791.151123 
   C1397.816284,792.731689 1397.251221,794.434753 1396.579712,796.083557 
   C1398.019775,795.968323 1399.627441,796.229919 1400.872681,795.676453 
   C1408.791382,792.156494 1419.102051,794.322815 1424.349243,800.477722 
   C1425.035522,801.282837 1427.101929,800.911377 1429.201050,800.772522 
M1333.210327,197.330383 
   C1329.297852,194.993088 1325.385498,192.655792 1322.547729,190.960464 
   C1316.730469,196.115265 1311.890137,200.404419 1306.931885,204.798096 
   C1311.065674,205.501907 1315.070190,205.862686 1318.885498,206.911392 
   C1324.108032,208.346817 1324.776978,211.068146 1321.440552,215.236038 
   C1320.413574,216.518860 1319.063110,217.852264 1318.781494,219.337158 
   C1318.486206,220.894287 1319.296753,222.661102 1319.624023,224.336273 
   C1321.082275,223.921402 1323.021729,223.963913 1323.921021,223.017746 
   C1329.934814,216.691406 1335.726807,210.154205 1341.924927,203.317032 
   C1338.695557,201.102829 1336.248779,199.425262 1333.210327,197.330383 
M254.368668,562.096924 
   C258.181396,566.477234 261.720306,571.150574 265.941071,575.094177 
   C267.639648,576.681274 270.833282,576.668213 273.346436,577.383423 
   C272.979462,574.427307 272.738190,571.446533 272.165222,568.530884 
   C272.003326,567.707031 270.719116,567.166565 270.212799,566.335754 
   C268.717957,563.882629 266.816071,561.496094 266.084137,558.808228 
   C265.270905,555.821777 265.948914,552.447144 265.263672,549.402222 
   C264.746735,547.105408 263.105560,545.061523 261.964783,542.905090 
   C260.589111,544.862183 259.221222,546.824829 257.828369,548.769592 
   C257.647552,549.022095 257.338257,549.182434 256.731262,549.675781 
   C254.005020,548.615601 251.072052,547.475098 248.139084,546.334595 
   C247.777542,546.679321 247.416000,547.024109 247.054459,547.368896 
   C249.369827,552.070251 251.685196,556.771667 254.368668,562.096924 
M340.408813,924.938721 
   C334.912354,928.494690 332.505249,934.101929 330.130585,939.866760 
   C327.836517,945.435913 325.027771,950.793030 322.489014,956.150635 
   C328.322571,958.137817 334.169678,957.711121 337.723175,953.468994 
   C340.984344,949.575989 343.441589,944.962708 345.888916,940.468445 
   C346.485504,939.372986 345.772064,937.475586 345.454163,935.991150 
   C344.962463,933.695435 344.115509,931.465637 343.753204,929.155518 
   C343.447723,927.207581 344.778656,924.507874 340.408813,924.938721 
M850.192444,289.008026 
   C859.445129,283.649628 868.230713,282.108643 875.609680,292.111603 
   C875.705078,292.240967 875.850342,292.376801 875.997864,292.417969 
   C878.207458,293.035065 880.421448,293.636688 882.634338,294.242004 
   C881.976746,291.891296 881.353821,289.529816 880.629883,287.199738 
   C880.499023,286.778656 879.973694,286.462830 879.596191,286.138245 
   C874.936035,282.131165 870.241943,278.163086 865.615540,274.117584 
   C863.048218,271.872589 860.891479,272.969025 859.157166,275.022156 
   C855.618286,279.211670 852.281799,283.572968 848.925537,287.912689 
   C848.802368,288.071960 849.347961,288.748413 850.192444,289.008026 
M1364.970337,192.571487 
   C1361.182251,194.414597 1359.424561,192.560440 1358.526855,189.063980 
   C1357.979736,186.933548 1357.369263,184.819427 1356.671143,182.274643 
   C1350.306152,190.463379 1350.549683,194.097427 1357.362793,200.151123 
   C1358.479370,201.143280 1359.488037,202.371063 1360.781128,203.028458 
   C1364.145996,204.739075 1367.641479,206.193024 1371.084839,207.749405 
   C1370.812012,203.129089 1370.742554,198.478745 1370.068604,193.917709 
   C1369.954834,193.148071 1367.234375,192.763641 1364.970337,192.571487 
M1634.678589,406.936707 
   C1635.096313,405.169281 1635.708862,403.422211 1635.895508,401.630707 
   C1636.484497,395.982697 1634.564331,393.322784 1628.963745,392.045959 
   C1626.714966,391.533295 1624.390503,391.157532 1622.091797,391.105469 
   C1618.670410,391.027985 1617.251465,392.835907 1619.357422,395.765686 
   C1622.239746,399.775787 1625.383301,403.656982 1628.872437,407.134552 
   C1629.857544,408.116516 1632.440796,407.495331 1634.678589,406.936707 
M1110.971069,239.738602 
   C1109.833740,240.033203 1108.696411,240.327805 1107.559082,240.622375 
   C1108.092773,241.919754 1108.306396,243.537964 1109.222778,244.451599 
   C1111.553833,246.775909 1114.438477,248.565475 1116.652954,250.980484 
   C1118.995605,253.535431 1120.620605,256.748779 1122.965454,259.301178 
   C1123.947998,260.370575 1125.976685,260.478882 1127.529663,261.024139 
   C1128.172485,259.135193 1129.421753,257.197327 1129.274536,255.372147 
   C1129.147217,253.795059 1127.722412,251.980484 1126.367432,250.906296 
   C1121.579468,247.110641 1116.575317,243.587814 1110.971069,239.738602 
M240.357529,526.432556 
   C243.530563,527.772217 246.623993,529.839966 249.904266,530.198547 
   C252.136078,530.442566 256.137238,528.763672 256.654694,527.104797 
   C257.442871,524.578064 256.448822,521.017090 255.061829,518.538818 
   C254.547714,517.620239 250.637024,518.045532 248.546844,518.710815 
   C246.084229,519.494629 243.791946,521.035034 241.677826,522.590027 
   C240.752335,523.270752 240.451874,524.801270 240.357529,526.432556 
M369.867645,384.510254 
   C371.678589,387.447296 373.341736,390.494171 375.392487,393.252930 
   C375.990631,394.057587 377.957855,394.623077 378.815552,394.234497 
   C379.651611,393.855652 380.564453,391.856903 380.250031,391.015289 
   C378.016174,385.035706 375.548584,379.138062 372.938995,373.311249 
   C372.621674,372.602753 370.795410,371.925781 370.064636,372.219543 
   C369.260254,372.542969 368.408386,374.033264 368.460876,374.965424 
   C368.626587,377.909210 369.205780,380.829681 369.867645,384.510254 
M1479.283081,179.968781 
   C1472.508667,182.312546 1466.962524,186.300262 1463.051392,192.358063 
   C1462.492065,193.224487 1461.983032,194.955521 1462.411011,195.442917 
   C1463.151367,196.285812 1464.803467,197.061752 1465.786865,196.798141 
   C1467.589966,196.314728 1469.920532,195.470749 1470.729736,194.040710 
   C1473.220581,189.638306 1476.354248,186.485611 1481.339355,185.032150 
   C1482.497681,184.694412 1483.157837,182.648254 1484.047363,181.388275 
   C1482.699097,180.862717 1481.350830,180.337158 1479.283081,179.968781 
M917.051575,874.825439 
   C910.698975,868.254456 902.669617,865.163452 893.607788,863.696533 
   C894.875488,866.081299 896.726257,867.695862 898.737793,869.075989 
   C903.514160,872.353027 908.214294,875.792969 913.260986,878.599365 
   C915.380859,879.778198 919.071594,880.540649 917.051575,874.825439 
M1242.998169,222.070679 
   C1240.924194,220.626953 1238.883179,219.125153 1236.696045,217.880112 
   C1236.561157,217.803345 1234.748657,219.683746 1234.608643,220.768616 
   C1233.776123,227.222412 1233.206055,233.710114 1232.547852,240.186417 
   C1233.258057,240.411163 1233.968140,240.635895 1234.678345,240.860641 
   C1237.499512,234.856293 1240.320801,228.851944 1242.998169,222.070679 
M1615.657349,237.916489 
   C1612.329712,236.365814 1611.625488,239.056808 1611.676880,241.021332 
   C1611.792358,245.433151 1612.424194,249.847427 1613.121704,254.216690 
   C1613.293213,255.291245 1614.476685,256.940033 1615.278687,256.990356 
   C1616.262573,257.052155 1617.560181,255.895935 1618.330200,254.947861 
   C1622.081055,250.329880 1621.319092,244.699081 1615.657349,237.916489 
M1298.011963,796.083740 
   C1298.956909,797.255249 1299.901855,798.426819 1300.846924,799.598328 
   C1301.293945,799.468872 1301.740967,799.339355 1302.187988,799.209900 
   C1302.397827,796.835083 1302.228394,794.343018 1302.916504,792.115967 
   C1303.731201,789.478943 1305.443726,787.132812 1306.465698,784.541748 
   C1307.201294,782.676880 1307.443726,780.617371 1307.906006,778.644653 
   C1305.932007,778.994934 1303.236816,778.651489 1302.113159,779.819702 
   C1298.006714,784.088867 1296.321899,789.367737 1298.011963,796.083740 
M1670.862305,732.051697 
   C1669.683105,733.438110 1668.503906,734.824524 1667.324829,736.210938 
   C1668.903320,737.252197 1670.360596,738.830139 1672.086182,739.219299 
   C1675.359619,739.957581 1678.786743,740.014465 1682.159424,740.355347 
   C1682.572510,732.177307 1679.639160,730.027832 1670.862305,732.051697 
M1711.693237,692.512634 
   C1709.706787,691.726196 1707.724731,690.324463 1705.732666,690.309937 
   C1703.278198,690.291931 1700.815186,691.429565 1698.355957,692.067749 
   C1700.332520,694.234375 1701.972168,697.008301 1704.402710,698.357056 
   C1706.058472,699.275879 1708.950928,698.600830 1711.053589,697.913757 
   C1713.493530,697.116394 1714.371338,695.223022 1711.693237,692.512634 
M1667.035156,644.618591 
   C1667.654907,646.150879 1668.274536,647.683228 1668.894165,649.215576 
   C1670.609497,648.511719 1673.296509,648.242554 1673.844971,647.016663 
   C1675.125000,644.155212 1675.588501,640.855286 1675.907349,637.685120 
   C1675.994507,636.817383 1674.520630,635.189026 1673.542480,634.989319 
   C1672.583984,634.793457 1670.859253,635.689331 1670.324341,636.604736 
   C1669.007324,638.858887 1668.180298,641.399597 1667.035156,644.618591 
M1071.389771,848.843018 
   C1070.331299,849.809387 1068.896362,850.583191 1068.284912,851.778320 
   C1066.477173,855.311340 1069.420776,861.368164 1072.631470,861.140015 
   C1075.103516,860.964355 1078.318237,855.738403 1078.185547,852.019165 
   C1078.028687,847.625488 1074.964478,848.328857 1071.389771,848.843018 
M1628.124146,211.683762 
   C1625.590332,208.047333 1622.502808,208.488113 1619.660278,210.974777 
   C1616.777466,213.496674 1617.367554,219.425095 1621.445679,224.017685 
   C1623.838989,219.926834 1626.020508,216.197998 1628.124146,211.683762 
M1626.145874,719.881714 
   C1620.375122,720.075684 1615.211914,721.569397 1611.045654,726.320496 
   C1616.425415,728.786804 1626.296509,726.070496 1630.700806,721.077759 
   C1629.308594,720.640259 1628.135254,720.271423 1626.145874,719.881714 
M308.401245,893.048462 
   C307.944153,894.132080 307.487061,895.215698 306.943542,896.504211 
   C314.531799,896.905762 321.452148,891.138123 320.652679,885.160095 
   C315.478210,885.690369 312.151337,889.056824 308.401245,893.048462 
M1267.873535,794.923096 
   C1267.332520,794.456909 1266.791504,793.990784 1266.250610,793.524597 
   C1265.866943,793.707520 1265.483154,793.890442 1265.099487,794.073303 
   C1266.111450,798.180847 1266.841675,802.392273 1268.297729,806.335876 
   C1268.766846,807.606628 1271.146606,808.172058 1272.652344,809.060181 
   C1272.901733,807.373413 1273.832153,805.396851 1273.262573,804.058716 
   C1271.976196,801.037170 1269.948364,798.331299 1267.873535,794.923096 
M1697.360718,712.324280 
   C1696.339233,713.462341 1694.310059,715.446777 1694.447998,715.610840 
   C1696.350098,717.875366 1698.416992,720.064331 1700.762817,721.852905 
   C1701.421875,722.355408 1703.115112,721.501526 1704.333984,721.269836 
   C1703.722656,719.337402 1703.480713,717.152832 1702.386841,715.549622 
   C1701.456055,714.185486 1699.543823,713.491028 1697.360718,712.324280 
M1643.310059,724.245911 
   C1643.795532,724.357483 1644.359619,724.686707 1644.753174,724.544250 
   C1646.881104,723.774048 1648.966797,722.887146 1651.067017,722.040588 
   C1649.025513,720.703003 1646.992920,719.351196 1644.930420,718.046875 
   C1644.687256,717.893127 1644.223633,717.881104 1643.956543,718.005737 
   C1642.283569,718.786499 1640.637329,719.624878 1638.982300,720.444214 
   C1640.173340,721.640625 1641.364380,722.837036 1643.310059,724.245911 
M348.722534,978.778442 
   C349.626617,979.926575 350.530701,981.074707 351.434814,982.222839 
   C351.925415,982.016907 352.416016,981.810974 352.906586,981.605103 
   C352.431152,978.118652 352.222015,974.561768 351.329865,971.185425 
   C351.034180,970.066467 348.981140,969.411804 347.731506,968.544922 
   C347.194336,969.918213 346.101959,971.355713 346.251740,972.649475 
   C346.469696,974.532043 347.582489,976.311035 348.722534,978.778442 
M539.723267,356.852448 
   C541.072632,356.680481 542.421997,356.508514 543.771362,356.336578 
   C543.995789,355.806000 544.220215,355.275391 544.444702,354.744812 
   C542.259033,353.041168 540.266846,350.899048 537.808167,349.814484 
   C536.533752,349.252350 534.433411,350.562988 532.706177,351.027496 
   C533.564026,352.444550 534.204285,354.083801 535.350708,355.206024 
   C536.216431,356.053528 537.729248,356.239929 539.723267,356.852448 
M1499.801758,745.500305 
   C1499.162231,747.544067 1498.522705,749.587769 1497.883179,751.631531 
   C1498.329346,751.979675 1498.775391,752.327820 1499.221436,752.676025 
   C1500.953369,751.724731 1503.210205,751.160889 1504.275513,749.717712 
   C1505.436523,748.144592 1505.492798,745.755981 1506.030640,743.722900 
   C1504.086304,744.115967 1502.141846,744.509033 1499.801758,745.500305 
M595.937073,941.091431 
   C595.450378,941.009766 594.890991,940.731018 594.489075,940.879272 
   C592.280396,941.694214 590.110229,942.613586 587.926819,943.497070 
   C590.034302,944.729004 592.134094,945.974670 594.259583,947.174683 
   C594.512878,947.317749 594.953186,947.321045 595.227539,947.203796 
   C596.792480,946.535278 598.333313,945.810608 599.882324,945.104919 
   C598.833252,943.841492 597.784180,942.578003 595.937073,941.091431 
M1425.019531,186.498672 
   C1427.030640,183.616486 1427.210938,180.822433 1424.364868,177.586258 
   C1421.954346,181.125854 1419.730469,184.391388 1417.506592,187.656921 
   C1417.735962,188.111496 1417.965454,188.566055 1418.194824,189.020630 
   C1420.300903,188.357544 1422.406860,187.694443 1425.019531,186.498672 
M1494.557617,166.084991 
   C1493.309570,165.825897 1492.061523,165.566788 1490.112183,165.162094 
   C1494.084351,171.723068 1498.746826,174.381607 1505.113403,173.133392 
   C1501.202271,170.480881 1498.196411,168.442245 1494.557617,166.084991 
M666.395508,324.314148 
   C664.074097,323.519165 661.644409,322.839661 662.865906,326.787354 
   C664.030762,330.552368 668.904480,332.480896 673.822815,330.585968 
   C671.288940,328.401855 669.133789,326.544128 666.395508,324.314148 
M242.719208,502.929260 
   C242.983337,503.702850 243.016586,504.810516 243.557159,505.184082 
   C244.895279,506.108765 246.516815,507.313263 247.923660,507.196045 
   C248.801361,507.122864 250.451691,504.187012 250.102158,503.683472 
   C248.793716,501.798767 246.804214,500.386871 245.070099,498.797699 
   C244.246643,499.933502 243.423203,501.069305 242.719208,502.929260 
M951.105347,862.595154 
   C950.004578,865.014648 949.295776,867.586182 952.486328,868.729675 
   C953.554382,869.112427 956.312561,867.982727 956.408325,867.284973 
   C956.716675,865.037354 956.144104,862.668884 955.911194,860.347107 
   C954.446411,860.872803 952.981567,861.398560 951.105347,862.595154 
M1449.480591,177.969345 
   C1448.466431,179.776367 1447.238037,181.506851 1446.550293,183.430603 
   C1446.298706,184.134232 1447.330200,185.296539 1447.776367,186.249527 
   C1449.435791,184.697510 1451.578125,183.408691 1452.610352,181.514877 
   C1453.529541,179.828781 1453.971802,176.973999 1449.480591,177.969345 
M1364.317627,794.023804 
   C1364.196289,795.399658 1364.074951,796.775574 1363.848999,799.337036 
   C1366.716064,797.819336 1368.724243,797.080627 1370.292358,795.802124 
   C1371.202759,795.059875 1371.406250,793.450562 1371.927856,792.231445 
   C1370.579468,791.854858 1369.173218,791.054260 1367.899902,791.228088 
   C1366.760132,791.383606 1365.767090,792.612793 1364.317627,794.023804 
M1527.541016,741.144348 
   C1526.682373,737.078552 1524.139893,738.670105 1522.684204,740.183655 
   C1521.295166,741.627747 1520.829834,743.960266 1519.958496,745.902283 
   C1521.801758,745.506104 1523.752075,745.354004 1525.444092,744.612549 
   C1526.311523,744.232361 1526.717773,742.799133 1527.541016,741.144348 
M1701.731323,668.858093 
   C1702.779785,670.255249 1703.581055,672.154053 1704.978638,672.843079 
   C1705.704590,673.200989 1708.003784,671.690308 1708.614014,670.515625 
   C1709.052734,669.671021 1707.852295,666.862793 1707.417969,666.870667 
   C1705.588623,666.903625 1703.772339,667.665161 1701.731323,668.858093 
M1393.880249,786.053955 
   C1392.862183,784.467285 1392.023071,782.690125 1390.690552,781.437927 
   C1390.385864,781.151611 1387.811035,782.404846 1387.318359,783.442200 
   C1386.831177,784.468201 1387.552490,787.265747 1387.922241,787.299072 
   C1389.760498,787.464172 1391.657837,786.973267 1393.880249,786.053955 
M1600.024170,222.475800 
   C1598.985352,224.275482 1597.732422,225.995804 1597.020386,227.916626 
   C1596.760864,228.616638 1597.788208,229.793716 1598.228394,230.753159 
   C1599.824707,229.322861 1601.918457,228.157822 1602.865723,226.381393 
   C1603.678345,224.857651 1604.677368,221.827911 1600.024170,222.475800 
M453.640198,940.779114 
   C452.199921,941.881287 450.736237,942.957092 449.382965,944.157349 
   C449.309265,944.222717 450.207275,945.890991 450.568298,945.858948 
   C453.377960,945.609741 456.166779,945.126282 458.962067,944.715271 
   C458.982361,944.049377 459.002655,943.383545 459.022949,942.717651 
   C457.497742,942.067810 455.972504,941.417908 453.640198,940.779114 
M1106.896606,227.546280 
   C1105.992065,226.673325 1105.226318,225.474854 1104.145996,225.013718 
   C1102.926147,224.493073 1101.406494,224.483200 1100.059326,224.632080 
   C1099.822388,224.658279 1099.309692,227.036850 1099.763672,227.491974 
   C1100.797974,228.529037 1102.289917,229.379425 1103.720215,229.651123 
   C1104.584351,229.815262 1105.681152,228.753510 1106.896606,227.546280 
M1492.628296,763.856567 
   C1491.033081,764.553467 1488.706055,764.854309 1488.052979,766.061096 
   C1487.428833,767.214172 1488.546997,769.310059 1488.899170,770.991516 
   C1489.604858,771.036682 1490.310547,771.081909 1491.016357,771.127075 
   C1491.705688,768.863281 1492.395142,766.599548 1492.628296,763.856567 
M532.357849,931.359680 
   C531.860229,928.089355 529.792480,928.645325 528.176941,930.045288 
   C525.676147,932.212646 526.252808,934.520203 529.144775,937.051941 
   C530.274353,935.204529 531.247498,933.612854 532.357849,931.359680 
M1198.825073,822.985535 
   C1198.078369,827.002380 1201.022461,827.774963 1203.711426,828.949829 
   C1205.767578,823.894409 1204.581299,822.254578 1198.825073,822.985535 
M1256.286743,813.912659 
   C1255.990112,812.312500 1255.693604,810.712402 1255.396973,809.112244 
   C1254.711792,808.986023 1254.026611,808.859802 1253.341431,808.733521 
   C1252.607300,811.059937 1251.873291,813.386353 1251.139038,815.712708 
   C1252.766113,815.332214 1254.393311,814.951782 1256.286743,813.912659 
M1689.986450,727.168579 
   C1691.541870,728.605591 1693.097290,730.042603 1694.652832,731.479675 
   C1695.246216,731.127197 1695.839600,730.774780 1696.432983,730.422363 
   C1695.763550,728.575012 1695.383545,726.504150 1694.239746,725.023132 
   C1693.916992,724.605286 1691.358032,725.914368 1689.986450,727.168579 
M1405.687256,184.535065 
   C1406.350098,185.431015 1407.012939,186.326965 1407.675781,187.222885 
   C1408.805420,186.382446 1410.668945,185.698898 1410.890137,184.664261 
   C1411.210083,183.167679 1410.340820,181.416840 1409.978882,179.774475 
   C1409.487183,179.792053 1408.995483,179.809631 1408.503784,179.827209 
   C1407.634888,181.182938 1406.765869,182.538666 1405.687256,184.535065 
M308.848328,391.255096 
   C307.488922,392.490723 306.129517,393.726349 304.404755,395.294067 
   C310.153412,396.484070 312.075348,395.192749 313.204254,389.617981 
   C311.779968,390.096039 310.633728,390.480804 308.848328,391.255096 
M1597.266602,206.254913 
   C1597.872437,209.060730 1598.478394,211.866531 1599.084229,214.672348 
   C1599.772705,214.497498 1600.461182,214.322647 1601.149658,214.147797 
   C1600.482910,211.259995 1599.838013,208.366394 1599.075562,205.504059 
   C1599.042603,205.380127 1597.929565,205.543945 1597.266602,206.254913 
z"
        />
        <path
            fill="#FAF0EF"
            opacity="1.000000"
            stroke="none"
            d="
M877.431030,902.014404 
   C876.232178,894.744324 876.232178,894.744324 867.009460,893.265808 
   C869.368469,892.422607 871.083374,892.231750 872.196411,891.312317 
   C873.842896,889.952148 875.061768,888.074524 876.462646,886.417297 
   C874.748352,885.880737 873.034119,885.344177 870.911743,884.679871 
   C870.009949,879.144653 872.331299,877.108887 878.292297,879.237793 
   C886.185669,882.056824 887.933167,886.556396 885.747009,898.001099 
   C885.643982,898.540222 886.673645,899.295715 887.584961,900.005005 
   C884.620544,900.719727 881.247009,901.379883 877.431030,902.014404 
z"
        />
        <path
            fill="#F9E8E6"
            opacity="1.000000"
            stroke="none"
            d="
M957.939514,887.868591 
   C956.996765,885.675476 955.310852,882.490784 956.105896,881.648987 
   C958.323547,879.300720 961.569397,877.923462 964.409302,876.162842 
   C964.839722,876.590332 965.270142,877.017883 965.700623,877.445374 
   C964.493042,879.643433 963.188721,881.796814 962.138611,884.067627 
   C961.833191,884.727905 962.254517,885.724365 962.700562,886.689087 
   C961.481750,887.189697 959.905212,887.567993 957.939514,887.868591 
z"
        />
        <path
            fill="#D93E46"
            opacity="1.000000"
            stroke="none"
            d="
M1284.549438,826.604614 
   C1283.792603,828.083374 1283.111206,829.890930 1281.969360,830.255371 
   C1279.053955,831.185730 1275.933105,831.472656 1272.587524,832.071960 
   C1275.880371,825.312378 1279.829712,823.505432 1284.549438,826.604614 
z"
        />
        <path
            fill="#F9E8E6"
            opacity="1.000000"
            stroke="none"
            d="
M972.142517,885.346985 
   C972.519531,885.007202 973.032227,884.853577 973.802002,884.786011 
   C973.465515,885.092407 972.871826,885.312805 972.142517,885.346985 
z"
        />
        <path
            fill="#F9E8E6"
            opacity="1.000000"
            stroke="none"
            d="
M966.101562,886.324829 
   C966.160889,886.104675 966.322632,886.033142 966.743652,886.018311 
   C966.736633,886.207886 966.470398,886.340637 966.101562,886.324829 
z"
        />
        <path
            fill="#F9E8E6"
            opacity="1.000000"
            stroke="none"
            d="
M968.088867,885.993652 
   C968.048950,885.712463 968.250793,885.588257 968.692627,885.682922 
   C968.685303,885.933960 968.484680,886.016907 968.088867,885.993652 
z"
        />
        <path
            fill="#FFFEFE"
            opacity="1.000000"
            stroke="none"
            d="
M305.999298,411.967468 
   C326.313446,408.304077 346.620819,404.602417 366.943420,400.986572 
   C394.976654,395.998810 423.025208,391.096832 451.052002,386.073395 
   C452.742310,385.770447 454.308868,384.742950 455.913269,384.009460 
   C456.639099,383.677582 457.432526,382.776764 457.998566,382.918671 
   C468.157623,385.465454 477.643646,381.328766 487.251434,379.652252 
   C603.396301,359.385590 719.486023,338.803070 835.594971,318.330231 
   C901.029236,306.792603 966.472656,295.306335 1031.910278,283.787354 
   C1032.539429,283.676605 1033.145508,283.435699 1034.225586,283.120270 
   C1033.449951,281.402466 1032.766235,279.888458 1031.439453,276.950134 
   C1034.222412,278.458649 1035.645264,279.546936 1037.249878,280.025452 
   C1039.666016,280.746002 1042.249512,281.573761 1044.688965,281.389709 
   C1048.292114,281.117859 1052.701294,281.022522 1055.148560,278.945160 
   C1057.510498,276.940399 1057.820190,272.518127 1059.342773,268.294220 
   C1061.215454,271.973846 1062.695190,274.881378 1064.306641,278.047699 
   C1092.745239,273.032715 1121.077026,268.036560 1149.420898,263.038269 
   C1148.838745,260.931458 1148.351318,259.167511 1147.659302,256.663452 
   C1149.195801,257.194000 1150.099121,257.223724 1150.311890,257.622192 
   C1153.021851,262.698303 1157.181152,261.767334 1161.765381,260.954956 
   C1254.410767,244.536499 1347.077271,228.237762 1439.718872,211.798111 
   C1441.361572,211.506592 1442.939209,209.972229 1444.219238,208.690262 
   C1447.155273,205.749832 1450.107788,205.986145 1451.638916,209.777008 
   C1471.712769,206.231049 1491.858643,202.715591 1511.969727,199.011856 
   C1513.171265,198.790573 1514.296143,197.199524 1515.111084,196.009308 
   C1517.716797,192.203903 1519.928345,188.104614 1522.772583,184.497635 
   C1524.009277,182.929214 1526.416626,182.283936 1527.868530,181.459824 
   C1526.808716,185.472855 1525.551514,189.052292 1524.998779,192.737396 
   C1524.622192,195.249435 1526.053101,196.688339 1529.019287,196.144684 
   C1535.405518,194.974167 1541.888306,194.260864 1548.198608,192.790070 
   C1549.888062,192.396301 1551.661133,190.146622 1552.298340,188.357986 
   C1552.631714,187.422028 1550.620972,185.609543 1549.587280,184.255341 
   C1549.310547,183.892944 1548.738159,183.750900 1548.293945,183.522491 
   C1544.697510,181.673004 1541.637207,179.224228 1542.089478,174.756256 
   C1542.569458,170.014664 1546.017456,167.250763 1550.264282,166.349243 
   C1557.920654,164.723999 1563.221191,160.920197 1565.999268,153.379318 
   C1566.843872,151.086731 1569.262695,149.374130 1570.960571,147.395874 
   C1571.453125,147.771179 1571.945679,148.146484 1572.438232,148.521790 
   C1570.688110,151.415588 1569.048340,154.383575 1567.163696,157.186905 
   C1563.822876,162.156281 1560.319580,167.016296 1556.921265,171.947601 
   C1556.455566,172.623337 1556.185181,173.437881 1555.852905,174.200562 
   C1554.289185,177.790497 1553.740845,181.428696 1556.968018,184.300278 
   C1557.831055,185.068100 1560.423828,185.199280 1561.229980,184.491760 
   C1564.340820,181.761642 1567.583008,178.788071 1565.535522,173.843674 
   C1565.294312,173.261246 1565.204834,172.615936 1564.666748,170.541473 
   C1567.485962,171.285797 1569.709106,171.402374 1571.368164,172.429779 
   C1573.445190,173.716019 1575.148560,175.666245 1576.853516,177.479858 
   C1580.159790,180.996735 1584.037598,180.994278 1588.300781,179.147827 
   C1588.656494,179.453766 1589.012207,179.759705 1589.368042,180.065643 
   C1586.051025,188.006332 1582.734009,195.947006 1579.261963,204.258789 
   C1574.253296,198.890015 1569.764404,194.078217 1565.275391,189.266434 
   C1564.662476,189.795929 1564.049683,190.325424 1563.436768,190.854935 
   C1565.548462,193.543015 1567.244873,196.756210 1569.851807,198.817886 
   C1576.848755,204.351303 1581.392700,211.197388 1582.965942,219.959396 
   C1585.614624,234.709579 1588.196777,249.471695 1590.637329,264.738037 
   C1590.611206,265.509277 1590.754761,265.771301 1590.898193,266.033325 
   C1590.898193,266.033356 1590.981934,266.082764 1590.952148,266.511719 
   C1591.259644,268.623840 1591.596924,270.307037 1591.934204,271.990265 
   C1594.110352,283.946655 1596.144409,295.931946 1598.564575,307.838776 
   C1599.156738,310.751984 1601.012695,313.408325 1602.244385,316.088776 
   C1601.934082,318.233063 1600.872314,320.908081 1601.295166,323.323334 
   C1616.484253,410.109436 1631.811035,496.871490 1647.128418,583.635193 
   C1647.359619,584.944458 1648.049316,586.257629 1647.954346,587.526306 
   C1647.122559,598.636047 1653.372803,604.089539 1663.055786,607.168762 
   C1666.214844,608.173340 1668.509033,608.686829 1670.831177,605.406677 
   C1671.699707,604.179749 1675.087524,603.439270 1676.528687,604.133423 
   C1683.839966,607.655212 1686.750122,619.002197 1682.285034,625.833496 
   C1681.394653,627.195740 1679.474487,628.806946 1678.155640,628.702820 
   C1676.887695,628.602722 1675.100708,626.605957 1674.715820,625.135254 
   C1671.662842,613.469849 1662.301514,610.002136 1651.437500,606.737793 
   C1652.105713,611.079773 1652.600464,614.748352 1653.242432,618.391113 
   C1656.679688,637.894897 1660.010742,657.419067 1663.669678,676.881470 
   C1665.776978,688.091125 1662.699097,698.397278 1655.568359,706.142151 
   C1651.023560,711.078308 1643.135864,714.258118 1636.252319,715.514038 
   C1571.243774,727.374695 1506.126831,738.639404 1441.045410,750.100769 
   C1368.713135,762.839111 1296.384888,775.601196 1224.055664,788.356628 
   C1156.811401,800.215332 1089.568237,812.080383 1022.324036,823.939575 
   C1008.875610,826.311279 995.408875,828.586365 981.996582,831.147034 
   C980.438293,831.444458 979.169250,833.282715 977.777466,834.420471 
   C976.329712,835.604004 974.895630,836.804321 973.455444,837.997192 
   C972.809692,836.468811 972.164001,834.940369 971.314392,832.929565 
   C952.525696,836.243469 933.407227,839.615601 914.590393,842.934448 
   C915.498474,852.389526 921.491516,857.300720 928.896484,861.164246 
   C931.083130,862.305176 933.337952,863.712280 934.868896,865.568665 
   C935.834045,866.738953 936.315735,869.893433 935.593140,870.520447 
   C934.322083,871.623657 931.484375,872.364868 930.160522,871.655945 
   C927.614197,870.292236 925.567261,867.906616 923.493347,865.769958 
   C919.895325,862.063171 916.708923,857.967957 910.491638,858.814941 
   C909.446472,858.957275 907.208618,856.483154 906.947388,854.971741 
   C906.393250,851.764709 906.778381,848.395386 906.778381,844.259033 
   C901.039001,846.264099 894.429565,844.063171 889.907166,849.729309 
   C889.484924,850.258301 888.129517,850.224487 887.229492,850.156860 
   C884.265564,849.934448 881.224915,848.972046 878.370239,849.382385 
   C869.482910,850.659912 860.667480,852.437866 852.771179,853.852112 
   C851.667664,856.688049 850.932983,858.921448 849.867188,860.983704 
   C849.684204,861.337891 848.090881,860.963135 847.149048,860.925171 
   C847.277222,859.095093 847.405396,857.265076 847.576782,854.818787 
   C840.607971,856.013611 833.348206,857.229553 826.099304,858.506958 
   C808.385071,861.628296 790.700378,864.929993 772.946167,867.802856 
   C768.832642,868.468506 766.244690,869.807495 765.124695,873.942200 
   C764.540283,876.099426 763.283142,878.074402 761.985840,880.874146 
   C760.477478,878.917969 759.127014,877.794983 758.598816,876.367676 
   C756.770935,871.428467 753.562073,871.163635 748.819946,872.008118 
   C655.030640,888.710693 561.225952,905.329895 467.362335,921.607849 
   C460.188080,922.851990 463.902283,927.643066 462.121033,931.771240 
   C460.448120,928.774292 459.101471,926.361755 457.512512,923.515259 
   C444.251617,925.843384 430.825775,928.194824 417.402100,930.558716 
   C403.951385,932.927307 390.517242,935.393860 377.049774,937.662903 
   C358.633453,940.765747 342.235413,929.140808 338.985504,910.730347 
   C321.585571,812.162170 304.213867,713.589050 286.794678,615.024292 
   C286.365326,612.594910 286.723511,609.752380 283.254333,608.596008 
   C282.538727,608.357422 282.674957,605.070068 282.709747,603.193359 
   C282.757751,600.605286 283.635986,597.926392 283.206635,595.451477 
   C275.065247,548.521851 266.780579,501.617065 258.532562,454.705933 
   C255.508728,437.507690 263.027435,423.803406 279.905975,417.011047 
   C281.403656,416.708221 282.200165,416.382294 282.996674,416.056396 
   C283.335480,415.946045 283.674286,415.835724 284.725861,415.837769 
   C288.618958,415.280487 291.799255,414.610870 294.979584,413.941223 
   C294.979584,413.941223 294.972290,414.003143 295.228943,414.018555 
   C295.972778,413.829651 296.459930,413.625366 296.947113,413.421051 
   C299.362610,412.964081 301.778107,412.507111 304.701324,412.229889 
   C305.472473,412.262238 305.735870,412.114868 305.999298,411.967468 
M877.044495,680.424744 
   C877.038269,680.629883 877.032104,680.835083 877.004028,680.996338 
   C877.004028,680.996338 877.044312,681.024963 876.294800,680.998901 
   C875.306274,681.234985 874.317749,681.471008 873.329224,681.707092 
   C873.393005,682.309326 873.456787,682.911499 873.520569,683.513672 
   C875.029724,683.298340 876.538818,683.083008 878.796631,682.961426 
   C882.287781,682.496033 884.615540,680.601135 886.621155,677.671387 
   C888.258057,675.280212 890.796692,673.506287 892.935486,671.458618 
   C893.375488,671.772095 893.815491,672.085510 894.255493,672.398987 
   C893.847839,674.751770 893.440186,677.104553 892.920654,680.102844 
   C901.200623,678.674255 909.201721,677.468689 917.117493,675.846191 
   C920.519653,675.148865 922.174072,676.188599 923.779846,679.137573 
   C930.468018,691.420593 937.488403,703.522583 944.200256,715.793091 
   C945.651611,718.446411 947.171265,719.232788 950.128845,718.540344 
   C954.821655,717.441650 959.625854,716.821167 964.379395,715.978516 
   C977.919922,713.577942 991.458740,711.167419 1005.667542,708.641846 
   C1004.369629,706.370728 1003.433777,704.663147 1002.434021,702.993835 
   C976.852051,660.280457 951.275024,617.564209 925.664368,574.868042 
   C921.585999,568.068848 918.372620,560.525818 909.496094,556.727600 
   C911.696716,556.123108 912.737183,555.837280 914.033325,555.481201 
   C890.428772,516.085327 867.090149,477.078857 843.593994,438.167572 
   C842.848816,436.933441 840.393677,435.922791 838.888123,436.100311 
   C833.110596,436.781433 827.383484,437.928589 821.660278,439.020477 
   C816.800598,439.947571 810.466675,439.541138 807.550537,442.474274 
   C804.596069,445.446014 804.944824,451.773346 803.999329,456.660400 
   C786.766296,545.737305 769.553650,634.818054 752.345215,723.899658 
   C750.434998,733.788147 748.576965,743.686768 746.668213,753.720154 
   C747.796082,753.854553 748.287720,754.028320 748.735168,753.949890 
   C766.772522,750.788147 784.792358,747.522278 802.853027,744.500732 
   C805.922363,743.987183 806.425537,742.239990 806.817810,739.811829 
   C809.101440,725.676880 811.364563,711.537231 813.887207,697.444397 
   C814.148438,695.985168 815.879395,693.995728 817.261292,693.671570 
   C822.428467,692.459473 827.734131,691.837952 833.264282,691.029541 
   C833.455322,691.019104 833.627991,690.957214 834.534851,690.486938 
   C835.347107,690.302246 836.159302,690.117493 837.399841,690.473389 
   C839.603455,689.639282 841.807068,688.805176 844.897888,688.055176 
   C853.501648,686.798279 862.105469,685.541321 871.235229,684.207581 
   C868.290405,681.592773 866.034485,679.589661 863.919250,676.717468 
   C863.963013,675.842896 864.006714,674.968262 864.025085,674.017822 
   C864.025085,674.017822 864.101318,674.041931 864.447205,673.949219 
   C864.678284,673.852051 864.848022,673.690430 865.634155,673.238220 
   C866.762634,673.577271 867.891052,673.916321 869.286316,674.630798 
   C869.532593,674.849121 869.819031,674.937866 870.065796,674.934937 
   C870.065796,674.934937 870.065002,674.846497 870.339600,675.413513 
   C870.917358,675.598206 871.495117,675.782898 872.674255,676.083557 
   C873.451782,676.717651 874.229248,677.351746 875.241577,678.458557 
   C875.552124,678.602844 875.862610,678.747070 876.084778,678.915527 
   C876.084778,678.915527 876.098328,678.824890 876.266602,679.358582 
   C876.586060,679.524353 876.905518,679.690186 877.123596,679.958801 
   C877.123596,679.958801 877.168274,679.821533 877.044495,680.424744 
M1422.823975,491.658569 
   C1419.218018,472.003937 1416.329834,452.183716 1411.841675,432.732666 
   C1404.697754,401.771576 1388.245972,377.429535 1358.219360,364.200348 
   C1337.002197,354.852417 1314.568604,353.323792 1292.011841,356.632629 
   C1264.505127,360.667664 1237.171875,365.886444 1209.767578,370.616699 
   C1200.966309,372.135895 1192.177979,373.729401 1183.138428,375.332397 
   C1200.415894,473.267059 1217.586426,570.595825 1234.789917,668.111023 
   C1236.797485,667.845520 1238.109741,667.744751 1239.391724,667.491089 
   C1251.611938,665.074280 1263.796509,662.462952 1276.054077,660.254761 
   C1284.377686,658.755188 1292.827271,657.961365 1301.165161,656.530029 
   C1303.803467,656.077148 1306.263428,654.583984 1309.871216,653.140198 
   C1310.576904,653.279236 1312.908325,654.452148 1314.883301,654.014954 
   C1331.111572,650.421387 1347.754517,647.908508 1363.366089,642.468994 
   C1411.048706,625.855042 1435.181519,587.226990 1430.075317,536.883545 
   C1428.566162,522.005066 1425.370117,507.297699 1422.823975,491.658569 
M576.050720,751.363220 
   C571.300598,724.345337 566.550476,697.327515 561.747986,670.011780 
   C563.673035,669.596069 565.092041,669.242676 566.528381,668.986450 
   C587.020447,665.331543 607.592285,662.073242 627.989136,657.948669 
   C653.999084,652.688965 676.183105,640.617798 689.823792,617.029236 
   C708.366333,584.963928 704.862427,552.473022 688.844238,520.570068 
   C683.098938,509.127380 674.462524,499.935638 664.803406,493.476868 
   C662.316956,495.050385 660.593506,496.140991 658.870056,497.231598 
   C658.870056,494.813232 658.870056,492.394836 658.870056,489.553925 
   C640.272644,478.547638 619.600342,475.385071 598.136902,478.710388 
   C569.508972,483.145721 541.027832,488.528412 512.486145,493.518555 
   C500.059265,495.691284 487.638702,497.899872 474.665894,500.189148 
   C491.914215,597.975525 509.081177,695.300598 526.342041,793.157898 
   C544.982666,789.867249 563.200012,786.651367 581.718750,783.382263 
   C579.783325,772.510864 577.976868,762.363770 576.050720,751.363220 
M1079.087891,393.641327 
   C1062.263306,396.609283 1045.438599,399.577209 1028.234619,402.612122 
   C1039.475830,466.326813 1050.615234,529.463928 1061.814941,592.942749 
   C1067.001465,588.763062 1071.558716,585.090393 1076.115967,581.417664 
   C1076.542603,581.755188 1076.969238,582.092712 1077.395752,582.430176 
   C1076.861450,584.225525 1076.715576,586.281799 1075.718384,587.766052 
   C1072.947266,591.890564 1070.175415,596.126038 1066.711426,599.634033 
   C1064.442749,601.931580 1063.603882,603.825989 1064.168091,606.945496 
   C1068.316406,629.876709 1072.316406,652.834778 1076.386353,675.780273 
   C1077.535034,682.256531 1078.761108,688.719116 1080.020508,695.563232 
   C1099.370972,692.156799 1118.009277,688.875732 1137.120850,685.511292 
   C1135.783569,677.577087 1134.543457,669.968933 1133.213013,662.376709 
   C1130.253906,645.488708 1127.474609,628.564392 1124.166382,611.744507 
   C1122.559326,603.574158 1123.849609,594.267822 1116.204590,588.059021 
   C1116.044067,587.928650 1116.184448,587.427734 1116.184448,587.099670 
   C1116.664062,587.136292 1117.143555,587.172913 1117.623169,587.209534 
   C1118.017090,584.910828 1118.996460,582.532593 1118.697021,580.328003 
   C1117.734619,573.240845 1116.462769,566.182800 1114.960938,559.188538 
   C1114.425049,556.692993 1115.842285,552.830872 1111.308960,552.198608 
   C1111.085693,552.167480 1111.028076,550.404419 1111.022827,549.447144 
   C1110.995483,544.449585 1111.010010,539.451782 1111.010010,535.449524 
   C1107.303223,534.609985 1104.420288,534.065186 1101.643799,533.184692 
   C1101.314575,533.080261 1101.318359,530.263428 1101.589966,530.186096 
   C1104.120117,529.465454 1106.742432,529.069336 1109.640381,528.522095 
   C1106.127441,508.665192 1102.495483,488.135803 1098.862305,467.600220 
   C1096.117798,468.546692 1093.952393,469.293457 1090.658447,470.429382 
   C1095.515625,458.734894 1094.212280,447.970001 1093.768677,436.539764 
   C1089.944092,437.832306 1086.942017,438.846924 1083.939941,439.861542 
   C1083.517700,439.464081 1083.095337,439.066589 1082.672974,438.669128 
   C1084.866211,434.738007 1087.106812,430.832153 1089.226562,426.861816 
   C1089.957397,425.492828 1091.057007,423.857117 1090.846436,422.523376 
   C1089.295532,412.697113 1087.455811,402.916443 1085.634766,392.717590 
   C1083.491455,392.992493 1081.718994,393.219788 1079.087891,393.641327 
z"
        />
        <path
            fill="#FCF6F6"
            opacity="1.000000"
            stroke="none"
            d="
M1428.865112,800.928711 
   C1427.101929,800.911377 1425.035522,801.282837 1424.349243,800.477722 
   C1419.102051,794.322815 1408.791382,792.156494 1400.872681,795.676453 
   C1399.627441,796.229919 1398.019775,795.968323 1396.579590,796.083496 
   C1397.251221,794.434753 1397.816284,792.731689 1398.621704,791.151123 
   C1399.606445,789.218567 1401.886353,787.269897 1401.697754,785.535461 
   C1400.970825,778.843811 1402.410156,774.183655 1410.704834,773.867798 
   C1410.730835,773.401978 1410.756958,772.936218 1410.782959,772.470398 
   C1408.380493,770.456909 1405.818604,768.599792 1403.633301,766.373047 
   C1402.252197,764.965820 1401.433228,763.006897 1400.362183,761.295410 
   C1402.603394,760.575439 1405.036499,758.904053 1407.050415,759.310242 
   C1413.582520,760.627808 1420.120728,764.358215 1415.864746,773.769348 
   C1414.540283,776.698181 1412.031616,780.590210 1416.008911,782.954224 
   C1416.778320,783.411560 1420.664185,778.948303 1422.869995,776.533325 
   C1424.015503,775.279114 1424.630859,773.540588 1426.204224,770.743225 
   C1426.545288,773.323975 1426.758179,774.381653 1426.814087,775.447449 
   C1427.164307,782.117615 1429.563232,784.358582 1436.229736,783.771240 
   C1441.499146,783.307007 1446.229980,781.728333 1449.740479,777.232788 
   C1450.870483,775.785706 1453.216187,775.287781 1455.005615,774.355652 
   C1455.742920,776.446106 1457.187622,778.624329 1456.943115,780.592834 
   C1456.804199,781.710693 1453.930054,782.544556 1452.233398,783.398315 
   C1450.390137,784.325989 1448.210449,784.789490 1446.653809,786.045898 
   C1440.731934,790.825500 1435.002441,795.843567 1428.865112,800.928711 
z"
        />
        <path
            fill="#FCF6F6"
            opacity="1.000000"
            stroke="none"
            d="
M1333.506226,197.539032 
   C1336.248779,199.425262 1338.695557,201.102829 1341.924927,203.317032 
   C1335.726807,210.154205 1329.934814,216.691406 1323.921021,223.017746 
   C1323.021729,223.963913 1321.082275,223.921402 1319.624023,224.336273 
   C1319.296753,222.661102 1318.486206,220.894287 1318.781494,219.337158 
   C1319.063110,217.852264 1320.413574,216.518860 1321.440552,215.236038 
   C1324.776978,211.068146 1324.108032,208.346817 1318.885498,206.911392 
   C1315.070190,205.862686 1311.065674,205.501907 1306.931885,204.798096 
   C1311.890137,200.404419 1316.730469,196.115265 1322.547729,190.960464 
   C1325.385498,192.655792 1329.297852,194.993088 1333.506226,197.539032 
z"
        />
        <path
            fill="#FCF6F6"
            opacity="1.000000"
            stroke="none"
            d="
M254.184616,561.784973 
   C251.685196,556.771667 249.369827,552.070251 247.054459,547.368896 
   C247.416000,547.024109 247.777542,546.679321 248.139084,546.334595 
   C251.072052,547.475098 254.005020,548.615601 256.731262,549.675781 
   C257.338257,549.182434 257.647552,549.022095 257.828369,548.769592 
   C259.221222,546.824829 260.589111,544.862183 261.964783,542.905090 
   C263.105560,545.061523 264.746735,547.105408 265.263672,549.402222 
   C265.948914,552.447144 265.270905,555.821777 266.084137,558.808228 
   C266.816071,561.496094 268.717957,563.882629 270.212799,566.335754 
   C270.719116,567.166565 272.003326,567.707031 272.165222,568.530884 
   C272.738190,571.446533 272.979462,574.427307 273.346436,577.383423 
   C270.833282,576.668213 267.639648,576.681274 265.941071,575.094177 
   C261.720306,571.150574 258.181396,566.477234 254.184616,561.784973 
z"
        />
        <path
            fill="#FCF6F6"
            opacity="1.000000"
            stroke="none"
            d="
M340.739502,924.798340 
   C344.778656,924.507874 343.447723,927.207581 343.753204,929.155518 
   C344.115509,931.465637 344.962463,933.695435 345.454163,935.991150 
   C345.772064,937.475586 346.485504,939.372986 345.888916,940.468445 
   C343.441589,944.962708 340.984344,949.575989 337.723175,953.468994 
   C334.169678,957.711121 328.322571,958.137817 322.489014,956.150635 
   C325.027771,950.793030 327.836517,945.435913 330.130585,939.866760 
   C332.505249,934.101929 334.912354,928.494690 340.739502,924.798340 
z"
        />
        <path
            fill="#FBF2F2"
            opacity="1.000000"
            stroke="none"
            d="
M1592.008789,271.684937 
   C1591.596924,270.307037 1591.259644,268.623840 1590.922974,266.550598 
   C1598.376221,263.609833 1606.808228,268.857849 1610.272583,278.420776 
   C1611.678955,275.648346 1612.599609,273.244873 1614.024536,271.191315 
   C1615.431152,269.164246 1617.348022,267.491119 1619.041138,265.662750 
   C1619.514404,265.894135 1619.987549,266.125519 1620.460815,266.356873 
   C1620.345947,268.026794 1620.512939,269.778900 1620.054932,271.348724 
   C1619.220947,274.207581 1618.073730,276.981567 1616.953247,279.748810 
   C1615.239990,283.980011 1615.119141,287.493378 1620.354004,289.332092 
   C1621.535034,289.746918 1622.410522,291.031525 1623.426636,291.915771 
   C1623.169556,292.486359 1622.912354,293.056946 1622.655151,293.627533 
   C1620.188354,292.889587 1617.479248,292.578369 1615.297119,291.339081 
   C1606.369629,286.268799 1598.768921,279.652100 1593.094482,270.982391 
   C1592.963257,270.782013 1592.687622,270.676117 1592.479248,270.526215 
   C1592.347168,270.810699 1592.215210,271.095154 1592.008789,271.684937 
z"
        />
        <path
            fill="#FBF2F2"
            opacity="1.000000"
            stroke="none"
            d="
M849.887146,289.095947 
   C849.347961,288.748413 848.802368,288.071960 848.925537,287.912689 
   C852.281799,283.572968 855.618286,279.211670 859.157166,275.022156 
   C860.891479,272.969025 863.048218,271.872589 865.615540,274.117584 
   C870.241943,278.163086 874.936035,282.131165 879.596191,286.138245 
   C879.973694,286.462830 880.499023,286.778656 880.629883,287.199738 
   C881.353821,289.529816 881.976746,291.891296 882.634338,294.242004 
   C880.421448,293.636688 878.207458,293.035065 875.997864,292.417969 
   C875.850342,292.376801 875.705078,292.240967 875.609680,292.111603 
   C868.230713,282.108643 859.445129,283.649628 849.887146,289.095947 
z"
        />
        <path
            fill="#FBF4F3"
            opacity="1.000000"
            stroke="none"
            d="
M1365.344360,192.386353 
   C1367.234375,192.763641 1369.954834,193.148071 1370.068604,193.917709 
   C1370.742554,198.478745 1370.812012,203.129089 1371.084839,207.749390 
   C1367.641479,206.193024 1364.145996,204.739075 1360.781128,203.028458 
   C1359.488037,202.371063 1358.479370,201.143280 1357.362793,200.151123 
   C1350.549683,194.097427 1350.306152,190.463379 1356.671143,182.274643 
   C1357.369263,184.819427 1357.979736,186.933548 1358.526855,189.063980 
   C1359.424561,192.560440 1361.182251,194.414597 1365.344360,192.386353 
z"
        />
        <path
            fill="#FBF4F3"
            opacity="1.000000"
            stroke="none"
            d="
M1634.484863,407.273071 
   C1632.440796,407.495331 1629.857544,408.116516 1628.872437,407.134552 
   C1625.383301,403.656982 1622.239746,399.775787 1619.357422,395.765686 
   C1617.251465,392.835907 1618.670410,391.027985 1622.091797,391.105469 
   C1624.390503,391.157532 1626.714966,391.533295 1628.963745,392.045959 
   C1634.564331,393.322784 1636.484497,395.982697 1635.895508,401.630707 
   C1635.708862,403.422211 1635.096313,405.169281 1634.484863,407.273071 
z"
        />
        <path
            fill="#FAF0EF"
            opacity="1.000000"
            stroke="none"
            d="
M1111.312012,239.850067 
   C1116.575317,243.587814 1121.579468,247.110641 1126.367432,250.906296 
   C1127.722412,251.980484 1129.147217,253.795059 1129.274536,255.372147 
   C1129.421753,257.197327 1128.172485,259.135193 1127.529663,261.024139 
   C1125.976685,260.478882 1123.947998,260.370575 1122.965454,259.301178 
   C1120.620605,256.748779 1118.995605,253.535431 1116.652954,250.980484 
   C1114.438477,248.565475 1111.553833,246.775909 1109.222778,244.451599 
   C1108.306396,243.537964 1108.092773,241.919754 1107.559082,240.622375 
   C1108.696411,240.327805 1109.833740,240.033203 1111.312012,239.850067 
z"
        />
        <path
            fill="#FBF2F2"
            opacity="1.000000"
            stroke="none"
            d="
M240.112549,526.189209 
   C240.451874,524.801270 240.752335,523.270752 241.677826,522.590027 
   C243.791946,521.035034 246.084229,519.494629 248.546844,518.710815 
   C250.637024,518.045532 254.547714,517.620239 255.061829,518.538818 
   C256.448822,521.017090 257.442871,524.578064 256.654694,527.104797 
   C256.137238,528.763672 252.136078,530.442566 249.904266,530.198547 
   C246.623993,529.839966 243.530563,527.772217 240.112549,526.189209 
z"
        />
        <path
            fill="#FAF0EF"
            opacity="1.000000"
            stroke="none"
            d="
M369.746307,384.134705 
   C369.205780,380.829681 368.626587,377.909210 368.460876,374.965424 
   C368.408386,374.033264 369.260254,372.542969 370.064636,372.219543 
   C370.795410,371.925781 372.621674,372.602753 372.938995,373.311249 
   C375.548584,379.138062 378.016174,385.035706 380.250031,391.015289 
   C380.564453,391.856903 379.651611,393.855652 378.815552,394.234497 
   C377.957855,394.623077 375.990631,394.057587 375.392487,393.252930 
   C373.341736,390.494171 371.678589,387.447296 369.746307,384.134705 
z"
        />
        <path
            fill="#FAF0EF"
            opacity="1.000000"
            stroke="none"
            d="
M1479.642822,179.890198 
   C1481.350830,180.337158 1482.699097,180.862717 1484.047363,181.388245 
   C1483.157837,182.648254 1482.497681,184.694412 1481.339355,185.032150 
   C1476.354248,186.485611 1473.220581,189.638306 1470.729736,194.040710 
   C1469.920532,195.470749 1467.589966,196.314728 1465.786865,196.798141 
   C1464.803467,197.061752 1463.151367,196.285812 1462.411011,195.442917 
   C1461.983032,194.955521 1462.492065,193.224487 1463.051392,192.358063 
   C1466.962524,186.300262 1472.508667,182.312546 1479.642822,179.890198 
z"
        />
        <path
            fill="#FAF0EF"
            opacity="1.000000"
            stroke="none"
            d="
M917.268555,875.149048 
   C919.071594,880.540649 915.380859,879.778198 913.260986,878.599365 
   C908.214294,875.792969 903.514160,872.353027 898.737793,869.075989 
   C896.726257,867.695862 894.875488,866.081299 893.607788,863.696533 
   C902.669617,865.163452 910.698975,868.254456 917.268555,875.149048 
z"
        />
        <path
            fill="#FAF0EF"
            opacity="1.000000"
            stroke="none"
            d="
M1243.070068,222.459137 
   C1240.320801,228.851944 1237.499512,234.856293 1234.678345,240.860641 
   C1233.968140,240.635895 1233.258057,240.411163 1232.547852,240.186417 
   C1233.206055,233.710114 1233.776123,227.222412 1234.608643,220.768616 
   C1234.748657,219.683746 1236.561157,217.803345 1236.696045,217.880112 
   C1238.883179,219.125153 1240.924194,220.626953 1243.070068,222.459137 
z"
        />
        <path
            fill="#FAF0EF"
            opacity="1.000000"
            stroke="none"
            d="
M1615.947754,238.123459 
   C1621.319092,244.699081 1622.081055,250.329880 1618.330200,254.947861 
   C1617.560181,255.895935 1616.262573,257.052155 1615.278687,256.990356 
   C1614.476685,256.940033 1613.293213,255.291245 1613.121704,254.216690 
   C1612.424194,249.847427 1611.792358,245.433151 1611.676880,241.021332 
   C1611.625488,239.056808 1612.329712,236.365814 1615.947754,238.123459 
z"
        />
        <path
            fill="#FAEAEA"
            opacity="1.000000"
            stroke="none"
            d="
M1297.911133,795.719421 
   C1296.321899,789.367737 1298.006714,784.088867 1302.113159,779.819702 
   C1303.236816,778.651489 1305.932007,778.994934 1307.906006,778.644653 
   C1307.443726,780.617371 1307.201294,782.676880 1306.465698,784.541748 
   C1305.443726,787.132812 1303.731201,789.478943 1302.916504,792.115967 
   C1302.228394,794.343018 1302.397827,796.835083 1302.187988,799.209900 
   C1301.740967,799.339355 1301.293945,799.468872 1300.846924,799.598328 
   C1299.901855,798.426819 1298.956909,797.255249 1297.911133,795.719421 
z"
        />
        <path
            fill="#FAF0EF"
            opacity="1.000000"
            stroke="none"
            d="
M1671.233521,731.934937 
   C1679.639160,730.027832 1682.572510,732.177307 1682.159424,740.355347 
   C1678.786743,740.014465 1675.359619,739.957581 1672.086182,739.219299 
   C1670.360596,738.830139 1668.903320,737.252197 1667.324829,736.210938 
   C1668.503906,734.824524 1669.683105,733.438110 1671.233521,731.934937 
z"
        />
        <path
            fill="#FAF0EF"
            opacity="1.000000"
            stroke="none"
            d="
M1711.979248,692.733337 
   C1714.371338,695.223022 1713.493530,697.116394 1711.053589,697.913757 
   C1708.950928,698.600830 1706.058472,699.275879 1704.402710,698.357056 
   C1701.972168,697.008301 1700.332520,694.234375 1698.355957,692.067749 
   C1700.815186,691.429565 1703.278198,690.291931 1705.732666,690.309937 
   C1707.724731,690.324463 1709.706787,691.726196 1711.979248,692.733337 
z"
        />
        <path
            fill="#FBF2F2"
            opacity="1.000000"
            stroke="none"
            d="
M1667.095703,644.221802 
   C1668.180298,641.399597 1669.007324,638.858887 1670.324341,636.604736 
   C1670.859253,635.689331 1672.583984,634.793457 1673.542480,634.989319 
   C1674.520630,635.189026 1675.994507,636.817383 1675.907349,637.685120 
   C1675.588501,640.855286 1675.125000,644.155212 1673.844971,647.016663 
   C1673.296509,648.242554 1670.609497,648.511719 1668.894165,649.215576 
   C1668.274536,647.683228 1667.654907,646.150879 1667.095703,644.221802 
z"
        />
        <path
            fill="#FBF4F3"
            opacity="1.000000"
            stroke="none"
            d="
M1071.768066,848.739014 
   C1074.964478,848.328857 1078.028687,847.625488 1078.185547,852.019165 
   C1078.318237,855.738403 1075.103516,860.964355 1072.631470,861.140015 
   C1069.420776,861.368164 1066.477173,855.311340 1068.284912,851.778320 
   C1068.896362,850.583191 1070.331299,849.809387 1071.768066,848.739014 
z"
        />
        <path
            fill="#FAEDEC"
            opacity="1.000000"
            stroke="none"
            d="
M1628.163086,212.076447 
   C1626.020508,216.197998 1623.838989,219.926834 1621.445679,224.017685 
   C1617.367554,219.425095 1616.777466,213.496674 1619.660278,210.974777 
   C1622.502808,208.488113 1625.590332,208.047333 1628.163086,212.076447 
z"
        />
        <path
            fill="#FAEAEA"
            opacity="1.000000"
            stroke="none"
            d="
M1626.553833,719.892212 
   C1628.135254,720.271423 1629.308594,720.640259 1630.700806,721.077759 
   C1626.296509,726.070496 1616.425415,728.786804 1611.045654,726.320496 
   C1615.211914,721.569397 1620.375122,720.075684 1626.553833,719.892212 
z"
        />
        <path
            fill="#FAEAEA"
            opacity="1.000000"
            stroke="none"
            d="
M294.567566,413.886078 
   C291.799255,414.610870 288.618958,415.280487 284.992096,415.882111 
   C285.247894,415.426056 285.950226,415.038025 286.234039,414.881226 
   C285.270538,411.549438 284.159943,408.399292 283.548889,405.155060 
   C283.376404,404.239441 284.440216,402.653503 285.365631,402.123688 
   C285.973724,401.775543 287.927185,402.486359 288.282471,403.211121 
   C289.819336,406.345825 290.989441,409.660339 292.292969,412.909454 
   C291.838226,413.216614 291.383484,413.523773 290.928741,413.830933 
   C292.004333,413.830933 293.079926,413.830933 294.567566,413.886078 
z"
        />
        <path
            fill="#FAEAEA"
            opacity="1.000000"
            stroke="none"
            d="
M308.630371,892.766235 
   C312.151337,889.056824 315.478210,885.690369 320.652679,885.160095 
   C321.452148,891.138123 314.531799,896.905762 306.943542,896.504211 
   C307.487061,895.215698 307.944153,894.132080 308.630371,892.766235 
z"
        />
        <path
            fill="#FAEAEA"
            opacity="1.000000"
            stroke="none"
            d="
M1268.047852,795.210083 
   C1269.948364,798.331299 1271.976196,801.037170 1273.262573,804.058716 
   C1273.832153,805.396851 1272.901733,807.373413 1272.652344,809.060181 
   C1271.146606,808.172058 1268.766846,807.606628 1268.297729,806.335876 
   C1266.841675,802.392273 1266.111450,798.180847 1265.099487,794.073303 
   C1265.483154,793.890442 1265.866943,793.707520 1266.250610,793.524597 
   C1266.791504,793.990784 1267.332520,794.456909 1268.047852,795.210083 
z"
        />
        <path
            fill="#FAEDEC"
            opacity="1.000000"
            stroke="none"
            d="
M1697.713257,712.412231 
   C1699.543823,713.491028 1701.456055,714.185486 1702.386841,715.549622 
   C1703.480713,717.152832 1703.722656,719.337402 1704.333984,721.269775 
   C1703.115112,721.501526 1701.421875,722.355408 1700.762817,721.852905 
   C1698.416992,720.064331 1696.350098,717.875366 1694.447998,715.610840 
   C1694.310059,715.446777 1696.339233,713.462341 1697.713257,712.412231 
z"
        />
        <path
            fill="#FAEAEA"
            opacity="1.000000"
            stroke="none"
            d="
M1642.932739,724.139648 
   C1641.364380,722.837036 1640.173340,721.640625 1638.982300,720.444214 
   C1640.637329,719.624878 1642.283569,718.786499 1643.956543,718.005737 
   C1644.223633,717.881104 1644.687256,717.893127 1644.930420,718.046875 
   C1646.992920,719.351196 1649.025513,720.703003 1651.067017,722.040527 
   C1648.966797,722.887146 1646.881104,723.774048 1644.753174,724.544250 
   C1644.359619,724.686707 1643.795532,724.357483 1642.932739,724.139648 
z"
        />
        <path
            fill="#FAEAEA"
            opacity="1.000000"
            stroke="none"
            d="
M348.518127,978.456299 
   C347.582489,976.311035 346.469696,974.532043 346.251740,972.649475 
   C346.101959,971.355713 347.194336,969.918213 347.731506,968.544922 
   C348.981140,969.411804 351.034180,970.066467 351.329865,971.185425 
   C352.222015,974.561768 352.431152,978.118652 352.906586,981.605103 
   C352.416016,981.810974 351.925415,982.016907 351.434814,982.222839 
   C350.530701,981.074707 349.626617,979.926575 348.518127,978.456299 
z"
        />
        <path
            fill="#F9E8E6"
            opacity="1.000000"
            stroke="none"
            d="
M539.338684,356.786499 
   C537.729248,356.239929 536.216431,356.053528 535.350708,355.206024 
   C534.204285,354.083801 533.564026,352.444550 532.706177,351.027527 
   C534.433411,350.562988 536.533752,349.252350 537.808167,349.814484 
   C540.266846,350.899048 542.259033,353.041168 544.444702,354.744812 
   C544.220215,355.275391 543.995789,355.806000 543.771362,356.336578 
   C542.421997,356.508514 541.072632,356.680481 539.338684,356.786499 
z"
        />
        <path
            fill="#FAEAEA"
            opacity="1.000000"
            stroke="none"
            d="
M1499.999634,745.201233 
   C1502.141846,744.509033 1504.086304,744.115967 1506.030518,743.722900 
   C1505.492798,745.755981 1505.436523,748.144592 1504.275513,749.717712 
   C1503.210205,751.160889 1500.953369,751.724731 1499.221436,752.676025 
   C1498.775391,752.327820 1498.329346,751.979675 1497.883179,751.631531 
   C1498.522705,749.587769 1499.162231,747.544067 1499.999634,745.201233 
z"
        />
        <path
            fill="#FAEDEC"
            opacity="1.000000"
            stroke="none"
            d="
M596.336060,941.203003 
   C597.784180,942.578003 598.833252,943.841492 599.882324,945.104980 
   C598.333313,945.810608 596.792480,946.535278 595.227539,947.203796 
   C594.953186,947.321045 594.512878,947.317749 594.259583,947.174683 
   C592.134094,945.974670 590.034302,944.729004 587.926819,943.497070 
   C590.110229,942.613586 592.280396,941.694214 594.489075,940.879272 
   C594.890991,940.731018 595.450378,941.009766 596.336060,941.203003 
z"
        />
        <path
            fill="#F9E8E6"
            opacity="1.000000"
            stroke="none"
            d="
M1424.766235,186.765015 
   C1422.406860,187.694443 1420.300903,188.357544 1418.194824,189.020630 
   C1417.965454,188.566055 1417.735962,188.111496 1417.506592,187.656921 
   C1419.730469,184.391388 1421.954346,181.125854 1424.364868,177.586258 
   C1427.210938,180.822433 1427.030640,183.616486 1424.766235,186.765015 
z"
        />
        <path
            fill="#F8E1E0"
            opacity="1.000000"
            stroke="none"
            d="
M1494.874023,166.244293 
   C1498.196411,168.442245 1501.202271,170.480881 1505.113403,173.133392 
   C1498.746826,174.381607 1494.084351,171.723068 1490.112183,165.162094 
   C1492.061523,165.566788 1493.309570,165.825897 1494.874023,166.244293 
z"
        />
        <path
            fill="#F9E8E6"
            opacity="1.000000"
            stroke="none"
            d="
M666.687012,324.500305 
   C669.133789,326.544128 671.288940,328.401855 673.822815,330.585968 
   C668.904480,332.480896 664.030762,330.552368 662.865906,326.787354 
   C661.644409,322.839661 664.074097,323.519165 666.687012,324.500305 
z"
        />
        <path
            fill="#FAF0EF"
            opacity="1.000000"
            stroke="none"
            d="
M242.659485,502.567200 
   C243.423203,501.069305 244.246643,499.933502 245.070084,498.797668 
   C246.804214,500.386871 248.793716,501.798767 250.102158,503.683472 
   C250.451691,504.187012 248.801361,507.122864 247.923660,507.196045 
   C246.516815,507.313263 244.895279,506.108765 243.557159,505.184082 
   C243.016586,504.810516 242.983337,503.702850 242.659485,502.567200 
z"
        />
        <path
            fill="#F8DCDB"
            opacity="1.000000"
            stroke="none"
            d="
M1002.879150,255.885620 
   C1001.023438,261.695953 999.167664,267.506287 997.229980,273.573273 
   C994.197937,267.812500 995.697266,263.017822 1002.630127,256.090576 
   C1002.915833,255.917603 1002.879211,255.885620 1002.879150,255.885620 
z"
        />
        <path
            fill="#FAEDEC"
            opacity="1.000000"
            stroke="none"
            d="
M951.311035,862.259766 
   C952.981567,861.398560 954.446411,860.872803 955.911194,860.347046 
   C956.144104,862.668884 956.716675,865.037354 956.408325,867.284973 
   C956.312561,867.982727 953.554382,869.112427 952.486328,868.729675 
   C949.295776,867.586182 950.004578,865.014648 951.311035,862.259766 
z"
        />
        <path
            fill="#F9E8E6"
            opacity="1.000000"
            stroke="none"
            d="
M1449.800781,177.795074 
   C1453.971802,176.973999 1453.529541,179.828781 1452.610352,181.514877 
   C1451.578125,183.408691 1449.435791,184.697510 1447.776367,186.249557 
   C1447.330200,185.296539 1446.298706,184.134232 1446.550293,183.430603 
   C1447.238037,181.506851 1448.466431,179.776367 1449.800781,177.795074 
z"
        />
        <path
            fill="#FAEAEA"
            opacity="1.000000"
            stroke="none"
            d="
M1364.513428,793.696045 
   C1365.767090,792.612793 1366.760132,791.383606 1367.899902,791.228088 
   C1369.173218,791.054260 1370.579468,791.854858 1371.927856,792.231445 
   C1371.406250,793.450562 1371.202759,795.059875 1370.292358,795.802124 
   C1368.724243,797.080627 1366.716064,797.819336 1363.848999,799.337036 
   C1364.074951,796.775574 1364.196289,795.399658 1364.513428,793.696045 
z"
        />
        <path
            fill="#F9E8E6"
            opacity="1.000000"
            stroke="none"
            d="
M1527.436768,741.493042 
   C1526.717773,742.799133 1526.311523,744.232361 1525.444092,744.612549 
   C1523.752075,745.354004 1521.801758,745.506104 1519.958496,745.902283 
   C1520.829834,743.960266 1521.295166,741.627747 1522.684204,740.183655 
   C1524.139893,738.670105 1526.682373,737.078552 1527.436768,741.493042 
z"
        />
        <path
            fill="#FAEDEC"
            opacity="1.000000"
            stroke="none"
            d="
M1701.841309,668.509155 
   C1703.772339,667.665161 1705.588623,666.903625 1707.417969,666.870667 
   C1707.852295,666.862793 1709.052734,669.671021 1708.614014,670.515625 
   C1708.003784,671.690308 1705.704590,673.200989 1704.978638,672.843079 
   C1703.581055,672.154053 1702.779785,670.255249 1701.841309,668.509155 
z"
        />
        <path
            fill="#FAEDEC"
            opacity="1.000000"
            stroke="none"
            d="
M1393.707275,786.383667 
   C1391.657837,786.973267 1389.760498,787.464172 1387.922241,787.299072 
   C1387.552490,787.265747 1386.831177,784.468201 1387.318359,783.442200 
   C1387.811035,782.404846 1390.385864,781.151611 1390.690552,781.437927 
   C1392.023071,782.690125 1392.862183,784.467285 1393.707275,786.383667 
z"
        />
        <path
            fill="#FAEAEA"
            opacity="1.000000"
            stroke="none"
            d="
M1600.349609,222.294647 
   C1604.677368,221.827911 1603.678345,224.857651 1602.865723,226.381393 
   C1601.918457,228.157822 1599.824707,229.322861 1598.228394,230.753143 
   C1597.788208,229.793716 1596.760864,228.616638 1597.020386,227.916626 
   C1597.732422,225.995804 1598.985352,224.275482 1600.349609,222.294647 
z"
        />
        <path
            fill="#FAEDEC"
            opacity="1.000000"
            stroke="none"
            d="
M454.043732,940.773560 
   C455.972504,941.417908 457.497742,942.067810 459.022949,942.717651 
   C459.002655,943.383545 458.982361,944.049377 458.962067,944.715271 
   C456.166779,945.126282 453.377960,945.609741 450.568298,945.858948 
   C450.207275,945.890991 449.309265,944.222717 449.382965,944.157349 
   C450.736237,942.957092 452.199921,941.881287 454.043732,940.773560 
z"
        />
        <path
            fill="#F8DCDB"
            opacity="1.000000"
            stroke="none"
            d="
M1106.785034,227.894424 
   C1105.681152,228.753510 1104.584351,229.815262 1103.720215,229.651123 
   C1102.289917,229.379425 1100.797974,228.529037 1099.763672,227.491974 
   C1099.309692,227.036850 1099.822388,224.658279 1100.059326,224.632080 
   C1101.406494,224.483200 1102.926147,224.493073 1104.145996,225.013718 
   C1105.226318,225.474854 1105.992065,226.673325 1106.785034,227.894424 
z"
        />
        <path
            fill="#F9E8E6"
            opacity="1.000000"
            stroke="none"
            d="
M1492.856445,764.096191 
   C1492.395142,766.599548 1491.705688,768.863281 1491.016357,771.127075 
   C1490.310547,771.081909 1489.604858,771.036682 1488.899170,770.991516 
   C1488.546997,769.310059 1487.428833,767.214172 1488.052979,766.061096 
   C1488.706055,764.854309 1491.033081,764.553467 1492.856445,764.096191 
z"
        />
        <path
            fill="#F9E8E6"
            opacity="1.000000"
            stroke="none"
            d="
M532.289246,931.690430 
   C531.247498,933.612854 530.274353,935.204529 529.144775,937.051941 
   C526.252808,934.520203 525.676147,932.212646 528.176941,930.045288 
   C529.792480,928.645325 531.860229,928.089355 532.289246,931.690430 
z"
        />
        <path
            fill="#F8DCDB"
            opacity="1.000000"
            stroke="none"
            d="
M1199.095581,822.748169 
   C1204.581299,822.254578 1205.767578,823.894409 1203.711426,828.949829 
   C1201.022461,827.774963 1198.078369,827.002380 1199.095581,822.748169 
z"
        />
        <path
            fill="#F9E8E6"
            opacity="1.000000"
            stroke="none"
            d="
M1256.153564,814.241943 
   C1254.393311,814.951782 1252.766113,815.332214 1251.139160,815.712708 
   C1251.873291,813.386353 1252.607300,811.059937 1253.341431,808.733521 
   C1254.026611,808.859802 1254.711792,808.986023 1255.396973,809.112244 
   C1255.693604,810.712402 1255.990112,812.312500 1256.153564,814.241943 
z"
        />
        <path
            fill="#F8E1E0"
            opacity="1.000000"
            stroke="none"
            d="
M1689.905518,726.799988 
   C1691.358032,725.914368 1693.916992,724.605286 1694.239746,725.023132 
   C1695.383545,726.504150 1695.763550,728.575012 1696.432983,730.422363 
   C1695.839600,730.774780 1695.246216,731.127197 1694.652832,731.479675 
   C1693.097290,730.042603 1691.541870,728.605591 1689.905518,726.799988 
z"
        />
        <path
            fill="#FAEAEA"
            opacity="1.000000"
            stroke="none"
            d="
M1405.792114,184.214722 
   C1406.765869,182.538666 1407.634888,181.182938 1408.503784,179.827209 
   C1408.995483,179.809631 1409.487183,179.792053 1409.978882,179.774475 
   C1410.340820,181.416840 1411.210083,183.167679 1410.890137,184.664261 
   C1410.668945,185.698898 1408.805420,186.382446 1407.675781,187.222900 
   C1407.012939,186.326965 1406.350098,185.431015 1405.792114,184.214722 
z"
        />
        <path
            fill="#F9E8E6"
            opacity="1.000000"
            stroke="none"
            d="
M309.167908,391.060303 
   C310.633728,390.480804 311.779968,390.096039 313.204254,389.617981 
   C312.075348,395.192749 310.153412,396.484070 304.404755,395.294067 
   C306.129517,393.726349 307.488922,392.490723 309.167908,391.060303 
z"
        />
        <path
            fill="#F8DCDB"
            opacity="1.000000"
            stroke="none"
            d="
M1597.293091,205.914322 
   C1597.929565,205.543945 1599.042603,205.380127 1599.075562,205.504059 
   C1599.838013,208.366394 1600.482910,211.259995 1601.149658,214.147797 
   C1600.461182,214.322647 1599.772705,214.497498 1599.084229,214.672348 
   C1598.478394,211.866531 1597.872437,209.060730 1597.293091,205.914322 
z"
        />
        <path
            fill="#FAEAEA"
            opacity="1.000000"
            stroke="none"
            d="
M282.665680,416.011780 
   C282.200165,416.382294 281.403656,416.708221 280.265228,416.999176 
   C280.727081,416.631836 281.530884,416.299469 282.665680,416.011780 
z"
        />
        <path
            fill="#FAEAEA"
            opacity="1.000000"
            stroke="none"
            d="
M296.679932,413.367065 
   C296.459930,413.625366 295.972778,413.829651 295.212646,413.991974 
   C295.430695,413.737732 295.921722,413.525421 296.679932,413.367065 
z"
        />
        <path
            fill="#F8DCDB"
            opacity="1.000000"
            stroke="none"
            d="
M1002.928345,255.937607 
   C1002.940796,255.957611 1003.297668,255.684464 1003.297668,255.684464 
   C1003.297668,255.684464 1003.128174,255.922073 1003.003662,255.903839 
   C1002.879211,255.885620 1002.915833,255.917603 1002.928345,255.937607 
z"
        />
        <path
            fill="#FBF2F2"
            opacity="1.000000"
            stroke="none"
            d="
M1590.929443,265.769989 
   C1590.754761,265.771301 1590.611206,265.509277 1590.602905,265.136230 
   C1590.812256,265.185699 1590.886475,265.346161 1590.929443,265.769989 
z"
        />
        <path
            fill="#FAEAEA"
            opacity="1.000000"
            stroke="none"
            d="
M305.738892,411.914917 
   C305.735870,412.114868 305.472473,412.262238 305.105865,412.254303 
   C305.161285,412.020111 305.319855,411.941254 305.738892,411.914917 
z"
        />
        <path
            fill="#D62C34"
            opacity="1.000000"
            stroke="none"
            d="
M1422.885498,492.085266 
   C1425.370117,507.297699 1428.566162,522.005066 1430.075317,536.883545 
   C1435.181519,587.226990 1411.048706,625.855042 1363.366089,642.468994 
   C1347.754517,647.908508 1331.111572,650.421387 1314.883301,654.014954 
   C1312.908325,654.452148 1310.576904,653.279236 1309.871216,653.140198 
   C1306.263428,654.583984 1303.803467,656.077148 1301.165161,656.530029 
   C1292.827271,657.961365 1284.377686,658.755188 1276.054077,660.254761 
   C1263.796509,662.462952 1251.611938,665.074280 1239.391724,667.491089 
   C1238.109741,667.744751 1236.797485,667.845520 1234.789917,668.111023 
   C1217.586426,570.595825 1200.415894,473.267059 1183.138428,375.332397 
   C1192.177979,373.729401 1200.966309,372.135895 1209.767578,370.616699 
   C1237.171875,365.886444 1264.505127,360.667664 1292.011841,356.632629 
   C1314.568604,353.323792 1337.002197,354.852417 1358.219360,364.200348 
   C1388.245972,377.429535 1404.697754,401.771576 1411.841675,432.732666 
   C1416.329834,452.183716 1419.218018,472.003937 1422.885498,492.085266 
M1252.690796,445.803497 
   C1260.983521,492.855469 1269.480103,539.873535 1277.320679,587.000732 
   C1278.346802,593.168213 1277.947510,599.447937 1281.752197,605.010376 
   C1285.653931,604.328674 1289.605225,603.748840 1293.508301,602.937073 
   C1309.130859,599.687988 1325.147461,597.638550 1340.257690,592.780090 
   C1364.065430,585.124939 1375.364502,565.984009 1372.058594,541.166138 
   C1369.840210,524.512207 1366.077026,508.067352 1363.718872,491.427765 
   C1361.660522,476.903137 1356.178467,462.820709 1357.825684,447.791168 
   C1357.888184,447.221802 1357.414185,446.559235 1357.101074,445.989044 
   C1355.615601,443.284424 1353.851685,440.703674 1352.635254,437.885315 
   C1346.184326,422.940002 1334.902344,414.148895 1319.039795,410.985840 
   C1306.309082,408.447296 1293.866577,410.787628 1281.361816,412.863159 
   C1278.778076,413.292023 1275.975342,412.401398 1273.126465,412.103546 
   C1266.645996,418.753418 1256.272461,415.339813 1248.085815,419.592407 
   C1249.543091,427.975311 1251.019775,436.469482 1252.690796,445.803497 
M1402.103638,470.426819 
   C1404.432251,470.630493 1406.760864,470.834198 1409.089478,471.037903 
   C1408.863770,468.822510 1408.957031,466.514771 1408.347412,464.410522 
   C1406.980713,459.692291 1405.620972,455.147980 1409.202148,450.711609 
   C1409.727661,450.060547 1409.606445,448.449738 1409.192749,447.557526 
   C1407.762085,444.472839 1400.845703,441.096313 1396.505859,441.535828 
   C1397.199707,443.623810 1397.704956,445.639954 1398.526123,447.517883 
   C1400.646606,452.366760 1400.974243,457.204071 1398.273071,461.855957 
   C1395.826904,466.068634 1396.779541,468.713287 1402.103638,470.426819 
M1301.600708,631.067871 
   C1304.231689,630.045105 1306.829468,628.924500 1309.500854,628.020996 
   C1314.312256,626.393555 1317.294067,621.013306 1315.957886,616.144714 
   C1314.995850,612.639648 1311.707520,611.432495 1309.085083,613.923157 
   C1307.720459,615.219116 1307.295166,617.459167 1306.039062,618.924255 
   C1303.883667,621.438354 1301.488892,623.766724 1299.032593,625.994141 
   C1297.966919,626.960449 1296.293579,627.276489 1295.285645,628.279175 
   C1294.606323,628.955017 1294.037109,630.771118 1294.433350,631.268372 
   C1295.103149,632.108765 1296.570557,632.655273 1297.714355,632.676392 
   C1298.772705,632.695862 1299.846924,631.864563 1301.600708,631.067871 
M1227.035156,526.701050 
   C1227.258667,525.993042 1227.482178,525.284973 1227.784790,524.326233 
   C1220.278931,526.505493 1217.816772,530.147400 1220.914062,535.507507 
   C1222.991943,532.562988 1224.844116,529.938416 1227.035156,526.701050 
M1241.949951,567.638245 
   C1241.655762,566.331787 1241.361572,565.025391 1240.979492,563.328491 
   C1236.239868,567.659241 1236.008911,571.855652 1240.346191,576.596375 
   C1240.942505,573.549072 1241.437500,571.019226 1241.949951,567.638245 
M1370.975830,410.501953 
   C1371.398682,411.874786 1371.821655,413.247620 1372.244507,414.620483 
   C1372.812012,414.603668 1373.379517,414.586884 1373.946899,414.570068 
   C1374.684814,411.508179 1375.422729,408.446289 1376.160645,405.384399 
   C1375.497314,405.142151 1374.833862,404.899902 1374.170532,404.657654 
   C1373.141479,406.353271 1372.112427,408.048920 1370.975830,410.501953 
M1377.632202,427.165405 
   C1380.002563,427.385803 1382.373047,427.606171 1384.743408,427.826569 
   C1384.858154,427.070770 1384.972900,426.314972 1385.087646,425.559174 
   C1381.892212,424.735474 1378.696899,423.911774 1375.501587,423.088074 
   C1375.288696,423.535004 1375.075806,423.981964 1374.862915,424.428925 
   C1375.552612,425.224915 1376.242432,426.020905 1377.632202,427.165405 
M1230.421143,588.933716 
   C1231.834351,590.071960 1233.247559,591.210205 1234.660767,592.348450 
   C1235.117676,591.919067 1235.574585,591.489685 1236.031494,591.060303 
   C1234.198853,588.377991 1232.366089,585.695679 1230.533447,583.013367 
   C1229.941162,583.327576 1229.348877,583.641785 1228.756592,583.956055 
   C1229.165161,585.413757 1229.573730,586.871460 1230.421143,588.933716 
M1239.646118,532.420654 
   C1239.712036,533.115051 1239.778076,533.809448 1239.952759,535.647705 
   C1242.619507,531.881042 1245.435791,529.459717 1244.102173,525.782288 
   C1243.471191,525.568665 1242.840210,525.355042 1242.209351,525.141479 
   C1241.400757,527.341797 1240.592285,529.542114 1239.646118,532.420654 
z"
        />
        <path
            fill="#D62C34"
            opacity="1.000000"
            stroke="none"
            d="
M576.110596,751.789917 
   C577.976868,762.363770 579.783325,772.510864 581.718750,783.382263 
   C563.200012,786.651367 544.982666,789.867249 526.342041,793.157898 
   C509.081177,695.300598 491.914215,597.975525 474.665894,500.189148 
   C487.638702,497.899872 500.059265,495.691284 512.486145,493.518555 
   C541.027832,488.528412 569.508972,483.145721 598.136902,478.710388 
   C619.600342,475.385071 640.272644,478.547638 658.870056,489.553925 
   C658.870056,492.394836 658.870056,494.813232 658.870056,497.231598 
   C660.593506,496.140991 662.316956,495.050385 664.803406,493.476868 
   C674.462524,499.935638 683.098938,509.127380 688.844238,520.570068 
   C704.862427,552.473022 708.366333,584.963928 689.823792,617.029236 
   C676.183105,640.617798 653.999084,652.688965 627.989136,657.948669 
   C607.592285,662.073242 587.020447,665.331543 566.528381,668.986450 
   C565.092041,669.242676 563.673035,669.596069 561.747986,670.011780 
   C566.550476,697.327515 571.300598,724.345337 576.110596,751.789917 
M624.121887,535.212585 
   C617.457703,531.893860 610.442871,531.282288 603.197571,532.534241 
   C592.213867,534.432251 581.229004,536.324463 570.252869,538.265381 
   C560.152893,540.051270 550.063782,541.898071 539.461365,543.809692 
   C543.753418,568.144348 547.959900,591.993286 552.203369,616.052307 
   C553.729919,615.974792 554.873291,616.036377 555.971863,615.843079 
   C575.634521,612.384827 595.318542,609.038696 614.942688,605.373047 
   C634.606628,601.700012 645.679871,587.094849 643.140930,567.313171 
   C642.148132,559.578003 639.074951,552.109924 637.110168,545.094666 
   C638.807556,542.525940 640.664124,539.716431 642.520630,536.906921 
   C640.593994,535.857788 638.425537,533.680237 636.798462,534.028931 
   C634.468933,534.528259 632.534912,536.873230 629.867493,538.848022 
   C628.950256,538.258179 626.865173,536.917297 624.121887,535.212585 
M674.524597,574.650452 
   C676.308655,573.909851 679.481201,573.401245 679.650085,572.390686 
   C680.697021,566.127380 679.139648,560.259521 675.765137,554.014954 
   C668.817383,561.261475 664.680908,568.905212 662.084106,577.331848 
   C661.719299,578.515381 663.860046,581.801819 664.718201,581.757080 
   C666.559082,581.661194 668.491028,580.236084 670.062439,578.957825 
   C671.485352,577.800415 672.479187,576.115356 674.524597,574.650452 
M664.593323,638.514648 
   C668.635193,636.488647 669.617310,628.587280 665.946838,626.302612 
   C665.226807,625.854309 663.118469,626.512390 662.439270,627.321960 
   C659.859192,630.397339 660.609558,634.945068 664.593323,638.514648 
M684.823669,546.737183 
   C683.588562,545.623535 682.353394,544.509888 681.118225,543.396240 
   C680.665344,543.663330 680.212402,543.930420 679.759521,544.197510 
   C680.648071,547.458374 681.192810,550.879456 682.599792,553.898743 
   C683.138245,555.054199 685.648010,555.291199 687.259399,555.946777 
   C687.486206,554.252747 688.125427,552.477234 687.812012,550.889893 
   C687.551453,549.570618 686.170532,548.472595 684.823669,546.737183 
M643.561218,645.075623 
   C642.509155,642.764343 640.986877,642.335388 639.758606,644.681885 
   C638.962524,646.202637 639.004272,648.161865 638.670654,649.924683 
   C640.213562,649.481750 641.871460,649.246887 643.240662,648.489868 
   C643.762573,648.201355 643.622253,646.715149 643.561218,645.075623 
z"
        />
        <path
            fill="#D62D35"
            opacity="1.000000"
            stroke="none"
            d="
M836.971558,689.932739 
   C836.159302,690.117493 835.347107,690.302246 834.026794,690.558594 
   C833.258301,690.630066 833.081299,690.751587 832.987854,690.994873 
   C827.734131,691.837952 822.428467,692.459473 817.261292,693.671570 
   C815.879395,693.995728 814.148438,695.985168 813.887207,697.444397 
   C811.364563,711.537231 809.101440,725.676880 806.817810,739.811829 
   C806.425537,742.239990 805.922363,743.987183 802.853027,744.500732 
   C784.792358,747.522278 766.772522,750.788147 748.735168,753.949890 
   C748.287720,754.028320 747.796082,753.854553 746.668213,753.720154 
   C748.576965,743.686768 750.434998,733.788147 752.345215,723.899658 
   C769.553650,634.818054 786.766296,545.737305 803.999329,456.660400 
   C804.944824,451.773346 804.596069,445.446014 807.550537,442.474274 
   C810.466675,439.541138 816.800598,439.947571 821.660278,439.020477 
   C827.383484,437.928589 833.110596,436.781433 838.888123,436.100311 
   C840.393677,435.922791 842.848816,436.933441 843.593994,438.167572 
   C867.090149,477.078857 890.428772,516.085327 914.033325,555.481201 
   C912.737183,555.837280 911.696716,556.123108 909.496094,556.727600 
   C918.372620,560.525818 921.585999,568.068848 925.664368,574.868042 
   C951.275024,617.564209 976.852051,660.280457 1002.434021,702.993835 
   C1003.433777,704.663147 1004.369629,706.370728 1005.667542,708.641846 
   C991.458740,711.167419 977.919922,713.577942 964.379395,715.978516 
   C959.625854,716.821167 954.821655,717.441650 950.128845,718.540344 
   C947.171265,719.232788 945.651611,718.446411 944.200256,715.793091 
   C937.488403,703.522583 930.468018,691.420593 923.779846,679.137573 
   C922.174072,676.188599 920.519653,675.148865 917.117493,675.846191 
   C909.201721,677.468689 901.200623,678.674255 892.920654,680.102844 
   C893.440186,677.104553 893.847839,674.751770 894.255493,672.398987 
   C893.815491,672.085510 893.375488,671.772095 892.935486,671.458618 
   C890.796692,673.506287 888.258057,675.280212 886.621155,677.671387 
   C884.615540,680.601135 882.287781,682.496033 878.338501,682.653442 
   C877.601746,681.905273 877.322998,681.465149 877.044312,681.024963 
   C877.044312,681.024963 877.004028,680.996338 877.268005,680.944336 
   C877.410706,680.535461 877.289490,680.178467 877.168274,679.821533 
   C877.168274,679.821533 877.123596,679.958801 877.092102,679.640015 
   C876.739868,679.155701 876.419067,678.990295 876.098328,678.824890 
   C876.098328,678.824890 876.084778,678.915527 876.028442,678.652832 
   C875.650391,678.255310 875.328552,678.120605 875.006775,677.985840 
   C874.229248,677.351746 873.451782,676.717651 872.211060,675.767029 
   C871.186890,675.249146 870.625916,675.047852 870.065002,674.846497 
   C870.065002,674.846497 870.065796,674.934937 869.992798,674.703003 
   C869.661072,674.226685 869.360962,674.154785 869.019531,674.255432 
   C867.891052,673.916321 866.762634,673.577271 865.086304,673.300476 
   C864.225586,673.481567 864.079895,673.707947 864.101318,674.041931 
   C864.101318,674.041931 864.025085,674.017822 863.737549,674.220459 
   C863.559509,675.477600 863.669067,676.532104 863.778625,677.586609 
   C866.034485,679.589661 868.290405,681.592773 871.235229,684.207581 
   C862.105469,685.541321 853.501648,686.798279 844.593872,687.677856 
   C844.932556,686.663879 846.194153,685.935669 846.115723,685.405823 
   C845.666016,682.369812 837.972595,679.076843 835.317627,680.635681 
   C832.834167,682.093811 832.531372,685.335388 834.633911,687.296814 
   C835.488831,688.094360 836.196777,689.049377 836.971558,689.932739 
M869.532471,579.966125 
   C868.695251,577.669861 867.858032,575.373657 867.020813,573.077393 
   C862.667419,574.755249 862.903870,570.510559 861.816711,568.650452 
   C854.956116,556.911682 848.371582,545.011597 840.901001,531.754333 
   C835.000854,567.714172 829.347656,602.168823 823.577087,637.338562 
   C836.492432,635.111450 848.551819,633.031982 861.029175,630.880432 
   C859.753967,633.378906 858.356262,635.066345 858.123535,636.901550 
   C857.932983,638.403992 858.772095,640.407288 859.831116,641.575378 
   C860.612610,642.437317 863.102417,643.023132 863.736084,642.472351 
   C864.925659,641.438232 865.955872,639.471985 865.905945,637.930481 
   C865.824341,635.409790 864.824890,632.918762 864.185181,630.302979 
   C873.777588,628.564209 883.303162,626.837524 893.321594,625.021545 
   C886.191101,612.323181 879.465088,600.471436 872.892151,588.535461 
   C871.549866,586.097900 870.782349,583.343750 869.532471,579.966125 
M816.782288,584.247498 
   C818.782166,578.163757 821.060852,572.115173 818.932861,565.314087 
   C815.527710,571.053894 812.968079,577.031555 811.101807,583.218262 
   C810.260925,586.005981 810.802856,589.210876 810.717346,592.226440 
   C812.632996,589.799622 814.548584,587.372803 816.782288,584.247498 
M780.897461,693.778503 
   C779.514954,694.755981 777.012329,695.668640 776.951355,696.722473 
   C776.733093,700.496460 776.976807,704.406433 777.861755,708.067749 
   C778.125061,709.157104 781.189941,710.351868 782.740723,710.096863 
   C783.953430,709.897522 785.427124,707.645325 785.709045,706.107910 
   C786.560974,701.462646 785.404419,697.252869 780.897461,693.778503 
M894.088745,656.420837 
   C896.429260,659.230652 899.539307,660.327881 902.146790,657.665283 
   C903.555237,656.227173 904.271606,652.700378 903.474609,650.944702 
   C902.795410,649.448608 899.294739,648.130676 897.415344,648.522766 
   C894.006958,649.233643 892.395325,652.014404 894.088745,656.420837 
M951.773071,692.258057 
   C955.121094,688.992615 959.262329,686.177490 959.356812,680.299927 
   C952.445068,682.064575 946.260010,689.668396 946.547852,695.685242 
   C948.257202,694.609802 949.754333,693.667969 951.773071,692.258057 
M911.028198,644.826782 
   C911.363342,646.422180 911.284119,648.311646 912.149414,649.530762 
   C913.024536,650.763672 914.834778,651.332947 916.235291,652.192932 
   C916.444763,650.611450 917.211548,648.826233 916.726135,647.498840 
   C916.085144,645.745728 914.516052,644.301331 913.180725,642.879333 
   C913.044678,642.734497 911.777893,643.651489 911.028198,644.826782 
M804.958130,556.844360 
   C803.280273,557.643982 801.422119,558.234009 800.062683,559.403625 
   C799.757019,559.666626 800.867554,562.431458 801.931335,563.156433 
   C802.763428,563.723511 805.592834,563.147766 805.694763,562.679382 
   C806.073364,560.939758 805.691040,559.034607 804.958130,556.844360 
z"
        />
        <path
            fill="#D62D35"
            opacity="1.000000"
            stroke="none"
            d="
M1079.517334,393.544220 
   C1081.718994,393.219788 1083.491455,392.992493 1085.634766,392.717590 
   C1087.455811,402.916443 1089.295532,412.697113 1090.846436,422.523376 
   C1091.057007,423.857117 1089.957397,425.492828 1089.226562,426.861816 
   C1087.106812,430.832153 1084.866211,434.738007 1082.672974,438.669128 
   C1083.095337,439.066589 1083.517700,439.464081 1083.939941,439.861542 
   C1086.942017,438.846924 1089.944092,437.832306 1093.768677,436.539764 
   C1094.212280,447.970001 1095.515625,458.734894 1090.658447,470.429382 
   C1093.952393,469.293457 1096.117798,468.546692 1098.862305,467.600220 
   C1102.495483,488.135803 1106.127441,508.665192 1109.640381,528.522095 
   C1106.742432,529.069336 1104.120117,529.465454 1101.589966,530.186096 
   C1101.318359,530.263428 1101.314575,533.080261 1101.643799,533.184692 
   C1104.420288,534.065186 1107.303223,534.609985 1111.010010,535.449524 
   C1111.010010,539.451782 1110.995483,544.449585 1111.022827,549.447144 
   C1111.028076,550.404419 1111.085693,552.167480 1111.308960,552.198608 
   C1115.842285,552.830872 1114.425049,556.692993 1114.960938,559.188538 
   C1116.462769,566.182800 1117.734619,573.240845 1118.697021,580.328003 
   C1118.996460,582.532593 1118.017090,584.910828 1117.623169,587.209534 
   C1117.143555,587.172913 1116.664062,587.136292 1116.184448,587.099670 
   C1116.184448,587.427734 1116.044067,587.928650 1116.204590,588.059021 
   C1123.849609,594.267822 1122.559326,603.574158 1124.166382,611.744507 
   C1127.474609,628.564392 1130.253906,645.488708 1133.213013,662.376709 
   C1134.543457,669.968933 1135.783569,677.577087 1137.120850,685.511292 
   C1118.009277,688.875732 1099.370972,692.156799 1080.020508,695.563232 
   C1078.761108,688.719116 1077.535034,682.256531 1076.386353,675.780273 
   C1072.316406,652.834778 1068.316406,629.876709 1064.168091,606.945496 
   C1063.603882,603.825989 1064.442749,601.931580 1066.711426,599.634033 
   C1070.175415,596.126038 1072.947266,591.890564 1075.718384,587.766052 
   C1076.715576,586.281799 1076.861450,584.225525 1077.395752,582.430176 
   C1076.969238,582.092712 1076.542603,581.755188 1076.115967,581.417664 
   C1071.558716,585.090393 1067.001465,588.763062 1061.814941,592.942749 
   C1050.615234,529.463928 1039.475830,466.326813 1028.234619,402.612122 
   C1045.438599,399.577209 1062.263306,396.609283 1079.517334,393.544220 
M1079.075195,634.471069 
   C1078.668945,630.360046 1078.262695,626.249023 1077.856445,622.138000 
   C1077.320923,622.173889 1076.785400,622.209839 1076.249756,622.245789 
   C1074.554077,630.637573 1071.780029,638.969971 1074.544067,647.607239 
   C1075.332764,647.708130 1076.121338,647.808960 1076.910034,647.909851 
   C1077.630249,643.744629 1078.350464,639.579407 1079.075195,634.471069 
M1073.645752,405.038666 
   C1071.531372,405.639038 1069.416992,406.239380 1066.496704,407.068573 
   C1071.740479,410.993378 1077.073242,410.688995 1082.628418,406.572113 
   C1079.495117,405.991302 1076.994629,405.527802 1073.645752,405.038666 
M1085.969727,666.629028 
   C1084.552979,664.912476 1083.297607,663.000183 1081.611084,661.611206 
   C1081.165649,661.244263 1078.338745,662.780334 1078.386108,662.953186 
   C1079.005981,665.208801 1079.946411,667.376343 1080.806152,669.566101 
   C1082.502563,668.842896 1084.198975,668.119751 1085.969727,666.629028 
M1073.477051,611.935486 
   C1075.033813,610.291870 1076.590576,608.648315 1078.147339,607.004700 
   C1077.678467,606.446045 1077.209717,605.887329 1076.740845,605.328674 
   C1074.033081,607.090210 1071.325317,608.851807 1068.617554,610.613342 
   C1068.817383,611.131287 1069.017334,611.649170 1069.217163,612.167114 
   C1070.416260,612.167114 1071.615234,612.167114 1073.477051,611.935486 
M1087.604614,608.535034 
   C1089.378174,613.442139 1091.130737,613.873291 1095.230225,610.208374 
   C1093.155151,608.108459 1091.324463,605.658752 1087.604614,608.535034 
z"
        />
        <path
            fill="#FAEDEC"
            opacity="1.000000"
            stroke="none"
            d="
M837.185669,690.203125 
   C836.196777,689.049377 835.488831,688.094360 834.633911,687.296814 
   C832.531372,685.335388 832.834167,682.093811 835.317627,680.635681 
   C837.972595,679.076843 845.666016,682.369812 846.115723,685.405823 
   C846.194153,685.935669 844.932556,686.663879 844.150269,687.635742 
   C841.807068,688.805176 839.603455,689.639282 837.185669,690.203125 
z"
        />
        <path
            fill="#FAF0EF"
            opacity="1.000000"
            stroke="none"
            d="
M876.669556,681.011963 
   C877.322998,681.465149 877.601746,681.905273 877.964233,682.606567 
   C876.538818,683.083008 875.029724,683.298340 873.520569,683.513672 
   C873.456787,682.911499 873.393005,682.309326 873.329224,681.707092 
   C874.317749,681.471008 875.306274,681.234985 876.669556,681.011963 
z"
        />
        <path
            fill="#FAF0EF"
            opacity="1.000000"
            stroke="none"
            d="
M863.848938,677.152039 
   C863.669067,676.532104 863.559509,675.477600 863.750183,674.258423 
   C864.006714,674.968262 863.963013,675.842896 863.848938,677.152039 
z"
        />
        <path
            fill="#FAF0EF"
            opacity="1.000000"
            stroke="none"
            d="
M870.202271,675.130005 
   C870.625916,675.047852 871.186890,675.249146 871.910339,675.709106 
   C871.495117,675.782898 870.917358,675.598206 870.202271,675.130005 
z"
        />
        <path
            fill="#FAF0EF"
            opacity="1.000000"
            stroke="none"
            d="
M875.124146,678.222168 
   C875.328552,678.120605 875.650391,678.255310 876.072632,678.640747 
   C875.862610,678.747070 875.552124,678.602844 875.124146,678.222168 
z"
        />
        <path
            fill="#FAF0EF"
            opacity="1.000000"
            stroke="none"
            d="
M864.274292,673.995605 
   C864.079895,673.707947 864.225586,673.481567 864.747314,673.413513 
   C864.848022,673.690430 864.678284,673.852051 864.274292,673.995605 
z"
        />
        <path
            fill="#FAF0EF"
            opacity="1.000000"
            stroke="none"
            d="
M869.152954,674.443115 
   C869.360962,674.154785 869.661072,674.226685 870.032715,674.684082 
   C869.819031,674.937866 869.532593,674.849121 869.152954,674.443115 
z"
        />
        <path
            fill="#FAF0EF"
            opacity="1.000000"
            stroke="none"
            d="
M876.182495,679.091736 
   C876.419067,678.990295 876.739868,679.155701 877.142822,679.588623 
   C876.905518,679.690186 876.586060,679.524353 876.182495,679.091736 
z"
        />
        <path
            fill="#FAF0EF"
            opacity="1.000000"
            stroke="none"
            d="
M877.106384,680.123169 
   C877.289490,680.178467 877.410706,680.535461 877.278992,680.966309 
   C877.032104,680.835083 877.038269,680.629883 877.106384,680.123169 
z"
        />
        <path
            fill="#FAEDEC"
            opacity="1.000000"
            stroke="none"
            d="
M833.126099,691.012207 
   C833.081299,690.751587 833.258301,690.630066 833.650452,690.737061 
   C833.627991,690.957214 833.455322,691.019104 833.126099,691.012207 
z"
        />
        <path
            fill="#FFFEFE"
            opacity="1.000000"
            stroke="none"
            d="
M1252.593628,445.383606 
   C1251.019775,436.469482 1249.543091,427.975311 1248.085815,419.592407 
   C1256.272461,415.339813 1266.645996,418.753418 1273.126465,412.103546 
   C1275.975342,412.401398 1278.778076,413.292023 1281.361816,412.863159 
   C1293.866577,410.787628 1306.309082,408.447296 1319.039795,410.985840 
   C1334.902344,414.148895 1346.184326,422.940002 1352.635254,437.885315 
   C1353.851685,440.703674 1355.615601,443.284424 1357.101074,445.989044 
   C1357.414185,446.559235 1357.888184,447.221802 1357.825684,447.791168 
   C1356.178467,462.820709 1361.660522,476.903137 1363.718872,491.427765 
   C1366.077026,508.067352 1369.840210,524.512207 1372.058594,541.166138 
   C1375.364502,565.984009 1364.065430,585.124939 1340.257690,592.780090 
   C1325.147461,597.638550 1309.130859,599.687988 1293.508301,602.937073 
   C1289.605225,603.748840 1285.653931,604.328674 1281.752197,605.010376 
   C1277.947510,599.447937 1278.346802,593.168213 1277.320679,587.000732 
   C1269.480103,539.873535 1260.983521,492.855469 1252.593628,445.383606 
z"
        />
        <path
            fill="#FBF4F3"
            opacity="1.000000"
            stroke="none"
            d="
M1401.735840,470.289795 
   C1396.779541,468.713287 1395.826904,466.068634 1398.273071,461.855957 
   C1400.974243,457.204071 1400.646606,452.366760 1398.526123,447.517883 
   C1397.704956,445.639954 1397.199707,443.623810 1396.505859,441.535828 
   C1400.845703,441.096313 1407.762085,444.472839 1409.192749,447.557526 
   C1409.606445,448.449738 1409.727661,450.060547 1409.202148,450.711609 
   C1405.620972,455.147980 1406.980713,459.692291 1408.347412,464.410522 
   C1408.957031,466.514771 1408.863770,468.822510 1409.089478,471.037903 
   C1406.760864,470.834198 1404.432251,470.630493 1401.735840,470.289795 
z"
        />
        <path
            fill="#FBF2F2"
            opacity="1.000000"
            stroke="none"
            d="
M1301.257324,631.237915 
   C1299.846924,631.864563 1298.772705,632.695862 1297.714355,632.676392 
   C1296.570557,632.655273 1295.103149,632.108765 1294.433350,631.268372 
   C1294.037109,630.771118 1294.606323,628.955017 1295.285645,628.279175 
   C1296.293579,627.276489 1297.966919,626.960449 1299.032593,625.994141 
   C1301.488892,623.766724 1303.883667,621.438354 1306.039062,618.924255 
   C1307.295166,617.459167 1307.720459,615.219116 1309.085083,613.923157 
   C1311.707520,611.432495 1314.995850,612.639648 1315.957886,616.144714 
   C1317.294067,621.013306 1314.312256,626.393555 1309.500854,628.020996 
   C1306.829468,628.924500 1304.231689,630.045105 1301.257324,631.237915 
z"
        />
        <path
            fill="#F9E8E6"
            opacity="1.000000"
            stroke="none"
            d="
M1226.865723,527.007446 
   C1224.844116,529.938416 1222.991943,532.562988 1220.914062,535.507507 
   C1217.816772,530.147400 1220.278931,526.505493 1227.784790,524.326233 
   C1227.482178,525.284973 1227.258667,525.993042 1226.865723,527.007446 
z"
        />
        <path
            fill="#F8E1E0"
            opacity="1.000000"
            stroke="none"
            d="
M1241.941284,568.063782 
   C1241.437500,571.019226 1240.942505,573.549072 1240.346191,576.596375 
   C1236.008911,571.855652 1236.239868,567.659241 1240.979492,563.328491 
   C1241.361572,565.025391 1241.655762,566.331787 1241.941284,568.063782 
z"
        />
        <path
            fill="#F8DCDB"
            opacity="1.000000"
            stroke="none"
            d="
M1371.029541,410.123230 
   C1372.112427,408.048920 1373.141479,406.353271 1374.170532,404.657654 
   C1374.833862,404.899902 1375.497314,405.142151 1376.160645,405.384399 
   C1375.422729,408.446289 1374.684814,411.508179 1373.946899,414.570068 
   C1373.379517,414.586884 1372.812012,414.603668 1372.244507,414.620483 
   C1371.821655,413.247620 1371.398682,411.874786 1371.029541,410.123230 
z"
        />
        <path
            fill="#F8E1E0"
            opacity="1.000000"
            stroke="none"
            d="
M1377.282227,426.991150 
   C1376.242432,426.020905 1375.552612,425.224915 1374.862915,424.428925 
   C1375.075806,423.981964 1375.288696,423.535004 1375.501587,423.088074 
   C1378.696899,423.911774 1381.892212,424.735474 1385.087646,425.559174 
   C1384.972900,426.314972 1384.858154,427.070770 1384.743408,427.826569 
   C1382.373047,427.606171 1380.002563,427.385803 1377.282227,426.991150 
z"
        />
        <path
            fill="#F8DCDB"
            opacity="1.000000"
            stroke="none"
            d="
M1230.201660,588.631470 
   C1229.573730,586.871460 1229.165161,585.413757 1228.756592,583.956055 
   C1229.348877,583.641785 1229.941162,583.327576 1230.533447,583.013367 
   C1232.366089,585.695679 1234.198853,588.377991 1236.031494,591.060303 
   C1235.574585,591.489685 1235.117676,591.919067 1234.660767,592.348450 
   C1233.247559,591.210205 1231.834351,590.071960 1230.201660,588.631470 
z"
        />
        <path
            fill="#F8DCDB"
            opacity="1.000000"
            stroke="none"
            d="
M1239.714966,532.081543 
   C1240.592285,529.542114 1241.400757,527.341797 1242.209351,525.141479 
   C1242.840210,525.355042 1243.471191,525.568665 1244.102173,525.782288 
   C1245.435791,529.459717 1242.619507,531.881042 1239.952759,535.647705 
   C1239.778076,533.809448 1239.712036,533.115051 1239.714966,532.081543 
z"
        />
        <path
            fill="#FEFDFD"
            opacity="1.000000"
            stroke="none"
            d="
M624.450989,535.394531 
   C626.865173,536.917297 628.950256,538.258179 629.867493,538.848022 
   C632.534912,536.873230 634.468933,534.528259 636.798462,534.028931 
   C638.425537,533.680237 640.593994,535.857788 642.520630,536.906921 
   C640.664124,539.716431 638.807556,542.525940 637.110168,545.094666 
   C639.074951,552.109924 642.148132,559.578003 643.140930,567.313171 
   C645.679871,587.094849 634.606628,601.700012 614.942688,605.373047 
   C595.318542,609.038696 575.634521,612.384827 555.971863,615.843079 
   C554.873291,616.036377 553.729919,615.974792 552.203369,616.052307 
   C547.959900,591.993286 543.753418,568.144348 539.461365,543.809692 
   C550.063782,541.898071 560.152893,540.051270 570.252869,538.265381 
   C581.229004,536.324463 592.213867,534.432251 603.197571,532.534241 
   C610.442871,531.282288 617.457703,531.893860 624.450989,535.394531 
z"
        />
        <path
            fill="#FBF4F3"
            opacity="1.000000"
            stroke="none"
            d="
M674.093384,574.656738 
   C672.479187,576.115356 671.485352,577.800415 670.062439,578.957825 
   C668.491028,580.236084 666.559082,581.661194 664.718201,581.757080 
   C663.860046,581.801819 661.719299,578.515381 662.084106,577.331848 
   C664.680908,568.905212 668.817383,561.261475 675.765137,554.014954 
   C679.139648,560.259521 680.697021,566.127380 679.650085,572.390686 
   C679.481201,573.401245 676.308655,573.909851 674.093384,574.656738 
z"
        />
        <path
            fill="#FAEDEC"
            opacity="1.000000"
            stroke="none"
            d="
M664.254578,638.388184 
   C660.609558,634.945068 659.859192,630.397339 662.439270,627.321960 
   C663.118469,626.512390 665.226807,625.854309 665.946838,626.302612 
   C669.617310,628.587280 668.635193,636.488647 664.254578,638.388184 
z"
        />
        <path
            fill="#F9E8E6"
            opacity="1.000000"
            stroke="none"
            d="
M685.054749,547.006897 
   C686.170532,548.472595 687.551453,549.570618 687.812012,550.889893 
   C688.125427,552.477234 687.486206,554.252747 687.259399,555.946777 
   C685.648010,555.291199 683.138245,555.054199 682.599792,553.898743 
   C681.192810,550.879456 680.648071,547.458374 679.759521,544.197510 
   C680.212402,543.930420 680.665344,543.663330 681.118225,543.396240 
   C682.353394,544.509888 683.588562,545.623535 685.054749,547.006897 
z"
        />
        <path
            fill="#F8E1E0"
            opacity="1.000000"
            stroke="none"
            d="
M643.672485,645.425171 
   C643.622253,646.715149 643.762573,648.201355 643.240662,648.489868 
   C641.871460,649.246887 640.213562,649.481750 638.670654,649.924683 
   C639.004272,648.161865 638.962524,646.202637 639.758606,644.681885 
   C640.986877,642.335388 642.509155,642.764343 643.672485,645.425171 
z"
        />
        <path
            fill="#FEFCFB"
            opacity="1.000000"
            stroke="none"
            d="
M869.641174,580.350830 
   C870.782349,583.343750 871.549866,586.097900 872.892151,588.535461 
   C879.465088,600.471436 886.191101,612.323181 893.321594,625.021545 
   C883.303162,626.837524 873.777588,628.564209 864.185181,630.302979 
   C864.824890,632.918762 865.824341,635.409790 865.905945,637.930481 
   C865.955872,639.471985 864.925659,641.438232 863.736084,642.472351 
   C863.102417,643.023132 860.612610,642.437317 859.831116,641.575378 
   C858.772095,640.407288 857.932983,638.403992 858.123535,636.901550 
   C858.356262,635.066345 859.753967,633.378906 861.029175,630.880432 
   C848.551819,633.031982 836.492432,635.111450 823.577087,637.338562 
   C829.347656,602.168823 835.000854,567.714172 840.901001,531.754333 
   C848.371582,545.011597 854.956116,556.911682 861.816711,568.650452 
   C862.903870,570.510559 862.667419,574.755249 867.020813,573.077393 
   C867.858032,575.373657 868.695251,577.669861 869.641174,580.350830 
z"
        />
        <path
            fill="#FAEDEC"
            opacity="1.000000"
            stroke="none"
            d="
M816.623230,584.596680 
   C814.548584,587.372803 812.632996,589.799622 810.717346,592.226440 
   C810.802856,589.210876 810.260925,586.005981 811.101807,583.218262 
   C812.968079,577.031555 815.527710,571.053894 818.932861,565.314087 
   C821.060852,572.115173 818.782166,578.163757 816.623230,584.596680 
z"
        />
        <path
            fill="#FBF2F2"
            opacity="1.000000"
            stroke="none"
            d="
M781.212036,693.953369 
   C785.404419,697.252869 786.560974,701.462646 785.709045,706.107910 
   C785.427124,707.645325 783.953430,709.897522 782.740723,710.096863 
   C781.189941,710.351868 778.125061,709.157104 777.861755,708.067749 
   C776.976807,704.406433 776.733093,700.496460 776.951355,696.722473 
   C777.012329,695.668640 779.514954,694.755981 781.212036,693.953369 
z"
        />
        <path
            fill="#FAEDEC"
            opacity="1.000000"
            stroke="none"
            d="
M893.919434,656.080383 
   C892.395325,652.014404 894.006958,649.233643 897.415344,648.522766 
   C899.294739,648.130676 902.795410,649.448608 903.474609,650.944702 
   C904.271606,652.700378 903.555237,656.227173 902.146790,657.665283 
   C899.539307,660.327881 896.429260,659.230652 893.919434,656.080383 
z"
        />
        <path
            fill="#FAEAEA"
            opacity="1.000000"
            stroke="none"
            d="
M951.512329,692.492065 
   C949.754333,693.667969 948.257202,694.609802 946.547852,695.685242 
   C946.260010,689.668396 952.445068,682.064575 959.356812,680.299927 
   C959.262329,686.177490 955.121094,688.992615 951.512329,692.492065 
z"
        />
        <path
            fill="#F8E1E0"
            opacity="1.000000"
            stroke="none"
            d="
M911.031982,644.451233 
   C911.777893,643.651489 913.044678,642.734497 913.180725,642.879333 
   C914.516052,644.301331 916.085144,645.745728 916.726135,647.498840 
   C917.211548,648.826233 916.444763,650.611450 916.235352,652.192932 
   C914.834778,651.332947 913.024536,650.763672 912.149414,649.530762 
   C911.284119,648.311646 911.363342,646.422180 911.031982,644.451233 
z"
        />
        <path
            fill="#FAEAEA"
            opacity="1.000000"
            stroke="none"
            d="
M805.276367,557.017944 
   C805.691040,559.034607 806.073364,560.939758 805.694763,562.679382 
   C805.592834,563.147766 802.763428,563.723511 801.931335,563.156433 
   C800.867554,562.431458 799.757019,559.666626 800.062683,559.403625 
   C801.422119,558.234009 803.280273,557.643982 805.276367,557.017944 
z"
        />
        <path
            fill="#FAEAEA"
            opacity="1.000000"
            stroke="none"
            d="
M1079.072998,634.942627 
   C1078.350464,639.579407 1077.630249,643.744629 1076.910034,647.909851 
   C1076.121338,647.808960 1075.332764,647.708130 1074.544067,647.607239 
   C1071.780029,638.969971 1074.554077,630.637573 1076.249756,622.245789 
   C1076.785400,622.209839 1077.320923,622.173889 1077.856445,622.138000 
   C1078.262695,626.249023 1078.668945,630.360046 1079.072998,634.942627 
z"
        />
        <path
            fill="#F8E1E0"
            opacity="1.000000"
            stroke="none"
            d="
M1074.069824,405.051483 
   C1076.994629,405.527802 1079.495117,405.991302 1082.628418,406.572113 
   C1077.073242,410.688995 1071.740479,410.993378 1066.496704,407.068573 
   C1069.416992,406.239380 1071.531372,405.639038 1074.069824,405.051483 
z"
        />
        <path
            fill="#FAEDEC"
            opacity="1.000000"
            stroke="none"
            d="
M1085.932495,667.012817 
   C1084.198975,668.119751 1082.502563,668.842896 1080.806152,669.566040 
   C1079.946411,667.376343 1079.005981,665.208801 1078.386108,662.953186 
   C1078.338745,662.780334 1081.165649,661.244263 1081.611084,661.611206 
   C1083.297607,663.000183 1084.552979,664.912476 1085.932495,667.012817 
z"
        />
        <path
            fill="#F8E1E0"
            opacity="1.000000"
            stroke="none"
            d="
M1073.145630,612.051270 
   C1071.615234,612.167114 1070.416260,612.167114 1069.217163,612.167114 
   C1069.017334,611.649170 1068.817383,611.131287 1068.617554,610.613342 
   C1071.325317,608.851807 1074.033081,607.090210 1076.740845,605.328674 
   C1077.209717,605.887329 1077.678467,606.446045 1078.147339,607.004700 
   C1076.590576,608.648315 1075.033813,610.291870 1073.145630,612.051270 
z"
        />
        <path
            fill="#F8E1E0"
            opacity="1.000000"
            stroke="none"
            d="
M1087.738037,608.200073 
   C1091.324463,605.658752 1093.155151,608.108459 1095.230225,610.208374 
   C1091.130737,613.873291 1089.378174,613.442139 1087.738037,608.200073 
z"
        />
    </svg>
);

export default PaidIcon;
