import {
    Box,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
} from '@chakra-ui/react';
import React from 'react';
import { CFormError } from '../../../Typography';
import { InputBaseProps } from './Input.types';
import { useInputBase } from './InputBase.hook';

export const CInputBase = ({
    onChange,
    testId,
    elementSize = '35px',
    inputValue,
    errorText,
    leftElement,
    rightElement,
    isControlledInput = false,
    hideError = false,
    maxW = '100%',
    ...props
}: InputBaseProps): JSX.Element => {
    const { valueProp, styleProps, handleChange } = useInputBase({
        onChange,
        inputValue,
        errorText,
        leftElement,
        rightElement,
        elementSize,
        isControlledInput,
    });
    return (
        <Box width="100%" maxW={maxW}>
            <InputGroup>
                {leftElement && (
                    <InputLeftElement width={elementSize} height="100%">
                        {leftElement}
                    </InputLeftElement>
                )}
                <Input
                    {...props}
                    {...styleProps}
                    {...valueProp}
                    onChange={handleChange}
                    data-test={`${testId}--input`}
                />
                z
                {rightElement && (
                    <InputRightElement width={elementSize} height="100%">
                        {rightElement}
                    </InputRightElement>
                )}
            </InputGroup>
            {!hideError && (
                <CFormError data-test={`${testId}--error`} text={errorText} />
            )}
        </Box>
    );
};
