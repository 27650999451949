import React, { FunctionComponent } from 'react';
import { CardProps } from './Card.types';
import { CardWrapper, CardHeader, CardContent, CardTitle } from './Card.styled';

export const Card: FunctionComponent<CardProps> = ({
    children,
    title,
    compact = false,
    header,
    headerProps,
    contentProps,
    borderless,
    ...rest
}) => {
    return (
        <CardWrapper {...rest}>
            {header && (
                <CardHeader
                    {...headerProps}
                    compact={compact}
                    borderless={borderless}
                >
                    {header}
                </CardHeader>
            )}
            {title && (
                <CardTitle compact={compact} title={title}>
                    {title}
                </CardTitle>
            )}
            <CardContent
                {...contentProps}
                borderTop={!header && !title}
                compact={compact}
                borderless={borderless}
            >
                {children}
            </CardContent>
        </CardWrapper>
    );
};

export default Card;
