import { AxiosInstance } from 'axios';
import { KoddiAPIResponse } from 'types';
import {
    ApproveIOPayload,
    CloseIOPayload,
    CreateIOPayload,
    IOIdentity,
    IOResponse,
    UpdateIOPayload,
} from './InsertionOrders.types';

class InsertionOrderAPI {
    protected axios: AxiosInstance;

    constructor(axios: AxiosInstance) {
        this.axios = axios;
    }

    public create = async ({
        memberGroupId,
        payload,
    }: CreateIOPayload): Promise<IOResponse> => {
        const response = await this.axios.post<KoddiAPIResponse<IOResponse>>(
            `v1/member_groups/${memberGroupId}/insertion_orders`,
            payload
        );
        return response.data.result;
    };

    public update = async ({
        memberGroupId,
        insertionOrderId,
        payload,
    }: UpdateIOPayload): Promise<IOResponse> => {
        const response = await this.axios.patch<KoddiAPIResponse<IOResponse>>(
            `v1/member_groups/${memberGroupId}/insertion_orders/${insertionOrderId}`,
            payload
        );
        return response.data.result;
    };

    public get = async ({
        memberGroupId,
        insertionOrderId,
    }: IOIdentity): Promise<IOResponse> => {
        const response = await this.axios.get<KoddiAPIResponse<IOResponse>>(
            `v1/member_groups/${memberGroupId}/insertion_orders/${insertionOrderId}`
        );
        return response.data.result;
    };

    public approve = async ({
        memberGroupId,
        insertionOrderId,
        last_edited_by,
    }: ApproveIOPayload): Promise<void> => {
        const response = await this.axios.put<KoddiAPIResponse<void>>(
            `v1/member_groups/${memberGroupId}/insertion_orders/${insertionOrderId}/approve`,
            last_edited_by
        );
        return response.data.result;
    };

    public close = async ({
        memberGroupId,
        insertionOrderId,
        last_edited_by,
    }: CloseIOPayload): Promise<void> => {
        const response = await this.axios.put<KoddiAPIResponse<void>>(
            `v1/member_groups/${memberGroupId}/insertion_orders/${insertionOrderId}/close`,
            last_edited_by
        );
        return response.data.result;
    };
}

export default InsertionOrderAPI;
