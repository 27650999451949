import produce, { Draft } from 'immer';
import subDays from 'date-fns/subDays';
import { initialDateRange } from 'app/koddi-components/src/DateRangePicker';
import { campaignReviewIdMap } from 'app/react-ui/utils/reviewIdMap.util';
import { CampaignReviewStatusID } from 'app/react-ui/api/Campaigns';
import { AppFiltersState, FiltersActions, FiltersAction } from './types';
import { addAvailableOperations, mapDefaultMetrics } from './utils';
import { initialContextSlices, newContextSlices } from './constants';

export const initialState: AppFiltersState = {
    dateRange: initialDateRange,
    slices: {
        ...initialContextSlices,
        ...newContextSlices,
    },
    metricDisplayOrder: {
        campaign: [],
        ad_group: [],
        entity: [],
        general: [],
    },
    dimensionDisplayOrder: {
        campaign: [],
        ad_group: [],
        entity: [],
        general: [],
    },
    reportConfig: null,
    reportConfigError: false,
    graphMetric: { label: 'Return on Ad Spend', value: 'ROAS' },
    currency: null,
    filterError: false,
};

/* eslint-disable default-case, no-param-reassign */
const appFiltersReducer = (
    state: AppFiltersState = initialState,
    action: FiltersActions
): AppFiltersState => {
    return produce(state, (draft: Draft<AppFiltersState>) => {
        switch (action.type) {
            case FiltersAction.UPDATE_DATE_RANGE_FILTER: {
                draft.dateRange = action.payload.dateRange;
                break;
            }
            case FiltersAction.UPDATE_GRAPH_METRIC: {
                draft.graphMetric = action.payload.metric;
                break;
            }
            case FiltersAction.DELETE_DATE_RANGE_FILTER: {
                draft.dateRange = {
                    startDate: subDays(new Date(), 7),
                    endDate: subDays(new Date(), 1),
                };
                break;
            }
            case FiltersAction.UPDATE_APP_SLICES: {
                const { context } = action.payload;
                draft.slices[context] = action.payload.slices;
                break;
            }
            case FiltersAction.UPDATE_APP_FILTER_SLICES: {
                const { context } = action.payload;
                draft.slices[context].filters = action.payload.filters;
                break;
            }
            case FiltersAction.GET_REPORT_CONFIG: {
                draft.reportConfigError = false;
                break;
            }
            case FiltersAction.GET_REPORT_CONFIG_SUCCESS: {
                draft.reportConfigError = false;
                draft.reportConfig = addAvailableOperations(
                    action.payload.data
                );

                draft.slices.general.dimensions = [
                    {
                        id: 'campaign_name',
                        name: 'Campaign Name',
                        category: 'Media Structure',
                        description: '',
                        filterable: false,
                        presentation: 'text',
                        selectable: true,
                        type: 'string',
                        contexts: ['general', 'offsite'],
                        acceptedValues: null,
                    },
                ];
                draft.slices.offsite.dimensions = [
                    {
                        id: 'campaign_id',
                        name: 'Campaign Id',
                        category: 'Media Structure',
                        description: '',
                        filterable: false,
                        presentation: 'text',
                        selectable: true,
                        type: 'string',
                        contexts: ['general', 'offsite'],
                        acceptedValues: null,
                    },
                ];
                const fieldOrder = action.payload.data.field_order;
                const reportConfig = action.payload.data;

                // review defaults
                draft.slices.review.filters = [
                    {
                        field: 'review_status',
                        operation: 'IN',
                        value: [
                            campaignReviewIdMap(
                                CampaignReviewStatusID.Approved
                            ),
                            campaignReviewIdMap(CampaignReviewStatusID.Denied),
                            campaignReviewIdMap(
                                CampaignReviewStatusID.UnderReview
                            ),
                        ],
                    },
                ];
                draft.slices.review.metrics = mapDefaultMetrics(
                    fieldOrder.campaign,
                    reportConfig,
                    'campaign'
                );

                // campaign defaults
                draft.slices.campaign.metrics = mapDefaultMetrics(
                    fieldOrder.campaign,
                    reportConfig,
                    'campaign'
                );

                // ad group defaults
                draft.slices.ad_group.metrics = mapDefaultMetrics(
                    fieldOrder.ad_group,
                    reportConfig,
                    'ad_group'
                );

                // entity defaults
                draft.slices.entity.metrics = mapDefaultMetrics(
                    fieldOrder.entity,
                    reportConfig,
                    'entity'
                );
                draft.slices.ad_group_entity.metrics = mapDefaultMetrics(
                    fieldOrder.ad_group_entity,
                    reportConfig,
                    'ad_group_entity'
                );

                draft.slices.media_plan.metrics = mapDefaultMetrics(
                    fieldOrder.media_plan,
                    reportConfig,
                    'media_plan'
                );
                draft.slices.general.metrics = mapDefaultMetrics(
                    fieldOrder.general,
                    reportConfig,
                    'general'
                );

                draft.slices.offsite.metrics = mapDefaultMetrics(
                    fieldOrder.offsite,
                    reportConfig,
                    'offsite'
                );

                draft.metricDisplayOrder.campaign = mapDefaultMetrics(
                    fieldOrder.campaign,
                    reportConfig,
                    'campaign'
                );
                draft.metricDisplayOrder.ad_group =
                    draft.slices.ad_group.metrics;
                draft.metricDisplayOrder.entity = draft.slices.entity.metrics;

                break;
            }
            case FiltersAction.GET_REPORT_CONFIG_ERROR: {
                draft.reportConfigError = true;
                break;
            }
            case FiltersAction.UPDATE_REPORT_CONFIG: {
                draft.reportConfigError = false;
                draft.reportConfig = action.payload.reportConfig;
                break;
            }
            case FiltersAction.UPDATE_METRIC_DISPLAY_ORDER: {
                draft.metricDisplayOrder[action.payload.context] =
                    action.payload.order;
                break;
            }
            case FiltersAction.UPDATE_DIMENSION_DISPLAY_ORDER: {
                draft.dimensionDisplayOrder[action.payload.context] =
                    action.payload.order;
                break;
            }
            case FiltersAction.SET_FILTER_ERROR: {
                draft.filterError = action.payload.error;
                break;
            }
        }
    });
};

export default appFiltersReducer;
