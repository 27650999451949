import { AxiosInstance } from 'axios';
import { BulkEditResponse } from 'koddi/types';
import { KoddiAPIResponse } from 'types';
import { AdGroup } from 'api/AdGroups';
import {
    ActivatedCampaignDraftData,
    BulkEditCampaignStatus,
    Campaign,
    CampaignBudgetInfo,
    CampaignSpecificArgs,
} from './Campaigns.types';

class KoddiCampaignsAPI {
    protected axios: AxiosInstance;

    constructor(axios: AxiosInstance) {
        this.axios = axios;
    }

    /**
     * Gets a campaign by id.
     */
    public get = async (
        advertiser_id: number,
        member_group_id: number,
        campaign_id: Campaign['id']
    ): Promise<Campaign> => {
        const response = await this.axios.get<KoddiAPIResponse<Campaign>>(
            `v1/member_groups/${member_group_id}/advertisers/${advertiser_id}/campaigns/${campaign_id}`
        );
        return response.data.result;
    };

    public getBudgetInfo = async (
        advertiser_id: number,
        member_group_id: number,
        campaign_id: Campaign['id']
    ): Promise<CampaignBudgetInfo> => {
        const response = await this.axios.get<
            KoddiAPIResponse<CampaignBudgetInfo>
        >(
            `v1/member_groups/${member_group_id}/advertisers/${advertiser_id}/campaigns/${campaign_id}/budget`
        );
        return response.data.result || [];
    };

    public getAdGroups = async (
        advertiser_id: number,
        member_group_id: number,
        campaign_id: AdGroup['id']
    ): Promise<AdGroup[] | null> => {
        const response = await this.axios.get<KoddiAPIResponse<any>>(
            `v1/member_groups/${member_group_id}/advertisers/${advertiser_id}/campaigns/${campaign_id}/ad_groups`
        );

        return response.data.result.ad_groups;
    };

    /**
     * Bulk edit campaigns by their ids.
     */
    public bulkEdit = async (
        memberGroupId: number,
        advertiserId: number,
        campaign_ids: Campaign['id'][],
        budget_percentage?: number,
        adjust_ad_groups?: boolean,
        status?: BulkEditCampaignStatus
    ): Promise<BulkEditResponse> => {
        const response = await this.axios.patch<KoddiAPIResponse<any>>(
            `v0.9/member_groups/${memberGroupId}/advertisers/${advertiserId}/campaigns/ad_groups/bulk`,
            {
                campaign_ids,
                status: status || undefined,
                budget_percentage,
                adjust_ad_groups,
            }
        );
        return response.data;
    };

    public checkForAdGroups = async (
        memberGroupId: number,
        advertiserId: number,
        campaign_ids: Campaign['id'][]
    ): Promise<boolean> => {
        const response = await this.axios.post<KoddiAPIResponse<any>>(
            `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/campaigns/has_ad_groups/bulk`,
            {
                campaign_ids,
            }
        );

        return response.data.result.has_ad_groups;
    };

    /**
     * @returns the difference between the campaign’s
     * budget and the advertiser’s total balance
     */
    public getBudgetBalanceDifference = async (
        memberGroupId: number,
        advertiserId: number,
        campaign_id: number
    ): Promise<number> => {
        const response = await this.axios.get<KoddiAPIResponse<any>>(
            `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/campaigns/${campaign_id}/funds/calculate_budget_balance_difference`
        );
        return response.data.result.budget_balance_difference;
    };

    public deleteCampaignAndAdgroup = async ({
        memberGroupId,
        advertiserId,
        campaignId,
    }: CampaignSpecificArgs): Promise<string> => {
        const response = await this.axios.delete<KoddiAPIResponse<string>>(
            `v0.9/member_groups/${memberGroupId}/advertisers/${advertiserId}/campaigns/${campaignId}`
        );
        return response.data.status;
    };

    /**
     * Endpoint to use for deleting campaigns and
     * ad groups created through draft system
     */
    public deleteDraftedCampaignAndAdgroups = async ({
        memberGroupId,
        advertiserId,
        campaignId,
    }: CampaignSpecificArgs): Promise<string> => {
        const response = await this.axios.delete<KoddiAPIResponse<string>>(
            `v2/member_groups/${memberGroupId}/advertisers/${advertiserId}/campaigns/${campaignId}`
        );
        return response.data.status;
    };

    public activateCampaignAndAdGroupDrafts = async ({
        memberGroupId,
        advertiserId,
        campaignUUID,
    }: {
        memberGroupId: number;
        advertiserId: number;
        campaignUUID: string;
    }): Promise<ActivatedCampaignDraftData> => {
        const response = await this.axios.post<
            KoddiAPIResponse<ActivatedCampaignDraftData>
        >(
            `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/campaign_drafts/${campaignUUID}/submit`
        );

        return response.data.result;
    };

    public approve = async ({
        memberGroupId,
        advertiserId,
        campaignId,
    }: CampaignSpecificArgs): Promise<true> => {
        await this.axios.post<KoddiAPIResponse<null>>(
            `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/campaigns/${campaignId}/reviews/approve`
        );
        return true;
    };

    public reject = async ({
        memberGroupId,
        advertiserId,
        campaignId,
        comment,
    }: CampaignSpecificArgs & { comment?: string }): Promise<true> => {
        await this.axios.post<KoddiAPIResponse<null>>(
            `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/campaigns/${campaignId}/reviews/deny`,
            { comment }
        );
        return true;
    };
}

export default KoddiCampaignsAPI;
