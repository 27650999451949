import { BaseSingleDatePickerStyles } from 'koddi-components/SingleDatePicker';
import styled, { css } from 'styled-components';

// eslint-disable-next-line prettier/prettier
export const DateRangePickerStyles = styled(BaseSingleDatePickerStyles)<{rangeIsValid: boolean; v2: boolean }>`
    ${({ theme, rangeIsValid, v2 }) => css`
        .react-datepicker {
            box-shadow: none;
        }
        .react-datepicker-popper {
            position: relative !important;
            transform: none !important;
            margin-top: 0px;
            top: 0px !important;
        }
        .react-datepicker__input-container {
            position: absolute;
            opacity: 0;
            z-index: -1;
        }
        .react-datepicker__day--selected {
            border: 2px solid ${theme.grayLight};
            font-weight: ${v2 ? 'bold' : 'normal'};
        }
        .react-datepicker__day--disabled {
            color: ${theme.grayLight};
            cursor: not-allowed;
            &:hover {
                background: ${theme.white};
            }
        }
        .react-datepicker__day--potenially_in_range {
            background-color: ${theme.grayLighter};
        }
        /** Calenday Day In Between Start and End Date */
        .react-datepicker__day--in-range {
            background-color: ${theme.grayLighter};
            color: #000000;
            border-radius: 0%;
            border: 0px;
            font-weight: normal;
            position: relative;
            z-index: 2;
            border-top: 2px solid ${theme.grayLight};
            border-bottom: 2px solid ${theme.grayLight};
        }
        /** Calendar Day Outside of Current Month */
        .react-datepicker__day--outside-month {
            visibility: ${v2 ? '' : 'hidden'};
            pointer-events: none;
            color: ${theme.grayLight};
        }
        /** Calendar Today Day */
        .react-datepicker__day--today {
            color: ${v2 && theme.primary};
        }
        /** Calendar Day Start of Week */
        .react-datepicker__day--in-range.react-datepicker__day--start_of_week {
            border-top-left-radius: 100%;
            border-bottom-left-radius: 100%;
            border-left: 2px solid ${theme.grayLight};
        }
        /** Calendar Day End of Week */
        .react-datepicker__day--in-range.react-datepicker__day--end_of_week {
            border-top-right-radius: 100%;
            border-bottom-right-radius: 100%;
            border-right: 2px solid ${theme.grayLight};
        }
        /** Calendar Day Start of Month */
        .react-datepicker__day--in-range.react-datepicker__day--start_of_month {
            border-top-left-radius: 100%;
            border-bottom-left-radius: 100%;
            border-left: 2px solid ${theme.grayLight};
        }
        /** Calendar Day End of Month */
        .react-datepicker__day--in-range.react-datepicker__day--end_of_month {
            border-top-right-radius: 100%;
            border-bottom-right-radius: 100%;
            border-right: 2px solid ${theme.grayLight};
        }
        /** We only want to style the start and end date when we have both a start and end date. */
        ${rangeIsValid &&
        css`
            /** Calendar Start and End Day of Range  */
            .react-datepicker__day--in-range.react-datepicker__day--range-start,
            .react-datepicker__day--in-range.react-datepicker__day--range-end {
                background-color: ${theme.grayLighter};
                color: #000000;
                position: relative;
                z-index: 2;
                font-weight: normal;
                border: 2px solid ${theme.grayLight};
                border-radius: 100%;
            }
            .react-datepicker__day--in-range.react-datepicker__day--range-start::before,
            .react-datepicker__day--in-range.react-datepicker__day--range-end::before {
                content: '';
                position: absolute;
                top: -2px;
                left: -2px;
                width: calc(100% + 0px);
                height: calc(100% + 0px);
                z-index: -2;
                background-color: ${theme.grayLighter};
                border: 2px solid ${theme.grayLight};
            }
            .react-datepicker__day--in-range.react-datepicker__day--range-start::after,
            .react-datepicker__day--in-range.react-datepicker__day--range-end::after {
                content: '';
                position: absolute;
                top: -2px;
                left: -2px;
                width: 100%;
                height: 100%;
                background: #ffffff;
                z-index: -1;
                border-radius: 100%;
                border: 2px solid ${theme.grayLight};
            }
            .react-datepicker__day--in-range.react-datepicker__day--range-start::before {
                border-top-left-radius: 100%;
                border-bottom-left-radius: 100%;
                border-right: 0px;
                left: 0px;
            }
            .react-datepicker__day--in-range.react-datepicker__day--range-end::before {
                border-top-right-radius: 100%;
                border-bottom-right-radius: 100%;
                border-left: 0px;
            }
            /** Calendar Start Day of Range  */
            .react-datepicker__day--in-range.react-datepicker__day--range-start {
            }
            /** Calendar Start Day of Range and End of Week */
            .react-datepicker__day--in-range.react-datepicker__day--range-start.react-datepicker__day--end_of_week::before,
            .react-datepicker__day--in-range.react-datepicker__day--range-start.react-datepicker__day--end_of_month::before {
                display: none;
            }
            /** Calendar End Day of Range  */
            .react-datepicker__day--in-range.react-datepicker__day--range-end {
            }
            /** Calendar End Day of Range and End of Week */
            .react-datepicker__day--in-range.react-datepicker__day--range-end.react-datepicker__day--start_of_week::before,
            .react-datepicker__day--in-range.react-datepicker__day--range-end.react-datepicker__day--start_of_month::before {
                display: none;
            }
            .react-datepicker__day--in-range.react-datepicker__day--range-end.react-datepicker__day--range-start {
                background: #ffffff;
            }
            .react-datepicker__day--in-range.react-datepicker__day--range-end.react-datepicker__day--range-start::before,
            .react-datepicker__day--in-range.react-datepicker__day--range-end.react-datepicker__day--range-start::after {
                display: none;
            }
        `}
    `}
`;

export const DateRangeCalendarsWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
`;
