import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const CGraphIcon = createIcon({
    displayName: 'CGraphIcon',
    viewBox: '-2 -2 28 28',
    // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
    path: (
        <path d="M16.36 10.91H12v7.64h4.36zm6.55-7.64h-4.36v15.28h4.36zM3.27 20.73V0H0v24h24v-3.27zM9.82 4.36H5.45v14.19h4.37z" />
    ),
});

export const COverviewIcon = createIcon({
    displayName: 'COverviewIcon',
    viewBox: '2 2 20 20',
    // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
    path: (
        <g id="statistics" transform="translate(0 -0.05)">
            <g id="Group_1198" transform="translate(0 5.81)">
                <g id="Group_1197">
                    <path
                        id="Path_3031"
                        d="M8.52,4.72H5.46c-0.29,0-0.51,0.24-0.51,0.52v7.86c0,0.29,0.22,0.52,0.51,0.52c0,0,0,0,0,0h3.06
            c0.29,0,0.51-0.24,0.51-0.52c0,0,0,0,0,0V5.24C9.03,4.96,8.8,4.72,8.52,4.72C8.52,4.72,8.52,4.72,8.52,4.72z"
                    />
                </g>
            </g>
            <g id="Group_1200" transform="translate(5.238 0.05)">
                <g id="Group_1199" transform="translate(0 0)">
                    <path
                        id="Path_3032"
                        d="M8.62,4.72H5.47c-0.29,0-0.52,0.23-0.52,0.52v13.62c0,0.29,0.23,0.52,0.52,0.52c0,0,0,0,0,0h3.14
            c0.29,0,0.52-0.24,0.52-0.52V5.24C9.14,4.95,8.91,4.72,8.62,4.72C8.62,4.72,8.62,4.72,8.62,4.72z"
                    />
                </g>
            </g>
            <g id="Group_1202" transform="translate(10.72 4.239)">
                <g id="Group_1201">
                    <path
                        id="Path_3033"
                        d="M8.4,4.72H5.44c-0.28,0.01-0.5,0.24-0.49,0.52c0,0,0,0,0,0v9.43c-0.01,0.28,0.21,0.52,0.49,0.52H8.4
            c0.28-0.01,0.5-0.24,0.49-0.52V5.24C8.9,4.96,8.68,4.73,8.4,4.72z"
                    />
                </g>
            </g>
        </g>
    ),
});

export const CCampaignIcon = createIcon({
    displayName: 'CCampaignIcon',
    viewBox: '0 2 22 22',
    // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
    path: (
        <g id="megaphone" transform="translate(-24.1 0.25)">
            <path
                id="Path_3025"
                d="M40.96,6.77l1.09-1.09c0.2-0.2,0.2-0.52,0-0.72c-0.2-0.2-0.52-0.2-0.72,0l-1.09,1.09
    c-0.2,0.2-0.2,0.52,0,0.72C40.44,6.97,40.76,6.97,40.96,6.77z"
            />
            <path
                id="Path_3026"
                d="M43.32,9.16h-1.09c-0.28-0.01-0.52,0.2-0.53,0.48c-0.01,0.28,0.2,0.52,0.48,0.53
    c0.01,0,0.03,0,0.05,0h1.09c0.28,0.01,0.52-0.2,0.53-0.48c0.01-0.28-0.2-0.52-0.48-0.53C43.35,9.16,43.33,9.16,43.32,9.16z"
            />
            <path
                id="Path_3027"
                d="M37.35,5.29c0.28,0,0.51-0.23,0.51-0.51V3.7c-0.01-0.28-0.25-0.5-0.53-0.48
    c-0.26,0.01-0.47,0.22-0.48,0.48v1.08C36.84,5.06,37.07,5.29,37.35,5.29L37.35,5.29z"
            />
            <path
                id="Path_3028"
                d="M28.3,15.82c-0.2,0.2-0.2,0.52,0,0.72c0,0,0,0,0,0l2.17,2.17c0.2,0.2,0.52,0.2,0.72,0l0.85-0.85l-2.89-2.89
    L28.3,15.82z"
            />
            <path
                id="Path_3029"
                d="M37.08,16.23l-3.56,1.67l2.43,2.43c0.2,0.2,0.52,0.2,0.72,0l1.9-1.9c0.2-0.2,0.2-0.52,0-0.72l0,0
    L37.08,16.23z"
            />
            <path
                id="Path_3030"
                d="M34.99,4.97c-0.2-0.2-0.52-0.2-0.72,0c-0.04,0.04-0.08,0.09-0.1,0.14l-4.3,9.15l2.89,2.89l9.15-4.3
    c0.25-0.12,0.36-0.42,0.24-0.67c-0.02-0.05-0.06-0.1-0.1-0.14L34.99,4.97z"
            />
        </g>
    ),
});
export const CPieChartIcon = createIcon({
    displayName: 'CPieChartIcon',
    viewBox: '-2 -2 20 20',
    // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
    path: (
        <path
            d="M-6451-12620.5a7.514,7.514,0,0,1,7-7.483v7.983h7.984a7.515,7.515,0,0,1-7.483,7A7.508,7.508,0,0,1-6451-12620.5Zm8-.5v-7.984c.165-.009.33-.016.5-.016a7.5,7.5,0,0,1,7.5,7.5c0,.168-.006.336-.019.5Z"
            transform="translate(6451.001 12629)"
        />
    ),
});
export const CSettingsIcon = createIcon({
    displayName: 'CSettingsIcon',
    viewBox: '-1 -1 18 18',
    // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
    path: (
        <path
            d="M54.544 226.314l-1.538 1.543-1.533-1.613a6.342 6.342 0 01-2.081.875.278.278 0 00-.14.217 39.297 39.297 0 000 1.784c.005.218-.063.286-.281.28a28.47 28.47 0 00-1.627 0c-.243.008-.29-.079-.285-.3.014-.645.005-1.291.005-1.874l-2.25-.893-1.475 1.536-1.554-1.569 1.579-1.527a5.729 5.729 0 01-.864-2.073c-.033-.173-.145-.143-.254-.143h-2.014v-2.178h1.47c.244 0 .545.071.715-.044.15-.1.138-.425.225-.639.2-.5.419-.995.656-1.55l-1.562-1.49 1.568-1.576c.436.444.885.883 1.312 1.343a.382.382 0 00.548.086 12.874 12.874 0 011.651-.65c.206-.067.254-.139.25-.334-.01-.646 0-1.293 0-1.962h2.184v2.1c0 .139.024.221.185.253a5.329 5.329 0 011.863.746c.135.087.2.026.287-.06q.708-.72 1.42-1.437l1.536 1.591c-.193.178-.439.4-.677.625-.259.248-.513.5-.76.761-.049.052-.1.173-.075.213a6.173 6.173 0 01.818 2.1h2.234v2.1h-2.228a5.681 5.681 0 01-.181.67c-.179.444-.365.889-.587 1.312a.271.271 0 00.057.4c.471.441.931.908 1.403 1.377zm-6.469-1.87a2.907 2.907 0 10-2.906-2.93 2.926 2.926 0 002.905 2.93z"
            transform="translate(-40.232 -213.56)"
        />
    ),
});
export const CSupportIcon = createIcon({
    displayName: 'CSupportIcon',
    viewBox: '0 0 24 24',
    // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
    path: (
        <path d="M12.836,.028C9.361-.202,5.961,1.066,3.509,3.521,1.057,5.977-.211,9.378,.03,12.854c.44,6.354,6.052,11.146,13.054,11.146h5.917c2.757,0,5-2.243,5-5v-6.66C24,5.861,19.096,.454,12.836,.028Zm-.933,18.972c-.828,0-1.5-.672-1.5-1.5s.672-1.5,1.5-1.5,1.5,.672,1.5,1.5-.672,1.5-1.5,1.5Zm1.928-6.512c-.521,.287-.928,.951-.928,1.512,0,.553-.448,1-1,1s-1-.447-1-1c0-1.285,.807-2.627,1.963-3.264,.763-.42,1.157-1.254,1.004-2.125-.138-.786-.81-1.458-1.596-1.596-.605-.107-1.196,.049-1.657,.435-.454,.382-.714,.94-.714,1.532,0,.553-.448,1-1,1s-1-.447-1-1c0-1.185,.521-2.301,1.428-3.063,.908-.762,2.107-1.078,3.289-.874,1.613,.283,2.938,1.607,3.221,3.221,.298,1.699-.509,3.396-2.009,4.223Z" />
    ),
});

export const CCheckMarkIcon = createIcon({
    displayName: 'CCheckMarkIcon',
    viewBox: '0 0 24 24',
    // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
    path: (
        <path d="M20.4 2.84L9.44 13.8 3.6 7.97 0 11.56l9.59 9.6L24 6.44l-3.6-3.6z" />
    ),
});

export const CEmailIcon = createIcon({
    displayName: 'CEmailIcon',
    viewBox: '0 0 512 512',
    // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
    path: (
        <svg xmlns="http://www.w3.org/2000/svg">
            <path
                fill="currentColor"
                d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"
            />
        </svg>
    ),
});

export const CBankInstituteIcon = createIcon({
    displayName: 'CBankInstituteIcon',
    viewBox: '0 0 512 512',
    // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
    path: (
        <svg xmlns="http://www.w3.org/2000/svg">
            <path
                fill="currentColor"
                d="M243.4 2.6l-224 96c-14 6-21.8 21-18.7 35.8S16.8 160 32 160v8c0 13.3 10.7 24 24 24H456c13.3 0 24-10.7 24-24v-8c15.2 0 28.3-10.7 31.3-25.6s-4.8-29.9-18.7-35.8l-224-96c-8-3.4-17.2-3.4-25.2 0zM128 224H64V420.3c-.6 .3-1.2 .7-1.8 1.1l-48 32c-11.7 7.8-17 22.4-12.9 35.9S17.9 512 32 512H480c14.1 0 26.5-9.2 30.6-22.7s-1.1-28.1-12.9-35.9l-48-32c-.6-.4-1.2-.7-1.8-1.1V224H384V416H344V224H280V416H232V224H168V416H128V224zM256 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"
            />
        </svg>
    ),
});

export const CSquareDollarSign = createIcon({
    displayName: 'CSquareDollarSignIcon',
    viewBox: '0 0 448 512',
    // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
    path: (
        <svg xmlns="http://www.w3.org/2000/svg">
            <path
                fill="currentColor"
                d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM244.8 133.6v14.2c9.7 1.2 19.4 3.9 29 6.6c1.9 .5 3.7 1 5.6 1.6c11.5 3.2 18.3 15.1 15.1 26.6s-15.1 18.2-26.6 15.1c-1.6-.4-3.1-.9-4.7-1.3c-7-2-14-3.9-21.1-5.3c-13.2-2.5-28.5-1.3-40.8 4c-11 4.8-20.1 16.4-7.6 24.4c9.8 6.3 21.8 9.5 33.2 12.6c2.4 .6 4.7 1.3 7 1.9c15.6 4.4 35.5 10.1 50.4 20.3c19.4 13.3 28.5 34.9 24.2 58.1c-4.1 22.4-19.7 37.1-38.4 44.7c-7.8 3.2-16.3 5.2-25.2 6.2l0 15.2c0 11.9-9.7 21.6-21.6 21.6s-21.6-9.7-21.6-21.6l0-17.4c-14.5-3.3-28.7-7.9-42.8-12.5c-11.3-3.7-17.5-16-13.7-27.3s16-17.5 27.3-13.7c2.5 .8 5 1.7 7.5 2.5c11.3 3.8 22.9 7.7 34.5 9.6c17 2.5 30.6 1 39.5-2.6c12-4.8 17.7-19.1 5.9-27.1c-10.1-6.9-22.6-10.3-34.5-13.5c-2.3-.6-4.5-1.2-6.8-1.9c-15.1-4.3-34-9.6-48.2-18.7c-19.5-12.5-29.4-33.3-25.2-56.4c4-21.8 21-36.3 39-44.1c5.5-2.4 11.4-4.3 17.5-5.7V133.6c0-11.9 9.7-21.6 21.6-21.6s21.6 9.7 21.6 21.6z"
            />
        </svg>
    ),
});

export const CPencilIcon = createIcon({
    displayName: 'CPencilIcon',
    viewBox: '0 0 24 24',
    // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
    path: (
        <path d="M0 20.39V24h3.59L18.44 9.13l-3.58-3.59zM20.44 0l-3 3L21 6.54l3-3z" />
    ),
});

export const CTrashIcon = createIcon({
    displayName: 'CTrashIcon',
    viewBox: '0 0 230 288',
    // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
    path: (
        <g>
            <path
                d="M211.2,15.1c-16.2,0-32.5-0.1-48.7,0.1c-3.1,0-5.3-0.8-7.3-3.1c-3.5-3.8-7.2-7.3-10.9-10.9c-19.2,0-38.5,0-57.7,0
		C83.1,4.6,79.5,8,76.2,11.7c-2.3,2.6-4.8,3.5-8.2,3.4c-17.4-0.2-34.8-0.2-52.2,0C7.1,15.2,1.6,20.7,1.4,29
		c-0.2,8.1,4.8,13.7,13.2,14.6c2,0.2,4,0.2,6,0.2c63.1,0,126.3-0.1,189.4,0.1c9,0,16.3-1.7,20-10.8c0-2,0-4,0-6
		C225.7,16.8,223,15.1,211.2,15.1z"
            />
            <path d="M144.4,1.1c0-0.1,0-0.3,0-0.4c-0.1,0-0.2,0-0.3,0C144,0.8,144.1,1,144.4,1.1z" />
            <path
                d="M210.3,72c-31.5,0.1-63,0.1-94.5,0.1c-31.8,0-63.7,0.1-95.5-0.1c-3.7,0-4.7,0.9-4.7,4.6c0.1,59.7,0.1,119.5,0.1,179.2
		c0,18.7,11.7,30.3,30.5,30.3c46.1,0,92.2,0,138.2,0c19,0,30.7-11.6,30.7-30.6c0-59.6,0-119.1,0.1-178.7
		C215.3,72.8,214.1,72,210.3,72z M86.9,241.9c0,11.7-7.5,17.8-18.2,15.2c-6.4-1.6-10-6.3-10-14.1c-0.1-16.9,0-33.8,0-50.7
		c0-4.5,0-9,0-13.4c0-21.2-0.1-42.4,0.1-63.6c0.1-11.1,9-17.2,19.4-13.6c5,1.7,7.8,5.3,8.7,10.5c0.2,1.5,0.2,3,0.2,4.5
		C86.9,158.3,86.9,200.1,86.9,241.9z M172.4,243.1c-0.1,10.6-7.7,16.4-17.9,14.1c-5.7-1.3-9.5-5.5-10.2-11.3
		c-0.1-1.1-0.2-2.3-0.2-3.5c0-42.1-0.1-84.2,0-126.3c0-11.8,8.9-18.2,19.6-14.4c5.6,2,8.6,6.4,8.7,13.6c0.1,15.4,0,30.8,0,46.2
		c0,6,0,11.9,0,17.9C172.4,200.6,172.6,221.8,172.4,243.1z"
            />
        </g>
    ),
});

export const CPlusIcon = createIcon({
    displayName: 'CPlusIcon',
    viewBox: '0 0 24 24',
    // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
    path: (
        <path d="M24 13.65v-3.3l-10.42.07L13.65 0h-3.3l.07 10.42L0 10.35v3.3l10.42-.08L10.35 24h3.3l-.07-10.43z" />
    ),
});

export const CMinusIcon = createIcon({
    displayName: 'CMinusIcon',
    viewBox: '0 0 24 24',
    // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
    path: (
        <path d="M24 13.65v-3.3l-10.42.07h-3.14L0 10.35v3.3l10.42-.08h3.14z" />
    ),
});

export const CBackIcon = createIcon({
    displayName: 'CBackIcon',
    viewBox: '0 0 478 511.69',
    path: (
        <path d="M67.39 141.1H295.4c87.58 0 133.12 17.12 157.98 56.11 23.79 37.32 24.62 90.3 24.62 166.24V490.8c0 11.52-9.37 20.89-20.89 20.89-11.53 0-20.9-9.37-20.9-20.89V363.45c0-68.92-.61-116.76-17.91-143.91-16.24-25.45-51.58-36.66-122.9-36.66H66.23l127.92 111.99c8.64 7.55 9.51 20.73 1.95 29.37-7.55 8.65-20.73 9.51-29.37 1.96L12.94 191.58l-3.22-3.41C2.87 179.98-.36 169.84.03 159.96c.44-10.28 4.74-20.26 12.85-27.8C63.01 89.05 115.69 47.03 166.73 4.79c8.88-7.34 22.04-6.1 29.37 2.78 7.34 8.88 6.1 22.03-2.78 29.37L67.39 141.1z" />
    ),
});

export const CSaveIcon = createIcon({
    displayName: 'CSaveIcon',
    viewBox: '0 0 448 512',
    // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
    path: (
        <path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V173.3c0-17-6.7-33.3-18.7-45.3L352 50.7C340 38.7 323.7 32 306.7 32H64zm0 96c0-17.7 14.3-32 32-32H288c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V128zM224 288a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
    ),
});

export const CDownloadIcon = createIcon({
    displayName: 'CDownloadIcon',
    viewBox: '0 0 24 24',
    // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
    path: (
        <path d="M19.71 11.14l-2.57-2.57L13.71 12V0h-3.42v12L6.86 8.57l-2.57 2.57L12 18.86zM0 20.57V24h24v-3.43z" />
    ),
});

export const CMaginfierIcon = createIcon({
    displayName: 'CMaginfierIcon',
    viewBox: '0 0 24 24',
    // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
    path: (
        <path d="M24 21.67l-4.89-4.89a10.84 10.84 0 002-6.22 10.56 10.56 0 10-10.56 10.55 10.87 10.87 0 006.23-2L21.67 24zm-20.67-11a7.23 7.23 0 117.22 7.22 7.21 7.21 0 01-7.22-7.2z" />
    ),
});

export const CUserIcon = createIcon({
    displayName: 'CUserIcon',
    viewBox: '0 0 24 24',
    // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
    path: (
        <g>
            <path d="M8.67 14.66h6.66A6.65 6.65 0 0122 21.31v3.33H2v-3.33a6.65 6.65 0 016.67-6.65z" />
            <circle cx="11.45" cy="7.46" r="6.09" />
        </g>
    ),
});

export const CSearchIcon = createIcon({
    displayName: 'CSearchIcon',
    viewBox: '0 0 24 24',
    // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
    path: (
        <path d="M24 21.67l-4.89-4.89a10.84 10.84 0 002-6.22 10.56 10.56 0 10-10.56 10.55 10.87 10.87 0 006.23-2L21.67 24zm-20.67-11a7.23 7.23 0 117.22 7.22 7.21 7.21 0 01-7.22-7.2z" />
    ),
});

export const CColumnChartIcon = createIcon({
    displayName: 'CColumnChartIcon',
    viewBox: '0 0 122.88 105.98',
    path: (
        <g>
            <path d="M122.88,105.98H9.59v-0.02c-2.65,0-5.05-1.08-6.78-2.81c-1.72-1.72-2.79-4.11-2.79-6.75H0V0h12.26v93.73h110.62V105.98 L122.88,105.98z M83.37,45.6h19.55c1.04,0,1.89,0.85,1.89,1.89v38.46c0,1.04-0.85,1.89-1.89,1.89H83.37 c-1.04,0-1.89-0.85-1.89-1.89V47.5C81.48,46.46,82.33,45.6,83.37,45.6L83.37,45.6z M25.36,22.07h19.55c1.04,0,1.89,0.85,1.89,1.89 v62c0,1.04-0.85,1.89-1.89,1.89H25.36c-1.04,0-1.89-0.85-1.89-1.89v-62C23.47,22.92,24.32,22.07,25.36,22.07L25.36,22.07 L25.36,22.07z M54.37,8.83h19.54c1.04,0,1.89,0.85,1.89,1.89v75.24c0,1.04-0.85,1.89-1.89,1.89H54.37c-1.04,0-1.89-0.85-1.89-1.89 V10.72C52.48,9.68,53.33,8.83,54.37,8.83L54.37,8.83z" />
        </g>
    ),
});

export const CSubtractIcon = createIcon({
    displayName: 'CSubtractIcon',
    viewBox: '0 0 512 511.99',
    path: (
        <g>
            <path d="M256 0c70.68 0 134.68 28.67 181.01 74.99 46.32 46.32 74.99 110.32 74.99 181S483.33 390.68 437.01 437c-46.33 46.33-110.33 74.99-181.01 74.99-70.68 0-134.68-28.66-181.01-74.99C28.67 390.68 0 326.67 0 255.99c0-70.68 28.67-134.68 74.99-181C121.32 28.67 185.32 0 256 0z" />
            <path
                fill="#fff"
                d="M381.4 234.68v42.63c0 10.08-8.3 18.38-18.38 18.38H148.98c-10.08 0-18.38-8.26-18.38-18.38v-42.63c0-10.12 8.26-18.38 18.38-18.38h214.04c10.12 0 18.38 8.33 18.38 18.38z"
            />
        </g>
    ),
});

export const CCheckIcon = createIcon({
    displayName: 'CCheckIcon',
    viewBox: '0 0 512 512',
    path: (
        <g>
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M256,0C115.39,0,0,115.39,0,256s115.39,256,256,256s256-115.39,256-256S396.61,0,256,0z M225.019,372.44L112.914,260.336    l42.422-42.422l71.646,71.646l143.833-130.752l40.371,44.385L225.019,372.44z"
            />
        </g>
    ),
});

export const CPendingIcon = createIcon({
    displayName: 'CPendingIcon',
    viewBox: '0 0 512 512',
    path: (
        <svg xmlns="http://www.w3.org/2000/svg">
            <path d="M256 0c-17.7 0-32 14.3-32 32V96c0 17.7 14.3 32 32 32s32-14.3 32-32V66.7C378.8 81.9 448 160.9 448 256c0 106-86 192-192 192S64 362 64 256c0-53.7 22-102.3 57.6-137.1c12.6-12.4 12.8-32.6 .5-45.3S89.5 60.8 76.8 73.1C29.5 119.6 0 184.4 0 256C0 397.4 114.6 512 256 512s256-114.6 256-256S397.4 0 256 0zM193 159c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l80 80c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80z" />
        </svg>
    ),
});

// old "StatusEndedIcon"
export const CUnavailableIcon = createIcon({
    displayName: 'CUnavailableIcon',
    viewBox: '0 0 24 24',
    path: (
        <path d="M12 0a12 12 0 1012 12A12 12 0 0012 0zm5.65 16L16 17.65l-4-3.95-3.95 3.95L6.35 16l4-3.95-4-3.95 1.7-1.7 3.95 4 3.95-4 1.7 1.7L13.7 12z" />
    ),
});

export const CWarningIcon = createIcon({
    displayName: 'CWarningIcon',
    viewBox: '0 0 72 72',
    path: (
        <>
            <g>
                <path d="M35.49,6.22c16.22,0,29.42,13.2,29.42,29.42s-13.2,29.42-29.42,29.42S6.08,51.86,6.08,35.64 S19.27,6.22,35.49,6.22 M35.49,2.22c-18.46,0-33.42,14.96-33.42,33.42s14.96,33.42,33.42,33.42s33.42-14.96,33.42-33.42 S53.95,2.22,35.49,2.22L35.49,2.22z" />
            </g>
            <g>
                <path d="M32.41,54.31c-0.14-2.07,1.27-3.58,3.19-3.52c1.66,0.05,2.95,1.5,2.98,3.17c0.03,1.84-1.13,3.27-2.98,3.32 C33.94,57.33,32.52,55.97,32.41,54.31z M33.14,44.37l-0.78-26.78c-0.05-1.77,1.37-3.23,3.13-3.23h0c1.77,0,3.19,1.46,3.13,3.23 l-0.78,26.78c-0.04,1.27-1.08,2.29-2.35,2.29h0C34.22,46.65,33.18,45.64,33.14,44.37z" />
            </g>
        </>
    ),
});

export const CInfoIcon = createIcon({
    displayName: 'CInfoIcon',
    viewBox: '0 0 24 24',
    path: (
        <path d="M12.07 0H12a12 12 0 10.07 0zm1.57 19.64h-3.28v-8.73h3.28zM12 8.73a2.19 2.19 0 112.18-2.18A2.18 2.18 0 0112 8.73z" />
    ),
});

export const CPerformanceIcon = createIcon({
    displayName: 'CPerformanceIcon',
    viewBox: '0 0 24 24',
    path: (
        <path d="M24 24V9.17l-4-4.32v12.27L2.83 0 0 2.83 17.12 20h-12l4.05 4z" />
    ),
});

export const CCloseIcon = createIcon({
    displayName: 'CCloseIcon',
    viewBox: '0 0 24 24',
    path: (
        <path d="M24 2.9L21.1 0 12 9.23 2.9 0 0 2.9 9.23 12 0 21.1 2.9 24l9.1-9.23L21.1 24l2.9-2.9-9.23-9.1z" />
    ),
});

export const CReviewIcon = createIcon({
    displayName: 'CReviewIcon',
    viewBox: '0 0 512 511',
    path: (
        <g>
            <path d="m405.332031 256.484375c-11.796875 0-21.332031 9.558594-21.332031 21.332031v170.667969c0 11.753906-9.558594 21.332031-21.332031 21.332031h-298.667969c-11.777344 0-21.332031-9.578125-21.332031-21.332031v-298.667969c0-11.753906 9.554687-21.332031 21.332031-21.332031h170.667969c11.796875 0 21.332031-9.558594 21.332031-21.332031 0-11.777344-9.535156-21.335938-21.332031-21.335938h-170.667969c-35.285156 0-64 28.714844-64 64v298.667969c0 35.285156 28.714844 64 64 64h298.667969c35.285156 0 64-28.714844 64-64v-170.667969c0-11.796875-9.539063-21.332031-21.335938-21.332031zm0 0" />
            <path d="m200.019531 237.050781c-1.492187 1.492188-2.496093 3.390625-2.921875 5.4375l-15.082031 75.4375c-.703125 3.496094.40625 7.101563 2.921875 9.640625 2.027344 2.027344 4.757812 3.113282 7.554688 3.113282.679687 0 1.386718-.0625 2.089843-.210938l75.414063-15.082031c2.089844-.429688 3.988281-1.429688 5.460937-2.925781l168.789063-168.789063-75.414063-75.410156zm0 0" />
            <path d="m496.382812 16.101562c-20.796874-20.800781-54.632812-20.800781-75.414062 0l-29.523438 29.523438 75.414063 75.414062 29.523437-29.527343c10.070313-10.046875 15.617188-23.445313 15.617188-37.695313s-5.546875-27.648437-15.617188-37.714844zm0 0" />
        </g>
    ),
});

export const CHistoryIcon = createIcon({
    displayName: 'CHistoryIcon',
    viewBox: '0 0 24 24',
    path: (
        <g xmlns="http://www.w3.org/2000/svg" fill="rgb(0,0,0)">
            <path d="m13.1 1a10.927 10.927 0 0 0 -10.534 8.223l-.732-1.107a1 1 0 1 0 -1.668 1.1l2.2 3.334a1.084 1.084 0 0 0 .634.425 1.024 1.024 0 0 0 .756-.145l3.3-2.223a1 1 0 1 0 -1.115-1.659l-1.501 1.012a8.909 8.909 0 1 1 8.66 11.04 8.892 8.892 0 0 1 -7.281-3.822 1 1 0 1 0 -1.64 1.143 10.881 10.881 0 0 0 19.821-6.321 10.963 10.963 0 0 0 -10.9-11z" />
            <path d="m13 5.95a1 1 0 0 0 -1 1v5.05a1.04 1.04 0 0 0 .293.707l3 3.027a1.013 1.013 0 0 0 1.414.007 1 1 0 0 0 .006-1.414l-2.713-2.738v-4.639a1 1 0 0 0 -1-1z" />
        </g>
    ),
});
