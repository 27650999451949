import { KoddiTheme } from 'koddi-components/ThemeProvider';

export const colorConfig = (theme: KoddiTheme): Record<string, any> => ({
    primary: {
        100: theme.primary,
        200: theme.primary,
        300: theme.primary,
        400: theme.primary,
        500: theme.primary,
        600: theme.primary,
        700: theme.primary,
        800: theme.primary,
        900: theme.primary,
    },
    secondary: {
        100: theme.secondary,
        200: theme.secondary,
        300: theme.secondary,
        400: theme.secondary,
        500: theme.secondary,
        600: theme.secondary,
        700: theme.secondary,
        800: theme.secondary,
        900: theme.secondary,
    },

    primaryColor: theme.primary,
    secondaryColor: theme.secondary,
    grayColor: theme.gray,
    disabledColor: theme.grayDarker,

    grayLight: theme.grayLight,
    grayLighter: theme.grayLighter,
    grayLightest: theme.grayLightest,

    grayDark: theme.grayDark,
    grayDarker: theme.grayDarker,
    grayDarkest: theme.grayDarkest,

    grayCool: theme.coolGray,
    slate: theme.slate,
    cardGray: theme.grayLightest,

    btnPrimary: theme.btnPrimary,
    btnSecondary: theme.btnSecondary,

    appNavBackground: theme.headerBackground,
    // backwards compatible with old header brand values
    avatarBackground: theme?.headerTextColor || theme?.avatarColor,
    avatarText: theme.headerBackground,
    // extend the above for all colors
});

export enum ColorSchemes {
    primary = 'primary',
    secondary = 'secondary',
    gray = 'gray',
}

export enum Colors {
    primary = 'primaryColor',
    secondary = 'secondaryColor',
    gray = 'grayColor',
    disabled = 'disabledColor',

    grayLight = 'grayLight',
    grayLighter = 'grayLighter',
    grayLightest = 'grayLightest',

    grayDark = 'grayDark',
    grayDarker = 'grayDarker',
    grayDarkest = 'grayDarkest',

    grayCool = 'grayCool',
    slate = 'slate',
    cardGray = 'grayLightest',

    white = 'white',
    black = 'black',

    successDark = 'green.800',
    success = 'green.500',
    successLight = 'green.200',

    errorDark = 'red.800',
    error = 'red.500',
    errorLight = 'red.200',

    warningDark = 'orange.700',
    warning = 'orange.400',
    warningLight = 'orange.100',

    skeleton = 'gray.400',

    btnPrimary = 'btnPrimary',
    btnSecondary = 'btnSecondary',

    appNavBackground = 'appNavBackground',
    avatarBackground = 'avatarBackground',
    avatarText = 'appNavBackground',

    inputIcon = 'gray',
}
