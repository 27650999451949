import { Tooltip } from '@chakra-ui/react';
import {
    BorderWidth,
    Colors,
    Spacing,
} from 'app/koddi-components/src/_ChakraComponents/ChakraTheme';

import { CText } from 'app/koddi-components/src/_ChakraComponents/Typography';
import React, { useMemo } from 'react';
import { MultiValueProps } from 'react-select';
import { OptionType } from '../../../Types';

export const SimpleMultiValue = ({
    getValue,
    ...props
}: MultiValueProps<OptionType>): JSX.Element => {
    const values = getValue();
    const tooltipList = useMemo(() => {
        if (!Array.isArray(values)) return [];
        const first20 = values.slice(0, 19);
        const totalLeftOver = values.length - 20;
        const leftOverString =
            totalLeftOver > 0
                ? `${totalLeftOver.toLocaleString()} more selected`
                : '';
        const valueToShow = [
            ...first20.map(({ label }) => label),
            leftOverString,
        ];
        return valueToShow;
    }, [values]);

    // hack to override react select types
    // if there is an index then we only want to return the first.
    /* @ts-ignore */
    if (props?.index > 0) return <></>;

    if (!Array.isArray(values)) return <></>;

    const count = values.length.toLocaleString();

    return (
        <Tooltip
            shouldWrapChildren
            label={tooltipList.map((value) => (
                <div key={value}>{value}</div>
            ))}
            isDisabled={!tooltipList.length}
        >
            <CText
                text={count}
                noWrap
                isTruncated
                borderRight={`${BorderWidth.Thinnest} solid`}
                borderRightColor={Colors.gray}
                pr={Spacing.XS}
                mr={Spacing.XS}
            />
        </Tooltip>
    );
};
