import { Box, Center, Tooltip } from '@chakra-ui/react';
import {
    BorderRadius,
    BorderWidth,
    Colors,
    FontSizes,
    Spacing,
} from 'app/koddi-components/src/_ChakraComponents/ChakraTheme';
import { CRow } from 'app/koddi-components/src/_ChakraComponents/Layouts';
import React, { useCallback } from 'react';
import { components as ReactSelectComponents, OptionProps } from 'react-select';
import { CCheckMarkIcon } from 'app/koddi-components/src/_ChakraComponents/Icons';
import { CTextButton } from 'app/koddi-components/src/_ChakraComponents/Buttons';
import { OptionWithProps } from '../SelectField.types';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const OptionWithCheckbox = ({
    isSelected,
    children,
    innerProps,
    ...rest
}: OptionProps<OptionWithProps>): JSX.Element | null => {
    const { isFocused, setValue, data, options } = rest;

    const style = {
        backgroundColor: 'transparent',
        color: 'inherit',
        padding: 0,
    };
    const handleOnlyClick = useCallback(() => {
        const permanentOptions = options.filter(
            (option) => !!option?.isPermanent
        );
        setValue([...permanentOptions, data], 'set-value');
    }, [options, setValue, data]);

    if (data?.isPermanent) {
        return null;
    }
    return (
        <Box position="relative">
            <Tooltip
                label={children}
                shouldWrapChildren
                openDelay={500}
                isDisabled={!rest.selectProps?.hasTooltip}
                placement="bottom-start"
            >
                <ReactSelectComponents.Option
                    isSelected={isSelected}
                    {...rest}
                    /* @ts-ignore */
                    innerProps={{ ...innerProps, style }}
                >
                    <CRow
                        noBreak
                        alignItems="center"
                        gap={Spacing.XS}
                        p={Spacing.XS}
                        bgColor={
                            rest.isFocused ? Colors.grayLighter : undefined
                        }
                        _hover={{ backgroundColor: Colors.grayLighter }}
                    >
                        <Center
                            height="16px"
                            minW="16px"
                            maxW="16px"
                            backgroundColor={
                                isSelected ? Colors.primary : 'transparent'
                            }
                            borderColor={
                                isSelected ? Colors.primary : Colors.grayLight
                            }
                            borderWidth={BorderWidth.Thin}
                            borderStyle="solid"
                            borderRadius={BorderRadius.XS}
                        >
                            {isSelected && (
                                <CCheckMarkIcon
                                    fill={Colors.white}
                                    width="70%"
                                />
                            )}
                        </Center>
                        <Box
                            overflow="hidden"
                            textOverflow="ellipsis"
                            flexGrow={1}
                            paddingRight={isFocused ? Spacing.LR : undefined}
                        >
                            {children}
                        </Box>
                    </CRow>
                </ReactSelectComponents.Option>
            </Tooltip>
            {isFocused && (
                <CTextButton
                    position="absolute"
                    top={0}
                    // translateY="-25%"
                    right={0}
                    onClick={handleOnlyClick}
                    testId="only-selection"
                    fontSize={FontSizes.sm}
                    background={Colors.grayLighter}
                    p={Spacing.XS}
                    px={Spacing.XS}
                    minWidth={Spacing.LR}
                    height="100%"
                    justifyContent="center"
                    alignContent="center"
                    display="flex"
                >
                    only
                </CTextButton>
            )}
        </Box>
    );
};
