import styled, { css } from 'styled-components';
import isUndefined from 'lodash/isUndefined';
import { cssProp } from 'koddi-components/utils';
import {
    FlexBoxProps,
    HorizontalFlexBoxProps,
    VerticalFlexBoxProps,
} from './FlexBox.types';
import { parseCSSValue } from './FlexBox.utils';

const FlexBox = styled.div<FlexBoxProps>`
    ${({
        alignItems,
        alignSelf,
        flexDirection,
        justifyContent,
        justifySelf,
        horizontalPadding,
        verticalPadding,
        padding,
        paddingBottom,
        paddingLeft,
        paddingRight,
        paddingTop,
        fillContainer,
        flex,
        flexWrap,
        flexShrink,
        inlineFlex,
        width,
        height,
        marginTop,
        marginBottom,
    }) => css`

        display: ${inlineFlex ? 'inline-flex' : 'flex'};
        flex: ${fillContainer ? '1 1 auto' : flex};
        align-self: ${fillContainer ? 'stretch' : alignSelf};
        align-items: ${alignItems};
        flex-direction: ${flexDirection};
        justify-content: ${justifyContent};
        margin-top: ${marginTop};
        margin-bottom: ${marginBottom};

        ${cssProp('justify-self', justifySelf)};
        ${cssProp('width', fillContainer ? '100%' : width)};
        ${cssProp('height', fillContainer ? '100%' : height)};
        ${cssProp('flex-shrink', flexShrink?.toString())};

        ${
            !isUndefined(horizontalPadding) &&
            css`
                padding: 0px ${horizontalPadding};
            `
        }

        ${
            !isUndefined(verticalPadding) &&
            css`
                padding: ${verticalPadding} 0px;
            `
        }

        ${
            !isUndefined(paddingBottom) &&
            css`
                padding-bottom: ${parseCSSValue(paddingBottom)};
            `
        }

        ${
            !isUndefined(paddingLeft) &&
            css`
                padding-left: ${parseCSSValue(paddingLeft)};
            `
        }

        ${
            !isUndefined(paddingRight) &&
            css`
                padding-right: ${parseCSSValue(paddingRight)};
            `
        }

        ${
            !isUndefined(paddingTop) &&
            css`
                padding-top: ${parseCSSValue(paddingTop)};
            `
        }

        ${
            !isUndefined(padding) &&
            css`
                padding: ${parseCSSValue(padding)};
            `
        }

        ${
            !isUndefined(flexWrap) &&
            css`
                flex-wrap: wrap;
            `
        }

        

    `}
`;

export const HorizontalFlexBox = styled(FlexBox)<HorizontalFlexBoxProps>`
    ${({
        fullWidth,
        flex,
        alignSelf,
        itemSpacing,
        spacingOnLastItem,
        justifyContent,
        marginTop,
        marginRight,
        marginBottom,
    }) => css`
        flex-direction: row;
        flex: ${fullWidth ? '1 1 auto' : flex};
        align-self: ${fullWidth ? 'stretch' : alignSelf};
        justify-content: ${justifyContent};
        margin-top: ${marginTop}px;
        margin-right: ${marginRight}px;
        ${marginBottom && `margin-bottom: ${marginBottom}px;`}
        ${itemSpacing &&
        !spacingOnLastItem &&
        css`
            > *:not(:last-child) {
                margin-right: ${itemSpacing}px;
            }
        `}
    `}
`;

export const VerticalFlexBox = styled(FlexBox)<VerticalFlexBoxProps>`
    ${({
        fullHeight,
        flex,
        alignSelf,
        itemSpacing,
        spacingOnLastItem,
        paddingLeft,
        marginRight,
        overflow,
        maxWidth,
    }) => css`
        max-width: ${maxWidth ?? 'none'};
        flex-direction: column;
        flex: ${fullHeight ? '1 1 auto' : flex};
        align-self: ${fullHeight ? 'stretch' : alignSelf};
        padding-left: ${`${paddingLeft}px` || 'initial'};
        margin-right: ${marginRight}px;
        overflow: ${overflow};
        ${itemSpacing &&
        !spacingOnLastItem &&
        css`
            > *:not(:last-child) {
                margin-bottom: ${itemSpacing}px;
            }
        `}
    `}
`;

export const FlexChild = styled(FlexBox)<VerticalFlexBoxProps>`
    ${({ fullHeight, flex, alignSelf }) => css`
        display: initial;
        flex-direction: column;
        flex: ${fullHeight ? '1 1 auto' : flex};
        align-self: ${fullHeight ? 'stretch' : alignSelf};
    `}
`;

export default FlexBox;
