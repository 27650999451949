import { ReduxActionWithRefresh, ReduxAction } from 'types';
import { ActivityState } from './activity/types';
import { MemberState } from './member/types';
import { MemberGroupState } from './memberGroup/types';
import { MemberGroupTypesState } from './memberGroupTypes/types';
import { PermissionState } from './permission/types';
import { RoleState } from './role/types';
import { RolePermissionState } from './rolePermission/types';
import { IndustryState } from './industry/types';
import { UserState } from './user/types';
import { EditorState } from './editor/types';
import { AdminAlertState } from './alert';
import { CurrencyState } from './currency/types';
import { LocalesState } from './locales/types';
import { AdvertiserGroupsState } from './advertiserGroups/types';
import { AdvertisersState } from './advertisers/types';
import { AdvertiserPaymentState } from './advertiserPayment/types';
import { TimezonesState } from './timezones/types';
import { EntityRegistrationState } from './entityRegistration/types';
import { ResourceHeaderState } from './resourceHeader/types';
import { ExperiencesState } from './Experiences/types';

export enum AdminAction {
    RESET_STATE = 'admin/RESET_STATE',
    GET_CLIENT_FORM_DATA = 'admin/GET_CLIENT_FORM_DATA',
    GET_ADVERTISER_FORM_DATA = 'admin/GET_ADVERTISER_FORM_DATA',
    GET_ADVERTISER_GROUP_FORM_DATA = 'admin/GET_ADVERTISER_GROUP_FORM_DATA',
}

export type GetClientFormDataAction = ReduxActionWithRefresh<
    AdminAction.GET_CLIENT_FORM_DATA
>;
export type GetAdvertiserFormDataAction = ReduxActionWithRefresh<
    AdminAction.GET_ADVERTISER_FORM_DATA
>;
export type GetAdvertiserGroupFormDataAction = ReduxActionWithRefresh<
    AdminAction.GET_ADVERTISER_GROUP_FORM_DATA
>;

export type ResetAdminStateAction = ReduxAction<AdminAction.RESET_STATE>;

export type AdminState = {
    activity: ActivityState;
    advertiserGroups: AdvertiserGroupsState;
    advertisers: AdvertisersState;
    advertiserPayment: AdvertiserPaymentState;
    alert: AdminAlertState;
    editor: EditorState;
    entityRegistration: EntityRegistrationState;
    experiences: ExperiencesState;
    member: MemberState;
    memberGroup: MemberGroupState;
    memberGroupTypes: MemberGroupTypesState;
    permission: PermissionState;
    role: RoleState;
    rolePermission: RolePermissionState;
    industry: IndustryState;
    user: UserState;
    currency: CurrencyState;
    locales: LocalesState;
    timezones: TimezonesState;
    resourceHeader: ResourceHeaderState;
};

export type AdminActions =
    | GetClientFormDataAction
    | GetAdvertiserFormDataAction
    | GetAdvertiserGroupFormDataAction;
