import React, { FunctionComponent } from 'react';
import { BaseSVGProps } from '../icon.types';

const MedicalReportIcon: FunctionComponent<BaseSVGProps> = ({
    width,
    height,
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 97.26 122.88"
    >
        <path d="M6.47,13H22v8.16h-9a5.53,5.53,0,0,0-5.47,5.46v82.74a5.51,5.51,0,0,0,5.47,5.46H84.32a5.48,5.48,0,0,0,5.46-5.46V26.58a5.51,5.51,0,0,0-5.46-5.46h-9V13H90.79a6.5,6.5,0,0,1,6.47,6.47v97a6.51,6.51,0,0,1-6.47,6.47H6.47A6.5,6.5,0,0,1,0,116.41v-97A6.49,6.49,0,0,1,6.47,13ZM19,34.69H41.54v4.5H19v-4.5ZM19,46.8h14.8v4.5H19V46.8ZM67.57,31.49h4.37A1.49,1.49,0,0,1,73.43,33v5.55H79A1.49,1.49,0,0,1,80.47,40v4.38A1.5,1.5,0,0,1,79,45.87H73.43V51.3a1.49,1.49,0,0,1-1.49,1.48H67.57a1.49,1.49,0,0,1-1.49-1.48V45.87H60.53a1.48,1.48,0,0,1-1.48-1.48V40a1.48,1.48,0,0,1,1.48-1.48h5.55V33a1.49,1.49,0,0,1,1.49-1.49ZM19,99.49H66.2V104H19v-4.5Zm0-13.57h61.5v4.5H19v-4.5Zm0-15.53H35.24l4.26-5.92L44.72,72l5.72-11.17L56.61,72.9l2.8-2.06,1.33-.45H80.46v4.5h-19L55,79.66l-4.58-9-5.14,10-5.86-8.44-1.91,2.65H19v-4.5ZM31,9h7.33A10,10,0,0,1,48,0a10,10,0,0,1,9.71,8.93L66.3,9a1.08,1.08,0,0,1,1.08,1.08V20.47a1.08,1.08,0,0,1-1.08,1.08H31a1.08,1.08,0,0,1-1.08-1.08V10.12A1.06,1.06,0,0,1,31,9Zm13,4.43a7.14,7.14,0,0,0,2.62,2.27,4.8,4.8,0,0,0,2.55,0,7.12,7.12,0,0,0,3.06-3.25,6.1,6.1,0,0,0,.1-1.08,4.38,4.38,0,1,0-8.74,0,5,5,0,0,0,.41,2Z" />
    </svg>
);

export default MedicalReportIcon;
