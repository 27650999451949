import React, { useMemo } from 'react';
import format from 'date-fns/format';
import isSameMonth from 'date-fns/isSameMonth';
import { useLocale } from 'koddi-components/LocaleProvider';
import { createLocaleMonths } from '../SingleDatePicker.utils';
import { SingleDatePickerHeaderProps } from './SingleDatePickerHeader.types';
import * as Styled from './SingleDatePickerHeader.styled';

const MONTHS = createLocaleMonths();

const SingleDatePickerHeader = ({
    date,
    changeMonth,
    prevMonthButtonDisabled = false,
    nextMonthButtonDisabled = false,
    disablePastMonths = false,
    increaseMonth,
    decreaseMonth,
    v2 = false,
}: SingleDatePickerHeaderProps): JSX.Element => {
    const { locale } = useLocale();
    const currentYear = format(date, 'yyyy');
    const currentMonth = MONTHS[locale][Number(format(date, 'M')) - 1];
    const dropdownItems = useMemo(
        () =>
            MONTHS[locale].map((month, monthNumber) => ({
                label: month,
                value: month,
                onClick: () => changeMonth(monthNumber),
            })),
        [changeMonth, locale]
    );
    const sameMonth = isSameMonth(date, new Date());

    return (
        <Styled.Wrapper>
            <Styled.MonthNavigator>
                {!prevMonthButtonDisabled &&
                !(disablePastMonths && sameMonth) ? (
                    <Styled.MonthIcon
                        icon="chevronLeft"
                        width={14}
                        height={14}
                        onClick={decreaseMonth}
                        aria-label="Move backward to switch to the previous month."
                        tabIndex={0}
                    />
                ) : null}
                <Styled.MonthNavigatorDropdown
                    btnText={`${currentMonth} ${currentYear}`}
                    items={dropdownItems}
                    iconRight="chevronDown"
                    usePortal={false}
                    v2={v2}
                />
                {!nextMonthButtonDisabled ? (
                    <Styled.ChevronRightIcon
                        icon="chevronLeft"
                        width={14}
                        height={14}
                        onClick={increaseMonth}
                        aria-label="Move forward to switch to the next month."
                        tabIndex={0}
                    />
                ) : null}
            </Styled.MonthNavigator>
        </Styled.Wrapper>
    );
};

export default SingleDatePickerHeader;
