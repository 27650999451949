/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { takeEvery, all, put } from 'redux-saga/effects';
import SagaRegistry from 'redux-core/sagaRegistry';

import { fetchTermsAndConditions } from 'redux-core/app/termsAndConditions/actions';
import {
    fetchClientContext,
    fetchEmbeddedAppContext,
} from 'redux-core/app/context';
import { getThemeByMemberGroupId } from 'redux-core/app/theme';
import { getReportConfig } from 'redux-core/app/filters/actions';
import { getCurrencyList } from 'redux-core/admin/currency/actions';

import {
    AppSetupAction,
    SetupAppAction,
    SetupEmbeddedAppAction,
} from './types';
import { setupAppSucess } from './actions';

export function* setupAppSaga(action: SetupAppAction) {
    const {
        user,
        memberGroupId,
        advertiserId,
        advertiserGroupId,
    } = action.payload;
    yield put(getThemeByMemberGroupId(memberGroupId));
    const memberGroupIdToUse = advertiserId
        ? memberGroupId
        : user.member_group_id;
    yield put(
        fetchClientContext(memberGroupIdToUse, advertiserId, advertiserGroupId)
    );
    yield put(
        fetchTermsAndConditions(user.user_id, memberGroupId, user.locale.id)
    );
    yield put(getReportConfig(memberGroupId));
    yield put(getCurrencyList());
    yield put(setupAppSucess());
}

export function* setupEmbedded(action: SetupEmbeddedAppAction) {
    const { memberGroupId } = action.payload;

    yield put(getThemeByMemberGroupId(memberGroupId));
    yield put(fetchEmbeddedAppContext(memberGroupId));
    yield put(getReportConfig(memberGroupId));
    yield put(setupAppSucess());
}

export function* watchAppSetup() {
    yield takeEvery(AppSetupAction.SETUP_APP, setupAppSaga);
    yield takeEvery(AppSetupAction.SETUP_EMBEDDED, setupEmbedded);
}

function* appSetupSagas() {
    yield all([watchAppSetup()]);
}

SagaRegistry.registerSaga(appSetupSagas);
