import React, { FunctionComponent } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { darken, lighten } from 'polished';
import { BaseSVGProps } from '../icon.types';

const dash = keyframes`
    0% {
        stroke-dashoffset: 100;
    }
    100% {
        stroke-dashoffset: 0;
    }
`;

export const CheckboxContainer = styled.div<{
    checked?: boolean;
    width?: string | number;
    disabled?: boolean;
}>`
    ${({ theme, checked, disabled }) => css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 50%;
        background-color: transparent;
        svg polyline {
            fill: none;
            stroke: #fff;
            stroke-miterlimit: 10;
            stroke-width: 3px;
            stroke-dasharray: 100;
        }
        rect,
        polyline {
            transition: opacity 0.1s linear;
            opacity: ${checked ? '1' : '0'};
        }
        path,
        rect,
        polyline {
            fill: ${disabled ? theme.grayLight : theme.black};
        }
        &:hover {
            cursor: ${disabled ? 'not-allowed' : 'pointer'};
            ${!disabled &&
            css`
                background-color: ${darken(0.1, theme.grayLighter)};
                polyline {
                    fill: ${lighten(0.1, theme.black)};
                }
            `}
        }
    `}
`;

const AnimatedContainer = styled(CheckboxContainer)`
    ${({ checked }) => css`
        svg polyline {
            animation: ${checked ? dash : 'none'} 2s ease-out both;
            animation-fill-mode: both;
        }
    `}
`;

export type CheckboxIconProps = BaseSVGProps & {
    checked?: boolean;
    width?: string | number;
    disabled?: boolean;
    onClick?: (event: React.MouseEvent<any>) => void;
    animated?: boolean;
};

const CheckboxIcon: FunctionComponent<CheckboxIconProps> = ({
    width,
    height,
    checked,
    disabled,
    onClick,
    animated = false,
}) => {
    const Container = animated ? AnimatedContainer : CheckboxContainer;
    return (
        <Container
            checked={checked}
            width={width}
            disabled={disabled}
            onClick={onClick}
            data-test="icon-checkbox-container"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={width}
                height={height}
                viewBox="0 0 24 24"
            >
                <path d="M20.4,3.6V20.4H3.6V3.6ZM24,0H0V24H24Z" />
                <rect x="3.6" y="3.6" width="16.8" height="16.8" />
                <polyline points="5.93 10.44 10.47 14.97 17.94 7.5" />
            </svg>
        </Container>
    );
};

export default CheckboxIcon;
