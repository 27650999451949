export const getDeepObjectValue = (
    object: Record<string, unknown>,
    selector: string
): number | string | boolean | undefined => {
    try {
        const keys = selector.split('.');

        const value = keys.reduce((obj: any, key: string) => {
            const isKeyHasArrayIndex = key.includes(']');

            if (!isKeyHasArrayIndex) return obj[key];

            const objKey = key?.split('[').shift();
            const arrayIndex = key?.split('[').pop()?.split(']').shift();

            if (arrayIndex === undefined || !objKey) {
                return obj;
            }

            return obj[objKey][arrayIndex];
        }, object);

        return value;
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(`Couldn't retrieve form value:`, (e as any).errorMessage);
        return undefined;
    }
};
