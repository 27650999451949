import { AxiosInstance } from 'axios';
import { KoddiAPIResponse } from 'types';
import {
    Experience,
    ExperienceDetails,
    MemberGroupExperiencesType,
} from '../types';

class MemberGroupExperiencesAPI {
    protected axios: AxiosInstance;

    constructor(axios: AxiosInstance) {
        this.axios = axios;
    }

    /**
     * @deprecated use useMemberGroupExperiences instead
     */
    public getMemberGroupExperiences = async (
        memberGroupId: number
    ): Promise<Experience[]> => {
        const response = await this.axios.get<
            KoddiAPIResponse<MemberGroupExperiencesType>
        >(`v1/member_groups/${memberGroupId}/experiences`);
        return response.data.result.experiences;
    };

    /**
     * @deprecated use useExperienceDetails instead
     */
    public getMemberGroupExperienceDetails = async (
        memberGroupId: number,
        experienceId: number
    ): Promise<ExperienceDetails> => {
        const response = await this.axios.get(
            `${window.API_ROUTE}v1/member_groups/${memberGroupId}/experiences/${experienceId}`
        );

        return response.data.result;
    };
}

export default MemberGroupExperiencesAPI;
