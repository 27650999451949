import { useDrawerArgs } from './Drawer.types';

export const useDrawer = ({
    onClose,
    onApply,
    closeOnApply = true,
}: useDrawerArgs): Record<string, any> => {
    const handleApply = () => {
        onApply?.();
        if (closeOnApply) {
            onClose();
        }
    };

    return {
        handleApply: onApply ? handleApply : undefined,
        onClose,
    };
};
