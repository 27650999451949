import { ReportConfig, ReportSlice } from 'api/Reports';
import { ReportFilter } from 'koddi/types';
import { SelectOption } from 'koddi-components/Select';
import { isSingleValue } from 'koddi-components/utils';
import { ValueType } from 'react-select';
import {
    UpdateDateRangeFilterAction,
    DeleteDateRangeFilterAction,
    UpdateAppSlicesAction,
    UpdateReportConfigAction,
    FiltersAction,
    DateRangeFilter,
    UpdateGraphMetricAction,
    GetReportConfigAction,
    GetReportConfigError,
    GetReportConfigSuccessAction,
    UpdateAppFilterSlicesAction,
    UpdateMetricDisplayOrder,
    SetFilterError,
    AppSlices,
    UpdateDimensionDisplayOrder,
} from './types';

export function getReportConfig(memberGroupId: number): GetReportConfigAction {
    return {
        type: FiltersAction.GET_REPORT_CONFIG,
        payload: {
            memberGroupId,
        },
    };
}

export function getReportConfigError(): GetReportConfigError {
    return {
        type: FiltersAction.GET_REPORT_CONFIG_ERROR,
    };
}

export function getReportConfigSuccess(
    config: ReportConfig
): GetReportConfigSuccessAction {
    return {
        type: FiltersAction.GET_REPORT_CONFIG_SUCCESS,
        payload: {
            data: config,
        },
    };
}

export function updateDateRangeFilter(
    dateRange: DateRangeFilter
): UpdateDateRangeFilterAction {
    return {
        type: FiltersAction.UPDATE_DATE_RANGE_FILTER,
        payload: { dateRange },
    };
}

export function updateGraphMetric(
    value: ValueType<SelectOption>
): UpdateGraphMetricAction {
    const metric = isSingleValue(value)
        ? value
        : value?.slice(0, 1)[0] || {
              label: 'Return on Ad Spend',
              value: 'ROAS',
          };
    return {
        type: FiltersAction.UPDATE_GRAPH_METRIC,
        payload: { metric },
    };
}

export function deleteDateRangeFilter(): DeleteDateRangeFilterAction {
    return {
        type: FiltersAction.DELETE_DATE_RANGE_FILTER,
    };
}

export function updateAppSlices(
    context: ReportSlice,
    slices: AppSlices
): UpdateAppSlicesAction {
    return {
        type: FiltersAction.UPDATE_APP_SLICES,
        payload: { context, slices },
    };
}

export function updateAppFilterSlices(
    context: ReportSlice,
    filters: ReportFilter[]
): UpdateAppFilterSlicesAction {
    return {
        type: FiltersAction.UPDATE_APP_FILTER_SLICES,
        payload: { context, filters },
    };
}

export function updateReportConfig(
    reportConfig: ReportConfig
): UpdateReportConfigAction {
    return {
        type: FiltersAction.UPDATE_REPORT_CONFIG,
        payload: { reportConfig },
    };
}
export function updateDimensionDisplayOrder(
    context: ReportSlice,
    order: string[]
): UpdateDimensionDisplayOrder {
    return {
        type: FiltersAction.UPDATE_DIMENSION_DISPLAY_ORDER,
        payload: { context, order },
    };
}

export function updateMetricDisplayOrder(
    context: ReportSlice,
    order: string[]
): UpdateMetricDisplayOrder {
    return {
        type: FiltersAction.UPDATE_METRIC_DISPLAY_ORDER,
        payload: { context, order },
    };
}
export function setFilterError(error: boolean): SetFilterError {
    return {
        type: FiltersAction.SET_FILTER_ERROR,
        payload: { error },
    };
}
