/* eslint-disable import/no-named-as-default */
import React from 'react';
import { useLocale } from 'koddi-components/LocaleProvider';
import { DateRangeButtonProps } from './DateRangeButton.types';
import * as Styled from './DateRangeButton.styled';
import { CChevronButton } from '../../_ChakraComponents';

const DateRangeButton = ({
    startDate,
    endDate,
    btnStyle = 'pill',
    presetDateRange,
    toggleDropdown,
    dropdownOpen,
    height,
    disabled,
    chakraButton = true,
    maxWidth,
    minWidth = '144px',
}: DateRangeButtonProps): JSX.Element => {
    const { formatDate } = useLocale();
    const presetDateRangeLabel = presetDateRange?.label;
    const startDateLabel = formatDate(startDate || new Date());
    const endDateLabel = endDate ? formatDate(endDate) : '';

    if (chakraButton) {
        return (
            <CChevronButton
                chevronDirection={dropdownOpen ? 'up' : 'down'}
                onClick={toggleDropdown}
                testId="date-range-dropdown-button"
                disabled={disabled}
                minWidth={minWidth}
                maxWidth={maxWidth}
            >
                {presetDateRangeLabel || `${startDateLabel} - ${endDateLabel}`}
            </CChevronButton>
        );
    }

    return (
        <Styled.DateRangeButton
            btnStyle={btnStyle}
            minWidth={minWidth}
            onClick={toggleDropdown}
            data-test="date-range-dropdown-button"
            dropdownOpen={dropdownOpen}
            btnHeight={height}
            iconRight={dropdownOpen ? 'chevronUp' : 'chevronDown'}
            disabled={disabled}
            maxWidth={maxWidth}
        >
            {presetDateRangeLabel || `${startDateLabel} - ${endDateLabel}`}
        </Styled.DateRangeButton>
    );
};

export default DateRangeButton;
