/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';
import {
    HorizontalFlexBox,
    VerticalFlexBox,
    FlexChild,
} from 'koddi-components/FlexBox';
import { cssProp, cssValue } from 'koddi-components/utils';
import { FieldsetProps, FormRowProps } from './Form.types';
import {
    createFormRowHorizontalSpacing,
    createFormRowMarginBottom,
} from './Form.utils';

export const FieldContainer = styled(FlexChild)`
    flex: 1;
    ${({ alignSelf, justifySelf }) => css`
        ${cssProp('align-self', alignSelf)}
        ${cssProp('justify-self', justifySelf)}
    `}
`;

export const Fieldset = styled.fieldset<FieldsetProps>`
    ${({ theme, borderTop, borderBottom }) => css`
        outline: none;
        border: none;
        padding: ${theme.spaceUnit * 6}px 0px;
        display: flex;
        flex-direction: column;
        border-top: ${borderTop ? `1px solid ${theme.grayLight}` : 'none'};
        border-bottom: ${borderBottom
            ? `1px solid ${theme.grayLight}`
            : 'none'};
    `}
`;

export const FormRow = styled(HorizontalFlexBox)<FormRowProps>`
    ${({
        theme,
        horizontalSpacing = 'small',
        verticalSpacing,
        alignItems = 'inherit',
        flexGrow,
        marginBottom,
    }) => css`
        align-items: ${alignItems};
        flex-grow: ${flexGrow};
        margin-bottom: ${marginBottom ||
        createFormRowMarginBottom(theme.spaceUnit, verticalSpacing || 'small')};
        > ${FieldContainer} {
            margin-right: ${createFormRowHorizontalSpacing(
                theme.spaceUnit,
                horizontalSpacing
            )};
            &:last-of-type {
                margin-right: 0px;
            }
        }
    `}
`;

export const FormSection = styled(VerticalFlexBox)<{ width?: number | string }>`
    ${({ theme, width }) => css`
        width: ${cssValue(width, '400px')};
        margin-right: ${theme.spaceUnit * 15}px;
    `}
`;

export const ErrorText = styled.span<{
    hasError?: boolean;
    fitErrorTextContent?: boolean;
}>`
    ${({ theme, hasError, fitErrorTextContent }) => css`
        color: ${theme.error};
        font-size: 10px;
        height: ${hasError && !fitErrorTextContent
            ? '14px'
            : hasError && fitErrorTextContent
            ? 'fit-content'
            : '14px'};
        display: block;
        margin-top: ${theme.spaceUnit * 2}px;
        opacity: ${hasError ? 1 : 0};
    `}
`;
