import { CSSProperties, Dispatch, SetStateAction } from 'react';
import {
    InputActionMeta,
    GroupedOptionsType,
    Props as SelectProps,
    ValueType,
} from 'react-select';
import { SelectComponents } from 'react-select/src/components';
import { DebouncedState } from 'use-debounce/lib/useDebouncedCallback';
import { OptionType, selectValue } from '../../Types';

export interface SelectFieldBaseProps<T extends selectValue = string | number>
    extends Omit<SelectProps, 'onChange' | 'value'> {
    options?: OptionType[];
    groupedOptions?: GroupedOptionsType<OptionType>;
    name: string;
    testId: string;
    onChange: (value?: T) => void;
    onClose?: () => void;
    errorText?: string;
    inputValue?: T;
    hideError?: boolean;
    isVirtualized?: boolean;
    components?: Partial<SelectComponents<OptionType>>;
    isControlledInput?: boolean;
    height?: number;
    minWidth?: number;
    isSortable?: boolean;
    selectedLabel?: string;
    hasTooltip?: boolean;
    resetSearchOnSelection?: boolean;
    placeholder?: string;
    shouldWrap?: boolean;
}

export type PaginatedMultiSelectFieldProps<
    T extends selectValue = string[] | number[]
> = SelectFieldBaseProps<T> & {
    fetchOptions: (
        search: string | undefined,
        page: number,
        count?: number
    ) => Promise<OptionType[]>;
    trackedPagination?: {
        page: number;
        count: number;
    };
    dependsOn?: boolean;
};

export type MultiSelectFieldProps<
    T extends string[] | number[]
> = SelectFieldBaseProps<T>;

export type SingleSelectFieldProps<T extends string | number> = Omit<
    SelectProps,
    'isMulti' | 'selectedLabel' | 'resetSearchOnSelection'
> &
    SelectFieldBaseProps<T>;

export type SelectFieldHookReturn = {
    handleChange: (option: ValueType<OptionType>) => void;
    loadOptions: DebouncedState<
        (value: string, callback: (options: OptionType[]) => void) => void
    >;
    defaultOptions: OptionType[] | GroupedOptionsType<OptionType>;
    portal: HTMLElement | null;
    valueProp:
        | {
              value?: OptionType;
          }
        | {
              defaultValue?: OptionType;
          };
    handleSearchChange: (value: string, { action }: InputActionMeta) => void;
    searchValue: string;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
};

export type SelectFieldThemeHookReturn = {
    theme: (baseTheme: any) => any;
    styles: Record<
        string,
        (baseStyles: CSSProperties, state?: any) => CSSProperties
    >;
};

export type PaginatedSelectFieldHookReturn = {
    portal: HTMLElement | null;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    valueProp: {
        value?: OptionType<string | number> | undefined;
    };
    searchValue: string;
    options: OptionType[];
    isLoading: boolean;
    handleSearchChange: (value: string, { action }: InputActionMeta) => void;
    handleMenuOpen: () => void;
    debouncedChange: DebouncedState<(option: ValueType<OptionType>) => void>;
    fetchAndSetOptions: (
        count?: number | undefined,
        pageNumber?: number | undefined
    ) => Promise<void>;
};

// derived from react selects InputActionsType
export enum InputActions {
    setValue = 'set-value',
    inputChange = 'input-change',
    inputBlur = 'input-blur',
    menuClose = 'menu-close',
}

export type OptionWithProps = OptionType & {
    isPermanent?: boolean;
    alwaysFirst?: boolean;
};
