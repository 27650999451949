import { AdvertiserGroup } from 'api/Admin/AdvertiserGroups';
import { Advertiser } from 'api/Advertiser/Advertiser.types';
import { CurrencyList } from 'api/Admin/Currency';
import { BiddingConfigReport } from 'api/Admin/Currency/Currency.types';
import {
    FetchAppContextSuccessAction,
    FetchAppContextError,
    AppContextAction,
    DefaultMemberGroup,
    SetPreviousMemberGroup,
    SetErrorCodeAction,
    SetMemberGroupCurrencies,
    SetMemberGroupBiddingConfigs,
    SetCalculatedPageCurrency,
    SetAdvertiserHasFunds,
    ContextMemberGroup,
    SetSavedReportTitle,
    FetchEmbeddedAppContextAction,
    SetAdvertiserContext,
    ContextAdvertiser,
    FetchClientContextAction,
    FetchAdvertiserContextAction,
    FetchAdvertiserGroupContextAction,
    ClearAdvertiserContext,
    ClearClientContext,
    ClearAdvertiserGroupContext,
    SetClientContext,
} from './types';

export function fetchClientContext(
    memberGroupId: number,
    advertiserId?: number | null,
    advertiserGroupId?: number | null
): FetchClientContextAction {
    return {
        type: AppContextAction.FETCH_CLIENT_CONTEXT,
        payload: {
            memberGroupId,
            advertiserId,
            advertiserGroupId,
        },
    };
}
export function fetchAdvertiserContext(
    advertiserId: number,
    memberGroupId?: number | null
): FetchAdvertiserContextAction {
    return {
        type: AppContextAction.FETCH_ADVERTISER_CONTEXT,
        payload: {
            advertiserId,
            memberGroupId,
        },
    };
}

export function fetchAdvertiserGroupContext(
    advertiserGroupId: number,
    memberGroupId?: number | null
): FetchAdvertiserGroupContextAction {
    return {
        type: AppContextAction.FETCH_ADVERTISER_GROUP_CONTEXT,
        payload: {
            advertiserGroupId,
            memberGroupId,
        },
    };
}

export function fetchAppContextSuccess(
    memberGroup: ContextMemberGroup | DefaultMemberGroup | null,
    advertiser?: Advertiser | null,
    advertiserGroup?: AdvertiserGroup | null
): FetchAppContextSuccessAction {
    return {
        type: AppContextAction.FETCH_APP_CONTEXT_SUCCESS,
        payload: {
            memberGroup,
            advertiser: advertiser ?? null,
            advertiserGroup: advertiserGroup ?? null,
        },
    };
}

export function fetchEmbeddedAppContext(
    memberGroupId: number
): FetchEmbeddedAppContextAction {
    return {
        type: AppContextAction.FETCH_EMBEDDED_APP_CONTEXT,
        payload: {
            memberGroupId,
        },
    };
}

export function fetchAppContextError(
    memberGroupId: number,
    advertiserId?: number | null,
    advertiserGroupId?: number | null
): FetchAppContextError {
    return {
        type: AppContextAction.FETCH_APP_CONTEXT_ERROR,
        payload: {
            memberGroupId,
            advertiserId: advertiserId ?? null,
            advertiserGroupId: advertiserGroupId ?? null,
        },
    };
}

export function setPreviousMemberGroupId(
    previousMemberGroupId: number | null
): SetPreviousMemberGroup {
    return {
        type: AppContextAction.SET_PREVIOUS_MEMBER_GROUP,
        payload: {
            previousMemberGroupId,
        },
    };
}

export function setSavedReportTitle(
    savedReportTitle: string | null
): SetSavedReportTitle {
    return {
        type: AppContextAction.SET_SAVED_REPORT_TITLE,
        payload: {
            savedReportTitle,
        },
    };
}

export function setErrorCode(errorCode: number | null): SetErrorCodeAction {
    return {
        type: AppContextAction.SET_ERROR_CODE,
        payload: {
            errorCode,
        },
    };
}

export function clearClientContext(): ClearClientContext {
    return {
        type: AppContextAction.CLEAR_CLIENT_CONTEXT,
    };
}

export function clearAdvertiserContext(): ClearAdvertiserContext {
    return {
        type: AppContextAction.CLEAR_ADVERTISER_CONTEXT,
    };
}

export function clearAdvertiserGroupContext(): ClearAdvertiserGroupContext {
    return {
        type: AppContextAction.CLEAR_ADVERTISER_GROUP_CONTEXT,
    };
}

export function setMemberGroupCurrencies(
    memberGroupCurrencies: CurrencyList
): SetMemberGroupCurrencies {
    return {
        type: AppContextAction.SET_MEMBER_GROUP_CURRENCIES,
        payload: {
            memberGroupCurrencies,
        },
    };
}
export function setMemberGroupBiddingConfigs(
    biddingConfigs: BiddingConfigReport
): SetMemberGroupBiddingConfigs {
    return {
        type: AppContextAction.SET_MEMBER_GROUP_BIDDING_CONFIGS,
        payload: {
            biddingConfigs,
        },
    };
}

export function setCalculatedPageCurrency(
    calculatedPageCurrency: number
): SetCalculatedPageCurrency {
    return {
        type: AppContextAction.SET_CALCULATED_PAGE_CURRENCY,
        payload: {
            calculatedPageCurrency,
        },
    };
}

export function setAdvertiserHasFunds(
    hasFunds: boolean
): SetAdvertiserHasFunds {
    return {
        type: AppContextAction.SET_ADVERTISER_HAS_FUNDS,
        payload: {
            hasFunds,
        },
    };
}

export function setAdvertiserContext(
    advertiser: ContextAdvertiser
): SetAdvertiserContext {
    return {
        type: AppContextAction.SET_ADVERTISER_CONTENT,
        payload: {
            advertiser,
        },
    };
}

export function setClientContext(
    memberGroup: ContextMemberGroup
): SetClientContext {
    return {
        type: AppContextAction.SET_CLIENT_CONTEXT,
        payload: {
            memberGroup,
        },
    };
}
