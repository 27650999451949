import format from 'date-fns/format';
/**
 * Formats dates into the required date format for sending to the Koddi
 * API. `yyyy-MM-dd`
 */
function formatDateForKoddiAPI(dateToFormat: string | Date): string {
    return format(new Date(dateToFormat), 'yyyy-MM-dd');
}

/**
 * Formats dates into the required date format with time stamp for sending to the Koddi
 * API. `yyyy-MM-dd 00:00:00`
 */
export function formatDateWithTimeForKoddiAPI(
    dateToFormat: string | Date,
    timeStamp?: 'start' | 'end'
): string {
    if (timeStamp === 'start') {
        return `${format(new Date(dateToFormat), 'yyyy-MM-dd')} 00:00:00`;
    }
    if (timeStamp === 'end') {
        return `${format(new Date(dateToFormat), 'yyyy-MM-dd')} 23:59:59`;
    }
    return format(new Date(dateToFormat), 'yyyy-MM-dd HH:mm:ss');
}

export default formatDateForKoddiAPI;
