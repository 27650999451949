import { FontValues } from './global.types';

export const fontOptions = [
    {
        label: 'Default',
        value: FontValues.default,
    },
    {
        label: 'Limitless',
        value: FontValues.limitless,
    },
];
