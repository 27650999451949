import React, { VoidFunctionComponent } from 'react';
import StatusDot from 'koddi-components/StatusDot';
import { StatusDisplayProps } from './StatusDisplay.types';
import { CRow } from '../Layouts';
import { Spacing } from '../ChakraTheme/configs/Spacing.config';
import { CText } from '../Typography';

const STATUS_MAP: Record<string, string> = {
    active: 'Active',
    inactive: 'Inactive',
    pending: 'Pending',
    archived: 'Archived',
    ended: 'Ended',
    locked: 'Locked',
    paused: 'Paused',
};

export const CStatus: VoidFunctionComponent<StatusDisplayProps> = ({
    status,
    showText = false,
}) => {
    return (
        <CRow noBreak alignItems="center" gap={Spacing.XS}>
            <StatusDot statusValue={status} />
            {showText && STATUS_MAP[status] && (
                <CText text={STATUS_MAP[status]} />
            )}
        </CRow>
    );
};
