import styled, { css } from 'styled-components';

export const DateRangePickerList = styled.ul`
    ${({ theme }) => css`
        padding: 0;
        list-style-type: none;
        width: 150px;
        border-right: 1px solid ${theme.grayLight};
        margin: 0px;
        font-size: 12px;
        background: #ffffff;
        text-transform: capitalize;
    `}
`;

export const DateRangePickerListItem = styled.li<{ selected: boolean }>`
    ${({ theme, selected }) => css`
        padding: ${theme.spaceUnit * 3}px;
        transition: 0.3s background-color linear;
        background: #ffffff;
        ${selected &&
        css`
            background-color: ${theme.grayLight};
            font-weight: bold;
        `}

        &:hover {
            background-color: ${theme.grayLight};
            cursor: pointer;
        }
    `}
`;
