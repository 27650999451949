import { KoddiLocaleKey } from 'koddi-components/LocaleProvider';
import { DatePickerPresetDateRange } from './DateRangePicker.types';

export const DATE_RANGE_PICKER_TRANSLATIONS: Record<
    DatePickerPresetDateRange,
    Record<KoddiLocaleKey, string>
> = {
    AllTime: {
        'en-US': 'All Time',
        es: 'Todo el Tiempo',
        de: 'Alle Zeit',
        ja: 'いつも',
        'zh-Hant-TW': '整天',
    },
    YearToDate: {
        'en-US': 'Year to Date',
        es: 'El año hasta la fecha',
        de: 'Der bisherige Jahresverlauf',
        ja: '年初来',
        'zh-Hant-TW': '今年迄今為止',
    },
    QuarterToDate: {
        'en-US': 'Quarter to Date',
        es: 'Trimestre hasta la fecha',
        de: 'Bisheriges Quartal',
        ja: '今四半期',
        'zh-Hant-TW': '季度至今',
    },
    MonthToDate: {
        'en-US': 'Month to Date',
        es: 'Mes hasta la fecha',
        de: 'Diesen Monat',
        ja: '過去1カ月間',
        'zh-Hant-TW': '本月至今',
    },
    LastQuarter: {
        'en-US': 'Last Quarter',
        es: 'Último cuarto',
        de: 'Letztes Vierteljahr',
        ja: '前四半期',
        'zh-Hant-TW': '上個季度',
    },
    LastMonth: {
        'en-US': 'Last Month',
        es: 'El mes pasado',
        de: 'Im vergangenen Monat',
        ja: '先月',
        'zh-Hant-TW': '上個月',
    },
    Last60Days: {
        'en-US': 'Last 60 Days',
        es: 'Los últimos 60 días',
        de: 'Letzten 60 Tage',
        ja: '過去60日間',
        'zh-Hant-TW': '最近60天',
    },
    Last30Days: {
        'en-US': 'Last 30 Days',
        es: 'Los últimos 30 días',
        de: 'Letzten 30 Tage',
        ja: '過去30日間',
        'zh-Hant-TW': '最近30天',
    },
    Last14Days: {
        'en-US': 'Last 14 Days',
        es: 'Los últimos 14 días',
        de: 'Letzten 14 Tage',
        ja: '過去14日間',
        'zh-Hant-TW': '最近14天',
    },
    Last7Days: {
        'en-US': 'Last 7 Days',
        es: 'Los últimos 7 días',
        de: 'Letzten 7 Tage',
        ja: '過去7日間',
        'zh-Hant-TW': '最近7天',
    },
    Yesterday: {
        'en-US': 'Yesterday',
        es: 'Ayer',
        de: 'Gestern',
        ja: '昨日',
        'zh-Hant-TW': '昨天',
    },
    Today: {
        'en-US': 'Today',
        es: 'Hoy',
        de: 'Heute',
        ja: '今日',
        'zh-Hant-TW': '今天',
    },
    Custom: {
        'en-US': 'Custom',
        es: 'Personalizado',
        de: 'Heute',
        ja: 'カスタム',
        'zh-Hant-TW': '风俗',
    },
};
