import styled, { css } from 'styled-components';
import { darken, lighten } from 'polished';
import { IconWrapper } from 'koddi-components/Icon';
import { ButtonGroup } from 'koddi-components/Button';
import { IconTooltipWrapper } from 'koddi-components/Tooltip';
import { HorizontalFlexBox } from 'koddi-components/FlexBox';

import { StyledInputProps, StyledInputInnerProps } from './input.types';
import { BorderWidth } from '../_ChakraComponents';

export const InputContainer = styled.div<{
    fullWidth?: boolean;
    flexDirection?: string;
    fieldWidth?: string;
}>`
    ${({ fullWidth, flexDirection, fieldWidth }) => css`
        display: flex;
        flex-direction: ${flexDirection || 'column'};
        width: ${fullWidth ? '100%' : fieldWidth || 'auto'};
    `}
`;

export const InputButtonGroup = styled(ButtonGroup)`
    ${({ theme }) => css`
        visibility: hidden;
        background-color: inherit;
        margin-right: ${theme.spaceUnit * 3}px;
        button {
            margin-right: ${theme.spaceUnit * 3}px;
            height: 30px;
        }
    `}
`;

export const LabelWrapper = styled.div<{
    labelToolTip?: boolean;
    visibility?: string;
}>`
    ${({ labelToolTip, visibility }) => {
        return css`
            display: ${labelToolTip ? 'flex' : 'block'};
            inline-size: max-content;
            & > label:after {
                visibility: ${visibility};
                color: red;
                content: ' *'};
            }
        `;
    }}
`;

export const LabelToolTip = styled(IconTooltipWrapper)`
    ${() => css`
        margin-bottom: 12px;
        margin-left: 10px;
    `}
`;

export const InputInnerContainer = styled.div<StyledInputInnerProps>`
    ${({
        theme,
        hasError,
        readOnly,
        disabled,
        customChevronIconMargin,
        v2,
        borderRadius,
        minWidth,
        maxHeight,
    }) => {
        let color = theme.black;
        let backgroundColor = theme.grayLighter;
        if (disabled || readOnly) {
            color = theme.gray;
            backgroundColor = theme.grayLightest;
        } else if (hasError) {
            color = theme.error;
            backgroundColor = theme.errorBackground;
        }
        const borderWidth = BorderWidth.Thinnest;
        const borderStyle = hasError || v2 ? 'solid' : '';
        let borderColor = hasError ? theme.error : '';
        if (v2 && !hasError) {
            borderColor = theme.grayLight;
        }
        let iconWrapperMarginLeft = `${theme.spaceUnit * 4}px`;
        if (customChevronIconMargin) {
            iconWrapperMarginLeft = '0px';
        }
        return css`
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            flex: 1;
            background-color: ${v2 ? theme.white : backgroundColor};
            border: ${borderWidth} ${borderStyle} ${borderColor};
            border-radius: ${v2 ? borderRadius || '5px' : ''};
            color: ${color};
            min-width: ${minWidth || '120px'};
            max-height: ${maxHeight || null};
            ${IconWrapper} {
                margin-left: ${iconWrapperMarginLeft};
            }
            &:hover > input[type='number'] + ${InputButtonGroup} {
                visibility: visible;
            }
            input[type='number']:focus + ${InputButtonGroup} {
                visibility: visible;
            }
            ${!disabled &&
            !readOnly &&
            css`
                &:focus-within {
                    background-color: ${v2 ? '' : darken(0.1, backgroundColor)};
                    border-color: ${v2 && !hasError ? `${theme.primary}` : ''};
                    color: ${lighten(0.1, color)};
                }
            `}
        `;
    }}
`;

export const Input = styled.input<StyledInputProps>`
    ${({ theme, disabled, v2, height, minHeight, paddingLeft }) => {
        return css`
            border: none;
            padding-left: ${paddingLeft || `${theme.spaceUnit * 4}px`};
            height: ${height || '30px'};
            font-size: 12px;
            font-family: ${theme.typography.fontFamily};
            transition: background-color, color 0.3s linear;
            color: inherit;
            background-color: inherit;
            flex: 1;
            width: 100%;
            cursor: ${disabled ? 'not-allowed' : 'text'};
            border-radius: ${v2 ? '5px' : ''};
            &:focus {
                outline: none;
                border: none;
            }
            &[type='textarea'] {
                min-height: ${minHeight || '120px'};
                padding: ${theme.spaceUnit * 2}px;
                font: inherit;
                font-size: 12px;
                line-height: 1rem;
            }
            &[type='number'] {
                -moz-appearance: textfield;
            }
            &[type='number']::-webkit-inner-spin-button,
            &[type='number']::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        `;
    }}
`;

export const InputCount = styled.div`
    text-align: right;
`;

export const MessagingGroup = styled(HorizontalFlexBox)`
    ${({ theme }) => css`
        font-size: 10px;
        margin: ${theme.spaceUnit}px 0 ${theme.spaceUnit * 2}px;
    `}
    & > * {
        margin-top: 0 !important;
        flex: 1;
    }
`;
