import React, { FunctionComponent } from 'react';

import { DropdownMenuProps } from './dropdown.types';
import * as Styled from './dropdown.styled';

const DropdownMenu: FunctionComponent<DropdownMenuProps> = ({
    items,
    closeMenu,
    style,
    forwardedRef,
    className,
    visible,
    usePortal,
    minWidth,
    'data-test': dataTest,
    ...rest
}) => (
    <Styled.DropdownMenuList
        {...rest}
        className={className}
        ref={forwardedRef}
        style={style}
        visible={visible}
        usePortal={usePortal}
        minWidth={minWidth}
    >
        {items?.map((item, index) => (
            <Styled.DropdownMenuListItem
                key={`dropdown-item-${item?.value || index}`}
                isDestructive={item.isDestructive}
                isDisabled={item.isDisabled}
                data-test={
                    item['data-test'] || `${dataTest}-list-item-${index}`
                }
                onClick={() => {
                    if (item.onClick && !item.isDisabled) {
                        item.onClick(item.value);
                        closeMenu();
                    }
                }}
            >
                {item.label}
            </Styled.DropdownMenuListItem>
        ))}
    </Styled.DropdownMenuList>
);

export default React.forwardRef<HTMLUListElement, DropdownMenuProps>(
    (props, ref) => <DropdownMenu forwardedRef={ref} {...props} />
) as FunctionComponent<DropdownMenuProps>;
