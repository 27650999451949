import { AxiosInstance } from 'axios';
import KoddiUsersAPI from './Admin/User';
import ActivityAPI from './Admin/Activity';
import MemberGroupAPI from './Admin/MemberGroup';
import MemberGroupTypesAPI from './Admin/MemberGroupTypes';
import MemberAPI from './Admin/Member';
import PermissionAPI from './Admin/Permission';
import RoleAPI from './Admin/Role';
import RolePermissionsAPI from './Admin/RolePermission';
import AdvertiserGroupsAPI from './Admin/AdvertiserGroups';
import KoddiThemeAPI from './Theme';
import { KoddiAPIRecord } from './api.types';
import KoddiAdvertiserAPI from './Advertiser';
import KoddiCampaignsAPI from './Campaigns';
import KoddiAdGroupsAPI from './AdGroups';
import SessionAPI from './Session';
import ReportAPI from './Reports';
import KoddiEntitiesAPI from './Entities';
import IndustryAPI from './Admin/Industry';
import CurrencyAPI from './Admin/Currency';
import KoddiLocalesAPI from './Admin/Locales';
import KoddiTimezonesAPI from './Admin/Timezones';
import EntityRegistrationAPI from './Admin/EntityRegistration';
import TargetingConfigurationAPI from './Admin/TargetingConfiguration';
import BillingAPI from './Billing';
import TemplateOverviewAPI from './Admin/TemplateOverview';
import MemberGroupExperiencesAPI from './Admin/MemberGroupExperiences/MemberGroupExperiences';
import InsertionOrderAPI from './Admin/InsertionOrders';
import KoddiRecommendationsAPI from './KoddiRecommendations';

export function createKoddiApis(axios: AxiosInstance): KoddiAPIRecord {
    return {
        AdGroups: new KoddiAdGroupsAPI(axios),
        Admin: {
            Activity: new ActivityAPI(axios),
            Member: new MemberAPI(axios),
            MemberGroup: new MemberGroupAPI(axios, { cacheTimeout: 600000 }),
            MemberGroupTypes: new MemberGroupTypesAPI(axios),
            Permission: new PermissionAPI(axios),
            Role: new RoleAPI(axios),
            RolePermission: new RolePermissionsAPI(axios),
            Industry: new IndustryAPI(axios),
            User: new KoddiUsersAPI(axios),
            AdvertiserGroup: new AdvertiserGroupsAPI(axios),
            Currency: new CurrencyAPI(axios),
            Locales: new KoddiLocalesAPI(axios),
            Timezones: new KoddiTimezonesAPI(axios),
            EntityRegistration: new EntityRegistrationAPI(axios),
            TargetingConfiguration: new TargetingConfigurationAPI(axios),
            TemplateOverview: new TemplateOverviewAPI(axios),
            MemberGroupExperiences: new MemberGroupExperiencesAPI(axios),
            InsertionOrder: new InsertionOrderAPI(axios),
        },
        Entities: new KoddiEntitiesAPI(axios),
        Campaigns: new KoddiCampaignsAPI(axios),
        Advertiser: new KoddiAdvertiserAPI(axios),
        Session: new SessionAPI(axios),
        Theme: new KoddiThemeAPI(axios, { cacheTimeout: 600000 }),
        Reports: new ReportAPI(axios),
        Billing: new BillingAPI(axios),
        KoddiRecommendations: new KoddiRecommendationsAPI(axios),
    };
}
