import React from 'react';
import { Box } from '@chakra-ui/react';
import { ReactMultiEmail } from 'react-multi-email';
import { EmailTag } from './atoms/EmailTag.component';
import { useEmailGrabber } from './EmailGrabber.hooks';
import { EmailGrabberProps } from './EmailGrabber.types';
import 'react-multi-email/dist/style.css';
import { CFormError } from '../../../Typography';

export const EmailGrabber = ({
    onChange,
    inputValue,
    maxEmails = 20,
    name,
    errorText,
    ...props
}: EmailGrabberProps): JSX.Element => {
    const {
        emails,
        handleChange,
        styles,
        handleKeyUp,
        handleKeyEvent,
    } = useEmailGrabber({
        onChange,
        name,
        inputValue,
        errorText,
    });
    return (
        <Box width="100%">
            <ReactMultiEmail
                {...props}
                style={styles}
                emails={emails}
                onChange={(_emails) => {
                    handleChange(_emails);
                }}
                enable={({ emailCnt }: { emailCnt: number }) => {
                    return emailCnt <= maxEmails;
                }}
                onKeyDown={handleKeyEvent}
                onKeyUp={handleKeyUp}
                getLabel={(email, index, removeEmail) => {
                    return (
                        <EmailTag
                            email={email}
                            key={index}
                            removeFunction={() => {
                                removeEmail(index);
                            }}
                        />
                    );
                }}
            />
            <CFormError data-test={`${name}--error`} text={errorText} />
        </Box>
    );
};
