import { ButtonStyle } from './button.types';

export const BUTTON_TYPES: Record<ButtonStyle, ButtonStyle> = {
    loginSecondary: 'loginSecondary',
    login: 'login',
    primary: 'primary',
    secondary: 'secondary',
    tertiary: 'tertiary',
    pill: 'pill',
    rect: 'rect',
    transparent: 'transparent',
    border: 'border',
    selector: 'selector',
    success: 'success',
    error: 'error',
};

export const COMPONENT_KEY = 'button';
