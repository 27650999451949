/* eslint-disable prefer-destructuring */
import { KoddiAPIResponse } from 'types';
import { KoddiListFilterParams, ReportFilter } from 'koddi/types';
import KoddiObjectAPI from '../../api.object';
import {
    KoddiUser,
    CreateKoddiUserInput,
    UpdateKoddiUserInput,
    KoddiUserListResponse,
    KoddiUserList,
    KoddiUserReportListResponse,
    KoddiUserReportList,
} from './User.types';
import { formatUser } from './User.utils';

export const KODDI_USER_SINGULAR_API = '/user';
export const KODDI_USER_PLURAL_API = '/users';
export const KODDI_USER_REPORT_API = '/users/report';

class KoddiUserAPI extends KoddiObjectAPI {
    /** Creates a new user in the Koddi Database */
    public create = async (
        user: CreateKoddiUserInput,
        memberGroupId?: number,
        advertiserId?: string,
        isClientContextual?: boolean,
        isAdvertiserContextual?: boolean
    ): Promise<KoddiUser> => {
        const formattedUser = formatUser(user as any);
        if (!formattedUser.locale) {
            (formattedUser as any).locale = {
                id: 1,
            };
        }
        const userName = formattedUser.email?.split('@');
        if (userName) {
            formattedUser.user_name = userName[0];
        }

        let endpoint: string;
        if (isAdvertiserContextual) {
            endpoint = `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/users`;
        } else if (isClientContextual) {
            endpoint = `v1/member_groups/${memberGroupId}/users`;
        } else {
            endpoint = `v1${KODDI_USER_PLURAL_API}`;
        }

        const response = await this.axios.post<KoddiAPIResponse<KoddiUser>>(
            endpoint,
            formattedUser
        );
        return response.data.result;
    };

    /** Gets the a user information from our system */
    public get = async (email: string): Promise<KoddiUser> => {
        const response = await this.axios.get<KoddiAPIResponse<KoddiUser>>(
            `v1${KODDI_USER_PLURAL_API}/user_by_email`,
            {
                params: { email },
            }
        );
        return response.data.result;
    };

    /** Gets the a user information from our system */
    public getByID = async (id: string | number): Promise<KoddiUser> => {
        const response = await this.axios.get<KoddiAPIResponse<KoddiUser>>(
            `v1${KODDI_USER_PLURAL_API}/${id}`
        );
        return response.data.result;
    };

    /** Gets a contextual client user's information from our system */
    public getByMemberGroupContextualID = async (
        userId: number,
        memberGroupId?: number
    ): Promise<KoddiUser> => {
        const response = await this.axios.get<KoddiAPIResponse<KoddiUser>>(
            `v1/member_groups/${memberGroupId}/users/${userId}`
        );
        return response.data.result;
    };

    /** Gets a contextual advertiser user's information from our system */
    public getByAdvertiserContextualID = async (
        memberGroupId: number,
        advertiserId: number,
        userId: number
    ): Promise<KoddiUser> => {
        const response = await this.axios.get<KoddiAPIResponse<KoddiUser>>(
            `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/users/${userId}`
        );
        return response.data.result;
    };

    /** Updates the user information for logged in SuperAdmin in the Koddi Database */
    public updateSelf = async (
        userId: number,
        user: UpdateKoddiUserInput
    ): Promise<KoddiUser> => {
        const formattedUser = formatUser(user);
        const response = await this.axios.put<KoddiAPIResponse<KoddiUser>>(
            `v1${KODDI_USER_PLURAL_API}/profiles/${userId}`,
            formattedUser
        );
        return response.data.result;
    };

    public updateBasicInfo = async (
        user: UpdateKoddiUserInput
    ): Promise<KoddiUser> => {
        const formattedUser = formatUser(user);
        const response = await this.axios.put<KoddiAPIResponse<KoddiUser>>(
            `v1${KODDI_USER_PLURAL_API}/basic_info`,
            formattedUser
        );
        return response.data.result;
    };

    /** Updates the user information for non-logged in user in the Koddi Database */
    public update = async (
        userId: number,
        user: UpdateKoddiUserInput
    ): Promise<KoddiUser> => {
        const formattedUser = formatUser(user);
        const response = await this.axios.put<KoddiAPIResponse<KoddiUser>>(
            `v1${KODDI_USER_PLURAL_API}/${userId}`,
            formattedUser
        );
        return response.data.result;
    };

    /** Updates the Contextual Advertiser user information in the Koddi Database */
    public updateContextualAdvertiserUser = async (
        memberGroupId: string | number,
        advertiserId: string | number,
        userId: string,
        user: UpdateKoddiUserInput
    ): Promise<KoddiUser> => {
        const formattedUser = formatUser(user);
        const response = await this.axios.put<KoddiAPIResponse<KoddiUser>>(
            `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/users/${userId}`,
            formattedUser
        );
        return response.data.result;
    };

    /** Updates the Contextual Client user information in the Koddi Database */
    public updateContextualClientUser = async (
        memberGroupId: string | number,
        userId: string,
        user: UpdateKoddiUserInput
    ): Promise<KoddiUser> => {
        const formattedUser = formatUser(user);
        const response = await this.axios.put<KoddiAPIResponse<KoddiUser>>(
            `v1/member_groups/${memberGroupId}/users/${userId}`,
            formattedUser
        );
        return response.data.result;
    };

    /** Deletes the given user. */
    public delete = async (userId: number): Promise<string> => {
        const response = await this.axios.delete<KoddiAPIResponse<string>>(
            `v1${KODDI_USER_PLURAL_API}/${userId}`
        );
        return response.data.result;
    };

    /** Deletes specific client access of user. */
    public deleteClientAccessOfUser = async (
        memberGroupId: number,
        userId: number
    ): Promise<string> => {
        const response = await this.axios.delete<KoddiAPIResponse<string>>(
            `v1/member_groups/${memberGroupId}/users/${userId}`
        );
        return response.data.result;
    };

    /** Deletes specific advertiser access of user. */

    public deleteAdvertiserAccessOfUser = async (
        memberGroupId: number,
        advertiserId: number,
        userId: number
    ): Promise<string> => {
        const response = await this.axios.delete<KoddiAPIResponse<string>>(
            `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/users/${userId}`
        );
        return response.data.result;
    };

    /** Triggers a reset password for the given user email. */
    public resetPassword = async (email: string): Promise<string> => {
        await this.axios.put(`v1${KODDI_USER_SINGULAR_API}/resetPassword`, {
            email,
        });
        return email;
    };

    /** Resends the invitation email for a new user */
    public resendEmail = async (
        userId: number,
        memberGroupId?: number,
        advertiserId?: number
    ): Promise<void> => {
        let endpoint: string;
        if (advertiserId && memberGroupId) {
            endpoint = `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/users/${userId}/resend_email`;
        } else if (memberGroupId) {
            endpoint = `v1/member_groups/${memberGroupId}/users/${userId}/resend_email`;
        } else {
            endpoint = `v1/users/${userId}/resend_email`;
        }

        await this.axios.post(endpoint);
    };

    /** Gets the paginated list of all active users in the DB. Has optional search by first and last name. */
    public getList = async (
        params?: KoddiListFilterParams
    ): Promise<KoddiUserList> => {
        const response = await this.axios.post<
            KoddiAPIResponse<KoddiUserListResponse>
        >(`v1${KODDI_USER_PLURAL_API}`, {
            pagination: {
                start: params?.start || 0,
                count: params?.count || 20,
            },
            name: params?.search,
        });
        return response.data.result.users;
    };

    /** Gets the paginated list of users report in the DB. */
    public getUserReport = async (
        params?: KoddiListFilterParams,
        filters?: ReportFilter[]
    ): Promise<KoddiUserReportList> => {
        const [id, direction] = params?.sort?.split(' ') ?? [];
        const response = await this.axios.post<
            KoddiAPIResponse<KoddiUserReportListResponse>
        >(`v1${KODDI_USER_REPORT_API}`, {
            pagination: {
                start: params?.start || 0,
                count: params?.count || 20,
            },
            filters,
            ...(id
                ? {
                      sort: [
                          {
                              field: id,
                              ...(direction ? { order: direction } : {}),
                          },
                      ],
                  }
                : {}),
        });
        return response.data.result.users;
    };

    /** Gets the paginated list of users report in the DB. */
    public getUsersByClientReport = async (
        memberGroupId: number,
        params?: KoddiListFilterParams,
        filters?: ReportFilter[]
    ): Promise<any> => {
        const [id, direction] = params?.sort?.split(' ') ?? [];
        const response = await this.axios.post<KoddiAPIResponse<any>>(
            `v1/member_groups/${memberGroupId}/users_report`,
            {
                pagination: {
                    start: params?.start || 0,
                    count: params?.count || 20,
                },
                filters,
                ...(id
                    ? {
                          sort: [
                              {
                                  field: id,
                                  ...(direction ? { order: direction } : {}),
                              },
                          ],
                      }
                    : {}),
            }
        );
        return response.data.result.users || [];
    };

    /** Gets the paginated list of users report in the DB. */
    public getUsersByAdvertiserReport = async (
        clientId: number,
        advertiserId: number,
        params?: KoddiListFilterParams,
        filters?: ReportFilter[]
    ): Promise<any> => {
        const [id, direction] = params?.sort?.split(' ') ?? [];
        const response = await this.axios.post<KoddiAPIResponse<any>>(
            `v1/member_groups/${clientId}/advertisers/${advertiserId}/users_report`,
            {
                pagination: {
                    start: params?.start || 0,
                    count: params?.count || 20,
                },
                filters,
                ...(id
                    ? {
                          sort: [
                              {
                                  field: id,
                                  ...(direction ? { order: direction } : {}),
                              },
                          ],
                      }
                    : {}),
            }
        );
        return response.data.result.users;
    };

    // Design huddle passthrough
    public getDesignHuddleToken = async (
        memberGroupId: number
    ): Promise<string> => {
        const response = await this.axios.post<
            KoddiAPIResponse<{ token: string }>
        >(
            `${window.API_ROUTE}v1/member_groups/${memberGroupId}/assets/authenticate`,
            {}
        );
        return response.data.result.token;
    };

    // Design huddle share passthrough
    public shareDesignHuddleProject = async ({
        memberGroupId,
        project_id,
    }: {
        memberGroupId: number;
        project_id: string;
    }): Promise<string> => {
        const response = await this.axios.post<
            KoddiAPIResponse<{ status: string }>
        >(`${window.API_ROUTE}v1/member_groups/${memberGroupId}/assets/share`, {
            project_id,
        });
        return response.data.status;
    };

    // SWR doesnt have a great way to handle query params so we need to use axios directly
    public setUserNotificationPreference = async (
        userId: number,
        queryParams: string
    ): Promise<void> => {
        await this.axios.put(`v1/users/${userId}/notifications/${queryParams}`);
    };
}

export default KoddiUserAPI;
