import { initialDateRange } from 'app/koddi-components/src/DateRangePicker';

// original slices
export const initialContextSlices = {
    campaign: { metrics: [], filters: [], dimensions: [] },
    ad_group: { metrics: [], filters: [] },
    media_plan: { metrics: [], filters: [] },
    entity: { metrics: [], filters: [] },
    ad_group_entity: { metrics: [], filters: [] },
    general: { metrics: [], filters: [], dimensions: [] },
};

// new slices, that include dateRange
export const newContextSlices = {
    saved: {
        dateRange: initialDateRange,
        metrics: [],
        filters: [],
        dimensions: [],
    },
    review: {
        dateRange: initialDateRange,
        metrics: [],
        filters: [],
        dimensions: [],
    },
    simple: {
        dateRange: initialDateRange,
        metrics: [],
        filters: [],
        dimensions: [],
    },
    offsite: {
        dateRange: initialDateRange,
        metrics: [],
        filters: [],
        dimensions: [],
    },
};
