import { Box, Tooltip } from '@chakra-ui/react';
import React, { FunctionComponent } from 'react';
import { Colors } from '../ChakraTheme/configs/Colors.config';
import { Opacity } from '../ChakraTheme/configs/Opacity.config';

interface CDisableComponentOverlayProps {
    showOverlay: boolean;
    tooltip?: string;
}

export const CDisableComponentOverlay: FunctionComponent<CDisableComponentOverlayProps> = ({
    showOverlay,
    tooltip,
    children,
}) => {
    return (
        <Box position="relative">
            {children}
            {showOverlay && (
                <Tooltip isDisabled={!tooltip} label={tooltip}>
                    <Box
                        data-test="disable-component-screen"
                        cursor="not-allowed"
                        minWidth="100%"
                        minHeight="100%"
                        position="absolute"
                        top="0px"
                        left="0px"
                        opacity={Opacity.Moderate}
                        background={Colors.disabled}
                    />
                </Tooltip>
            )}
        </Box>
    );
};
