import { AxiosInstance } from 'axios';

abstract class KoddiObjectAPI {
    protected axios: AxiosInstance;

    constructor(axios: AxiosInstance) {
        this.axios = axios;
    }

    abstract create(...args: any[]): Promise<any>;

    abstract get(...args: any[]): Promise<any>;

    abstract delete(...args: any[]): Promise<any>;

    abstract update(...args: any[]): Promise<any>;
}

export default KoddiObjectAPI;
