import Button from 'koddi-components/Button';
import styled, { css } from 'styled-components';
import { StyledButtonProps } from 'koddi-components/Button/button.types';

export const DateRangeButton = styled(Button)<
    StyledButtonProps & {
        dropdownOpen: boolean;
        btnHeight?: number;
        maxWidth?: string;
        minWidth: string;
    }
>`
    ${({ btnHeight, maxWidth, minWidth }) => css`
        position: relative;
        width: 100%;
        white-space: nowrap;
        height: ${btnHeight ? `${btnHeight}px` : 'initial'};}}
        ${maxWidth && `max-width: ${maxWidth};`}
        ${minWidth && `min-width: ${minWidth};`}
    `}
`;
