import { ApplicationRootState } from 'redux-core/types';
import { createSelector } from 'reselect';
import { ExperiencesState } from './types';

const selectExperiencesState = (
    state: ApplicationRootState
): ExperiencesState => state.admin.experiences;

export const selectMemberGroupExperiences = createSelector(
    selectExperiencesState,
    (state: ExperiencesState) => state.memberGroupExperiences
);

export const selectAdvertiserExperiences = createSelector(
    selectExperiencesState,
    (state: ExperiencesState) => state.advertiserExperiences
);

export const selectSelectedExperienceDetails = createSelector(
    selectExperiencesState,
    (state: ExperiencesState) => state.selectedExperienceDetails
);
