import {
    KoddiLocaleKey,
    KODDI_LOCALE_TO_DATEFNS,
} from 'koddi-components/LocaleProvider';

export function createLocaleMonths(): Record<KoddiLocaleKey, string[]> {
    const localeKeys = Object.keys(KODDI_LOCALE_TO_DATEFNS) as KoddiLocaleKey[];
    const months = new Array(12).fill(null).map((_, index) => index);
    return localeKeys.reduce((record, localeKey) => {
        const { localize } = KODDI_LOCALE_TO_DATEFNS[localeKey];
        return {
            ...record,
            [localeKey]: months.map((month) => localize?.month(month) ?? month),
        };
    }, {} as Record<KoddiLocaleKey, string[]>);
}

/** Determines if text should be white or black depeninding on the contrast of the background color.. */
export function getTextColor(bgColor: string): '#000000' | '#ffffff' {
    const color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
    const r = parseInt(color.substring(0, 2), 16); // hexToR
    const g = parseInt(color.substring(2, 4), 16); // hexToG
    const b = parseInt(color.substring(4, 6), 16); // hexToB
    const backgroundIsLight = r * 0.299 + g * 0.587 + b * 0.114 > 186;
    return backgroundIsLight ? '#000000' : '#ffffff';
}
