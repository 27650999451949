import React from 'react';
import { SingleSelectFieldProps } from '../SelectField.types';
import { CSelectFieldBase } from '../SelectField.component';

export const CSelectField = <T extends string | number = string>({
    ...props
}: SingleSelectFieldProps<T>): JSX.Element => <CSelectFieldBase {...props} />;

export const CVirtualizedSelectField = <T extends string | number = string>({
    ...props
}: SingleSelectFieldProps<T>): JSX.Element => (
    <CSelectFieldBase {...props} isVirtualized />
);
