import { PaymentMethod } from 'react-ui/api/Advertiser/Advertiser.types';
import {
    AdvertiserPaymentMethodAction,
    LoadPaymentMethodParams,
    LoadAdvertiserPaymentMethodsAction,
    DeletePaymentMethodParams,
    DeletePaymentMethodActionType,
    AddPaymentMethodFormAction,
    SetDefaultPaymentMethodParams,
    SetDefaultPaymentMethodActionType,
    SetDefaultBillingAddressActionType,
    SetOtherBillingAddressActionType,
    SetSelectedBillingAddressParams,
    SetSelectedBillingAddressActionType,
    StripeBillingAddressWithCustomer,
    AddressModalAction,
    EditPaymentModalAction,
    ShowAddressModalActionType,
    ShowAddressModalParams,
    StripeBillingAddressWithName,
} from './types';

export const showAddPaymentModal = (): any => ({
    type: 'show_add_payment_modal',
    payload: {
        showModal: true,
    },
});

export const closeAddPaymentModal = (): any => ({
    type: 'close_add_payment_modal',
    payload: {
        showModal: false,
    },
});

export const showEditPaymentModal = (paymentMethodId: string): any => ({
    type: EditPaymentModalAction.SHOW_MODAL,
    payload: {
        showModal: true,
        paymentMethodId,
    },
});

export const closeEditPaymentModal = (): any => ({
    type: EditPaymentModalAction.CLOSE_MODAL,
    payload: {
        showModal: false,
        paymentMethodId: null,
    },
});

export const showBillingAddressModal = (
    payload: ShowAddressModalParams
): ShowAddressModalActionType => {
    return {
        type: AddressModalAction.SHOW_MODAL,
        payload: {
            ...payload,
            showModal: true,
        },
    };
};
type CloseAddressModalProps = {
    address?: StripeBillingAddressWithName;
};
export const closeBillingAddressModal = (
    address?: CloseAddressModalProps
): any => {
    return {
        type: AddressModalAction.CLOSE_MODAL,
        payload: {
            showModal: false,
            name: null,
            address,
        },
    };
};

export const setAddFundsAmount = (amount: string | number): any => ({
    type: 'set_add_funds_amount',
    payload: {
        amount,
    },
});

export const LoadAdvertiserPaymentMethods = (
    payload: LoadPaymentMethodParams
): LoadAdvertiserPaymentMethodsAction => ({
    type: AdvertiserPaymentMethodAction.FETCH,
    payload,
});

export const PaymentsLoadedSuccessAction = (payload: PaymentMethod[]): any => ({
    type: AdvertiserPaymentMethodAction.LOADED,
    payload,
});

export const ClearPaymentMethodsAction = (): any => ({
    type: AdvertiserPaymentMethodAction.CLEAR,
});

export const DeletePaymentMethodAction = (
    payload: DeletePaymentMethodParams
): DeletePaymentMethodActionType => ({
    type: AdvertiserPaymentMethodAction.DELETE_PAYMENT_METHOD,
    payload,
});

export const setDefaultPaymentMethod = (
    payload: SetDefaultPaymentMethodParams
): SetDefaultPaymentMethodActionType => ({
    type: AddPaymentMethodFormAction.SET_DEFAULT_PAYMENT_METHOD,
    payload,
});

export const setDefaultBillingAddress = (
    payload: StripeBillingAddressWithCustomer
): SetDefaultBillingAddressActionType => ({
    type: AddPaymentMethodFormAction.SET_DEFAULT_BILLING_ADDRESS,
    payload,
});

export const setOtherBillingAddress = (
    payload: StripeBillingAddressWithCustomer | null
): SetOtherBillingAddressActionType => ({
    type: AddPaymentMethodFormAction.SET_OTHER_BILLING_ADDRESS,
    payload,
});

export const resetOtherBillingAddress = (): SetOtherBillingAddressActionType => ({
    type: AddPaymentMethodFormAction.SET_OTHER_BILLING_ADDRESS,
    payload: null,
});

export const setSelectedBillingAddress = (
    payload: SetSelectedBillingAddressParams
): SetSelectedBillingAddressActionType => ({
    type: AddPaymentMethodFormAction.SET_SELECTED_BILLING_ADDRESS,
    payload,
});
